import React, { useContext } from "react";
import { css } from "emotion";
import { Button, Header, Grid, Card, Popup } from "semantic-ui-react";
import { UserContext } from "../../User";
import { withRouter } from "react-router-dom";
import { getRequest } from "../../../helpers/ApiHelpers";
import { Highlight } from "../../search/Highlight";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import { Popup as CustomPopup } from "../../Popup";

function ManualCard({ manual, deleteManual }) {
  const { user } = useContext(UserContext);

  async function download(id) {
    const result = await getRequest(`/manual/downloadManual?id=${id}`);
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  async function email(id) {
    const result = await getRequest(
      `/manual/emailManual?id=${id}&senderEmail=${user.mail}&displayName=${user.displayName}`
    );
    if (result.success) {
      convertToFileDownload(result.data.msgFile, result.data.name);
    }
  }

  return (
    <Card.Group itemsPerRow={1}>
      <Card>
        <Card.Content>
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              <Grid.Column floated="left">
                {manual.note ? (
                  <CustomPopup
                    on={["hover", "focus"]}
                    header={"Note"}
                    content={manual.note}
                    trigger={
                      <Header
                        as="h3"
                        className={css({
                          color: "#47517b !important",
                          fontSize: "20px !important",
                          marginBottom: "0px !important",
                        })}
                      >
                        <Highlight children={manual.name} />
                        <i
                          style={{
                            marginLeft: 32,
                            top: 2,
                            position: "relative",
                            color: "#c2c5d3",
                            cursor: "pointer",
                          }}
                          className="fas fa-comment-alt-lines noteTrigger"
                        />
                      </Header>
                    }
                  />
                ) : (
                  <Header
                    as="h3"
                    className={css({
                      color: "#47517b !important",
                      fontSize: "20px !important",
                      marginBottom: "0px !important",
                    })}
                  >
                    <Highlight children={manual.name} />
                  </Header>
                )}
              </Grid.Column>
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                <Popup
                  size="tiny"
                  content="Download"
                  trigger={
                    <Button
                      style={{ position: "relative" }}
                      icon
                      onClick={() => download(manual.id)}
                    >
                      <span
                        className={css({
                          "& .fa-arrow-to-bottom:before": {
                            fontSize: 18,
                            position: "absolute",
                            top: 9,
                            left: 12,
                          },
                        })}
                      >
                        <i className="fal fa-arrow-to-bottom" />
                      </span>
                    </Button>
                  }
                />
                <Popup
                  size="tiny"
                  content="Email"
                  trigger={
                    <Button icon onClick={() => email(manual.id)}>
                      <i className="fal fa-share" />
                    </Button>
                  }
                />
                {(user.isOwner || user.isOwnerPlus) && (
                  <Popup
                    size="tiny"
                    content="Delete"
                    trigger={
                      <Button icon onClick={() => deleteManual()}>
                        <i className="fal fa-trash-alt" />
                      </Button>
                    }
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </Card.Group>
  );
}

export default withRouter(ManualCard);
