import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { css } from "emotion";
import { Icon } from "semantic-ui-react";
import { postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { Highlight } from "../../search/Highlight";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import { getRequest } from "../../../helpers/ApiHelpers";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  fontWeight: 300
});

const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 8
});

const DragHandle = SortableHandle(() => (
  <span
    className={css({
      position: "absolute",
      right: 16,
      top: 31,
      color: "#c3c3c3",
      cursor: "grab"
    })}
  >
    <i className="fal fa-sort" />
  </span>
));

function DragSortNotes({ items, saveSortPath, editNotePath, history, draggable, editable, deleteAction, downloadUrl }) {
  const [state, setState] = useState({
    items,
    sorted: false
  });
  const [initialized, setInitialized] = useState(false);

  async function saveSort() {
    postRequest(saveSortPath, state.items);
  }

  async function download(id) {
    const result = await getRequest(`${downloadUrl}?id=${id}`);
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  const SortableItem = SortableElement(({ value }) => (
    <Card.Group itemsPerRow={1} key={value.id}>
      <Card
        style={{
          marginLeft: 0,
          marginRight: 0,
          backgroundColor: value.highlight ? "#00b7d326" : null
        }}
      >
        <Card.Content>
          <label
            className={css({
              color: "#707898",
              fontSize: 14,
              fontFamily: "Fakt-Bold",
              lineHeight: "17px"
            })}
          >
            {value.subject ? <Highlight children={value.subject} /> : "Note"}
          </label>
          <p className={paragraphStyles}>
            <Highlight children={value.note} />
          </p>
          {value.files && value.files.length > 0 && (
            <React.Fragment>
              {value.files &&
                value.files.map(f => (
                  <p className={paragraphStyles} key={f.id}>
                    <a
                      onClick={() => download(f.id)}
                      className={css({
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#00b8d4"
                        }
                      })}
                    >
                      <i
                        className="fal fa-paperclip"
                        style={{
                          color: "#00b8d4",
                          marginRight: 8
                        }}
                      />
                      <Highlight children={f.fileName} />
                    </a>
                  </p>
                ))}
            </React.Fragment>
          )}
        </Card.Content>
        <React.Fragment>
          {draggable && editable && <DragHandle />}
          {editable && (
            <React.Fragment>
              <span
                className={css({
                  "&:hover i": { color: "#00b8d4" },
                  cursor: "pointer",
                  position: "absolute",
                  right: 36,
                  top: 31
                })}
                onClick={() => history.push(`${editNotePath}/${value.id}`)}
              >
                <Icon className="fal fa-pen" />
              </span>
              {deleteAction && (
                <span
                  className={css({
                    "&:hover i": { color: "#00b8d4" },
                    cursor: "pointer",
                    position: "absolute",
                    right: 56,
                    top: 31
                  })}
                  onClick={() => deleteAction(value)}
                >
                  <Icon className="fal fa-trash" />
                </span>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </Card>
    </Card.Group>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem key={`item-${value.id}-${index}`} index={index} value={value} />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState(state => ({
      ...state,
      sorted: true,
      items: arrayMove(state.items, oldIndex, newIndex).map((n, i) => ({
        ...n,
        sortNum: i
      }))
    }));
  };

  useEffect(() => {
    if (initialized && state.sorted) {
      saveSort(state.items);
    }
  }, [state.items, initialized]);

  useEffect(() => {
    setState(state => ({ ...state, items: items }));
    setInitialized(true);
  }, [items]);

  return <SortableList items={state.items} onSortEnd={onSortEnd} useDragHandle />;
}

export default withRouter(DragSortNotes);
