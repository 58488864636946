import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { css } from "emotion";
import { getRequest } from "../../../helpers/ApiHelpers";
import { Button, Grid, Message } from "semantic-ui-react";
import { StateContext } from "../../State";
import { UserContext } from "../../User";
import shortid from "shortid";
import CalculatorCard from "./CalculatorCard";
import PremiumCard from "./PremiumCard";

function Calculators({ history, match: { params } }) {
  const { selectedState } = useContext(StateContext);
  const { user } = useContext(UserContext);
  const [state, setState] = useState({
    premium: {},
    calculators: {},
    initialLoading: true
  });

  async function getLatestCalculators() {
    const result = await getRequest("/premiumCalculator/getLatestCalculators");
    if (result.success) {
      setState(state => ({
        ...state,
        calculators: result.data
      }));
    }
  }

  async function getPremiumByState(stateId) {
    const result = await getRequest(`/premiumCalculator/getPremiumByState`, {
      stateId
    });
    if (result.success) {
      setState(state => ({
        ...state,
        premium: result.data
      }));
    }
  }

  useEffect(() => {
    getPremiumByState(selectedState);
    getLatestCalculators();
  }, []);

  useEffect(() => {
    getPremiumByState(selectedState);
  }, [selectedState]);

  return (
    <React.Fragment>
      <Grid>
        <Grid.Row columns={2} verticalAlign="middle">
          {user.isOwnerPlus && (
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                icon
                className="blueButton"
                onClick={() => history.push(`/rates/new-calculator`)}
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() => history.push(`/rates/new-calculator`)}
              >
                Add/Update Calculator
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <div className={css({ marginBottom: 19.75, marginTop: 28 })}>
        <PremiumCard
          premium={state.premium}
          editRoute={`/rates/edit-premium`}
        />
        {state.calculators.length > 0 ? (
          state.calculators.map(c => (
            <CalculatorCard calculator={c} key={shortid.generate()} />
          ))
        ) : (
          <Message size="big">No available calculators...</Message>
        )}
      </div>
    </React.Fragment>
  );
}

export default withRouter(Calculators);
