import React, { useEffect, useContext } from "react";
import { css } from "emotion";
import { Dropdown } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "./State";

function StateDropdown({
  match: { params },
  pushHistory,
  search,
  noStateAble
}) {
  const {
    selectedState,
    updateNoStateAndSelectedState,
    noState,
    updateNoState,
    states
  } = useContext(StateContext);

  const stateList = noStateAble
    ? [{ id: "no_state", name: "All" }, ...states]
    : states;

  useEffect(() => {
    if (+params.state) {
      updateNoStateAndSelectedState(false, +params.state);
    }
    if (params.state === "no_state") {
      updateNoState(true);
    }
  }, []);

  return (
    <React.Fragment>
      {!search && (
        <span
          className={css({
            fontSize: 24,
            marginRight: 16,
            top: 3,
            position: "relative"
          })}
        >
          State of
        </span>
      )}
      <Dropdown
        placeholder="State"
        search
        selection
        floating
        button
        onChange={(e, { value }) => {
          pushHistory(value);
          if (value === "no_state") {
            updateNoState(true);
          } else {
            updateNoStateAndSelectedState(false, value);
          }
        }}
        text={
          states.length > 0
            ? noState
              ? "All"
              : stateList.find((s) => s.id === +selectedState)?.name
            : ""
        }
        options={stateList.map((s) => ({
          key: s.id,
          text: s.name,
          value: s.id
        }))}
        className={css({
          backgroundColor: "#47517b !important",
          color: "#fff !important",
          opacity: 1,
          fontSize: "1.05rem !important",
          marginRight: 8,
          "&.ui.search.dropdown.active > input.search, .ui.search.dropdown.visible > input.search": {
            color: "#fff !important"
          },
          "&.ui.active.search.dropdown input.search:focus + .text, &.ui.dropdown:not(.button) > .default.text, .ui.default.dropdown:not(.button) > .text": {
            color: "#fff !important"
          }
        })}
      />
    </React.Fragment>
  );
}

export default withRouter(StateDropdown);
