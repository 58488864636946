import React, { useState, useEffect, useContext, useMemo } from "react";
import { Dropdown, Grid, Button, Modal, Divider, Header } from "semantic-ui-react";
import { css } from "emotion";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import { withRouter } from "react-router-dom";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { useToasts } from "react-toast-notifications";
import { linkStyles } from "./linkStyles";
import DragSortNotes from "./DragSortNotes";
import DeleteNoteAlert from "./DeleteNoteAlert";
import BIGuidelinesTable from "./BIGuidelinesTable";
import getPAStateId from "./getPAStateId";

function BIGuidelinesToOmit({ history, match, searchResults }) {
  // stewart is the default
  const defaultUnderwiter = 6;
  const { user } = useContext(UserContext);
  const { addToast } = useToasts();
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  const searchUnderwriters =
    searchResults && searchResults[0] != undefined
      ? searchResults
          .reduce((pre, curr) => {
            return pre.concat(curr.biGuidelinesToOmits);
          }, [])
          .reduce((p, b) => {
            const foundValue = p.find(v => v.value === b.underwriterId);
            if (foundValue) {
              return p;
            }
            return [
              ...p,
              {
                key: b.underwriterId,
                value: b.underwriterId,
                text: b.underwriterName
              }
            ];
          }, [])
      : [{}];

  const [state, setState] = useState({
    scheduleSections: [],
    otherExceptions: searchResults || [],
    underwriters: searchUnderwriters,
    selectedUnderwriter: searchResults
      ? searchUnderwriters[0]?.value
      : match.params.underwriterId !== "undefined"
      ? +match.params.underwriterId
      : defaultUnderwiter,
    selectedSection: +match.params.category || 1,
    otherExceptionToDelete: null
  });

  const [policyNotes, setPolicyNotes] = useState([]);
  const [noteToDelete, setNoteToDelete] = useState(null);

  const [codeCategories, setCategories] = useState([]);
  const [codes, setCodes] = useState([]);
  const [otherExceptions, setOtherExceptions] = useState([]);

  let stateId = getPAStateId(selectedState, state.selectedUnderwriter);

  async function getCodeCategories() {
    const result = await getRequest(`/codeCategory/getCodeCategories`);
    if (result.success) {
      setCategories([...result.data, { id: null, name: "Other" }]);
    }
  }

  async function getScheduleSections() {
    const result = await getRequest("/scheduleBII/getScheduleSections");
    if (result.success) {
      setState(state => ({
        ...state,
        scheduleSections: result.data
      }));
    }
  }

  async function getStateUnderwriters() {
    const result = await getRequest(`/underwriter/getStateUnderwriters?id=${+match.params.state || 31}`);
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data.map(u => ({
          key: u.id,
          value: u.id,
          text: u.name
        }))
      }));
    }
  }

  async function getPolicyBINotesByState() {
    if (!state.selectedUnderwriter) {
      return;
    }
    const result = await getRequest("/bIGuidelinesToOmit/getPolicyBINotesByState", {
      stateId,
      underwriterId: state.selectedUnderwriter
    });
    if (result.success) {
      setPolicyNotes(result.data);
    }
  }

  async function getBIGuidelinesToOmitByState() {
    const result = await getRequest(`/bIGuidelinesToOmit/getBIGuidelinesToOmitByState`, {
      stateId
    });
    if (result.success) {
      setCodes(result.data);
    }
  }

  async function handleDeleteNote() {
    const result = await postRequest(`/bIGuidelinesToOmit/deletePolicyBINote?id=${noteToDelete.id}`);
    if (result.success) {
      setPolicyNotes(notes => notes.filter(n => n.id != noteToDelete.id));
      setNoteToDelete(null);
    }
  }

  useEffect(() => {
    if (!searchResults) {
      getStateUnderwriters();
      getBIGuidelinesToOmitByState();
      setState({
        ...state,
        initialLoading: !selectedState
      });
    }
  }, [selectedState, state.selectedUnderwriter]);

  useEffect(() => {
    getPolicyBINotesByState();
  }, [state.selectedUnderwriter]);

  const setSelectedUnderwriter = () => {
    const paramUnderwriter = +match.params.underwriterId;
    if (paramUnderwriter === "undefined") {
      history.push(
        `/policy-instructions/states/${selectedState}/sections/bi/categories/${state.selectedSection}/underwriters/${defaultUnderwiter}`
      );
    }
    const paramUndewriterExists = state.underwriters.some(u => u.value === paramUnderwriter);
    const stewartExists = state.underwriters.some(u => u.text === "Stewart");

    if (paramUndewriterExists) {
      setState({ ...state, selectedUnderwriter: paramUnderwriter });
    } else if (stewartExists) {
      var stewartId = state.underwriters.find(u => u.text === "Stewart").value;
      setState({ ...state, selectedUnderwriter: stewartId });
      history.push(
        `/policy-instructions/states/${selectedState}/sections/bi/categories/${state.selectedSection}/underwriters/${stewartId}`
      );
    } else {
      var firstUnderwiter = state.underwriters[0]?.value;
      setState({ ...state, selectedUnderwriter: firstUnderwiter });
      history.push(
        `/policy-instructions/states/${selectedState}/sections/bi/categories/${state.selectedSection}/underwriters/${firstUnderwiter}`
      );
    }
  };

  useEffect(() => {
    if (!searchResults) {
      setSelectedUnderwriter();
    }
  }, [state.underwriters, match.params.underwriterId]);

  useEffect(() => {
    setState(state => ({
      ...state,
      selectedSection: +match.params.category || 1
    }));
  }, [match.params.category]);

  const codesToRender = searchResults ? searchResults : codes;

  async function deleteOtherException(id) {
    const result = await postRequest(`/bIGuidelinesToOmit/deleteOtherException?id=${id}`, id);
    if (result.success) {
      setState(state => ({
        ...state,
        otherExceptionToDelete: null
      }));
      setOtherExceptions(otherExceptions => otherExceptions.filter(e => e.id !== id));
    }
  }

  async function getOtherExceptionsByIds(ids) {
    const result = await getRequest(`/bIGuidelinesToOmit/getOtherExceptionsByIds`, {
      ids
    });
    return result.data;
  }

  const getAndSetOtherExceptionsByIds = async () => {
    if (searchResults.length > 0) {
      const codeIds = searchResults.map(sr => +sr?.id).filter(c => c);
      const otherExceptionCodes = await getOtherExceptionsByIds(codeIds);
      setOtherExceptions(otherExceptionCodes);
    } else {
      setOtherExceptions([]);
    }
  };

  async function getOtherExceptionsByState() {
    const result = await getRequest(`/bIGuidelinesToOmit/getOtherExceptionsByState`, {
      stateId
    });
    return result.data;
  }

  const getAndSetOtherExceptionsByState = async () => {
    const otherExceptionCodes = await getOtherExceptionsByState();
    const filteredByUnderwriter = otherExceptionCodes.filter(e =>
      e.biGuidelinesToOmits.some(b => b.underwriterId === state.selectedUnderwriter)
    );
    setOtherExceptions(filteredByUnderwriter);
  };

  useEffect(() => {
    if (searchResults) {
      getAndSetOtherExceptionsByIds();
    } else {
      getAndSetOtherExceptionsByState();
    }
  }, [searchResults, state.selectedUnderwriter]);

  useEffect(() => {
    getCodeCategories();
    getScheduleSections();
  }, []);

  const codesAndSections = [
    {
      section: "Template",
      isOther: false,
      codes: codeCategories
        .filter(category =>
          codesToRender.filter(c => c && c.scheduleSectionId === 2).some(c => c.codeCategoryId === category.id)
        )
        .map(category => ({
          category: category.name,
          isOther: false,
          codes: codesToRender.filter(c => c && c.scheduleSectionId === 2 && c.codeCategoryId === category.id)
        }))
    },
    {
      section: "Other Exceptions",
      codes: [
        {
          category: "Other Exceptions",
          isOther: true,
          codes: otherExceptions
        }
      ]
    },
    {
      section: "Specifics",
      codes: codeCategories
        .filter(category =>
          codesToRender.filter(c => c && c.scheduleSectionId === 1).some(c => c.codeCategoryId === category.id)
        )
        .map(category => ({
          category: category.name,
          isOther: false,
          codes: codesToRender.filter(c => c && c.scheduleSectionId === 1 && c.codeCategoryId === category.id)
        }))
    }
  ];

  return (
    <div
      className={css({
        "& .highlight, & .highlight:nth-of-type(2n)": {
          backgroundColor: "#00b7d326 !important"
        }
      })}
    >
      <Grid style={{ marginBottom: 17 }}>
        {!searchResults && (
          <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
            <Grid.Column
              floated="left"
              className={css({
                textAlign: "left",
                color: "#47517B",
                fontSize: 20,
                fontFamily: "Fakt-Bold"
              })}
            >
              BI{" "}
              <Dropdown
                size="large"
                selection
                placeholder="Select Underwriter"
                style={{ fontSize: 16, marginLeft: 16, marginRight: 16 }}
                options={state.underwriters}
                value={state.selectedUnderwriter}
                onChange={(_, { value }) =>
                  !searchResults
                    ? history.push(
                        `/policy-instructions/states/${selectedState}/sections/bi/categories/${state.selectedSection}/underwriters/${value}`
                      )
                    : setState(state => ({
                        ...state,
                        selectedUnderwriter: value
                      }))
                }
              />{" "}
            </Grid.Column>
            {userCanEdit && !searchResults && state.selectedUnderwriter && (
              <Grid.Column floated="right" className={css({ textAlign: "right" })}>
                <span
                  className={linkStyles}
                  onClick={() =>
                    history.push(
                      `/policy-instructions/states/${selectedState}/underwriters/${state.selectedUnderwriter}/new-policy-bi-note`
                    )
                  }
                >
                  Add Note
                </span>
                <Button
                  icon
                  className="blueButton"
                  onClick={() =>
                    history.replace(
                      `/policy-instructions/states/${selectedState}/underwriters/${state.selectedUnderwriter}/new-other-exception`
                    )
                  }
                >
                  <i className="fal fa-plus" />
                </Button>
                <span
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 14,
                    color: "#000",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00b8d4"
                    }
                  })}
                  onClick={() =>
                    history.push(
                      `/policy-instructions/states/${selectedState}/underwriters/${state.selectedUnderwriter}/new-other-exception`
                    )
                  }
                >
                  New Other Exception
                </span>
              </Grid.Column>
            )}
          </Grid.Row>
        )}
      </Grid>
      {policyNotes && (
        <DragSortNotes
          items={policyNotes.map(n => ({
            ...n,
            files: n.files
          }))}
          downloadURI="/bIGuidelinesToOmit/downloadPolicyBINoteFile"
          saveSortPath="/bIGuidelinesToOmit/sortNotes"
          draggable={userCanEdit}
          deleteAction={value => setNoteToDelete(value)}
          userCanEdit={userCanEdit}
          editNotePath={`/policy-instructions/edit-policy-bi-note`}
        />
      )}
      {codesAndSections.map(
        cs =>
          cs.codes.length > 0 &&
          cs.codes.some(c => c.codes.length > 0) && (
            <React.Fragment>
              <Divider horizontal className={css({ marginTop: "38px !important" })}>
                <Header as="h4">{cs.section}</Header>
              </Divider>
              {cs.codes.map(c => (
                <BIGuidelinesTable
                  code={c}
                  searchResults={searchResults}
                  addToast={addToast}
                  userCanEdit={userCanEdit}
                  state={state}
                  history={history}
                  selectedState={selectedState}
                  setState={setState}
                />
              ))}
            </React.Fragment>
          )
      )}
      {state.otherExceptionToDelete && (
        <Modal
          size={"tiny"}
          open={state.otherExceptionToDelete !== null}
          onClose={() => setState(state => ({ ...state, otherExceptionToDelete: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Delete Exception</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete exception <strong>{state.otherExceptionToDelete.code}</strong>?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState(state => ({
                  ...state,
                  otherExceptionToDelete: null
                }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteOtherException(state.otherExceptionToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {noteToDelete && <DeleteNoteAlert onClose={() => setNoteToDelete(null)} deleteNote={handleDeleteNote} />}
    </div>
  );
}

export default withRouter(BIGuidelinesToOmit);
