import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Dropdown, Checkbox, Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";
import getPAStateId from "./getPAStateId";

export function EditAddBIGuidelinesToOmit({ match, history }) {
  const stateId = getPAStateId(+match.params.state, +match.params.underwriterId);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    guidelinesToOmit: {
      underwriterId: +match.params.underwriterId,
      guidelinesToOmit: undefined,
      scheduleBIICodeId: +match.params.scheduleId
    },
    underwriters: [],
    selectedUnderwriters: [+match.params.underwriterId],
    editMode: match.path.includes("edit"),
    errorList: [],
    confirmEdit: null,
    allUnderwriters: false,
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: false,
    codeStates: [],
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    editAllStates: false,
    selectedStates: [stateId]
  });
  const [highlightedStates, setHighlightedStates] = useState([]);
  const [editNonHighlightedStates, setEditNotHighlightedStates] = useState(false);

  async function getBIGuidelinesToOmitById(underwriterId, scheduleBIICodeId) {
    const result = await getRequest(`/bIGuidelinesToOmit/getBIGuidelinesToOmitById`, {
      underwriterId,
      scheduleBIICodeId
    });
    if (result.success) {
      if (result.data) {
        setState(state => ({
          ...state,
          guidelinesToOmit: result.data
        }));
      } else {
        getScheduleBIICodeById(scheduleBIICodeId);
      }
    }
  }

  async function getHighlightedBIGuidelines(scheduleBIICodeId) {
    const result = await getRequest(`/bIGuidelinesToOmit/getHighlightedBIGuidelines`, {
      scheduleBIICodeId
    });
    if (result.success) {
      if (result.data) {
        setHighlightedStates(result.data);
      }
    }
  }

  async function getScheduleBIICodeById(scheduleBIICodeId) {
    const result = await getRequest(`/scheduleBII/GetScheduleBIICodeById`, {
      id: scheduleBIICodeId
    });
    if (result.success) {
      if (result.data) {
        setState(state => ({
          ...state,
          guidelinesToOmit: {
            ...state.guidelinesToOmit,
            scheduleBIICode: result.data
          }
        }));
      }
    }
  }

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data
          .filter(u => u.name !== "All Underwriters")
          .map(u => ({
            key: u.id,
            value: u.id,
            text: u.name
          }))
      }));
    }
  }

  async function getStateUnderwriters() {
    const result = await getRequest(`/underwriter/getStateUnderwriters?id=${+match.params.state || 31}`);
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data.map(u => ({
          key: u.id,
          value: u.id,
          text: u.name
        }))
      }));
    }
  }

  async function getStates(states) {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState(state => ({
        ...state,
        states: result.data
          .filter(d => states?.some(s => s === d.name))
          .map(s => ({
            key: s.id,
            value: s.id,
            text: s.name
          }))
      }));
    }
  }

  async function getCodeStates(id) {
    const result = await getRequest(`/scheduleBII/getCodeStates`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        codeStates: result.data
      }));
    }
  }

  useEffect(() => {
    getStates(state.codeStates);
  }, [state.codeStates]);

  useEffect(() => {
    if (state.editMode) {
      getCodeStates(match.params.scheduleId);
      getBIGuidelinesToOmitById(match.params.underwriterId, match.params.scheduleId);
      getHighlightedBIGuidelines(match.params.scheduleId);
    }
    getStateUnderwriters();
  }, []);

  async function saveAddChanges() {
    const result = await postRequest(`/bIGuidelinesToOmit/addBIGuidelinesToOmit`, {
      bIGuidelinesToOmit: state.guidelinesToOmit,
      underwriterIds: state.selectedUnderwriters
    });
    if (result.success) {
      logEvent(null, state.editMode ? "Edited" : "Added", "Guidelines To Omit");
      history.goBack();
    }
  }

  async function saveEditChanges() {
    setState(state => ({
      ...state,
      submitting: true
    }));
    let stateIds = [];
    if (editNonHighlightedStates) {
      const filteredCodeStates = state.codeStates.filter(cs => highlightedStates.some(hs => hs.name === cs) === false);
      stateIds = filteredCodeStates.map(fcs => state.states.find(s => s.text === fcs).value);
    } else {
      stateIds = state.customStates ? state.selectedStates : [stateId];
    }
    const result = await postRequest(`/bIGuidelinesToOmit/editBIGuidelinesToOmit`, {
      bIGuidelinesToOmit: state.guidelinesToOmit,
      underwriterIds: state.selectedUnderwriters,
      stateIds,
      editAllStates: state.editAllStates
    });
    if (result.success) {
      history.goBack();
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Guidelines to Omit`}
      </Header>
      <Form>
        <Grid>
          {stateId != 52 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <Checkbox
                    checked={state.allUnderwriters}
                    onChange={() =>
                      setState(state => ({
                        ...state,
                        allUnderwriters: !state.allUnderwriters,
                        selectedUnderwriters: !state.allUnderwriters
                          ? state.underwriters.map(u => u.value)
                          : state.selectedUnderwriters
                      }))
                    }
                    label="Select all Underwriters"
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Underwriters</label>
                  <Dropdown
                    size="large"
                    fluid
                    multiple
                    selection
                    placeholder="Select Underwriters"
                    style={{ fontSize: 16 }}
                    options={state.underwriters}
                    value={state.selectedUnderwriters}
                    onChange={(_, { value }) => {
                      setState(state => ({
                        ...state,
                        selectedUnderwriters: value
                      }));
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Guidelines to Omit</label>
                <TextArea
                  placeholder="Guidelines to Omit"
                  style={{ minHeight: 292 }}
                  value={state.guidelinesToOmit.guidelinesToOmit}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      guidelinesToOmit: {
                        ...state.guidelinesToOmit,
                        guidelinesToOmit: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Highlight Row
                  <Checkbox
                    value={!state.guidelinesToOmit?.highlight}
                    checked={state.guidelinesToOmit?.highlight}
                    onChange={() => {
                      setState(state => ({
                        ...state,
                        guidelinesToOmit: {
                          ...state.guidelinesToOmit,
                          highlight: !state.guidelinesToOmit?.highlight
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <Button size="large" className="cancelButton" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  setState(state => ({
                    ...state,
                    submitAttempted: true
                  }));
                  if (state.editMode && state.codeStates.length > 1 && state.errorList.length < 1) {
                    setState(state => ({ ...state, confirmEdit: true }));
                  } else {
                    state.editMode ? saveEditChanges() : saveAddChanges();
                  }
                }}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      {state.confirmEdit && (
        <Modal
          size={"tiny"}
          open={state.confirmEdit !== null}
          onClose={() => setState(state => ({ ...state, confirmEdit: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Edit Options</Modal.Header>
          <Modal.Content>
            <p className={css({ fontFamily: "Fakt-Bold" })}>
              Exception Code{" "}
              <span
                className={css({
                  color: "#767676",
                  textDecoration: "underline"
                })}
              >
                {state.guidelinesToOmit.scheduleBIICode.code}
              </span>{" "}
              found in the following states.
            </p>
            {state.codeStates.map(s => s).join(", ")}
            {highlightedStates.length > 0 && (
              <>
                <p className={css({ fontFamily: "Fakt-Bold", marginTop: 16 })}>
                  Exception Code{" "}
                  <span
                    className={css({
                      color: "#767676",
                      textDecoration: "underline"
                    })}
                  >
                    {state.guidelinesToOmit.scheduleBIICode.code}
                  </span>{" "}
                  is highlighted in the following states.
                </p>
                {highlightedStates.map(s => s?.name).join(", ")}
              </>
            )}
            <Form.Field className={css({ marginTop: 16 })}>
              <Checkbox
                label={"Edit " + state.guidelinesToOmit.scheduleBIICode.state.name}
                checked={!state.editAllStates && !state.customStates && !editNonHighlightedStates}
                size="large"
                onClick={() => {
                  setState(state => ({
                    ...state,
                    editAllStates: false,
                    customStates: false
                  }));
                  setEditNotHighlightedStates(false);
                }}
              />
            </Form.Field>
            {highlightedStates.length > 0 && (
              <Form.Field>
                <Checkbox
                  label={"Non-highlighted States"}
                  checked={editNonHighlightedStates}
                  size="large"
                  onClick={() => {
                    setState(state => ({
                      ...state,
                      editAllStates: false,
                      customStates: false
                    }));
                    setEditNotHighlightedStates(value => !value);
                  }}
                />
              </Form.Field>
            )}
            <Form.Field>
              <Checkbox
                label={"Edit All States"}
                checked={state.editAllStates}
                size="large"
                onClick={() => {
                  setState(state => ({
                    ...state,
                    editAllStates: true,
                    customStates: false
                  }));
                  setEditNotHighlightedStates(false);
                }}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label={"Custom States"}
                checked={state.customStates}
                size="large"
                onClick={() => {
                  setState(state => ({
                    ...state,
                    editAllStates: false,
                    customStates: true
                  }));
                  setEditNotHighlightedStates(false);
                }}
              />
            </Form.Field>
            {state.customStates && (
              <Form.Field required className={css({ marginTop: 8 })}>
                <label>States</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  multiple
                  placeholder="Select State"
                  style={{ fontSize: 16 }}
                  options={state.states}
                  value={state.selectedStates}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      selectedStates: value
                    }))
                  }
                />
              </Form.Field>
            )}
          </Modal.Content>
          <Modal.Actions>
            <React.Fragment>
              <Button
                negative
                onClick={() =>
                  setState(state => ({
                    ...state,
                    confirmEdit: null
                  }))
                }
                style={{
                  backgroundColor: "#fff",
                  border: "solid 1px #e5e5ea",
                  color: "rgba(0, 0, 0, 0.87)"
                }}
              >
                Cancel
              </Button>
              <Button
                positive
                onClick={() => saveEditChanges()}
                style={{ backgroundColor: "#1f2b5e" }}
                data-automation="confirmDelete"
              >
                Submit
              </Button>
            </React.Fragment>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

withRouter(EditAddBIGuidelinesToOmit);
