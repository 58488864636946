import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Loader } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../State";
import StateDropdown from "../StateDropdown";
import InvoiceCharges from "./invoiceCharges/InvoiceCharges";
import { Taxes } from "./taxes/Taxes";
import Manuals from "./manuals/Manuals";
import Premiums from "./premiums/Premiums";
import pathToRegexp from "path-to-regexp";
import shortid from "shortid";
import AgencyAgreements from "./agencyAgreements/AgencyAgreements";

function Rates({ history, match: { params }, match }) {
  const { selectedState } = useContext(StateContext);
  if (!params.state || params.state === "no_state") {
    history.replace(
      `/rates/states/${selectedState || 31}/types/invoice-charges`
    );
  }
  const [state, setState] = useState({
    selectedRateType: null,
    initialLoading: true,
    noResults: false,
    types: [
      { name: "Invoice Charges", path: "invoice-charges" },
      { name: "Taxes", path: "taxes" },
      { name: "Agency Agreements", path: "agency-agreements" },
      { name: "Premiums", path: "premiums/sections/op-endorsements" },
      { name: "Manuals", path: "manuals" },
    ],
  });

  useEffect(() => {
    const selectedType = params.type;
    setState({
      ...state,
      selectedRateType: selectedType || state.types[0].path,
      initialLoading: false,
    });
  }, [params.type]);

  return (
    <div>
      {state.initialLoading ? (
        <Loader active size="massive" />
      ) : (
        <div className={css({ paddingBottom: 40 })}>
          <StateDropdown
            pushHistory={(value) => {
              const toPath = pathToRegexp.compile(match.path);
              const newPath = toPath({ ...match.params, state: value });
              history.push(newPath);
            }}
          />
          <div className={css({ marginTop: 40, marginBottom: 40 })}>
            {state.types.map((t) => (
              <Button
                className="link"
                active={state.selectedRateType === t.path.split("/")[0]}
                onClick={() =>
                  history.push(`/rates/states/${selectedState}/types/${t.path}`)
                }
                key={shortid.generate()}
              >
                {t.name}
              </Button>
            ))}
          </div>
          {state.selectedRateType === "invoice-charges" && (
            <InvoiceCharges editable={true} sortable={true} />
          )}
          {state.selectedRateType === "taxes" && <Taxes />}
          {state.selectedRateType === "agency-agreements" && (
            <AgencyAgreements />
          )}
          {state.selectedRateType === "premiums" && <Premiums />}
          {state.selectedRateType === "manuals" && <Manuals />}
        </div>
      )}
    </div>
  );
}

export default withRouter(Rates);
