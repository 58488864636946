import React, { useState, useEffect, useContext } from "react";
import { Menu } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import Liens from "./Liens";
import AdditionalLiens from "./AdditionalLiens";

function LiensSection({ history, match: { params } }) {
  const { selectedState } = useContext(StateContext);
  
  const [state, setState] = useState({
    selectedSection: params.section || "liens"
  });

  useEffect(() => {
    setState({
      ...state,
      selectedSection: params.section || "liens",
      initialLoading: false
    });
  }, [params.section]);

  if (!selectedState) {
    return <div />;
  }

  return (
    <React.Fragment>
      <Menu tabular style={{ marginTop: 55, marginBottom: 32 }}>
        <Menu.Item
          style={{ marginLeft: 21 }}
          name="Liens"
          active={state.selectedSection === "liens"}
          onClick={() =>
            history.push(
              `/info/states/${selectedState}/types/liens/sections/liens`
            )
          }
        />
        <Menu.Item
          name="Additional Liens"
          active={state.selectedSection === "additional-liens"}
          onClick={() =>
            history.push(
              `/info/states/${selectedState}/types/liens/sections/additional-liens`
            )
          }
        />
      </Menu>
      {state.selectedSection === "liens" && <Liens editable={true} />}
      {state.selectedSection === "additional-liens" && <AdditionalLiens />}
    </React.Fragment>
  );
}

export default withRouter(LiensSection);
