import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Input, Checkbox, Message, Modal } from "semantic-ui-react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import shortid from "shortid";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import { logEvent } from "../../../helpers/analyticsLogger";

css({
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  marginBottom: 17,
  lineHeight: 1.71
});
export function EditAddScheduleBIICode({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    codeCategories: [],
    scheduleSections: [],
    newCategory: undefined,
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    code: { scheduleSectionId: +match.params.category, code: null },
    codeStates: [+match.params.state],
    editMode: match.path.includes("edit"),
    editAllStates: false,
    editCode: false,
    editWording: false,
    editNote: false,
    editCaseScenario: false,
    editHighlightRow: false,
    editTaskText: false,
    confirmEdit: null,
    originalCodeName: null,
    files: [],
    filesToDelete: []
  });

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState(state => ({
        ...state,
        states: result.data.map(s => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allLicensedStatesList: [52, ...result.data]
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function getScheduleBIICodeById(id) {
    const result = await getRequest(`/scheduleBII/getScheduleBIICodeById`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        code: result.data,
        originalCodeName: result.data.code
      }));
    }
  }

  async function getCodeStates(id) {
    const result = await getRequest(`/scheduleBII/getCodeStates`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        codeStates: result.data
      }));
    }
  }

  async function getScheduleSections() {
    const result = await getRequest("/scheduleBII/getScheduleSections");
    if (result.success) {
      setState(state => ({
        ...state,
        scheduleSections: result.data
      }));
    }
  }

  async function getCodeCategories() {
    const result = await getRequest(`/codeCategory/getCodeCategories`);
    if (result.success) {
      setState(state => ({
        ...state,
        codeCategories: [
          {
            key: null,
            value: null,
            text: "None"
          },
          ...result.data.map(c => ({
            key: c.id,
            text: c.name,
            value: c.id
          }))
        ]
      }));
    }
  }

  function getErrorList() {
    let list = [];
    if (state.code.code === "" || state.code.code === null) {
      list.push("You must provide a code.");
    }
    if (!state.codeStates.length > 0) {
      list.push("You must select at least one state.");
    }
    return list;
  }

  useEffect(() => {
    getScheduleSections();
    getCodeCategories();
    if (state.editMode) {
      getScheduleBIICodeById(match.params.id);
      getCodeStates(match.params.id);
    } else {
      getStates();
      getAllStatesList();
      getAllLicensedStatesList();
      getAllUnlicensedStatesList();
    }
  }, []);

  useEffect(() => {
    setState(state => ({
      ...state,
      customStates: !state.allLicensedStates && !state.allUnlicensedStates && !state.allStates
    }));
  }, [state.allStates, state.allUnlicensedStates, state.allLicensedStates]);

  useEffect(() => {
    setState(state => ({ ...state, errorList: getErrorList() }));
  }, [state.codeStates, state.code.code, state.submitAttempted]);

  useEffect(() => {
    getScheduleSections();
    getCodeCategories();
    if (state.editMode) {
      getScheduleBIICodeById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState(state => ({
        ...state,
        submitAttempted: true
      }));
      if (!state.errorList.length > 0) {
        setState(state => ({
          ...state,
          submitting: true
        }));
        let result;
        if (state.editMode) {
          result = await postRequest("/scheduleBII/editScheduleBIICode", {
            scheduleBIICode: {
              ...state.code
            },
            newCategory: state.newCategory,
            editAllStates: state.editAllStates,
            editCode: state.editCode,
            editNote: state.editNote,
            editWording: state.editWording,
            editCaseScenario: state.editCaseScenario,
            editHighlightRow: state.editHighlightRow,
            files: await Promise.all(
              state.files.map(async f => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            filesToDelete: state.filesToDelete
          });
        } else {
          result = await postRequest("/scheduleBII/addScheduleBIICodeToMultipleStates", {
            scheduleBIICode: {
              ...state.code
            },
            newCategory: state.newCategory,
            codeStates: state.codeStates,
            files: await Promise.all(
              state.files.map(async f => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            filesToDelete: state.filesToDelete
          });
        }
        if (result.success) {
          logEvent(null, state.editMode ? "Edited" : "Added", "Schedule BII Code");
          history.goBack();
        }
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {state.editMode ? "Edit" : "New"} Code
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                {state.scheduleSections.map(s => (
                  <Form.Field key={"section" + s.id}>
                    <Checkbox
                      label={s.name}
                      checked={state.code.scheduleSectionId === s.id}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          code: {
                            ...state.code,
                            scheduleSectionId: s.id
                          }
                        }))
                      }
                    />
                  </Form.Field>
                ))}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Code</label>
                <Input
                  placeholder="Code"
                  size="large"
                  value={state.code.code}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      editCode: true,
                      code: {
                        ...state.code,
                        code: value
                      }
                    }))
                  }
                  error={state.submitAttempted && (state.code.code === "" || state.code.code === null)}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Category</label>
                <Dropdown
                  size="large"
                  options={state.codeCategories}
                  placeholder="Choose Category"
                  search
                  selection
                  allowAdditions
                  style={{ fontSize: 16 }}
                  value={state.code.codeCategoryId}
                  onAddItem={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      newCategory: value,
                      codeCategories: [
                        ...state.codeCategories.filter(c => c.value !== 0),
                        {
                          key: 0,
                          text: value,
                          value: 0
                        }
                      ],
                      code: { ...state.code, codeCategoryId: 0 }
                    }))
                  }
                  onChange={(e, { value, text }) => {
                    setState({
                      ...state,
                      code: { ...state.code, codeCategoryId: value },
                      newCategory: text
                    });
                  }}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {state.code.scheduleSectionId === 1 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>Case Scenario</label>
                  <TextArea
                    placeholder="Case Scenario"
                    size="large"
                    value={state.code.caseScenario}
                    onChange={(_, { value }) =>
                      setState(state => ({
                        ...state,
                        editCaseScenario: true,
                        code: {
                          ...state.code,
                          caseScenario: value
                        }
                      }))
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Wording</label>
                <TextArea
                  placeholder="Wording"
                  style={{ minHeight: 196 }}
                  value={state.code.wording}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      editWording: true,
                      code: {
                        ...state.code,
                        wording: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 196 }}
                  value={state.code.note}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      editNote: true,
                      code: {
                        ...state.code,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Task Text</label>
                <Input
                  placeholder="Task Text"
                  value={state.code.taskText}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      editTaskText: true,
                      code: {
                        ...state.code,
                        taskText: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={files =>
                    setState(state => ({
                      ...state,
                      files: [...state.files, ...files]
                    }))
                  }
                  deleteFileToApp={fileName =>
                    setState(state => ({
                      ...state,
                      files: [...state.files.filter(f => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.code.files &&
                state.code.files.map(f => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        code: {
                          ...state.code,
                          files: [...state.code.files.filter(nf => nf.id !== f.id)]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i className="fal fa-trash-alt" style={{ marginRight: 8 }} />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          {!state.editMode && (
            <React.Fragment>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field>
                    <Checkbox
                      label="All states"
                      checked={state.allStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: true,
                          allLicensedStates: false,
                          allUnlicensedStates: false,
                          customStates: false,
                          codeStates: state.allStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="All licensed states"
                      checked={state.allLicensedStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: true,
                          allUnlicensedStates: false,
                          customStates: false,
                          codeStates: state.allLicensedStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="All unlicensed states"
                      checked={state.allUnlicensedStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: false,
                          allUnlicensedStates: true,
                          customStates: false,
                          codeStates: state.allUnlicensedStatesList
                        }))
                      }
                      data-automation="allUnlicensedStates"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="Custom (choose your own state selection)"
                      checked={state.customStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: false,
                          allUnlicensedStates: false,
                          customStates: true
                        }))
                      }
                    />
                  </Form.Field>
                  {state.customStates && (
                    <Form.Field required>
                      <label>States</label>
                      <Dropdown
                        size="large"
                        fluid
                        multiple
                        selection
                        placeholder="Select States"
                        style={{ fontSize: 16 }}
                        options={state.states}
                        value={state.codeStates}
                        onChange={(_, { value }) => {
                          setState(state => ({
                            ...state,
                            codeStates: value
                          }));
                        }}
                        error={state.submitAttempted && !state.codeStates.length > 0}
                      />
                    </Form.Field>
                  )}
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Highlight Row
                  <Checkbox
                    value={!state.code.highlight}
                    checked={state.code.highlight}
                    onChange={() => {
                      setState(state => ({
                        ...state,
                        editHighlightRow: true,
                        code: {
                          ...state.code,
                          highlight: !state.code.highlight
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <Button size="large" className="cancelButton" onClick={() => history.goBack()} data-automation="cancel">
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  setState(state => ({
                    ...state,
                    submitAttempted: true
                  }));
                  if (state.editMode && state.codeStates.length > 1 && state.errorList.length < 1) {
                    setState(state => ({ ...state, confirmEdit: true }));
                  } else {
                    saveChanges();
                  }
                }}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      {state.confirmEdit && (
        <Modal
          size={"tiny"}
          open={state.confirmEdit !== null}
          onClose={() => setState(state => ({ ...state, confirmEdit: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Edit Options</Modal.Header>
          <Modal.Content>
            <p className={css({ fontFamily: "Fakt-Bold" })}>
              Code{" "}
              <span
                className={css({
                  color: "#767676",
                  textDecoration: "underline"
                })}
              >
                {state.originalCodeName}
              </span>{" "}
              found in the following states.
            </p>
            {state.codeStates.map(s => s).join(", ")}
            <Form.Field className={css({ marginTop: 16 })}>
              <Checkbox
                label={"Edit " + state.code.state.name}
                checked={!state.editAllStates}
                size="large"
                onClick={() =>
                  setState(state => ({
                    ...state,
                    editAllStates: false
                  }))
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label={"Edit All States"}
                checked={state.editAllStates}
                size="large"
                onClick={() =>
                  setState(state => ({
                    ...state,
                    editAllStates: true
                  }))
                }
              />
            </Form.Field>
            {state.editAllStates && (
              <div className={css({ marginTop: 16 })}>
                <Form.Field>
                  <Checkbox
                    label={"Code"}
                    checked={state.editCode}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editCode: !state.editCode
                      }))
                    }
                  />
                </Form.Field>
                {state.code.scheduleSectionId === 1 && (
                  <Form.Field>
                    <Checkbox
                      label={"Case Scenario"}
                      checked={state.editCaseScenario}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          editCaseScenario: !state.editCaseScenario
                        }))
                      }
                    />
                  </Form.Field>
                )}
                <Form.Field>
                  <Checkbox
                    label={"Wording"}
                    checked={state.editWording}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editWording: !state.editWording
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Note"}
                    checked={state.editNote}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editNote: !state.editNote
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Task Text"}
                    checked={state.taskText}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        taskText: !state.taskText
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Highlight Row"}
                    checked={state.editHighlightRow}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editHighlightRow: !state.editHighlightRow
                      }))
                    }
                  />
                </Form.Field>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <React.Fragment>
              <Button
                negative
                onClick={() =>
                  setState(state => ({
                    ...state,
                    confirmEdit: null
                  }))
                }
                style={{
                  backgroundColor: "#fff",
                  border: "solid 1px #e5e5ea",
                  color: "rgba(0, 0, 0, 0.87)"
                }}
              >
                Cancel
              </Button>
              <Button
                positive
                onClick={saveChanges}
                style={{ backgroundColor: "#1f2b5e" }}
                data-automation="confirmDelete"
              >
                Submit
              </Button>
            </React.Fragment>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}
