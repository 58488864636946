import React, { useState, useEffect, useContext } from "react";
import { Menu } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import GeneralInfo from "./GeneralInfo";
import pathToRegexp from "path-to-regexp";
import StateDropdown from "../../StateDropdown";
import HelpfulWordings from "./HelpfulWordings";
import BIGuidelinesToOmit from "./BIGuidelinesToOmit";
import Endorsements from "./Endorsements";

export function PolicyInstructions({ history, match: { params }, match }) {
  const { selectedState } = useContext(StateContext);
  if (!params.state || params.state === "no_state") {
    history.replace(`/policy-instructions/states/${selectedState || 31}/sections/${params.section || "general-info"}`);
  }
  const [state, setState] = useState({
    selectedSection: params.section || "general-info",
    selectedUnderwriter: params.underwriterId || 1
  });

  useEffect(() => {
    setState({
      ...state,
      selectedSection: params.section || "general-info",
      initialLoading: false
    });
  }, [params.section]);

  useEffect(() => {
    setState({
      ...state,
      selectedUnderwriter: params.underwriterId || 1
    });
  }, [params.underwriterId]);

  return (
    <React.Fragment>
      <StateDropdown
        pushHistory={value => {
          const toPath = pathToRegexp.compile(match.path);
          const newPath = toPath({ ...match.params, state: value });
          history.push(newPath);
        }}
      />
      <Menu tabular style={{ marginTop: 55, marginBottom: 32 }}>
        <Menu.Item
          style={{ marginLeft: 21 }}
          name="General Info"
          active={state.selectedSection === "general-info"}
          onClick={() =>
            history.push(
              `/policy-instructions/states/${selectedState}/sections/general-info/underwriters/${state.selectedUnderwriter}`
            )
          }
        />
        <Menu.Item
          name="BI"
          active={state.selectedSection === "bi"}
          onClick={() =>
            history.push(
              `/policy-instructions/states/${selectedState}/sections/bi/underwriters/${state.selectedUnderwriter}`
            )
          }
        />
        <Menu.Item
          name="Endorsements"
          active={state.selectedSection === "endorsements"}
          onClick={() =>
            history.push(
              `/policy-instructions/states/${selectedState}/sections/endorsements/underwriters/${state.selectedUnderwriter}`
            )
          }
        />
        <Menu.Item
          name="Helpful Wordings"
          active={state.selectedSection === "helpful-wordings"}
          onClick={() =>
            history.push(
              `/policy-instructions/states/${selectedState}/sections/helpful-wordings/categories/1/underwriters/${state.selectedUnderwriter}`
            )
          }
        />
      </Menu>
      {state.selectedSection === "general-info" && <GeneralInfo />}
      {state.selectedSection === "helpful-wordings" && <HelpfulWordings />}
      {state.selectedSection === "bi" && <BIGuidelinesToOmit />}
      {state.selectedSection === "endorsements" && <Endorsements />}
    </React.Fragment>
  );
}

withRouter(PolicyInstructions);
