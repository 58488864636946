import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Dropdown, Input, Checkbox, Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import FileUpload from "../../FileUpload";
import shortid from "shortid";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import { logEvent } from "../../../helpers/analyticsLogger";
import getPAStateId from "./getPAStateId";

export function EditAddEndorsement({ match, history }) {
  const selectedState = +match.params.state;
  let stateId = selectedState;
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    endorsement: {
      stateId,
      underwriterId: +match.params.underwriter
    },
    editMode: match.path.includes("edit"),
    errorList: [],
    selectedUnderwriters: match.path.includes("edit") ? [] : [+match.params.underwriter],
    underwriters: [],
    file: null,
    filesToDelete: [],
    confirmEdit: null,
    allUnderwriters: false,
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: match.path.includes("edit") ? false : true,
    states: [],
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    endorsementStateNames: [],
    selectedStates: match.path.includes("edit") ? [] : [stateId],
    editAllStates: false,
    editName: false,
    editExplanation: false,
    editGuidelinesForOP: false,
    editGuidelinesForLP: false,
    editFiles: false,
    originalEndorsementName: ""
  });

  async function getEndorsementById(id) {
    const result = await getRequest(`/policyEndorsement/getEndorsementById`, {
      id
    });
    if (result.success) {
      if (result.data) {
        setState(state => ({
          ...state,
          endorsement: result.data,
          selectedUnderwriters: [result.data.underwriterId],
          selectedStates: [result.data.stateId],
          originalEndorsementName: result.data.name
        }));
      }
    }
  }

  const removePAUnderwriterStates = states => {
    return states.filter(s => s.text.includes("Pennsylvania -") === false);
  };

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      let allStates = result.data.map(s => ({
        key: s.id,
        value: s.id,
        text: s.name
      }));
      const filteredStates = removePAUnderwriterStates(allStates);
      setState(state => ({
        ...state,
        states: filteredStates
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allLicensedStatesList: [52, ...result.data]
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data
          .filter(u => u.name !== "All Underwriters")
          .map(u => ({
            key: u.id,
            value: u.id,
            text: u.name
          }))
      }));
    }
  }

  async function getStateUnderwriters(stateId) {
    const result = await getRequest(`/underwriter/getStateUnderwriters?id=${+match.params.state || stateId || 31}`);
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data.map(u => ({
          key: u.id,
          value: u.id,
          text: u.name
        }))
      }));
    }
  }

  async function getEndorsementStateNames(id) {
    const result = await getRequest(`/policyEndorsement/getEndorsementStateNames`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        endorsementStateNames: result.data
      }));
    }
  }

  useEffect(() => {
    getStates();
    getStateUnderwriters();
    if (state.editMode) {
      getEndorsementStateNames(match.params.endorsementId);
      getEndorsementById(match.params.endorsementId);
    } else {
      getAllStatesList();
      getAllLicensedStatesList();
      getAllUnlicensedStatesList();
    }
  }, []);

  useEffect(() => {
    getStateUnderwriters(state.endorsement.stateId);
  }, [state.endorsement.id]);

  async function saveEditChanges() {
    if (!state.errorList.length > 0) {
      setState(state => ({
        ...state,
        submitting: true
      }));
      const base64File =
        (state.file && state.file.length > 0) || (state.file && state.file.path)
          ? await readFileAsBase64(state.file)
          : null;
      const fileExtension = state.file ? state.file.name.split(".").pop() : "";
      const result = await postRequest(`/policyEndorsement/editEndorsement`, {
        ...state.endorsement,
        fileName:
          (state.file && state.file.length > 0) || (state.file && state.file.path)
            ? state.file.name
            : state.endorsement.fileName,
        base64File,
        fileExtension,
        editAllStates: state.editAllStates,
        editName: state.editName,
        editExplanation: state.editExplanation,
        editGuidelinesForOP: state.editGuidelinesForOP,
        editGuidelinesForLP: state.editGuidelinesForLP,
        editFiles: state.editFiles,
        underwriterIds:
          state.selectedUnderwriters.length > 0 ? state.selectedUnderwriters : [state.endorsement.underwriterId],
        stateIds: state.customStates ? state.selectedStates : [state.endorsement.stateId]
      });
      if (result.success) {
        logEvent(null, state.editMode ? "Edited" : "Added", "Endorsements");
        history.goBack();
      }
    }
  }

  async function saveAddChanges() {
    if (!state.errorList.length > 0) {
      setState(state => ({
        ...state,
        submitting: true
      }));
      const base64File =
        (state.file && state.file.length > 0) || (state.file && state.file.path)
          ? await readFileAsBase64(state.file)
          : null;
      const fileExtension = state.file ? state.file.name.split(".").pop() : "";
      const result = await postRequest(`/policyEndorsement/addEndorsement`, {
        ...state.endorsement,
        fileName:
          (state.file && state.file.length > 0) || (state.file && state.file.path)
            ? state.file.name
            : state.endorsement.fileName,
        base64File,
        fileExtension,
        underwriterIds: state.selectedUnderwriters,
        stateIds: state.selectedStates
      });
      if (result.success) {
        history.goBack();
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Endorsement`}
      </Header>
      <Form>
        <Grid>
          {+match.params.state !== 52 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <Checkbox
                    checked={state.allUnderwriters}
                    onChange={() =>
                      setState(state => ({
                        ...state,
                        allUnderwriters: !state.allUnderwriters,
                        selectedUnderwriters: !state.allUnderwriters
                          ? state.underwriters.map(u => u.value)
                          : state.selectedUnderwriters,
                        editUnderwriter: true
                      }))
                    }
                    label="Select all Underwriters"
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Underwriters</label>
                  <Dropdown
                    size="large"
                    fluid
                    multiple
                    selection
                    placeholder="Select Underwriters"
                    style={{ fontSize: 16 }}
                    options={state.underwriters}
                    value={state.selectedUnderwriters}
                    onChange={(_, { value }) => {
                      setState(state => ({
                        ...state,
                        selectedUnderwriters: value,
                        editUnderwriter: true
                      }));
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Name</label>
                <Input
                  placeholder="Name"
                  value={state.endorsement.name}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      endorsement: {
                        ...state.endorsement,
                        name: value
                      },
                      editName: true
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Explanation</label>
                <TextArea
                  placeholder="Explanation"
                  style={{ minHeight: 192 }}
                  value={state.endorsement.explanation}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      endorsement: {
                        ...state.endorsement,
                        explanation: value
                      },
                      editExplanation: true
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Guidelines for Issuing / OP</label>
                <TextArea
                  placeholder="Guidelines for Issuing / OP"
                  style={{ minHeight: 192 }}
                  value={state.endorsement.opGuideline}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      endorsement: {
                        ...state.endorsement,
                        opGuideline: value
                      },
                      editGuidelinesForOP: true
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Guidelines for Issuing / LP</label>
                <TextArea
                  placeholder="Guidelines for Issuing / LP"
                  style={{ minHeight: 192 }}
                  value={state.endorsement.lpGuideline}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      endorsement: {
                        ...state.endorsement,
                        lpGuideline: value
                      },
                      editGuidelinesForLP: true
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <FileUpload
                  setFileToApp={file => setState(state => ({ ...state, file }))}
                  fileList={state.file ? [state.file] : []}
                  deleteFileToApp={() =>
                    setState(state => ({
                      ...state,
                      file: null,
                      editFiles: true
                    }))
                  }
                />
              </Form.Field>
              {state.editMode && state.endorsement.fileName && (
                <p
                  key={shortid.generate()}
                  onClick={() =>
                    setState(state => ({
                      ...state,
                      endorsement: {
                        ...state.endorsement,
                        fileName: undefined
                      },
                      file: undefined,
                      editFiles: true
                    }))
                  }
                  className={css({
                    "&:hover": { color: "#707898" },
                    cursor: "pointer"
                  })}
                >
                  <i className="fal fa-trash-alt" style={{ marginRight: 8 }} />
                  {state.endorsement.fileName}
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
          {!state.editMode && (
            <React.Fragment>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field>
                    <Checkbox
                      label="All states"
                      checked={state.allStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: true,
                          allLicensedStates: false,
                          allUnlicensedStates: false,
                          customStates: false,
                          endorsementStateNames: state.allStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="All licensed states"
                      checked={state.allLicensedStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: true,
                          allUnlicensedStates: false,
                          customStates: false,
                          endorsementStateNames: state.allLicensedStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="All unlicensed states"
                      checked={state.allUnlicensedStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: false,
                          allUnlicensedStates: true,
                          customStates: false,
                          endorsementStateNames: state.allUnlicensedStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="Custom (choose your own state selection)"
                      checked={state.customStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: false,
                          allUnlicensedStates: false,
                          customStates: true
                        }))
                      }
                    />
                  </Form.Field>
                  {state.customStates && (
                    <Form.Field required>
                      <label>States</label>
                      <Dropdown
                        size="large"
                        fluid
                        multiple
                        selection
                        placeholder="Select States"
                        style={{ fontSize: 16 }}
                        options={state.states}
                        value={state.selectedStates}
                        onChange={(_, { value }) => {
                          setState(state => ({
                            ...state,
                            selectedStates: value
                          }));
                        }}
                        error={state.submitAttempted && !state.selectedStates.length > 0}
                      />
                    </Form.Field>
                  )}
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}
          <Grid.Row columns={1}>
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <Button size="large" className="cancelButton" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  setState(state => ({
                    ...state,
                    submitAttempted: true
                  }));
                  if (state.editMode && state.endorsementStateNames.length > 1 && state.errorList.length < 1) {
                    setState(state => ({ ...state, confirmEdit: true }));
                  } else {
                    state.editMode ? saveEditChanges() : saveAddChanges();
                  }
                }}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      {state.confirmEdit && (
        <Modal
          size={"tiny"}
          open={state.confirmEdit !== null}
          onClose={() => setState(state => ({ ...state, confirmEdit: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Edit Options</Modal.Header>
          <Modal.Content>
            <p className={css({ fontFamily: "Fakt-Bold" })}>
              Endorsement{" "}
              <span
                className={css({
                  color: "#767676",
                  textDecoration: "underline"
                })}
              >
                {state.originalEndorsementName}
              </span>{" "}
              found in the following states.
            </p>
            {state.endorsementStateNames.map(s => s).join(", ")}
            <Form.Field className={css({ marginTop: 16 })}>
              <Checkbox
                label={"Edit " + state.endorsement.state.name}
                checked={!state.editAllStates && !state.customStates}
                size="large"
                onClick={() =>
                  setState(state => ({
                    ...state,
                    editAllStates: false,
                    customStates: false
                  }))
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label={"Edit All States"}
                checked={state.editAllStates && !state.customStates}
                size="large"
                onClick={() =>
                  setState(state => ({
                    ...state,
                    editAllStates: true,
                    customStates: false
                  }))
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label={"Custom States"}
                checked={state.customStates}
                size="large"
                onClick={() =>
                  setState(state => ({
                    ...state,
                    editAllStates: false,
                    customStates: true
                  }))
                }
              />
            </Form.Field>
            {state.customStates && (
              <Form.Field required className={css({ marginTop: 8 })}>
                <label>States</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  multiple
                  placeholder="Select State"
                  style={{ fontSize: 16 }}
                  options={state.states}
                  value={state.selectedStates}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      selectedStates: value
                    }))
                  }
                />
              </Form.Field>
            )}
            {(state.editAllStates || state.customStates) && (
              <div className={css({ marginTop: 16 })}>
                <Form.Field>
                  <Checkbox
                    label={"Name"}
                    checked={state.editName}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editName: !state.editName
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Explanation"}
                    checked={state.editExplanation}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editExplanation: !state.editExplanation
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Guidelines for OP"}
                    checked={state.editGuidelinesForOP}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editGuidelinesForOP: !state.editGuidelinesForOP
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Guidelines for LP"}
                    checked={state.editGuidelinesForLP}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editGuidelinesForLP: !state.editGuidelinesForLP
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Files"}
                    checked={state.editFiles}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editFiles: !state.editFiles
                      }))
                    }
                  />
                </Form.Field>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <React.Fragment>
              <Button
                negative
                onClick={() =>
                  setState(state => ({
                    ...state,
                    confirmEdit: null
                  }))
                }
                style={{
                  backgroundColor: "#fff",
                  border: "solid 1px #e5e5ea",
                  color: "rgba(0, 0, 0, 0.87)"
                }}
              >
                Cancel
              </Button>
              <Button
                positive
                onClick={() => saveEditChanges()}
                style={{ backgroundColor: "#1f2b5e" }}
                data-automation="confirmDelete"
              >
                Submit
              </Button>
            </React.Fragment>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

withRouter(EditAddEndorsement);
