import React, { useState, useEffect } from "react";
import { getRequest } from "../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";

const StateContext = React.createContext({});

function StateContextProvider({ children, match }) {
  const [state, setState] = useState({
    selectedState: match.params.state, // Extract 'state' parameter from the URL
    noState: true
  });

  const [states, setStates] = useState([]);
  const [statesAndPAStates, setStatesAndPAStates] = useState([]);
  const [showAlsoPAStates, setShowAlsoPAStates] = useState(
    window.location.pathname.includes("commitment-instructions") || window.location.pathname.includes("search")
  );

  async function getStates() {
    try {
      const result = await getRequest("/general/getStates");
      if (result.success) {
        const statesWithoutPAStates = result.data.filter(s => !s.name.includes("Pennsylvania -"));
        setStates(statesWithoutPAStates);
        setStatesAndPAStates(result.data);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  }

  useEffect(() => {
    getStates();
  }, []);

  //remove underwriter PA States when not on commitment-instructions
  useEffect(() => {
    setShowAlsoPAStates(
      window.location.pathname.includes("commitment-instructions") || window.location.pathname.includes("search")
    );
  }, [window.location.pathname]);
  ///////////////////////////////////////////////////////////////

  return (
    <StateContext.Provider
      value={{
        selectedState: state.selectedState,
        updateSelectedState: value => setState(prevState => ({ ...prevState, selectedState: value })),
        noState: state.noState,
        updateNoState: value => setState(prevState => ({ ...prevState, noState: value })),
        updateNoStateAndSelectedState: (noState, selectedState) =>
          setState(prevState => ({ ...prevState, noState, selectedState })),
        states: showAlsoPAStates ? statesAndPAStates : states
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export { StateContext };

export default withRouter(StateContextProvider);
