import React, { useState, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Modal } from "semantic-ui-react";
import { postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import GeneralInfoNotes from "./GeneralInfoNotes";

const linkStyles = css({
  fontSize: 14,
  lineHeight: "36px",
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
});

function GeneralInfo({ history, match: { params } }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwner || user.isOwnerPlus;

  const [state, setState] = useState({
    noResults: false,
    noteToDelete: null
  });

  async function deleteNote(id, type) {
    const result = await postRequest(
      `/policyGeneralInfo/deletePolicyGeneralInfoNote?id=${id}`,
      id
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        noteToDelete: null
      }));
    }
  }

  return (
    <div style={{ paddingBottom: 36 }}>
      <Grid style={{ marginBottom: 17 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column
            floated="left"
            className={css({
              textAlign: "left",
              color: "#47517B",
              fontSize: 20,
              fontFamily: "Fakt-Bold",
              lineHeight: "36px"
            })}
          >
            General Info
          </Grid.Column>
          {userCanEdit && (
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <span
                className={linkStyles}
                onClick={() =>
                  history.push(
                    `/policy-instructions/states/${selectedState}/new-general-info-note`
                  )
                }
              >
                Add Note
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <GeneralInfoNotes
        deleteAction={(value) =>
          setState((state) => ({ ...state, noteToDelete: value }))
        }
      />
      {state.noteToDelete && (
        <Modal
          size={"tiny"}
          open={state.noteToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, noteToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, noteToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteNote(state.noteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

export default withRouter(GeneralInfo);
