import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, Modal, Label } from "semantic-ui-react";
import { css } from "emotion";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import { withRouter } from "react-router-dom";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import Codes from "./Codes";
import NoteAccordion from "../../NoteAccordion";
import DragSortNotes from "./DragSortNotes";

const linkStyles = css({
  fontSize: 14,
  lineHeight: 1.71,
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
});

function ScheduleBI({ history, match }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  const [state, setState] = useState({
    scheduleSections: [],
    noteToDelete: null,
    sorted: false,
    selectedSection: +match.params.category || 1,
    stateLabel: undefined
  });

  const [showAll, setShowAll] = useState(false);
  const [scheduleBINotesBeforeCodes, setScheduleBINotesBeforeCodes] = useState([]);
  const [scheduleBINotesAfterCodes, setScheduleBINotesAfterCodes] = useState([]);

  async function getScheduleBILabelByState(stateId) {
    const result = await getRequest(`/scheduleBI/getScheduleBILabelByState`, {
      stateId
    });
    if (result.success) {
      setState(state => ({
        ...state,
        stateLabel: result.data.name
      }));
    }
  }

  async function getScheduleBINotesByState(stateId) {
    const result = await getRequest(`/scheduleBI/getScheduleBINotesByState`, {
      stateId
    });
    if (result.success) {
      setScheduleBINotesBeforeCodes(result.data.filter(d => !d.isAfterCodes));
      setScheduleBINotesAfterCodes(result.data.filter(d => d.isAfterCodes));
    }
  }

  async function getScheduleSections() {
    const result = await getRequest("/scheduleBI/getScheduleSections");
    if (result.success) {
      setState(state => ({
        ...state,
        scheduleSections: result.data
      }));
    }
  }

  useEffect(() => {
    getScheduleBILabelByState(selectedState);
    getScheduleSections();
  }, []);

  useEffect(() => {
    setState(state => ({
      ...state,
      selectedSection: +match.params.category || 1
    }));
  }, [match.params.category]);

  useEffect(() => {
    getScheduleBILabelByState(selectedState);
    getScheduleBINotesByState(selectedState);
  }, [selectedState]);

  async function deleteScheduleBINote(id) {
    const result = await postRequest(`/scheduleBI/deleteScheduleBINote?id=${id}`);
    if (result.success) {
      setScheduleBINotesBeforeCodes(scheduleBINotesBeforeCodes.filter(n => n.id !== id));
      setScheduleBINotesAfterCodes(scheduleBINotesAfterCodes.filter(n => n.id !== id));
      setState(state => ({
        ...state,
        noteToDelete: null
      }));
    }
  }

  const filteredNotesBeforeCodes = scheduleBINotesBeforeCodes.filter(
    n => n.scheduleSectionId === state.selectedSection
  );

  const filteredNotesAfterCodes = scheduleBINotesAfterCodes.filter(n => n.scheduleSectionId === state.selectedSection);

  const isCollapsible = selectedState === 52;

  return (
    <React.Fragment>
      <Grid style={{ marginBottom: 17 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column
            floated="left"
            className={css({
              textAlign: "left",
              color: "#47517B",
              fontSize: 20,
              fontFamily: "Fakt-Bold"
            })}
          >
            <span className={css({ marginRight: 18, lineHeight: "36px" })}>
              {selectedState === 44 ? "Schedule C" : "Schedule BI"}
            </span>
            {state.scheduleSections.map(s => (
              <Button
                key={s.id}
                className={css({
                  backgroundColor: state.selectedSection === s.id ? "#47517B !important" : "#ebecf1!important    ",
                  color: state.selectedSection === s.id ? "#ffffff !important" : "rgba(0, 0, 0, 0.87) !important"
                })}
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/sections/schedule-bi/categories/${s.id}`
                  )
                }
              >
                {s.name}
              </Button>
            ))}
          </Grid.Column>
          {userCanEdit && (
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              {state.selectedSection === 1 && (
                <span
                  className={linkStyles}
                  onClick={() =>
                    history.push(
                      `/commitment-instructions/states/${selectedState}/${
                        state.stateLabel ? "edit" : "add"
                      }-state-label`
                    )
                  }
                >
                  {`${state.stateLabel ? "Edit" : "Add"} Label`}
                </span>
              )}
              <span
                className={linkStyles}
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-schedule-bi-note/categories/${state.selectedSection}`
                  )
                }
              >
                Add Note
              </span>
              <Button
                icon
                className="blueButton"
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-schedule-bi-code/categories/${state.selectedSection}`
                  )
                }
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-schedule-bi-code/categories/${state.selectedSection}`
                  )
                }
              >
                New Code
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {(filteredNotesBeforeCodes.length > 0 || filteredNotesAfterCodes.length > 0) && isCollapsible && (
        <Button basic onClick={() => setShowAll(showAll => !showAll)} style={{ marginBottom: 32 }}>
          {showAll ? "Collapse All" : "Open All"}
        </Button>
      )}
      {state.stateLabel && state.selectedSection === 1 && (
        <div>
          <Label className="primaryLabel" style={{ paddingLeft: 24, paddingRight: 24, marginBottom: 16 }}>
            {state.stateLabel}
          </Label>
        </div>
      )}
      {filteredNotesBeforeCodes.length > 0 && (
        <div className={css({ marginBottom: 24 })}>
          {!isCollapsible && (
            <DragSortNotes
              items={filteredNotesBeforeCodes}
              saveSortPath="/scheduleBI/sortNotes"
              draggable={userCanEdit}
              editNotePath={`/commitment-instructions/edit-schedule-bi-note`}
              editable={userCanEdit}
              downloadUrl={`/scheduleBI/downloadFile`}
              deleteAction={value => setState(state => ({ ...state, noteToDelete: value }))}
            />
          )}
          {isCollapsible && (
            <NoteAccordion
              notes={filteredNotesBeforeCodes}
              setNotes={setScheduleBINotesBeforeCodes}
              deleteAction={value => setState(state => ({ ...state, noteToDelete: value }))}
              showAll={showAll}
              downloadURL="/scheduleBI/downloadFile"
              saveSortURL="/scheduleBI/sortNotes"
              editURL="/commitment-instructions/edit-schedule-bi-note"
            />
          )}
        </div>
      )}
      <Codes type="BI" section={state.selectedSection} downloadUrl={`/scheduleBI/downloadCodeFile`} />
      {!isCollapsible && filteredNotesAfterCodes.length > 0 && (
        <DragSortNotes
          items={filteredNotesAfterCodes}
          saveSortPath="/scheduleBI/sortNotes"
          draggable={userCanEdit}
          editNotePath={`/commitment-instructions/edit-schedule-bi-note`}
          editable={userCanEdit}
          downloadUrl={`/scheduleBI/downloadFile`}
          deleteAction={value => setState(state => ({ ...state, noteToDelete: value }))}
        />
      )}
      {isCollapsible && filteredNotesAfterCodes.length > 0 && (
        <NoteAccordion
          notes={filteredNotesAfterCodes}
          setNotes={setScheduleBINotesAfterCodes}
          deleteAction={value => setState(state => ({ ...state, noteToDelete: value }))}
          showAll={showAll}
          downloadURL="/scheduleBI/downloadFile"
          saveSortURL="/scheduleBI/sortNotes"
          editURL="/commitment-instructions/edit-schedule-bi-note"
        />
      )}
      {state.noteToDelete && (
        <Modal
          size={"tiny"}
          open={state.noteToDelete !== null}
          onClose={() => setState(state => ({ ...state, noteToDelete: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              {state.noteToDelete.subject && <strong>{state.noteToDelete.subject}</strong>}?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => setState(state => ({ ...state, noteToDelete: null }))}
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteScheduleBINote(state.noteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(ScheduleBI);
