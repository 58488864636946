import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Table, Icon, Modal, Card } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import CustomTable from "../../CustomTable";
import { Highlight } from "../../search/Highlight";
import { paragraphStyles } from "../../styles/paragraphStyles";

const linkStyles = css({
  fontSize: 14,
  lineHeight: 1.71,
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

function AgencyAgreements({ history, match: { params } }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  if (!params.state || params.state === "no_state") {
    history.replace(`AgencyAgreements/states/${selectedState || 31}`);
  }
  const [state, setState] = useState({
    states: [],
    agencyAgreements: [],
    notes: [],
    initialLoading: true,
    agreementToDelete: null,
    noResults: false,
    noteToDelete: null,
  });

  async function getNotesByState() {
    const result = await getRequest("/agencyAgreement/getNotesByState", {
      stateId: selectedState,
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        notes: result.data,
        initialLoaded: true,
      }));
    }
  }

  async function getAgencyAgreementsByState() {
    const result = await getRequest(
      "/agencyAgreement/getAgencyAgreementsByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        agencyAgreements: result.data,
        initialLoading: false,
        noResults: result.data.length < 1,
      }));
    }
  }

  async function deleteAgencyAgreement(id) {
    const result = await postRequest(
      `/agencyAgreement/deleteAgencyAgreement?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        agencyAgreements: state.agencyAgreements.filter((a) => a.id !== id),
        agreementToDelete: null,
      }));
    }
  }

  async function deleteNote(id) {
    const result = await postRequest(`/agencyAgreement/deleteNote?id=${id}`);
    if (result.success) {
      setState((state) => ({
        ...state,
        notes: state.notes.filter((n) => n.id !== id),
        noteToDelete: null,
      }));
    }
  }

  useEffect(() => {
    getAgencyAgreementsByState();
    getNotesByState();
  }, []);

  useEffect(() => {
    getAgencyAgreementsByState();
    getNotesByState();
  }, [selectedState]);

  return (
    <div>
      <React.Fragment>
        <div className={css({ marginTop: 48, marginBottom: 40 })}>
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
              {(user.isOwner || user.isOwnerPlus) && (
                <Grid.Column
                  floated="right"
                  className={css({ textAlign: "right" })}
                >
                  <span
                    className={linkStyles}
                    onClick={() =>
                      history.push(
                        `/rates/new-agency-agreement-note/states/${selectedState}`
                      )
                    }
                  >
                    Add Note
                  </span>
                  <Button
                    icon
                    className="blueButton"
                    onClick={() => history.push(`/rates/new-agency-agreement`)}
                  >
                    <i className="fal fa-plus" />
                  </Button>
                  <span
                    className={css({
                      fontFamily: "Fakt-Bold",
                      fontSize: 14,
                      color: "#000",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#00b8d4",
                      },
                    })}
                    onClick={() => history.push(`/rates/new-agency-agreement`)}
                  >
                    New Agency Agreement
                  </span>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
      {state.notes &&
        state.notes.map((n) => (
          <Card.Group
            itemsPerRow={1}
            className={css({ "&.ui.cards ~ .ui.cards": { marginTop: 0 } })}
            key={n.id}
          >
            <Card style={{ marginLeft: 0, marginRight: 0 }}>
              <Card.Content>
                <label
                  className={css({
                    color: "#707898",
                    fontSize: 14,
                    fontFamily: "Fakt-Bold",
                    lineHeight: "17px",
                  })}
                >
                  {n.subject ? n.subject : "Note"}
                </label>
                <p className={paragraphStyles}>{n.note}</p>
              </Card.Content>
              {(user.isOwner || user.isOwnerPlus) && (
                <React.Fragment>
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer",
                      position: "absolute",
                      right: 24,
                      top: 31,
                    })}
                    onClick={() =>
                      history.push(`/rates/edit-agency-agreement-note/${n.id}`)
                    }
                  >
                    <Icon className="fal fa-pen" />
                  </span>
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer",
                      position: "absolute",
                      right: 48,
                      top: 31,
                    })}
                    onClick={() =>
                      setState((state) => ({ ...state, noteToDelete: n }))
                    }
                  >
                    <Icon className="fal fa-trash" />
                  </span>
                </React.Fragment>
              )}
            </Card>
          </Card.Group>
        ))}
      <div
        className={css({
          width: "100%",
          overflowX: "auto",
          borderRadius: "0.28571429rem",
          marginTop: 16,
        })}
      >
        <CustomTable
          fixed={true}
          width={3000}
          compact={true}
          customStyles={{
            width: "2700px !important",
            "& tr td:first-of-type": {
              paddingLeft: "40.5px !important",
            },
            "& tr td": {
              paddingTop: "18px !important",
              paddingBottom: "18px !important",
            },
            "& th": {
              position: "sticky",
              top: 0,
              zIndex: 1,
            },
            "& tr th:first-of-type, tr td:first-of-type": {
              paddingLeft: "60px !important",
            },
          }}
        >
          <Table.Header>
            <Table.Row verticalAlign="top">
              <Table.HeaderCell textAlign="left">
                Madison CO/Underwriter
              </Table.HeaderCell>
              <Table.HeaderCell>Premium Splits</Table.HeaderCell>
              <Table.HeaderCell>Standard Endorsement Splits</Table.HeaderCell>
              <Table.HeaderCell>
                Extra Hazardous Endorsement Splits
              </Table.HeaderCell>
              <Table.HeaderCell>CPL/CSL Split</Table.HeaderCell>
              <Table.HeaderCell>Authority Limit (Hi-Li)</Table.HeaderCell>
              <Table.HeaderCell>Who can Authorize the Policy</Table.HeaderCell>
              <Table.HeaderCell>Notes</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {state.agencyAgreements && state.agencyAgreements.length < 1 && (
              <Table.Row>
                <Table.Cell>No agreements available</Table.Cell>
              </Table.Row>
            )}
            {state.agencyAgreements &&
              state.agencyAgreements.map((a) => (
                <Table.Row verticalAlign="top" key={a.id}>
                  <Table.Cell
                    className={css({
                      textAlign: "left !important",
                      border: "none !important",
                      position: "relative !important",
                    })}
                  >
                    {(user.isOwner || user.isOwnerPlus) && (
                      <React.Fragment>
                        <span
                          className={css({
                            "&:hover i": { color: "#00b8d4" },
                            cursor: "pointer",
                            verticalAlign: "top",
                            position: "absolute",
                            left: 16,
                          })}
                          onClick={() =>
                            history.push(`/rates/edit-agency-agreement/${a.id}`)
                          }
                        >
                          <Icon
                            style={{ paddingRight: 32.5 }}
                            className="fal fa-pen"
                          />
                        </span>
                        <span
                          className={css({
                            "&:hover i": { color: "#00b8d4" },
                            cursor: "pointer",
                            verticalAlign: "top",
                            position: "absolute",
                            left: 38,
                          })}
                          onClick={() => {
                            setState((state) => ({
                              ...state,
                              agreementToDelete: a,
                            }));
                          }}
                        >
                          <Icon
                            style={{ paddingRight: 32.5 }}
                            className="fal fa-trash"
                          />
                        </span>
                      </React.Fragment>
                    )}
                    <React.Fragment>
                      <div
                        className={css({
                          display: "inline-block",
                          width: "calc(100% - 70px)",
                        })}
                      >
                        <div
                          className={css({
                            fontFamily: "Fakt-Bold",
                          })}
                        >
                          <Highlight children={a.titleCompany.name} />
                        </div>
                        <br />
                        <Highlight children={a.underwriter.name} />
                      </div>
                    </React.Fragment>
                  </Table.Cell>
                  <Table.Cell>
                    <Highlight children={a.premiumSplit} />
                  </Table.Cell>
                  <Table.Cell>
                    <Highlight children={a.stadardEndorsementSplit} />
                  </Table.Cell>
                  <Table.Cell>
                    <Highlight children={a.extraHazardousEndorsementSplit} />
                  </Table.Cell>
                  <Table.Cell>
                    <Highlight children={a.cplAndCSLSplit} />
                  </Table.Cell>
                  <Table.Cell>
                    <Highlight children={a.authorityLimit} />
                  </Table.Cell>
                  <Table.Cell>
                    <Highlight children={a.authorizer} />
                  </Table.Cell>
                  <Table.Cell>
                    <Highlight children={a.note} />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </CustomTable>
      </div>
      {state.agreementToDelete && (
        <Modal
          size={"tiny"}
          open={state.agreementToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, agreementToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Agency Agreement</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete agreement with <br />
              <strong>
                {state.agreementToDelete.titleCompany.name}
              </strong> and{" "}
              <strong>{state.agreementToDelete.underwriter.name}</strong> ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, agreementToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteAgencyAgreement(state.agreementToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {state.noteToDelete && (
        <Modal
          size={"tiny"}
          open={state.noteToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, noteToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              {state.noteToDelete.subject && (
                <strong>{state.noteToDelete.subject}</strong>
              )}
              ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, noteToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteNote(state.noteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

export default withRouter(React.memo(AgencyAgreements));
