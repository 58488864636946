import React from "react";
import { css } from "emotion";
import { Button, Header, Grid, Card, Popup } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { getRequest } from "../../../helpers/ApiHelpers";
import { Highlight } from "../../search/Highlight";

import {convertToFileDownload} from '../../../helpers/ConvertToFileDownload'

function CalculatorCard({ calculator }) {

  async function download(id) {
    const result = await getRequest(
      `/premiumCalculator/downloadCalculator?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  return (
    <Card.Group itemsPerRow={1}>
      <Card style={{ paddingBottom: 30 }}>
        <Card.Content>
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              <Grid.Column floated="left">
                <Header
                  as="h3"
                  className={css({
                    color: "#47517b !important",
                    fontSize: "20px !important",
                    marginBottom: "0px !important"
                  })}
                >
                  <Highlight children={calculator.name} />
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                <Popup
                  size="tiny"
                  content="Download"
                  trigger={
                    <Button
                      style={{ position: "relative" }}
                      icon
                      onClick={() => download(calculator.id)}
                    >
                      <span
                        className={css({
                          "& .fa-arrow-to-bottom:before": {
                            fontSize: 18,
                            position: "absolute",
                            top: 9,
                            left: 12
                          }
                        })}
                      >
                        <i className="fal fa-arrow-to-bottom" />
                      </span>
                    </Button>
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <div
              className={css({
                fontSize: 12,
                position: "absolute",
                bottom: 30
              })}
            >
              {`Last updated ${new Date(
                calculator.lastUpdated
              ).toLocaleDateString("en-US")}`}
            </div>
          </Grid>
        </Card.Content>
      </Card>
    </Card.Group>
  );
}

export default withRouter(CalculatorCard);
