import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Input, Dropdown, Message } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import shortid from "shortid";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddPolicyGeneralInfoNote({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    note: {},
    files: [],
    filesToDelete: [],
    editMode: match.path.includes("edit"),
    errorList: [],
    policyGeneralInfoNoteTypes: []
  });

  async function getPolicyGeneralInfoNoteTypes() {
    const result = await getRequest(`/policyGeneralInfo/getPolicyGeneralInfoNoteTypes`);
    if (result.success) {
      setState(state => ({
        ...state,
        policyGeneralInfoNoteTypes: result.data.map(s => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getPolicyGeneralInfoNoteById(id) {
    const result = await getRequest(`/policyGeneralInfo/getPolicyGeneralInfoNoteById`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        note: result.data
      }));
    }
  }

  useEffect(() => {
    getPolicyGeneralInfoNoteTypes();
    if (state.editMode) {
      getPolicyGeneralInfoNoteById(match.params.id);
    }
  }, []);

  useEffect(() => {
    setState(state => ({ ...state, errorList: getErrorList() }));
  }, [state.note.subject, state.note.policyGeneralInfoNoteTypeId, state.submitAttempted]);

  async function saveChanges() {
    setState(state => ({
      ...state,
      submitting: true
    }));
    const result = await postRequest(`/policyGeneralInfo/editAddGeneralInfoNote`, {
      ...state.note,
      stateId: state.editMode ? state.note.stateId : +match.params.state,
      files: await Promise.all(
        state.files.map(async f => ({
          base64File: await readFileAsBase64(f),
          fileName: f.name
        }))
      ),
      filesToDelete: state.filesToDelete
    });
    if (result.success) {
      logEvent(null, state.editMode ? "Edited" : "Added", "General Info");
      history.goBack();
    }
  }

  function getErrorList() {
    let list = [];
    if (state.note.subject === null || state.note.subject === "") {
      list.push("You must provide a subject.");
    }
    if (state.note.policyGeneralInfoNoteTypeId === null || state.note.policyGeneralInfoNoteTypeId === "") {
      list.push("You must provide a note type.");
    }
    return list;
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} General Info Note`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Type</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select Type"
                  style={{ fontSize: 16 }}
                  options={state.policyGeneralInfoNoteTypes}
                  value={state.note.policyGeneralInfoNoteTypeId}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      note: {
                        ...state.note,
                        policyGeneralInfoNoteTypeId: value
                      }
                    }))
                  }
                  error={state.submitAttempted && state.note.policyGeneralInfoNoteTypeId === null}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.note.subject}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      note: {
                        ...state.note,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.note.note}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      note: {
                        ...state.note,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={files =>
                    setState(state => ({
                      ...state,
                      files: [...state.files, ...files]
                    }))
                  }
                  deleteFileToApp={fileName =>
                    setState(state => ({
                      ...state,
                      files: [...state.files.filter(f => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.note.policyGeneralInfoNoteFiles &&
                state.note.policyGeneralInfoNoteFiles.map(f => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        note: {
                          ...state.note,
                          policyGeneralInfoNoteFiles: [
                            ...state.note.policyGeneralInfoNoteFiles.filter(nf => nf.id !== f.id)
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i className="fal fa-trash-alt" style={{ marginRight: 8 }} />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <Button size="large" className="cancelButton" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button size="large" className="saveButton" onClick={saveChanges}>
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddPolicyGeneralInfoNote);
