import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  Dropdown,
  TextArea
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddAgencyAgreement({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const defaultAgencyAgreement = {
    stateId: selectedState,
    titleCompanyId: undefined,
    underwriterId: undefined,
    premiumSplit: undefined,
    stadardEndorsementSplit: undefined,
    extraHazardousEndorsementSplit: undefined,
    cPLAndCSLSplit: undefined,
    authorityLimit: undefined,
    authorizer: undefined,
    note: undefined
  };
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    titleCompanies: [],
    underwriters: [],
    agencyAgreement: defaultAgencyAgreement,
    editMode: match.path === "/rates/edit-agency-agreement/:id",
    errorList: []
  });

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState((state) => ({
        ...state,
        underwriters: result.data
          .filter((u) => u.name !== "All Underwriters")
          .map((u) => ({
            key: u.id,
            value: u.id,
            text: u.name
          }))
      }));
    }
  }

  async function getTitleCompanies() {
    const result = await getRequest("/titleCompany/getTitleCompanies");
    if (result.success) {
      setState((state) => ({
        ...state,
        titleCompanies: result.data.map((t) => ({
          key: t.id,
          value: t.id,
          text: t.name
        }))
      }));
    }
  }

  async function getAgencyAgreementById(id) {
    const result = await getRequest(`/agencyAgreement/getAgencyAgreementById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        agencyAgreement: result.data
      }));
    }
  }

  useEffect(() => {
    if (state.editMode) {
      getAgencyAgreementById(match.params.id);
    }
    getTitleCompanies();
    getUnderwriters();
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/agencyAgreement/${
            state.editMode ? "editAgencyAgreement" : "addAgencyAgreement"
          }`,
          state.agencyAgreement
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Agency Agreement"
          );
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.agencyAgreement.titleCompanyId) {
      list.push("You must provide a title company.");
    }
    if (!state.agencyAgreement.underwriterId) {
      list.push("You must provide an underwriter.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.agencyAgreement]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Agency Agreement`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Madison CO/JV</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select Company"
                  style={{ fontSize: 16 }}
                  options={state.titleCompanies}
                  value={state.agencyAgreement.titleCompanyId}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        titleCompanyId: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    state.agencyAgreement.titleCompanyId === null
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Underwriter</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select Underwriter"
                  style={{ fontSize: 16 }}
                  options={state.underwriters}
                  value={state.agencyAgreement.underwriterId}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        underwriterId: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    state.agencyAgreement.underwriterId === null
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Premuim Splits</label>
                <TextArea
                  placeholder="Premuim Splits"
                  style={{ minHeight: 96 }}
                  value={state.agencyAgreement.premiumSplit}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        premiumSplit: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Standard Endorsement Splits</label>
                <TextArea
                  placeholder="Standard Endorsement Splits"
                  style={{ minHeight: 96 }}
                  value={state.agencyAgreement.stadardEndorsementSplit}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        stadardEndorsementSplit: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Extra Hazardous Endorsement Splits</label>
                <TextArea
                  placeholder="Extra Hazardous Endorsement Splits"
                  style={{ minHeight: 96 }}
                  value={state.agencyAgreement.extraHazardousEndorsementSplit}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        extraHazardousEndorsementSplit: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>CPL/CSL Split</label>
                <TextArea
                  placeholder="CPL/CSL Split"
                  style={{ minHeight: 96 }}
                  value={state.agencyAgreement.cplAndCSLSplit}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        cplAndCSLSplit: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Authority Limit (Hi-Li)</label>
                <TextArea
                  placeholder="Authority Limit (Hi-Li)"
                  style={{ minHeight: 96 }}
                  value={state.agencyAgreement.authorityLimit}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        authorityLimit: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Who can Authorize the Policy</label>
                <TextArea
                  placeholder="Who can Authorize the Policy"
                  style={{ minHeight: 96 }}
                  value={state.agencyAgreement.authorizer}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        authorizer: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.agencyAgreement.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreement: {
                        ...state.agencyAgreement,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddAgencyAgreement);
