import axios from 'axios';
import {postRequest} from "./ApiHelpers";

const logEvent = (eventFunction: string, description: string, additionalInfo: string | undefined = undefined) => {
  (async () => {
    try {
      await postRequest('/AnalyticsLogging/logevent', {function: eventFunction, url: window.location.href, description, additionalInfo});
    } catch {
    }
  })();
}

const logPageView = () => {
  (async () => {
    try {
      await postRequest('/AnalyticsLogging/logpageview', {url: window.location.href})
    } catch {
    }
  })();
}

export {logEvent, logPageView};