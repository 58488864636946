import React, { useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Card, Popup } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { getAnswer } from "../../../helpers/GetAnswer";
import { UserContext } from "../../User";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap"
});

function PremiumCard({ premium, editRoute, history }) {
  const { user } = useContext(UserContext);
  return (
    <Card.Group itemsPerRow={1}>
      <Card
        className={css({
          backgroundColor: "#f5f6f8 !important",
          boxShadow: "none !important"
        })}
      >
        <Card.Content>
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              <Grid.Column floated="left">
                <div>
                  <div
                    className={css({
                      color: "#707898",
                      fontFamily: "Fakt-Bold",
                      fontSize: 14,
                      marginBottom: 8
                    })}
                  >
                    Is premium negotiable?
                  </div>
                  <p className={paragraphStyles}>
                    {getAnswer(premium.isPremiumNegotiable)}
                    {(premium.isPremiumNegotiable === true ||
                      premium.isPremiumNegotiable === false) &&
                      premium.note &&
                      premium.note.length > 0 &&
                      " - "}
                    {premium.note}
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                {user.isOwnerPlus && (
                  <Popup
                    size="tiny"
                    content="Edit"
                    trigger={
                      <Button icon onClick={() => history.push(editRoute)}>
                        <i className="fal fa-pencil" />
                      </Button>
                    }
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </Card.Group>
  );
}

export default withRouter(PremiumCard);
