import React, { Component, useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Contacts from "./components/contacts/Contacts";
import { EditAddContact } from "./components/contacts/EditAddContact";
import { UserContextProvider } from "./components/User";
import StateContextProvider, { StateContext } from "./components/State";
import { SearchWordsContextProvider } from "./components/search/SearchWords";
import { UnderwriterContextProvider } from "./components/Underwriter";
import { LatestContactUpdatedContextProvider } from "./components/LatestContactUpdated";
import { EditAddCompany } from "./components/contacts/EditAddCompany";
import Info from "./components/info/Info";
import Rates from "./components/rates/Rates";
import { EditWithholdingTax } from "./components/rates/taxes/EditWithholdingTax";
import { EditMortgageTax } from "./components/rates/taxes/EditMortgageTax";
import { EditTransferTax } from "./components/rates/taxes/EditTransferTax";
import { AddManual } from "./components/rates/manuals/AddManual";
import { AddOPEndorsement } from "./components/rates/premiums/AddOPEndorsement";
import AddOPUnderwriter from "./components/rates/premiums/AddOPUnderwriter";
import AddLPEndorsement from "./components/rates/premiums/AddLPEndorsement";
import AddLPUnderwriter from "./components/rates/premiums/AddLPUnderwriter";
import { EditPremium } from "./components/rates/premiums/EditPremium";
import { AddCalculator } from "./components/rates/premiums/AddCalculator";
import Search from "./components/search/Search";
import EditAddForm from "./components/forms/EditAddForm";
import Forms from "./components/forms/Forms";
import TrainingMedia from "./components/trainingMedia/TrainingMedia";
import EditAddTrainingMedia from "./components/trainingMedia/EditAddTrainingMedia";
import { ToastProvider } from "react-toast-notifications";
import { AddQuoteTemplate } from "./components/rates/premiums/AddQuoteTemplate";
import { EditLien } from "./components/info/liens/EditLien";
import { EditAddAdditionalLien } from "./components/info/liens/EditAddAdditionalLien";
import Admin from "./components/admin/Admin";
import { EditAddUnderwriter } from "./components/admin/EditAddUnderwriter";
import { EditAddTitleCompany } from "./components/admin/EditAddTitleCompany";
import { EditAddAgencyAgreement } from "./components/rates/agencyAgreements/EditAddAgencyAgreement";
import { EditAddStateIdiosyncrasy } from "./components/info/stateIdiosyncrasies/EditAddStateIdiosyncrasy";
import { EditAddRecording } from "./components/info/recordings/EditAddRecording";
import { EditGeneralInfo } from "./components/info/orderEntryInstructions/EditGeneralInfo";
import { EditAddHelpfulWording } from "./components/info/orderEntryInstructions/AddEditHelpfulWording";
import { EditAddInvoiceCharge } from "./components/rates/invoiceCharges/EditAddInvoiceCharge";
import { EditAddSearchGuideline } from "./components/info/orderEntryInstructions/EditAddSearchGuideline";
import { EditAddSearchGuidelineNote } from "./components/info/orderEntryInstructions/EditAddSearchGuidelineNote";
import { EditAddRemittanceTargets } from "./components/info/orderEntryInstructions/remittanceTargets/EditAddRemittanceTargets";
import { EditAddRemittanceTargetNote } from "./components/info/orderEntryInstructions/EditAddRemittanceTargetNote";
import Websites from "./components/websites/Websites";
import { EditAddWebsite } from "./components/websites/EditAddWebsite";
import { EditAddScheduleBICode } from "./components/info/commitmentInstructions/EditAddScheduleBICode";
import { EditAddScheduleBINote } from "./components/info/commitmentInstructions/EditAddScheduleBINote";
import { EditAddScheduleBIICode } from "./components/info/commitmentInstructions/EditAddScheduleBIICode";
import { EditAddScheduleBIINote } from "./components/info/commitmentInstructions/EditAddScheduleBIINote";
import { EditAddGeneralInfoNote } from "./components/info/commitmentInstructions/EditAddGeneralInfoNote";
import { EditAddPolicyGeneralInfoNote } from "./components/info/policyInstructions/EditAddPolicyGeneralInfoNote";
import { EditAddScheduleANote } from "./components/info/commitmentInstructions/EditAddScheduleANote";
import { CommitmentInstructions } from "./components/info/commitmentInstructions/CommitmentInstructions";
import OrderEntryInstructions from "./components/info/orderEntryInstructions/OrderEntryInstructions";
import { PolicyInstructions } from "./components/info/policyInstructions/PolicyInstructions";
import { EditAddPolicyHelpfulWording } from "./components/info/policyInstructions/EditAddPolicyHelpfulWording";
import { EditAddBIGuidelinesToOmit } from "./components/info/policyInstructions/EditAddBIGuidelinesToOmit";
import { EditAddOtherException } from "./components/info/policyInstructions/EditAddOtherException";
import { EditAddEndorsement } from "./components/info/policyInstructions/EditAddEndorsement";
import { EditAddGeneralRule } from "./components/info/orderEntryInstructions/EditAddGeneralRule";
import { EditAddOPEndorsementNote } from "./components/rates/premiums/EditAddOPEndorsementNote";
import { EditAddLPEndorsementNote } from "./components/rates/premiums/EditAddLPEndorsementNote";
import { EditAddAgencyAgreementNote } from "./components/rates/agencyAgreements/EditAddAgencyAgreementNote";
import { EditScheduleBIStateLabel } from "./components/info/commitmentInstructions/EditScheduleBIStateLabel";
import { EditAddHighLiabilityApproval } from "./components/info/highLiabilityApprovals/EditAddHighLiabilityApproval";
import { EditAddPolicySurveyNote } from "./components/info/surveys/EditAddPolicySurveyNote";
import { EditAddSurveyLegalDescriptionNote } from "./components/info/surveys/EditAddSurveyLegalDescriptionNote";
import { Surveys } from "./components/info/surveys/Surveys";
import { EditAddSurveyExceptionCode } from "./components/info/surveys/EditAddSurveyExceptionCode";
import { logPageView } from "./helpers/analyticsLogger";
import { EditAddModification } from "./components/info/modifications/EditAddStateModification";
import TrainingAndSystemTips from "./components/trainingMedia/TrainingAndSystemTips";
import { EditAddSystemTips } from "./components/trainingMedia/EditAddSystemTips";
import { EditAddSurveyHelpfulWording } from "./components/info/surveys/AddEditSurveyHelpfulWording";
import { EditAddPolicyBINote } from "./components/info/policyInstructions/EditAddPolicyBINote";
import { EditAddPolicyEndorsementNote } from "./components/info/policyInstructions/EditAddPolicyEndorsementNote";
import Index from "./components/index/Index";

const RouterLogger = (props) => {
  const count = React.useRef(0);
  useEffect(() => {
    if (window.location.pathname === "/") {
      return; //don't log pageview for root url
    }
    count.current++;
    if (count.current % 2 === 1) {
      logPageView();
    }
  }, [props.location]);

  return <>{props.children}</>;
};

const RouterLoggerHOC = withRouter((props) => <RouterLogger {...props} />);

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <RouterLoggerHOC>
        <UserContextProvider>
          <StateContextProvider>
            <ToastProvider placement="bottom-right" autoDismissTimeout={15000}>
              <Layout>
                <LatestContactUpdatedContextProvider>
                  <Route exact path="/contacts" component={Contacts} />
                  <Route
                    exact
                    path="/contacts/states/:state/departments/:department/underwriters/:underwriter"
                    component={Contacts}
                  />
                  <Route
                    exact
                    path="/contacts/states/:state/departments/:department"
                    component={Contacts}
                  />
                  <Route
                    path="/contacts/new-contact/states/:state/departments/:department"
                    component={EditAddContact}
                  />
                  <Route
                    path="/contacts/new-company/states/:state/departments/:department"
                    component={EditAddCompany}
                  />
                  <Route
                    path="/contacts/edit-contact/:id"
                    component={EditAddContact}
                  />
                  <Route
                    path="/contacts/edit-company/:id"
                    component={EditAddCompany}
                  />{" "}
                  <UnderwriterContextProvider>
                    <SearchWordsContextProvider>
                      <Route exact path="/" component={Search} />
                      <Route exact path="/search" component={Search} />
                      <Route
                        exact
                        path="/search/states/:state"
                        component={Search}
                      />
                      <Route
                        exact
                        path="/search/states/:state/words/:word"
                        component={Search}
                      />
                      <Route
                        exact
                        path="/search/states/:state/words"
                        component={Search}
                      />
                      <Route
                        exact
                        path="/search/states/:state/filter/:filter"
                        component={Search}
                      />
                      <Route
                        exact
                        path="/search/states/:state/words/:word/filter/:filter"
                        component={Search}
                      />
                    </SearchWordsContextProvider>
                    <Route exact path="/info" component={Info} />
                    <Route
                      exact
                      path="/info/states/:state/types/:type"
                      component={Info}
                    />
                    <Route
                      exact
                      path="/info/states/:state/types/:type/sections/:section"
                      component={Info}
                    />
                    <Route
                      exact
                      path="/info/states/:state/types/:type/sections/:section/categories/:category"
                      component={Info}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/states/:state/new-schedule-a-note"
                      component={EditAddScheduleANote}
                    />
                    <Route
                      path="/commitment-instructions/edit-schedule-a-note/:id"
                      component={EditAddScheduleANote}
                    />
                    <Route
                      path="/commitment-instructions/edit-schedule-bi-code/:id"
                      component={EditAddScheduleBICode}
                    />
                    <Route
                      path="/commitment-instructions/states/:state/new-schedule-bi-code/categories/:category"
                      component={EditAddScheduleBICode}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/edit-schedule-bi-note/:id"
                      component={EditAddScheduleBINote}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/states/:state/new-schedule-bi-note/categories/:category"
                      component={EditAddScheduleBINote}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/states/:state/add-state-label"
                      component={EditScheduleBIStateLabel}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/states/:state/edit-state-label"
                      component={EditScheduleBIStateLabel}
                    />
                    <Route
                      path="/commitment-instructions/edit-schedule-bii-code/:id"
                      component={EditAddScheduleBIICode}
                    />
                    <Route
                      path="/commitment-instructions/states/:state/new-schedule-bii-code/categories/:category"
                      component={EditAddScheduleBIICode}
                    />
                    <Route
                      path="/surveys/new-survey-exception-code/sections/:section"
                      component={EditAddSurveyExceptionCode}
                    />
                    <Route
                      path="/surveys/edit-survey-exception-code/:id"
                      component={EditAddSurveyExceptionCode}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/edit-schedule-bii-note/:id"
                      component={EditAddScheduleBIINote}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/states/:state/new-schedule-bii-note/categories/:category"
                      component={EditAddScheduleBIINote}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/edit-commitment-general-info-note/:id"
                      component={EditAddGeneralInfoNote}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/states/:state/new-commitment-general-info-note"
                      component={EditAddGeneralInfoNote}
                    />
                    <Route
                      exact
                      path="/info/states/:state/edit-lien"
                      component={EditLien}
                    />
                    <Route
                      exact
                      path="/info/new-additional-lien"
                      component={EditAddAdditionalLien}
                    />
                    <Route
                      exact
                      path="/info/edit-additional-lien/:id"
                      component={EditAddAdditionalLien}
                    />
                    <Route
                      exact
                      path="/info/edit-state-idiosyncrasy/:id"
                      component={EditAddStateIdiosyncrasy}
                    />
                    <Route
                      exact
                      path="/info/new-state-idiosyncrasy"
                      component={EditAddStateIdiosyncrasy}
                    />
                    <Route
                      exact
                      path="/info/edit-modification/:id"
                      component={EditAddModification}
                    />
                    <Route
                      exact
                      path="/info/new-modification"
                      component={EditAddModification}
                    />
                    <Route
                      exact
                      path="/info/new-recording"
                      component={EditAddRecording}
                    />
                    <Route
                      exact
                      path="/info/edit-recording/:id"
                      component={EditAddRecording}
                    />
                    <Route
                      exact
                      path="/info/new-high-liability-approval"
                      component={EditAddHighLiabilityApproval}
                    />
                    <Route
                      exact
                      path="/info/edit-high-liability-approval/:id"
                      component={EditAddHighLiabilityApproval}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/states/:state/edit-general-info"
                      component={EditGeneralInfo}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/new-helpful-wording/:id"
                      component={EditAddHelpfulWording}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-helpful-wording/:id"
                      component={EditAddHelpfulWording}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-search-guideline/:id"
                      component={EditAddSearchGuideline}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/new-search-guideline"
                      component={EditAddSearchGuideline}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-search-guideline-note/:id"
                      component={EditAddSearchGuidelineNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/new-search-guideline-note"
                      component={EditAddSearchGuidelineNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-remittance-targets/:id"
                      component={EditAddRemittanceTargets}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-remittance-target-note/:id"
                      component={EditAddRemittanceTargetNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-additional-split-remittance-target-note/:id"
                      component={EditAddRemittanceTargetNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-additional-over-ten-m-split-remittance-target-note/:id"
                      component={EditAddRemittanceTargetNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-additional-over-fifteen-m-split-remittance-target-note/:id"
                      component={EditAddRemittanceTargetNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-additional-over-twenty-five-m-split-remittance-target-note/:id"
                      component={EditAddRemittanceTargetNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-split-remittance-target-note/:id"
                      component={EditAddRemittanceTargetNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/new-remittance-target-note/:id"
                      component={EditAddRemittanceTargetNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/new-split-remittance-target-note/:id"
                      component={EditAddRemittanceTargetNote}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/new-general-rule/:id"
                      component={EditAddGeneralRule}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/edit-general-rule/:id"
                      component={EditAddGeneralRule}
                    />
                    <Route
                      exact
                      path="/commitment-instructions"
                      component={CommitmentInstructions}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/states/:state/sections/:section"
                      component={CommitmentInstructions}
                    />
                    <Route
                      exact
                      path="/commitment-instructions/states/:state/sections/:section/categories/:category"
                      component={CommitmentInstructions}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions"
                      component={OrderEntryInstructions}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/states/:state/sections/:section"
                      component={OrderEntryInstructions}
                    />
                    <Route
                      exact
                      path="/order-entry-instructions/states/:state/sections/:section/categories/:category"
                      component={OrderEntryInstructions}
                    />
                    <Route
                      exact
                      path="/policy-instructions"
                      component={PolicyInstructions}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/sections/:section"
                      component={PolicyInstructions}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/sections/:section/categories/:category/underwriters/:underwriterId"
                      component={PolicyInstructions}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/sections/:section/underwriters/:underwriterId"
                      component={PolicyInstructions}
                    />
                    <Route
                      exact
                      path="/policy-instructions/edit-general-info-note/:id"
                      component={EditAddPolicyGeneralInfoNote}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/new-general-info-note"
                      component={EditAddPolicyGeneralInfoNote}
                    />
                    <Route
                      exact
                      path="/policy-instructions/new-helpful-wording/:id"
                      component={EditAddPolicyHelpfulWording}
                    />
                    <Route
                      exact
                      path="/policy-instructions/edit-helpful-wording/:id"
                      component={EditAddPolicyHelpfulWording}
                    />
                    <Route
                      exact
                      path="/policy-instructions/edit-guidelines-to-omit/:underwriterId/:scheduleId"
                      component={EditAddBIGuidelinesToOmit}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/edit-guidelines-to-omit/:underwriterId/:scheduleId"
                      component={EditAddBIGuidelinesToOmit}
                    />
                    <Route
                      exact
                      path="/policy-instructions/add-guidelines-to-omit/:underwriterId/:scheduleId"
                      component={EditAddBIGuidelinesToOmit}
                    />
                    <Route
                      exact
                      path="/policy-instructions/edit-policy-bi-note/:id"
                      component={EditAddPolicyBINote}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/underwriters/:underwriter/new-policy-bi-note"
                      component={EditAddPolicyBINote}
                    />
                    <Route
                      exact
                      path="/policy-instructions/edit-policy-endorsement-note/:id"
                      component={EditAddPolicyEndorsementNote}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/underwriters/:underwriter/new-policy-endorsement-note"
                      component={EditAddPolicyEndorsementNote}
                    />
                    <Route
                      exact
                      path="/policy-instructions/edit-other-exception/:scheduleId"
                      component={EditAddOtherException}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/underwriters/:underwriterId/new-other-exception"
                      component={EditAddOtherException}
                    />
                    <Route
                      exact
                      path="/policy-instructions/states/:state/underwriters/:underwriter/new-endorsement"
                      component={EditAddEndorsement}
                    />
                    <Route
                      exact
                      path="/policy-instructions/edit-endorsement/:endorsementId"
                      component={EditAddEndorsement}
                    />
                    <Route
                      exact
                      path="/surveys/edit-survey-note/:id"
                      component={EditAddPolicySurveyNote}
                    />
                    <Route
                      exact
                      path="/surveys/states/:state/new-survey-note"
                      component={EditAddPolicySurveyNote}
                    />
                    <Route
                      exact
                      path="/surveys/edit-legal-description-note/:id"
                      component={EditAddSurveyLegalDescriptionNote}
                    />
                    <Route
                      exact
                      path="/surveys/states/:state/new-legal-description-note"
                      component={EditAddSurveyLegalDescriptionNote}
                    />
                    <Route exact path="/surveys" component={Surveys} />
                    <Route
                      exact
                      path="/surveys/states/:state"
                      component={Surveys}
                    />
                    <Route
                      exact
                      path="/surveys/states/:state/tabs/:tab"
                      component={Surveys}
                    />
                    <Route
                      path="/surveys/states/:state/tabs/:tab/categories/:category"
                      component={Surveys}
                    />
                    <Route
                      exact
                      path="/surveys/new-helpful-wording"
                      component={EditAddSurveyHelpfulWording}
                    />
                    <Route
                      exact
                      path="/surveys/edit-helpful-wording/:id"
                      component={EditAddSurveyHelpfulWording}
                    />
                    <Route exact path="/forms" component={Forms} />
                    <Route
                      exact
                      path="/forms/states/:state"
                      component={Forms}
                    />
                    <Route
                      exact
                      path="/forms/edit-form/:id"
                      component={EditAddForm}
                    />
                    <Route
                      exact
                      path="/forms/new-form"
                      component={EditAddForm}
                    />
                    <Route
                      exact
                      path="/training"
                      component={TrainingAndSystemTips}
                    />
                    <Route
                      exact
                      path="/training/:section"
                      component={TrainingAndSystemTips}
                    />
                    <Route
                      exact
                      path="/training/edit-training-media/:id"
                      component={EditAddTrainingMedia}
                    />
                    <Route
                      exact
                      path="/training/new-training-media"
                      component={EditAddTrainingMedia}
                    />
                    <Route
                      exact
                      path="/training/edit-system-tip/:id"
                      component={EditAddSystemTips}
                    />
                    <Route
                      exact
                      path="/training/new-system-tip"
                      component={EditAddSystemTips}
                    />
                    <Route exact path="/rates" component={Rates} />
                    <Route
                      exact
                      path="/rates/states/:state/types/:type"
                      component={Rates}
                    />
                    <Route
                      exact
                      path="/rates/states/:state/types/:type/sections/:section"
                      component={Rates}
                    />
                  </UnderwriterContextProvider>
                </LatestContactUpdatedContextProvider>
                <Route
                  exact
                  path="/rates/new-invoice-charge/:id"
                  component={EditAddInvoiceCharge}
                />
                <Route
                  exact
                  path="/rates/edit-invoice-charge/:id"
                  component={EditAddInvoiceCharge}
                />
                <Route
                  exact
                  path="/rates/states/:state/edit-withholding-tax"
                  component={EditWithholdingTax}
                />
                <Route
                  exact
                  path="/rates/states/:state/edit-mortgage-tax"
                  component={EditMortgageTax}
                />
                <Route
                  exact
                  path="/rates/states/:state/edit-transfer-tax"
                  component={EditTransferTax}
                />
                <Route
                  exact
                  path="/rates/edit-agency-agreement/:id"
                  component={EditAddAgencyAgreement}
                />
                <Route
                  exact
                  path="/rates/new-agency-agreement"
                  component={EditAddAgencyAgreement}
                />
                <Route
                  exact
                  path="/rates/edit-agency-agreement-note/:id"
                  component={EditAddAgencyAgreementNote}
                />
                <Route
                  exact
                  path="/rates/new-agency-agreement-note/states/:state"
                  component={EditAddAgencyAgreementNote}
                />
                <Route exact path="/rates/new-manual" component={AddManual} />
                <Route
                  exact
                  path="/rates/new-op-endorsement"
                  component={AddOPEndorsement}
                />
                <Route
                  exact
                  path="/rates/new-op-underwriter"
                  component={AddOPUnderwriter}
                />
                <Route
                  exact
                  path="/rates/new-lp-endorsement"
                  component={AddLPEndorsement}
                />
                <Route
                  exact
                  path="/rates/new-lp-underwriter"
                  component={AddLPUnderwriter}
                />
                <Route
                  exact
                  path="/rates/states/:state/new-op-endorsement-note"
                  component={EditAddOPEndorsementNote}
                />
                <Route
                  exact
                  path="/rates/edit-op-endorsement-note/:id"
                  component={EditAddOPEndorsementNote}
                />
                <Route
                  exact
                  path="/rates/states/:state/new-lp-endorsement-note"
                  component={EditAddLPEndorsementNote}
                />
                <Route
                  exact
                  path="/rates/edit-lp-endorsement-note/:id"
                  component={EditAddLPEndorsementNote}
                />
                <Route
                  exact
                  path="/rates/new-calculator"
                  component={AddCalculator}
                />
                <Route
                  exact
                  path="/rates/edit-premium"
                  component={EditPremium}
                />
                <Route
                  exact
                  path="/rates/new-quote-template"
                  component={AddQuoteTemplate}
                />
                <Route exact path="/admin" component={Admin} />
                <Route exact path="/admin/underwriters" component={Admin} />
                <Route exact path="/admin/title-companies" component={Admin} />
                <Route
                  exact
                  path="/admin/edit-underwriter/:id"
                  component={EditAddUnderwriter}
                />
                <Route
                  exact
                  path="/admin/edit-title-company/:id"
                  component={EditAddTitleCompany}
                />
                <Route
                  exact
                  path="/admin/new-title-company"
                  component={EditAddTitleCompany}
                />
                <Route
                  exact
                  path="/admin/new-underwriter"
                  component={EditAddUnderwriter}
                />
                <Route exact path="/websites" component={Websites} />
                <Route
                  exact
                  path="/websites/states/:state"
                  component={Websites}
                />
                <Route
                  path="/websites/edit-website/:id"
                  component={EditAddWebsite}
                />
                <Route
                  path="/websites/new-website"
                  component={EditAddWebsite}
                />
                <Route path="/index" component={Index} />
              </Layout>
            </ToastProvider>
          </StateContextProvider>
        </UserContextProvider>
      </RouterLoggerHOC>
    );
  }
}
