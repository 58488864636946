import React, { useState, useContext, useEffect } from "react";
import { Accordion, Icon } from "semantic-ui-react";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { css } from "emotion";
import { withRouter } from "react-router-dom";
import { Highlight } from "../components/search/Highlight";
import { convertToFileDownload } from "../helpers/ConvertToFileDownload";
import { getRequest, postRequest } from "../helpers/ApiHelpers";
import { paragraphStyles } from "../components/info/policyInstructions/paragraphStyles";
import { labelStyles } from "../components/info/policyInstructions/labelStyles";
import { UserContext } from "../components/User";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";

function NoteAccordion({
  history,
  match: { params },
  notes,
  setNotes,
  deleteAction,
  showAll,
  downloadURL,
  saveSortURL,
  editURL
}) {
  const [activeIndexes, setActiveIndexes] = useState([]);
  const { user } = useContext(UserContext);
  const userCanEdit = user.isAdmin || user.isOwner || user.isOwnerPlus;

  const handleClick = (newIndex) => {
    if (activeIndexes.some((i) => i === newIndex)) {
      const removedIndexList = activeIndexes.filter((i) => i !== newIndex);
      setActiveIndexes(removedIndexList);
    } else {
      const addedIndexList = [...activeIndexes, newIndex];
      setActiveIndexes(addedIndexList);
    }
  };

  async function saveSort(notes) {
    postRequest(saveSortURL, notes);
  }

  async function download(id) {
    const result = await getRequest(`${downloadURL}?id=${id}`);
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  useEffect(() => {
    if (showAll) {
      const indexes = notes.map((n) => n.id);
      setActiveIndexes(indexes);
    } else {
      setActiveIndexes([]);
    }
  }, [showAll]);

  const DragHandle = SortableHandle(() => (
    <span
      className={css({
        marginRight: 8,
        color: "#c3c3c3",
        cursor: "grab"
      })}
    >
      <i className="fal fa-sort" />
    </span>
  ));

  const SortableItem = SortableElement(({ value: n }) => {
    const isActive = activeIndexes.some((i) => i === n.id);
    return (
      <>
        <Accordion.Title
          active={isActive}
          index={0}
          onClick={() => handleClick(n.id)}
          key={n.id}
        >
          {userCanEdit && <DragHandle />}
          <Icon name="dropdown" />
          {n.subject}
        </Accordion.Title>
        <Accordion.Content active={isActive}>
          <Card.Group itemsPerRow={1} key={n.id}>
            <Card
              style={{
                marginLeft: 0,
                marginRight: 0,
                backgroundColor: n.highlight ? "#00b7d326" : null
              }}
            >
              <Card.Content>
                <label
                  className={css({
                    color: "#707898",
                    fontSize: 14,
                    fontFamily: "Fakt-Bold",
                    lineHeight: "17px"
                  })}
                >
                  {n.subject ? <Highlight children={n.subject} /> : "Note"}
                </label>
                <p className={paragraphStyles}>
                  <Highlight children={n.note} />
                </p>
                {n.files && n.files.length > 0 && (
                  <div className={labelStyles}>Attachments</div>
                )}
                {n.files &&
                  n.files.map((f) => (
                    <p className={paragraphStyles} key={f.id}>
                      <a
                        onClick={() => download(f.id)}
                        className={css({
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#00b8d4"
                          }
                        })}
                      >
                        <i
                          className="fal fa-paperclip"
                          style={{ color: "#00b8d4", marginRight: 8 }}
                        />
                        <Highlight children={f.fileName} />
                      </a>
                    </p>
                  ))}
              </Card.Content>
              {userCanEdit && (
                <React.Fragment>
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer",
                      position: "absolute",
                      right: 36,
                      top: 31
                    })}
                    onClick={() => history.push(`${editURL}/${n.id}`)}
                  >
                    <Icon className="fal fa-pen" />
                  </span>
                  {deleteAction && (
                    <span
                      className={css({
                        "&:hover i": { color: "#00b8d4" },
                        cursor: "pointer",
                        position: "absolute",
                        right: 56,
                        top: 31
                      })}
                      onClick={() => deleteAction(n)}
                    >
                      <Icon className="fal fa-trash" />
                    </span>
                  )}
                </React.Fragment>
              )}
            </Card>
          </Card.Group>
        </Accordion.Content>
      </>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) => {
          return (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              value={value}
            />
          );
        })}
      </div>
    );
  });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const sortedNotes = arrayMove(notes, oldIndex, newIndex).map((n, i) => ({
      ...n,
      sortNum: i
    }));
    saveSort(sortedNotes);
    const updatedNotes = notes.map((gi) => {
      const sortedNote = sortedNotes.find((n) => n.id === gi.id);
      if (sortedNote) {
        return sortedNote;
      } else {
        return gi;
      }
    });
    updatedNotes.sort((a, b) => a.sortNum - b.sortNum);
    setNotes(updatedNotes);
  };

  return (
    <Accordion>
      <SortableList items={notes} onSortEnd={onSortEnd} useDragHandle />
    </Accordion>
  );
}

export default withRouter(NoteAccordion);
