import React, { useState, useEffect, useContext } from "react";
import SearchGuidelines from "../orderEntryInstructions/SearchGuidelines";
import { Button, Grid, Modal, Label } from "semantic-ui-react";
import { css } from "emotion";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import { withRouter } from "react-router-dom";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import NoteAccordion from "../../NoteAccordion";
import DragSortNotes from "./DragSortNotes";

function ScheduleA({ history, match }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  const [state, setState] = useState({
    scheduleA: {},
    attorneyState: undefined
  });

  const [showAll, setShowAll] = useState(false);
  const [notes, setNotes] = useState([]);
  const [legalDescriptionNotes, setLegalDescriptionNotes] = useState([]);

  async function getGeneralInfoByState(stateId) {
    const result = await getRequest(`/generalInfo/getGeneralInfoByState`, {
      stateId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        attorneyState:
          result.data.licensedAndUnlicensed &&
          result.data.licensedAndUnlicensed.includes("Attorney State")
      }));
    }
  }

  async function getScheduleANotesByState(stateId) {
    const result = await getRequest(`/scheduleA/getScheduleANotesByState`, {
      stateId
    });
    if (result.success) {
      setNotes(result.data.filter((d) => d.scheduleANoteTypeId !== 3));
      setLegalDescriptionNotes(
        result.data.filter((d) => d.scheduleANoteTypeId === 3)
      );
    }
  }

  useEffect(() => {
    getScheduleANotesByState(selectedState);
    getGeneralInfoByState(selectedState);
  }, [match.params.state, selectedState]);

  async function deleteScheduleANote(id) {
    const result = await postRequest(`/scheduleA/deleteScheduleANote?id=${id}`);
    if (result.success) {
      setNotes(notes.filter((n) => n.id !== id));
      setLegalDescriptionNotes(
        legalDescriptionNotes.filter((n) => n.id !== id)
      );
      setState((state) => ({
        ...state,
        noteToDelete: null
      }));
    }
  }

  const isCollapsible = selectedState === 52;

  return (
    <React.Fragment>
      {(notes.length > 0 || legalDescriptionNotes.length > 0) && isCollapsible && (
        <Button
          basic
          onClick={() => setShowAll((showAll) => !showAll)}
          style={{ marginBottom: 32 }}
        >
          {showAll ? "Collapse All" : "Open All"}
        </Button>
      )}
      <Grid style={{ marginBottom: 17 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column
            floated="left"
            className={css({
              textAlign: "left",
              color: "#47517B",
              fontSize: 20,
              fontFamily: "Fakt-Bold"
            })}
          >
            Schedule A
          </Grid.Column>
          {userCanEdit && (
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                icon
                className="blueButton"
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-schedule-a-note`
                  )
                }
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-schedule-a-note`
                  )
                }
              >
                New Info
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {state.attorneyState && (
        <Label
          className="primaryLabel"
          style={{ paddingLeft: 24, paddingRight: 24, marginBottom: 16 }}
        >
          Attorney State
        </Label>
      )}
      {notes.length > 0 && (
        <div className={css({ marginBottom: "0.875em", marginTop: "0.875em" })}>
          {!isCollapsible && (
            <DragSortNotes
              items={notes}
              saveSortPath="/scheduleA/sortNotes"
              draggable={userCanEdit}
              editable={userCanEdit}
              editNotePath={`/commitment-instructions/edit-schedule-a-note`}
              downloadUrl={`/scheduleA/downloadFile`}
              deleteAction={(value) =>
                setState((state) => ({ ...state, noteToDelete: value }))
              }
            />
          )}
          {isCollapsible && (
            <NoteAccordion
              notes={notes}
              setNotes={setNotes}
              deleteAction={(value) =>
                setState((state) => ({ ...state, noteToDelete: value }))
              }
              showAll={showAll}
              downloadURL="/scheduleA/downloadFile"
              saveSortURL="/scheduleA/sortNotes"
              editURL="/commitment-instructions/edit-schedule-a-note"
            />
          )}
        </div>
      )}
      {legalDescriptionNotes.length > 0 && (
        <div
          className={css({
            textAlign: "left",
            color: "#47517B",
            fontSize: 20,
            fontFamily: "Fakt-Bold",
            marginTop: 32,
            marginBottom: 8
          })}
        >
          Legal Description
        </div>
      )}
      {legalDescriptionNotes.length > 0 && (
        <div className={css({ marginBottom: "0.875em", marginTop: "0.875em" })}>
          {!isCollapsible && (
            <DragSortNotes
              items={legalDescriptionNotes}
              saveSortPath="/scheduleA/sortNotes"
              draggable={userCanEdit}
              editable={userCanEdit}
              editNotePath={`/commitment-instructions/edit-schedule-a-note`}
              downloadUrl={`/scheduleA/downloadFile`}
              deleteAction={(value) =>
                setState((state) => ({ ...state, noteToDelete: value }))
              }
            />
          )}
          {isCollapsible && (
            <NoteAccordion
              notes={legalDescriptionNotes}
              setNotes={setLegalDescriptionNotes}
              deleteAction={(value) =>
                setState((state) => ({ ...state, noteToDelete: value }))
              }
              showAll={showAll}
              downloadURL="/scheduleA/downloadFile"
              saveSortURL="/scheduleA/sortNotes"
              editURL="/commitment-instructions/edit-schedule-a-note"
            />
          )}
        </div>
      )}
      <SearchGuidelines editable={false} />
      {state.noteToDelete && (
        <Modal
          size={"tiny"}
          open={state.noteToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, noteToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              {state.noteToDelete.subject && (
                <strong>{state.noteToDelete.subject}</strong>
              )}
              ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, noteToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteScheduleANote(state.noteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(ScheduleA);
