import React, {useState, useEffect, useContext} from "react";
import {css} from "emotion";
import {
    Grid,
    Form,
    Header,
    Input,
    TextArea,
    Message,
    Button
} from "semantic-ui-react";
import {getRequest, postRequest} from "../../helpers/ApiHelpers";
import { apiPost } from "../../adalConfig"
import {withRouter} from "react-router-dom";
import {UserContext} from "../User";
import FileUpload from "../FileUpload";
import {logEvent} from "../../helpers/analyticsLogger";

export function EditAddTrainingMedia({match, history}) {
    const {user} = useContext(UserContext);
    const defaultTrainingMedia = {
        id: undefined,
        name: undefined,
        note: undefined,
        fileName: undefined,
        filePath: undefined
    };
    const [state, setState] = useState({
        submitAttempted: false,
        submitting: false,
        trainingMedia: defaultTrainingMedia,
        file: null,
        filesToDelete: [],
        editMode: match.path === "/training/edit-training-media/:id",
        errorList: []
    });

    async function getTrainingMediaById(id) {
        const result = await getRequest(`/trainingMedia/getTrainingMediaById`, {
            id
        });
        if (result.success) {
            setState((state) => ({
                ...state,
                trainingMedia: result.data
            }));
        }
    }

    useEffect(() => {
        state.editMode && getTrainingMediaById(match.params.id);
    }, []);

    async function uploadFileInChunks(file) {
        const CHUNK_SIZE = 1024 * 1024 * 5; // Chunk size is 5MB
        let start = 0;
        
        async function sendChunk(fileName) {
            const blob = file.slice(start, start + CHUNK_SIZE);
            
            const formData = new FormData();
            formData.append('file', blob);
            
            // Send the data to the server
            await apiPost(`/api/TrainingMedia/uploadfile/${fileName}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            // Move to the next chunk
            start += CHUNK_SIZE;
            if (start < file.size) {
                await sendChunk(fileName);
            }
        }

        function getRandomFileName(length) {
            const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            return [...Array(length)].map(_ => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
        }
        
        const fileName = getRandomFileName(10); 
        await sendChunk(fileName);
        return fileName;
    }

    async function saveChanges() {
        if (!state.submitting) {
            setState((state) => ({
                ...state,
                submitAttempted: true,
                submitting: !state.errorList.length > 0
            }));
            
            if (!state.errorList.length > 0) {
                const fileName = await uploadFileInChunks(state.file);
                const fileExtension = state.file
                    ? state.file.name.split(".").pop()
                    : "";

                const result = await postRequest(
                    `/trainingMedia/${
                        state.editMode ? "editTrainingMedia" : "addTrainingMedia"
                    }`,
                    {
                        ...state.trainingMedia,
                        fileName:
                            (state.file && state.file.length > 0) ||
                            (state.file && state.file.path)
                                ? state.file.name
                                : state.trainingMedia.fileName,
                        fileExtension,
                        holdingFile: fileName
                    }
                );

                if (result.success) {
                    logEvent(null, state.editMode ? "Edited" : "Added", "TrainingMedias");
                    setTimeout(function () {
                        history.goBack();
                    }, 1100);
                }
            }
        }
    }

    function getErrorList() {
        let list = [];
        if (!state.trainingMedia.name) {
            list.push("You must provide a name.");
        }
        return list;
    }

    useEffect(() => {
        setState((state) => ({...state, errorList: getErrorList()}));
    }, [state.trainingMedia]);

    return (
        <div className={css({width: 605, paddingBottom: 34})}>
            <Header style={{fontSize: 24, marginBottom: 41}} as="h3">
                {`${state.editMode ? "Edit" : "New"} Training Media`}
            </Header>
            <Form>
                <Grid>
                    <Grid.Row column={1}>
                        <Grid.Column>
                            <Form.Field required>
                                <label>Name</label>
                                <Input
                                    placeholder="Name"
                                    size="large"
                                    value={state.trainingMedia.name}
                                    onChange={(_, {value}) =>
                                        setState(() => ({
                                            ...state,
                                            trainingMedia: {...state.trainingMedia, name: value}
                                        }))
                                    }
                                    error={
                                        state.submitAttempted &&
                                        (state.trainingMedia.name === "" ||
                                            state.trainingMedia.name === undefined)
                                    }
                                    autoComplete="new-password"
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Form.Field>
                                <label>Note</label>
                                <TextArea
                                    placeholder="Note"
                                    style={{minHeight: 96}}
                                    value={state.trainingMedia.note}
                                    onChange={(_, {value}) =>
                                        setState((state) => ({
                                            ...state,
                                            trainingMedia: {
                                                ...state.trainingMedia,
                                                note: value
                                            }
                                        }))
                                    }
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    {!state.editMode && (
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Form.Field>
                                    <FileUpload
                                        setFileToApp={(file) =>
                                            setState((state) => ({...state, file}))
                                        }
                                        fileList={state.file ? [state.file] : []}
                                        deleteFileToApp={() =>
                                            setState((state) => ({
                                                ...state,
                                                file: null
                                            }))
                                        }
                                    />
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {state.editMode && state.trainingMedia.fileName && (
                        <p
                            className={css({
                                "&:hover": {color: "#707898"},
                                cursor: "pointer"
                            })}
                        >
                            {state.trainingMedia.fileName}
                        </p>
                    )}
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Message
                                visible={state.submitAttempted && state.errorList.length > 0}
                                error
                                header="There was some errors with your submission"
                                list={state.errorList}
                                data-automation="message"
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column
                            floated="right"
                            className={css({textAlign: "right"})}
                        >
                            <Button
                                size="large"
                                className="cancelButton"
                                onClick={() => history.goBack()}
                                data-automation="cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                size="large"
                                className="saveButton"
                                onClick={() => saveChanges()}
                                data-automation="save"
                            >
                                {state.submitting ? "Submitting..." : "Save"}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </div>
    );
}

export default withRouter(EditAddTrainingMedia);
