export default function getPAStateId(selectedState, selectedUnderwriter) {
  if (selectedState === 39) {
    if (selectedUnderwriter === 4) {
      return 56;
    } else if (selectedUnderwriter === 3 || selectedUnderwriter === 8) {
      return 55;
    } else {
      return selectedState;
    }
  } else {
    return selectedState;
  }
}
