import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Input } from "semantic-ui-react";
import { StateContext } from "../../State";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import shortid from "shortid";
import { logEvent } from "../../../helpers/analyticsLogger";
css({
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  marginBottom: 17,
  lineHeight: 1.71
});
export function EditAddAdditionalLien({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    additionalLien: {},
    additionalLienFiles: [],
    additionalLienFilesToDelete: [],
    editMode: match.path === "/info/edit-additional-lien/:id"
  });

  async function getAdditionalLienById(id) {
    const result = await getRequest(`/additionalLien/getAdditionalLienById`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        additionalLien: result.data
      }));
    }
  }

  useEffect(() => {
    if (state.editMode) {
      getAdditionalLienById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState(state => ({ ...state, submitting: true }));
      const result = await postRequest(
        "/additionalLien/editAddAdditionalLien",
        {
          ...state.additionalLien,
          stateId: state.editMode
            ? state.additionalLien.stateId
            : selectedState,
          additionalLienFiles: await Promise.all(
            state.additionalLienFiles.map(async f => ({
              base64File: await readFileAsBase64(f),
              fileName: f.name
            }))
          ),
          additionalLienFilesToDelete: state.additionalLienFilesToDelete
        }
      );
      if (result.success) {
        logEvent(
          null,
          state.editMode ? "Edited" : "Added",
          "Additional Lien"
        );
        setTimeout(() => history.goBack(), 1000);
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        Additional Lien
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Name</label>
                <Input
                  size="large"
                  value={state.additionalLien.name}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      additionalLien: {
                        ...state.additionalLien,
                        name: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    (state.additionalLien.name === "" ||
                      state.additionalLien.name === null)
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Duration</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.additionalLien.duration}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      additionalLien: {
                        ...state.additionalLien,
                        duration: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.additionalLien.note}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      additionalLien: {
                        ...state.additionalLien,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.additionalLienFiles}
                  setFileToApp={files =>
                    setState(state => ({
                      ...state,
                      additionalLienFiles: [
                        ...state.additionalLienFiles,
                        ...files
                      ]
                    }))
                  }
                  deleteFileToApp={fileName =>
                    setState(state => ({
                      ...state,
                      files: [...state.files.filter(f => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.additionalLien.additionalLienFiles &&
                state.additionalLien.additionalLienFiles.map(f => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        additionalLienFilesToDelete: [
                          ...state.additionalLienFilesToDelete,
                          f.id
                        ],
                        additionalLien: {
                          ...state.additionalLien,
                          additionalLienFiles: [
                            ...state.additionalLien.additionalLienFiles.filter(
                              alf => alf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}
