import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { logEvent } from "../../../helpers/analyticsLogger";

const defaultPremium = {
  isPremiumNegotiable: null
};

export function EditPremium({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    premium: defaultPremium
  });

  async function getPremiumByState(stateId) {
    const result = await getRequest(`/premiumCalculator/getPremiumByState`, {
      stateId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        premium: result.data
      }));
    }
  }

  useEffect(() => {
    getPremiumByState(selectedState);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({ ...state, submitting: true }));
      const result = await postRequest("/premiumCalculator/editPremium", {
        ...state.premium,
        stateId: selectedState
      });
      if (result.success) {
        logEvent(null, "Edited", "Premium");
        setTimeout(() => history.goBack(), 1000);
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        Premium
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Is premium negotiable?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.premium.isPremiumNegotiable}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      premium: {
                        ...state.premium,
                        isPremiumNegotiable: !state.premium.isPremiumNegotiable
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.premium.isPremiumNegotiable === false ? true : false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      premium: {
                        ...state.premium,
                        isPremiumNegotiable:
                          state.premium.isPremiumNegotiable === false
                            ? true
                            : false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.premium.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      premium: {
                        ...state.premium,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditPremium);
