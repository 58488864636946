import React, { useContext } from "react";
import { css } from "emotion";
import { Button, Header, Grid, Card, Label } from "semantic-ui-react";
import { UserContext } from "../../User";
import { withRouter } from "react-router-dom";

function TaxCard({ title, isApplicable, children, editRoute, history }) {
  const { user } = useContext(UserContext);
  return (
    <Card.Group itemsPerRow={1}>
      <Card>
        <Card.Content>
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              <Grid.Column floated="left">
                <Header
                  as="h3"
                  className={css({
                    color: "#47517b !important",
                    fontSize: "20px !important",
                    marginBottom: "0px !important"
                  })}
                >
                  {title}{" "}
                  {isApplicable !== null &&
                    isApplicable !== undefined &&
                    (isApplicable ? (
                      <Label className="primaryLabel">Applicable</Label>
                    ) : (
                      <Label className="secondaryLabel">Not Applicable</Label>
                    ))}
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                {(user.isOwner || user.isOwnerPlus) && (
                  <Button icon onClick={() => history.push(editRoute)}>
                    <i className="fal fa-pencil" />
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {children && (
            <div
              className={css({
                marginTop: 24
              })}
            >
              {children}
            </div>
          )}
        </Card.Content>
      </Card>
    </Card.Group>
  );
}

export default withRouter(TaxCard);
