import React, { useState, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { css } from "emotion";
import { Button } from "semantic-ui-react";
import shortid from "shortid";

export default function FileUpload({
  setFileToApp,
  deleteFileToApp,
  multiple,
  fileList,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    setFileToApp(multiple ? acceptedFiles : acceptedFiles[0]);
  });

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  });

  const removeFile = (fileName) => {
    deleteFileToApp(fileName);
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00b8d4",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const baseStyle = {
    padding: "51px 0 52px 0",
    textAlign: "center",
    borderRadius: 4,
    borderWidth: "2px",
    borderStyle: "dashed",
    borderColor: "#e5e5ea",
    outline: "none",
    height: 192,
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject]
  );

  const files = fileList.map((file) => (
    <div
      key={shortid.generate()}
      className={css({
        "& i:hover": {
          opacity: 0.6,
        },
        color: "#444444",
        fontSize: 14,
        lineHeight: 1.5,
      })}
    >
      {file.path}
      <i
        style={{
          position: "absolute",
          top: 16,
          right: 16,
          cursor: "pointer",
          zIndex: 1000,
        }}
        className="fal fa-times"
        onClick={() => removeFile(file.path)}
      />
    </div>
  ));

  return (
    <section>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p
          className={css({
            fontFamily: "Fakt-Bold",
            lineHeight: 1.71,
            fontSize: 14,
            marginBottom: 16,
          })}
        >
          {`Drag 'n' drop, or click to select file${multiple ? "s" : ""}`}
        </p>
        <Button size="large" className={css({ color: "#fff !important" })}>
          {`Select file${multiple ? "s" : ""}`}
        </Button>
      </div>
      {files.length > 0 &&
        files.map((file) => (
          <div
            key={shortid.generate()}
            className={css({
              padding: "13px 16px",
              backgroundColor: "#f7f7f9",
              borderRadius: 5,
              position: "relative",
              marginTop: 16,
            })}
          >
            {file}
          </div>
        ))}
    </section>
  );
}
