import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Modal } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import HelpfulWordingRowSort from "./HelpfulWordingRowSort";

function HelpfulWordings({ history, match: { params }, searchResults }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  const [state, setState] = useState({
    helpfulWordings: [],
    noResults: false,
    initialLoaded: false,
    wordingToDelete: null,
    helpfulWordingCategories: [],
    selectedCategory: +params.category || 1
  });

  async function getHelpfulWordings() {
    const result = await getRequest(
      "/policyHelpfulWording/getHelpfulWordingsByState",
      {
        stateId: selectedState
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWordings: result.data,
        initialLoaded: true
      }));
    }
  }

  async function getHelpfulWordingCategories() {
    const result = await getRequest(
      "/policyHelpfulWording/getHelpfulWordingCategories"
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWordingCategories: result.data
      }));
    }
  }

  async function deleteHelpfulWording(id) {
    const result = await postRequest(
      `/policyHelpfulWording/deleteHelpfulWording?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWordings: state.helpfulWordings.filter((w) => w.id !== id),
        wordingToDelete: null
      }));
    }
  }

  useEffect(() => {
    !searchResults && getHelpfulWordingCategories();
  }, []);

  useEffect(() => {
    if (selectedState) {
      !searchResults && getHelpfulWordings();
    }
  }, [selectedState]);

  useEffect(() => {
    !searchResults &&
      setState((state) => ({ ...state, selectedCategory: +params.category }));
  }, [params]);

  const filteredHelpfulWordings = searchResults
    ? [...searchResults.map((r) => r.document)]
    : state.helpfulWordings.filter(
        (w) => w.policyHelpfulWordingCategoryId === state.selectedCategory
      );

  return (
    <React.Fragment>
      {!searchResults && (
        <Grid>
          <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
            <Grid.Column floated="left" width={12}>
              <div
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 20,
                  lineHeight: 1.2,
                  color: "#47517b",
                  display: "inline-block",
                  marginRight: 64
                })}
              >
                <span className={css({ marginRight: 18, lineHeight: "36px" })}>
                  Helpful Wordings
                </span>
                {state.helpfulWordingCategories.map((c) => (
                  <Button
                    key={c.id}
                    className={css({
                      backgroundColor:
                        +state.selectedCategory === c.id
                          ? "#47517B !important"
                          : "#ebecf1!important    ",
                      color:
                        +state.selectedCategory === c.id
                          ? "#ffffff !important"
                          : "rgba(0, 0, 0, 0.87) !important"
                    })}
                    onClick={() =>
                      history.push(
                        `/policy-instructions/states/${selectedState}/sections/helpful-wordings/categories/${
                          c.id
                        }/underwriters/${+params.underwriterId}`
                      )
                    }
                  >
                    {c.name}:
                  </Button>
                ))}
              </div>
            </Grid.Column>
            {userCanEdit && (
              <Grid.Column
                floated="right"
                width={3}
                className={css({ textAlign: "right" })}
              >
                <Button
                  icon
                  className="blueButton"
                  onClick={() =>
                    history.push(
                      `/policy-instructions/new-helpful-wording/${selectedState}`
                    )
                  }
                >
                  <i className="fal fa-plus" />
                </Button>
                <span
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 14,
                    color: "#000",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00b8d4"
                    }
                  })}
                  onClick={() =>
                    history.push(
                      `/policy-instructions/new-helpful-wording/${selectedState}`
                    )
                  }
                >
                  New Wording
                </span>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
      <HelpfulWordingRowSort
        helpfulWordings={filteredHelpfulWordings}
        searchResults={searchResults}
        setHelpfulWordings={(helpfulWordings) =>
          setState((state) => ({ ...state, helpfulWordings }))
        }
        deleteHelpfulWording={(w) =>
          setState((state) => ({ ...state, wordingToDelete: w }))
        }
      />
      {state.wordingToDelete && (
        <Modal
          size={"tiny"}
          open={state.wordingToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, wordingToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Wording</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete <br />
              <strong>{state.wordingToDelete.subject}</strong> ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, wordingToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteHelpfulWording(state.wordingToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(HelpfulWordings);
