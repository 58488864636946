import React from "react";
import Pop from "reactjs-popup";

const contentStyle = {
  width: 750,
  whiteSpace: "pre-wrap",
  fontSize: 16,
  padding: 16,
  maxHeight: "85vh",
  overflowY: "scroll",
  overflowX: "hidden",
  boxShadow: "rgba(0, 0, 0, 0.16) 0px 0px 3px",
  borderRadius: 8,
  backgroundColor: "#fff",
  wordBreak: "break-word"
};

export function Popup({ trigger, on, header, content }) {
  return (
    <Pop
      keepTooltipInside=".tooltipBoundary"
      position={["top center", "bottom right", "bottom left"]}
      on={on}
      trigger={<div>{trigger}</div>}
      {...{ contentStyle }}
    >
      <h3 style={{ marginTop: 0, fontFamily: "Fakt-Bold" }}>{header}</h3>
      <p>{content}</p>
    </Pop>
  );
}
