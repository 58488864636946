import React from "react";
import { css } from "emotion";
import { Table } from "semantic-ui-react";

export default function CustomTable({
  children,
  customStyles,
  fixed = true,
  compact,
  mediumCompact,
  extraCompact,
}) {
  return (
    <Table
      fixed={fixed}
      striped
      celled
      textAlign="center"
      className={css({
        boxShadow: "0 8px 11px -8px rgba(112, 120, 152, 0.13) !important",
        "& tr th:not(:first-of-type), tr td:not(:first-of-type)": {
          borderLeft: "solid 1px #c2c5d3 !important",
        },
        "& tr th": {
          borderTop: "none !important",
          borderBottom: "none !important",
          paddingTop: "25px !important",
          paddingBottom: "25px !important",
          paddingRight: compact
            ? "15px !important"
            : extraCompact
            ? "36px !important"
            : mediumCompact
            ? "25px !important"
            : "53px !important",
          paddingLeft: compact
            ? "15px !important"
            : extraCompact
            ? "36px !important"
            : mediumCompact
            ? "25px !important"
            : "53px !important",
          whiteSpace: "pre-wrap !important",
          fontFamily: "FaktSlab-Normal !important",
          fontWeight: "normal !important",
          fontSize: "16px !important",
          backgroundColor: "#ebecf1 !important",
        },
        "& tr td": {
          borderTop: "none !important",
          borderBottom: "none !important",
          paddingTop: extraCompact ? "8px !important" : "25px !important",
          paddingBottom: extraCompact ? "8px !important" : "25px !important",
          paddingRight: compact
            ? "15px !important"
            : extraCompact
            ? "36px !important"
            : mediumCompact
            ? "25px !important"
            : "53px !important",
          paddingLeft: compact
            ? "15px !important"
            : extraCompact
            ? "36px !important"
            : mediumCompact
            ? "25px !important"
            : "53px !important",
          whiteSpace: "pre-wrap !important",
        },
        "& tr:first-of-type > th:first-of-type, tr:first-of-type > th:last-child": {
          borderRadius: "0px !important",
        },
        "& > tr:nth-of-type(2n), tr:nth-of-type(2n)": {
          backgroundColor: "#f5f6f8 !important",
        },
        "& tr td:first-of-type": {
          paddingLeft: compact
            ? "35px !important"
            : extraCompact
            ? "36px !important"
            : "69px !important",
          textAlign: extraCompact ? "left" : "inherit",
        },
        "& th, td": {
          textOverflow: "unset !important",
        },
        ...customStyles,
      })}
    >
      {children}
    </Table>
  );
}
