import React from "react";

export default function LogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
    >
      <g fill="#000E48" fillRule="evenodd">
        <path d="M.25 1.609h17.272V0H.25zM4.061 19.73H5.67V8.042H4.06zM8.082 19.73h1.609V8.042H8.082zM12.103 19.73h1.609V8.042h-1.61zM15.486 5.63h-13.2a.803.803 0 1 1 0-1.61h13.2a.804.804 0 0 1 0 1.61" />
      </g>
    </svg>
  );
}
