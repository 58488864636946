import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import {
  Button,
  Grid,
  Loader,
  Modal,
  Table,
  Message,
  Checkbox,
  Form,
  Radio,
} from "semantic-ui-react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../User";
import { StateContext } from "../State";
import StateDropdown from "../StateDropdown";
import CustomTable from "../CustomTable";
import LinesEllipsis from "react-lines-ellipsis";
import { convertToFileDownload } from "../../helpers/ConvertToFileDownload";
import { Highlight } from "../search/Highlight";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { Popup } from "../Popup";

const emailLinkStyles = css({
  fontSize: 14,
  lineHeight: 1.71,
  color: "#00b8d4",
  marginLeft: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

function Forms({ history, match: { params }, formSearchResults, categories }) {
  const { user } = useContext(UserContext);
  const { addToast } = useToasts();
  const { selectedState, states } = useContext(StateContext);
  if (!formSearchResults && (!params.state || params.state === "no_state")) {
    history.replace(`forms/states/${selectedState || 31}`);
  }
  const [state, setState] = useState({
    states: [],
    forms: [],
    initialLoading: true,
    noResults: false,
    formToDelete: null,
    categories: categories ? categories : [],
    deleteConfirmationError: false,
    formsToEmail: formSearchResults
      ? formSearchResults.map((f) => {
          return {
            id: f.id,
            shouldEmail: false,
            formCategoryId: f.formCategoryId,
          };
        })
      : [],
    confirmEmail: false,
    alertEmptyEmail: false,
    mergeDocs: false,
    downloading: false,
  });

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data,
      }));
    }
  }

  async function getFormsByState() {
    const result = await getRequest("/form/getFormsByState", {
      stateId: selectedState,
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        forms: result.data,
        formsToEmail: result.data.map((f) => {
          return {
            id: f.id,
            shouldEmail: false,
            formCategoryId: f.formCategoryId,
            fileName: f.fileName,
          };
        }),
        categories: [
          ...new Set(result.data.map((d) => d.formCategoryId)),
        ].sort(),
        initialLoading: false,
        noResults: result.data.length < 1,
      }));
    }
  }

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    if (!formSearchResults) {
      getFormsByState();
    }
  }, [selectedState]);

  const generateEmail = async () => {
    setState((state) => ({ ...state, downloading: true }));
    const result = await getRequest(`/form/emailForms`, {
      formIds: state.formsToEmail.filter((f) => f.shouldEmail).map((f) => f.id),
      senderEmail: user.mail,
      displayName: user.displayName,
      mergeDocs: state.mergeDocs,
      state: states.find((s) => s.id === selectedState).name,
      formType: state.categoryName,
    });
    if (result.success) {
      convertToFileDownload(result.data.msgFile, result.data.name);
    }
    setState((state) => ({ ...state, downloading: false }));
  };

  const emailForms = () => {
    const pdfForms = state.formsToEmail.filter(
      (f) => f.shouldEmail && f.fileName.includes(".pdf")
    );
    const formsLength = state.formsToEmail
      .filter((f) => f.shouldEmail)
      .map((f) => f.id).length;
    if (pdfForms.length > 1) {
      setState((state) => ({ ...state, confirmEmail: true }));
    } else if (formsLength > 0) {
      generateEmail();
    } else {
      setState((state) => ({
        ...state,
        alertEmptyEmail: true,
      }));
    }
  };

  async function deleteForm(formId) {
    const result = await postRequest(`/form/deleteForm?formId=${formId}`);
    if (result.success) {
      if (formSearchResults) {
        window.location.reload();
      } else {
        const filteredForms = state.forms.filter((f) => f.id !== formId);
        setState((state) => ({
          ...state,
          forms: filteredForms,
          categories: [
            ...new Set(filteredForms.map((d) => d.formCategoryId)),
          ].sort(),
          formToDelete: null,
        }));
      }
    }
    if (!result.success) {
      setState((state) => ({
        ...state,
        deleteConfirmationError: true,
      }));
    }
  }

  async function download(id) {
    const result = await getRequest(`/form/downloadForm?id=${id}`);
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  const filteredForms = formSearchResults ? formSearchResults : state.forms;

  return (
    <div>
      {!formSearchResults && (
        <React.Fragment>
          {state.initialLoading || state.downloading ? (
            <Loader active size="massive" />
          ) : (
            <div>
              <StateDropdown
                pushHistory={(value) => history.push(`/forms/states/${value}`)}
              />
              <div className={css({ marginTop: 48 })}>
                <Grid>
                  <Grid.Row
                    columns={2}
                    verticalAlign="middle"
                    style={{ padding: 0 }}
                  >
                    <Grid.Column floated="left">
                      <div
                        className={css({
                          fontFamily: "Fakt-Bold",
                          fontSize: 20,
                          color: "#000e48",
                          marginBottom: 40,
                          marginTop: 40,
                        })}
                      >
                        {filteredForms &&
                          filteredForms.length > 0 &&
                          filteredForms.find(
                            (f) => f.formCategoryId === state.categories[0]
                          ).formCategory.name}
                        {!formSearchResults && filteredForms.length > 0 && (
                          <span
                            className={emailLinkStyles}
                            onClick={() => emailForms()}
                          >
                            Email Form(s)
                          </span>
                        )}
                      </div>
                    </Grid.Column>
                    {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
                      <Grid.Column
                        floated="right"
                        className={css({ textAlign: "right" })}
                      >
                        <Button
                          icon
                          className="blueButton"
                          onClick={() => history.push(`/forms/new-form`)}
                        >
                          <i className="fal fa-plus" />
                        </Button>
                        <span
                          className={css({
                            fontFamily: "Fakt-Bold",
                            fontSize: 14,
                            color: "#000",
                            cursor: "pointer",
                            "&:hover": {
                              color: "#00b8d4",
                            },
                          })}
                          onClick={() => history.push(`/forms/new-form`)}
                        >
                          New Form
                        </span>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {state.categories &&
        state.categories.map((c, index) => (
          <React.Fragment key={c}>
            {!formSearchResults && index > 0 && (
              <div
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 20,
                  color: "#000e48",
                  marginBottom: 40,
                  marginTop: formSearchResults ? 0 : 40,
                })}
              >
                {filteredForms &&
                  filteredForms.find(
                    (form) => form && form.formCategoryId === c
                  ) &&
                  filteredForms.find(
                    (form) => form && form.formCategoryId === c
                  ).formCategory.name}
                {!formSearchResults && index === 0 && (
                  <span
                    className={emailLinkStyles}
                    onClick={() => emailForms()}
                  >
                    Email Form(s)
                  </span>
                )}
              </div>
            )}

            <CustomTable
              customStyles={{
                "& tr td:first-of-type": {
                  paddingLeft: "40.5px !important",
                },
                "& tr td": {
                  paddingTop: "18px !important",
                  paddingBottom: "18px !important",
                },
                "& th": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                },
                "& tr th:not(:first-of-type), tr td:not(:first-of-type)": {
                  borderLeft: "none !important",
                },
                "& tr th:first-of-type, tr td:first-of-type": {
                  paddingLeft: "60px !important",
                },
                marginBottom: "40px !important",
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
                  <Table.HeaderCell textAlign="left">Notes</Table.HeaderCell>
                  <Table.HeaderCell />
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {filteredForms
                  .filter((form) => form.formCategoryId === c)
                  .sort(function(a, b) {
                    return (
                      (a.sortIndex === null) - (b.sortIndex === null) ||
                      +(a.sortIndex > b.sortIndex) ||
                      -(a.sortIndex < b.sortIndex)
                    );
                  })
                  .map((f) => (
                    <Table.Row verticalAlign="middle" key={f.id}>
                      <Table.Cell
                        className={css({
                          textAlign: "left !important",
                          border: "none !important",
                          position: "relative !important",
                        })}
                      >
                        {!formSearchResults && f.filePath && (
                          <Checkbox
                            checked={
                              state.formsToEmail.find((fte) => fte.id === f.id)
                                .shouldEmail
                            }
                            size="large"
                            onClick={() =>
                              setState((state) => ({
                                ...state,
                                formsToEmail: state.formsToEmail.map((fte) =>
                                  fte.id === f.id
                                    ? {
                                        ...fte,
                                        shouldEmail: !state.formsToEmail.find(
                                          (fte) => fte.id === f.id
                                        ).shouldEmail,
                                      }
                                    : fte
                                ),
                              }))
                            }
                            style={{
                              position: "absolute",
                              left: 24,
                            }}
                          />
                        )}
                        <span className={css({ fontFamily: "Fakt-Bold" })}>
                          <Highlight children={f.name} />
                        </span>
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          textAlign: "left",
                        }}
                      >
                        {f.note && (
                          <Popup
                            on={["hover", "focus"]}
                            header={f.name}
                            content={f.note}
                            trigger={
                              !formSearchResults ? (
                                <CopyToClipboard
                                  text={f.note}
                                  onCopy={() =>
                                    addToast("Copied!", {
                                      appearance: "success",
                                      autoDismiss: true,
                                    })
                                  }
                                >
                                  <div
                                    className={css({
                                      cursor: "pointer",
                                    })}
                                  >
                                    <LinesEllipsis
                                      text={f.note}
                                      maxLine="2"
                                      ellipsis="..."
                                    />
                                  </div>
                                </CopyToClipboard>
                              ) : (
                                <div>
                                  <LinesEllipsis
                                    text={f.note}
                                    maxLine="2"
                                    ellipsis="..."
                                  />
                                </div>
                              )
                            }
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          textAlign: "right",
                        }}
                      >
                        <Button
                          icon
                          onClick={() => download(f.id)}
                          disabled={!f.filePath}
                        >
                          <i className="fal fa-arrow-to-bottom" />
                        </Button>
                        {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
                          <React.Fragment>
                            <Button
                              icon
                              onClick={() =>
                                history.push(`/forms/edit-form/${f.id}`)
                              }
                            >
                              <i className="fal fa-pencil" />
                            </Button>
                            <Button
                              icon
                              onClick={() =>
                                setState((state) => ({
                                  ...state,
                                  formToDelete: f,
                                }))
                              }
                            >
                              <i className="fal fa-trash-alt" />
                            </Button>
                          </React.Fragment>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </CustomTable>
          </React.Fragment>
        ))}
      {state.formToDelete && (
        <Modal
          size={"tiny"}
          open={state.formToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, formToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Form</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              <strong>{state.formToDelete.name}</strong> ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            {!state.deleteConfirmationError ? (
              <React.Fragment>
                <Button
                  negative
                  onClick={() =>
                    setState((state) => ({ ...state, formToDelete: null }))
                  }
                  style={{
                    backgroundColor: "#fff",
                    border: "solid 1px #e5e5ea",
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  No
                </Button>
                <Button
                  positive
                  onClick={() => deleteForm(state.formToDelete.id)}
                  style={{ backgroundColor: "#1f2b5e" }}
                >
                  Yes
                </Button>
              </React.Fragment>
            ) : (
              <Button
                positive
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    formToDelete: null,
                    deleteConfirmationError: false,
                  }))
                }
                style={{ backgroundColor: "#1f2b5e" }}
                data-automation="okDeleteAlert"
              >
                Ok
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      )}
      {state.noResults && (
        <div className={css({ marginTop: 40 })}>
          <Message size="big">
            No <strong>forms</strong> found
          </Message>
        </div>
      )}
      {state.confirmEmail && (
        <Modal
          size={"tiny"}
          open={state.confirmEmail}
          onClose={() =>
            setState((state) => ({ ...state, confirmEmail: false }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Email Forms</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                Please select one of the following options:
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Merged docs"
                  name="radioGroup"
                  checked={state.mergeDocs}
                  onClick={() =>
                    setState((state) => ({ ...state, mergeDocs: true }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Unmerged docs"
                  name="radioGroup"
                  checked={!state.mergeDocs}
                  onClick={() =>
                    setState((state) => ({ ...state, mergeDocs: false }))
                  }
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              onClick={() => {
                setState((state) => ({
                  ...state,
                  confirmEmail: false,
                }));
                generateEmail();
              }}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Generate Email
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {state.alertEmptyEmail && (
        <Modal
          size={"tiny"}
          open={state.alertEmptyEmail}
          onClose={() =>
            setState((state) => ({ ...state, alertEmptyEmail: false }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Email Forms</Modal.Header>
          <Modal.Content>
            <p>No forms have been selected!</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              onClick={() => {
                setState((state) => ({
                  ...state,
                  alertEmptyEmail: false,
                }));
              }}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

export default withRouter(React.memo(Forms));
