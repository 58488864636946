import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  Input,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddSurveyHelpfulWording({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    helpfulWording: {
      surveyHelpfulWordingCategoryId: 1
    },
    editMode: match.path === "/surveys/edit-helpful-wording/:id",
    errorList: [],
    surveyHelpfulWordingCategories: [],
    productType: []
  });

  async function getHelpfulWordingById(id) {
    const result = await getRequest(
      `/surveyHelpfulWording/getSurveyHelpfulWordingById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWording: result.data
      }));
    }
  }

  async function getSurveyHelpfulWordingCategories() {
    const result = await getRequest(
      "/surveyHelpfulWording/getSurveyHelpfulWordingCategories"
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        surveyHelpfulWordingCategories: result.data
      }));
    }
  }

  useEffect(() => {
    getSurveyHelpfulWordingCategories();
    if (state.editMode) {
      getHelpfulWordingById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/surveyHelpfulWording/${
            state.editMode
              ? "editSurveyHelpfulWording"
              : "addSurveyHelpfulWording"
          }`,
          state.helpfulWording
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Helpful Wordings"
          );
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.helpfulWording.subject) {
      list.push("You must provide a subject.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.helpfulWording]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Helpful Wording`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                {state.surveyHelpfulWordingCategories.map((c) => (
                  <Form.Field>
                    <Checkbox
                      label={c.name}
                      checked={
                        state.helpfulWording.surveyHelpfulWordingCategoryId ===
                        c.id
                      }
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          helpfulWording: {
                            ...state.helpfulWording,
                            surveyHelpfulWordingCategoryId: c.id
                          }
                        }))
                      }
                    />
                  </Form.Field>
                ))}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.helpfulWording.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Body</label>
                <TextArea
                  placeholder="Body"
                  style={{ minHeight: 292 }}
                  value={state.helpfulWording.body}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        body: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 192 }}
                  value={state.helpfulWording.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddSurveyHelpfulWording);
