import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Input,
  Message,
  TextArea
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import shortid from "shortid";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddHighLiabilityApproval({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const defaultHighLiabilityApproval = {
    subject: undefined,
    note: undefined
  };
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    highLiabilityApproval: defaultHighLiabilityApproval,
    files: [],
    filesToDelete: [],
    editMode: match.path === "/info/edit-high-liability-approval/:id",
    errorList: []
  });

  async function getHighLiabilityApprovalById(id) {
    const result = await getRequest(
      `/highLiabilityApproval/getHighLiabilityApprovalById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        highLiabilityApproval: result.data
      }));
    }
  }

  useEffect(() => {
    state.editMode && getHighLiabilityApprovalById(match.params.id);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/highLiabilityApproval/${
            state.editMode
              ? "editHighLiabilityApproval"
              : "addHighLiabilityApproval"
          }`,
          {
            ...state.highLiabilityApproval,
            stateId: selectedState,
            files: await Promise.all(
              state.files.map(async (f) => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            filesToDelete: state.filesToDelete
          }
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "High Liability Approval"
          );
          setTimeout(() => history.goBack(), 1000);
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.highLiabilityApproval.note) {
      list.push("You must provide a note.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.highLiabilityApproval]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} High Liability Approval`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  size="large"
                  value={state.highLiabilityApproval.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      highLiabilityApproval: {
                        ...state.highLiabilityApproval,
                        subject: value
                      }
                    }))
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  value={state.highLiabilityApproval.note}
                  style={{ minHeight: 192 }}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      highLiabilityApproval: {
                        ...state.highLiabilityApproval,
                        note: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    (state.highLiabilityApproval.subject === "" ||
                      state.highLiabilityApproval.subject === undefined)
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={(files) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files, ...files]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files.filter((f) => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.highLiabilityApproval.highLiabilityApprovalFiles &&
                state.highLiabilityApproval.highLiabilityApprovalFiles.map(
                  (f) => (
                    <p
                      key={shortid.generate()}
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          filesToDelete: [...state.filesToDelete, f.id],
                          highLiabilityApproval: {
                            ...state.highLiabilityApproval,
                            highLiabilityApprovalFiles: [
                              ...state.highLiabilityApproval.highLiabilityApprovalFiles.filter(
                                (af) => af.id !== f.id
                              )
                            ]
                          }
                        }))
                      }
                      className={css({
                        "&:hover": { color: "#707898" },
                        cursor: "pointer"
                      })}
                    >
                      <i
                        className="fal fa-trash-alt"
                        style={{ marginRight: 8 }}
                      />
                      {f.fileName}
                    </p>
                  )
                )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddHighLiabilityApproval);
