import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Input,
  Dropdown,
  Message,
  TextArea
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { logEvent } from "../../../helpers/analyticsLogger";

export function AddLPEndorsement({ history }) {
  const { selectedState } = useContext(StateContext);

  const defaultEndorsement = {
    stateId: selectedState || 31,
    lpUnderwriters: []
  };

  const [state, setState] = useState({
    states: [{}],
    submitAttempted: false,
    submitting: false,
    endorsement: defaultEndorsement,
    underwriters: [],
    errorList: []
  });

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getLPUnderwritersByState() {
    const result = await getRequest("/endorsement/getLPUnderwritersByState", {
      stateId: state.endorsement.stateId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        underwriters: result.data,
        endorsement: {
          ...state.endorsement,
          lpUnderwriters: result.data.map((d) => {
            return { underwriterId: d.id, charge: "" };
          })
        }
      }));
    }
  }

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: state.endorsement.stateId ? true : false
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          "/endorsement/addLPEndorsement",
          state.endorsement
        );
        if (result.success) {
          logEvent(null, "Added", "LP Endorsement");
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.endorsement.stateId) {
      list.push("You must select a state.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
    getLPUnderwritersByState();
  }, [state.endorsement.stateId]);

  useEffect(() => {
    getStates();
    getLPUnderwritersByState();
  }, []);

  function handleInputChange(value, prop) {
    setState((state) => ({
      ...state,
      endorsement: { ...state.endorsement, [prop]: value }
    }));
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        New LP Endorsement
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Endorsement Name</label>
                <Input
                  size="large"
                  value={state.endorsement.name}
                  onChange={(_, { value }) => handleInputChange(value, "name")}
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Free on LP if issued on LP</label>
                <Input
                  size="large"
                  value={state.endorsement.freeOnLPIfIssuedOnLP}
                  onChange={(_, { value }) =>
                    handleInputChange(value, "freeOnLPIfIssuedOnOP")
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {state.underwriters.length > 0 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.92857143em",
                      color: "rgba(0, 0, 0, 0.87)"
                    }}
                  >
                    Underwriter Charges
                  </label>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          {state.endorsement.lpUnderwriters.map((u) => (
            <Grid.Row columns={2}>
              <Grid.Column width={4}>
                <label
                  style={{
                    position: "relative",
                    top: 14,
                    fontWeight: "bold",
                    fontSize: "0.92857143em",
                    color: "rgba(0, 0, 0, 0.87)"
                  }}
                >
                  {
                    state.underwriters.find(
                      (underwriter) => underwriter.id === u.underwriterId
                    ).name
                  }
                </label>
              </Grid.Column>
              <Grid.Column width={12}>
                <Form.Field>
                  <Input
                    size="large"
                    placeholder="Charge"
                    value={u.charge}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        endorsement: {
                          ...state.endorsement,
                          lpUnderwriters: state.endorsement.lpUnderwriters.map(
                            (lpu) =>
                              lpu.underwriterId === u.underwriterId
                                ? { ...lpu, charge: value }
                                : lpu
                          )
                        }
                      }))
                    }
                    autoComplete="new-password"
                  />
                </Form.Field>
                <Form.Field>
                  <TextArea
                    placeholder="Note"
                    style={{ minHeight: 96 }}
                    value={u.note}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        endorsement: {
                          ...state.endorsement,
                          lpUnderwriters: state.endorsement.lpUnderwriters.map(
                            (lpu) =>
                              lpu.underwriterId === u.underwriterId
                                ? { ...lpu, note: value }
                                : lpu
                          )
                        }
                      }))
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          ))}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>State</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select State"
                  style={{ fontSize: 16 }}
                  options={state.states}
                  value={state.endorsement.stateId}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      endorsement: { ...state.endorsement, stateId: value }
                    }))
                  }
                  data-automation="state"
                  error={
                    state.submitAttempted &&
                    (state.endorsement.stateId === "" ||
                      state.endorsement.stateId === null)
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  saveChanges();
                }}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(AddLPEndorsement);
