import axios from "axios";
import qs from "qs";
import { apiFetch, apiPost } from "../adalConfig";

export async function postRequest(
  requestUrl,
  body,
  shouldRedirectOnFail,
  cancelToken
) {
  try {
    const config = {};
    if (cancelToken) {
      config.cancelToken = cancelToken;
    }
    const response = await apiPost(`/api${requestUrl}`, body, config);
    return {
      success: true,
      code: 200,
      data: response.data
    };
  } catch (error) {
    console.log(error);
    const status = error.response ? error.response.status : null;
    if (status === 401 && shouldRedirectOnFail) {
      // not logged in
      redirectToLogin();
    }

    return {
      success: false,
      code: status,
      data: ""
    };
  }
}

export async function getRequest(
  requestUrl,
  params,
  shouldRedirectOnFail,
  cancelToken
) {
  try {
    const config = {
      params,
      paramsSerializer(p) {
        return qs.stringify(p, { arrayFormat: "repeat" });
      }
    };
    if (cancelToken) {
      config.cancelToken = cancelToken;
    }
    const response = await apiFetch(`/api${requestUrl}`, config);
    return {
      success: true,
      code: 200,
      data: response.data
    };
  } catch (error) {
    let status = null;
    if (axios.isCancel(error)) {
      status = "cancelled";
    } else {
      console.log(error);
      status = error.response ? error.response.status : null;
      if (status === 401 && shouldRedirectOnFail) {
        // not logged in
        redirectToLogin();
      }
    }

    return {
      success: false,
      code: status,
      data: ""
    };
  }
}

function redirectToLogin() {
  window.location.replace("/login");
}
