import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Table, Form, Icon, Modal } from "semantic-ui-react";
import Textarea from "react-textarea-autosize";
import { UserContext } from "../../User";
import CustomTable from "../../CustomTable";
import { StateContext } from "../../State";
import { UnderwriterContext } from "../../Underwriter";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { Highlight } from "../../search/Highlight";
import { sortEndorsements } from "./SortEndorsements";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { paragraphStyles } from "../../styles/paragraphStyles";
import { Popup } from "../../Popup";

const linkStyles = css({
  fontSize: 14,
  lineHeight: 1.71,
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

function LPEndorsements({ history, endorsementSearchResults }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const { underwriters } = useContext(UnderwriterContext);

  const [state, setState] = useState({
    selectedRateType: null,
    initialLoading: true,
    noResults: false,
    endorsements: endorsementSearchResults || [],
    endorsementUnderwriters: [],
    endorsementToEdit: {},
    notes: [],
    noteToDelete: null,
    submitting: false,
    endorsementToDelete: null,
  });

  async function getLPEndorsementsByState() {
    const result = await getRequest("/endorsement/getLPEndorsementsByState", {
      stateId: selectedState,
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        endorsements: sortEndorsements(result.data),
        endorsementToEdit: {},
      }));
    }
  }

  async function getLPEndorsementNotesByState() {
    const result = await getRequest(
      "/endorsement/getLPEndorsementNotesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        notes: result.data,
        initialLoaded: true,
      }));
    }
  }

  useEffect(() => {
    if (!endorsementSearchResults) {
      getLPEndorsementsByState();
      getLPEndorsementNotesByState();
      setState({
        ...state,
        initialLoading: !selectedState,
      });
    }
  }, [selectedState, underwriters]);

  useEffect(() => {
    setState({
      ...state,
      endorsementUnderwriters:
        underwriters.length > 0
          ? [
              ...new Set(
                state.endorsements
                  .map((e) => e.lpUnderwriters.map((u) => u.underwriterId))
                  .flat()
              ),
            ]
          : [],
    });
  }, [state.endorsements]);

  async function saveChanges() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/endorsement/editLPEndorsement",
      state.endorsementToEdit
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        endorsements: state.endorsements.map((e) =>
          e.id === result.data.id ? result.data : e
        ),
        submitting: false,
        endorsementToEdit: {},
      }));
    }
  }

  const editTextareaStyles = css({
    backgroundColor: "transparent !important",
    borderRadius: "5px !important",
    border: "solid 1px #c2c5d3 !important",
    fontSize: "12px !important",
    resize: "none !important",
  });

  const filteredUnderwriters = underwriters.filter((u) =>
    state.endorsementUnderwriters.some((eu) => eu === u.id)
  );

  async function deleteNote(id) {
    const result = await postRequest(
      `/endorsement/deleteLPEndorsementNote?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        notes: state.notes.filter((n) => n.id !== id),
        noteToDelete: null,
      }));
    }
  }

  async function deleteEndorsement(id) {
    const result = await postRequest(
      `/endorsement/deleteLPEndorsement?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        endorsements: state.endorsements.filter((e) => e.id !== id),
        endorsementToDelete: null,
      }));
    }
  }

  return (
    <React.Fragment>
      <Grid style={{ marginBottom: 32 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column floated="left">
            <div
              className={css({
                fontFamily: "Fakt-Bold",
                fontSize: 20,
                lineHeight: 1.2,
                color: "#47517b",
                display: "inline-block",
                marginRight: 64,
              })}
            >
              Loan Policy Endorsements
            </div>
            {(user.isOwner || user.isOwnerPlus) && !endorsementSearchResults && (
              <div
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  lineHeight: 1.71,
                  color: "#00b8d4",
                  display: "inline-block",
                  cursor: "pointer",
                  "&:hover": {
                    lpacity: 0.6,
                  },
                })}
                onClick={() => history.push(`/rates/new-lp-underwriter`)}
              >
                Add Underwriter
              </div>
            )}
          </Grid.Column>
          {(user.isOwner || user.isOwnerPlus) && !endorsementSearchResults && (
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <span
                className={linkStyles}
                onClick={() =>
                  history.push(
                    `/rates/states/${selectedState}/new-lp-endorsement-note`
                  )
                }
              >
                Add Note
              </span>
              <Button
                icon
                className="blueButton"
                onClick={() => history.push(`/rates/new-lp-endorsement`)}
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4",
                  },
                })}
                onClick={() => history.push(`/rates/new-lp-endorsement`)}
              >
                New LP Endorsement
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {state.notes &&
        state.notes.map((n) => (
          <Card.Group
            itemsPerRow={1}
            className={css({ "&.ui.cards ~ .ui.cards": { marginTop: 0 } })}
          >
            <Card style={{ marginLeft: 0, marginRight: 0 }}>
              <Card.Content>
                <label
                  className={css({
                    color: "#707898",
                    fontSize: 14,
                    fontFamily: "Fakt-Bold",
                    lineHeight: "17px",
                  })}
                >
                  {n.subject ? n.subject : "Note"}
                </label>
                <p className={paragraphStyles}>{n.note}</p>
              </Card.Content>
              <span
                className={css({
                  "&:hover i": { color: "#00b8d4" },
                  cursor: "pointer",
                  position: "absolute",
                  right: 24,
                  top: 31,
                })}
                onClick={() =>
                  history.push(`/rates/edit-lp-endorsement-note/${n.id}`)
                }
              >
                <Icon className="fal fa-pen" />
              </span>
              <span
                className={css({
                  "&:hover i": { color: "#00b8d4" },
                  cursor: "pointer",
                  position: "absolute",
                  right: 48,
                  top: 31,
                })}
                onClick={() =>
                  setState((state) => ({ ...state, noteToDelete: n }))
                }
              >
                <Icon className="fal fa-trash" />
              </span>
            </Card>
          </Card.Group>
        ))}
      <CustomTable
        customStyles={{
          "& tr td:first-of-type": {
            paddingLeft: "40.5px !important",
          },
          "& th": {
            position: "sticky",
            top: 0,
            zIndex: 1,
          },
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>Endorsement Name</Table.HeaderCell>
            {filteredUnderwriters.map((u) => (
              <Table.HeaderCell key={u.name}>
                Charge / <Highlight children={u.name} />
              </Table.HeaderCell>
            ))}
            <Table.HeaderCell>Free on LP if issued on OP</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {state.endorsements.length < 1 && (
            <Table.Row>
              <Table.Cell>No endorsements available</Table.Cell>
            </Table.Row>
          )}
          {state.endorsements.map((e) => (
            <Table.Row verticalAlign="top" key={e.id}>
              <Table.Cell
                style={{
                  textAlign: "left",
                }}
              >
                {(user.isOwner || user.isOwnerPlus) && (
                  <React.Fragment>
                    {state.endorsementToEdit.id !== e.id && (
                      <React.Fragment>
                        <span
                          className={css({
                            "&:hover i": { color: "#00b8d4" },
                            cursor: "pointer",
                            verticalAlign: "top",
                            display: "inline-block",
                          })}
                          onClick={() =>
                            setState((state) => ({
                              ...state,
                              endorsementToEdit: e,
                            }))
                          }
                        >
                          <Icon className="fal fa-pen" />
                        </span>
                        <span
                          className={css({
                            "&:hover i": { color: "#00b8d4" },
                            cursor: "pointer",
                            verticalAlign: "top",
                            display: "inline-block",
                          })}
                          onClick={() =>
                            setState((state) => ({
                              ...state,
                              endorsementToDelete: e,
                            }))
                          }
                        >
                          <Icon
                            style={{ paddingRight: 32.5 }}
                            className="fal fa-trash"
                          />
                        </span>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
                {state.endorsementToEdit.id === e.id &&
                (user.isOwner || user.isOwnerPlus) ? (
                  <Form>
                    <Icon
                      style={{
                        color: "#00b8d4",
                        paddingRight: 32.5,
                        marginTop: 9,
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      className="fal fa-check"
                      onClick={() => saveChanges()}
                    />
                    <Textarea
                      style={{
                        width: "calc(100% - 36px)",
                      }}
                      className={editTextareaStyles}
                      rows={1}
                      placeholder="Name"
                      value={state.endorsementToEdit.name || ""}
                      onChange={(event) => {
                        event.persist();
                        setState((state) => ({
                          ...state,
                          endorsementToEdit: {
                            ...state.endorsementToEdit,
                            name: event.target.value,
                          },
                        }));
                      }}
                    />
                  </Form>
                ) : (
                  <React.Fragment>
                    <div
                      className={css({
                        display: "inline-block",
                        width: "calc(100% - 70px)",
                        fontFamily: "Fakt-Bold",
                      })}
                    >
                      <Highlight children={e.name} />
                    </div>
                  </React.Fragment>
                )}
              </Table.Cell>
              {filteredUnderwriters.map((fu) => {
                const charge = e.lpUnderwriters.find(
                  (lpu) => lpu.underwriterId === fu.id
                );
                return charge ? (
                  <Table.Cell key={fu.id + e.id}>
                    {state.endorsementToEdit.id === e.id &&
                    (user.isOwner || user.isOwnerPlus) ? (
                      <Form>
                        <Textarea
                          className={editTextareaStyles}
                          rows={1}
                          placeholder="Charge"
                          value={
                            state.endorsementToEdit.lpUnderwriters.find(
                              (u) => u.id === charge.id
                            ).charge
                          }
                          onChange={(event) => {
                            event.persist();
                            setState((state) => ({
                              ...state,
                              endorsementToEdit: {
                                ...state.endorsementToEdit,
                                lpUnderwriters: [
                                  ...state.endorsementToEdit.lpUnderwriters,
                                ]
                                  .filter((u) => u.id !== charge.id)
                                  .concat([
                                    {
                                      ...state.endorsementToEdit.lpUnderwriters.find(
                                        (u) => u.id === charge.id
                                      ),
                                      charge: event.target.value,
                                    },
                                  ]),
                              },
                            }));
                          }}
                        />
                        <div style={{ marginTop: 8 }}>
                          <Textarea
                            className={editTextareaStyles}
                            rows={1}
                            placeholder="Note"
                            value={
                              state.endorsementToEdit.lpUnderwriters.find(
                                (u) => u.id === charge.id
                              ).note
                            }
                            onChange={(event) => {
                              event.persist();
                              setState((state) => ({
                                ...state,
                                endorsementToEdit: {
                                  ...state.endorsementToEdit,
                                  lpUnderwriters: [
                                    ...state.endorsementToEdit.lpUnderwriters,
                                  ]
                                    .filter((u) => u.id !== charge.id)
                                    .concat([
                                      {
                                        ...state.endorsementToEdit.lpUnderwriters.find(
                                          (u) => u.id === charge.id
                                        ),
                                        note: event.target.value,
                                      },
                                    ]),
                                },
                              }));
                            }}
                          />
                        </div>
                      </Form>
                    ) : (
                      <React.Fragment>
                        <Highlight children={charge.charge} />
                        {e.lpUnderwriters.find((u) => u.id === charge.id)
                          .note && (
                          <Popup
                            on={["hover", "focus"]}
                            header="Note"
                            content={
                              e.lpUnderwriters.find((u) => u.id === charge.id)
                                .note
                            }
                            trigger={
                              <i
                                style={{
                                  marginLeft: 16,
                                  top: 2,
                                  position: "relative",
                                  color: "#c2c5d3",
                                  cursor: "pointer",
                                  verticalAlign: "top",
                                }}
                                className="fas fa-comment-alt-lines noteTrigger"
                              />
                            }
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Table.Cell>
                ) : (
                  <Table.Cell key={fu.id + e.id} />
                );
              })}
              <React.Fragment>
                <Table.Cell>
                  {state.endorsementToEdit.id === e.id &&
                  (user.isOwner || user.isOwnerPlus) ? (
                    <Form>
                      <Textarea
                        className={editTextareaStyles}
                        rows={1}
                        placeholder="Free on LP if issued on OP"
                        value={state.endorsementToEdit.freeOnLPIfIssuedOnOP}
                        onChange={(event) => {
                          event.persist();
                          setState((state) => ({
                            ...state,
                            endorsementToEdit: {
                              ...state.endorsementToEdit,
                              freeOnLPIfIssuedOnOP: event.target.value,
                            },
                          }));
                        }}
                      />
                    </Form>
                  ) : (
                    <Highlight children={e.freeOnLPIfIssuedOnOP} />
                  )}
                </Table.Cell>
              </React.Fragment>
            </Table.Row>
          ))}
        </Table.Body>
      </CustomTable>
      {state.endorsementToDelete && (
        <Modal
          size={"tiny"}
          open={state.endorsementToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, endorsementToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Endorsement</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              <strong>{state.endorsementToDelete.name}</strong> ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, endorsementToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteEndorsement(state.endorsementToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {state.noteToDelete && (
        <Modal
          size={"tiny"}
          open={state.noteToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, noteToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              <strong>{state.noteToDelete.subject}</strong> ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, noteToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteNote(state.noteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(React.memo(LPEndorsements));
