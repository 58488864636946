import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Table, Icon } from "semantic-ui-react";
import { getRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import CustomTable from "../../CustomTable";
import { Highlight } from "../../search/Highlight";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import LinesEllipsis from "react-lines-ellipsis";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { Popup } from "../../Popup";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
});

async function download(id) {
  const result = await getRequest(`/additionalLien/downloadFile?id=${id}`);
  if (result.success) {
    convertToFileDownload(result.data.base64File, result.data.name);
  }
}

function AdditionalLiens({
  history,
  match: { params },
  additionalLienSearchResults,
}) {
  const { user } = useContext(UserContext);
  const { addToast } = useToasts();
  const { selectedState } = useContext(StateContext);

  const [state, setState] = useState({
    selectedRateType: null,
    initialLoading: true,
    noResults: false,
    additionalLiens: [],
    submitting: false,
  });

  async function getAdditionalLiensByState() {
    const result = await getRequest(
      "/additionalLien/getAdditionalLiensByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalLiens: result.data,
      }));
    }
  }

  useEffect(() => {
    if (!additionalLienSearchResults) {
      getAdditionalLiensByState();
      setState({
        ...state,
        initialLoading: !selectedState,
      });
    }
  }, [params.state, selectedState]);

  const filteredAdditionalLiens = additionalLienSearchResults
    ? additionalLienSearchResults
    : state.additionalLiens;

  return (
    <React.Fragment>
      {!additionalLienSearchResults && (
        <Grid>
          <Grid.Row columns={2} verticalAlign="middle">
            {(user.isOwner || user.isOwnerPlus || user.isAdmin) &&
              !additionalLienSearchResults && (
                <Grid.Column
                  floated="right"
                  className={css({ textAlign: "right" })}
                >
                  <Button
                    icon
                    className="blueButton"
                    onClick={() => history.push(`/info/new-additional-lien`)}
                    data-automation="addInvoiceCharge"
                  >
                    <i className="fal fa-plus" />
                  </Button>
                  <span
                    className={css({
                      fontFamily: "Fakt-Bold",
                      fontSize: 14,
                      color: "#000",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#00b8d4",
                      },
                    })}
                    onClick={() => history.push(`/info/new-additional-lien`)}
                  >
                    New Additional Lien
                  </span>
                </Grid.Column>
              )}
          </Grid.Row>
        </Grid>
      )}
      <div
        className={css({
          marginBottom: 19.75,
          marginTop: !additionalLienSearchResults ? 40 : 0,
        })}
      >
        <CustomTable
          customStyles={{
            "& tr td:first-of-type": {
              paddingLeft: "40.5px !important",
            },
            "& th": {
              position: "sticky",
              top: 0,
              zIndex: 1,
            },
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Lien Name</Table.HeaderCell>
              <Table.HeaderCell>Duration</Table.HeaderCell>
              <Table.HeaderCell>Notes</Table.HeaderCell>
              <Table.HeaderCell>Attachments</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {filteredAdditionalLiens.length < 1 && (
              <Table.Row>
                <Table.Cell>No liens available</Table.Cell>
              </Table.Row>
            )}
            {filteredAdditionalLiens.map((l) => (
              <Table.Row key={l.id}>
                <Table.Cell
                  style={{
                    textAlign: "left",
                  }}
                >
                  {(user.isOwner || user.isOwnerPlus || user.isAdmin) && (
                    <React.Fragment>
                      <span
                        className={css({
                          "&:hover i": { color: "#00b8d4" },
                          cursor: "pointer",
                          verticalAlign: "top",
                          display: "inline-block",
                        })}
                        onClick={() =>
                          history.push(`/info/edit-additional-lien/${l.id}`)
                        }
                      >
                        <Icon
                          style={{ paddingRight: 32.5 }}
                          className="fal fa-pen"
                        />
                      </span>
                    </React.Fragment>
                  )}
                  <React.Fragment>
                    <div
                      className={css({
                        display: "inline-block",
                        width: "calc(100% - 70px)",
                      })}
                    >
                      <span className={css({ fontFamily: "Fakt-Bold" })}>
                        <Highlight children={l.name} />
                      </span>
                    </div>
                  </React.Fragment>
                </Table.Cell>
                <Table.Cell>
                  <Highlight children={l.duration} />
                </Table.Cell>
                <Table.Cell
                  style={{
                    textAlign: "left",
                  }}
                >
                  {l.note && (
                    <Popup
                      on={["hover", "focus"]}
                      header={l.name}
                      content={l.note}
                      trigger={
                        !additionalLienSearchResults ? (
                          <CopyToClipboard
                            text={l.note}
                            onCopy={() =>
                              addToast("Copied!", {
                                appearance: "success",
                                autoDismiss: true,
                              })
                            }
                          >
                            <div
                              className={css({
                                cursor: "pointer",
                              })}
                            >
                              <LinesEllipsis
                                text={l.note}
                                maxLine="2"
                                ellipsis="..."
                              />
                            </div>
                          </CopyToClipboard>
                        ) : (
                          <div>
                            <LinesEllipsis
                              text={l.note}
                              maxLine="2"
                              ellipsis="..."
                            />
                          </div>
                        )
                      }
                    />
                  )}
                </Table.Cell>
                <Table.Cell>
                  {l.additionalLienFiles &&
                    l.additionalLienFiles.map((f) => (
                      <p className={paragraphStyles} key={f.id}>
                        <a
                          onClick={() => download(f.id)}
                          className={css({
                            "&:hover": {
                              textDecoration: "underline",
                              color: "#00b8d4",
                              cursor: "pointer",
                            },
                          })}
                        >
                          <i
                            className="fal fa-paperclip"
                            style={{ color: "#00b8d4", marginRight: 8 }}
                          />
                          <Highlight children={f.fileName} />
                        </a>
                      </p>
                    ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </CustomTable>
      </div>
    </React.Fragment>
  );
}

export default withRouter(React.memo(AdditionalLiens));
