import React, { useState, useEffect, useContext } from "react";
import { Button, Dropdown, Grid, Modal } from "semantic-ui-react";
import { css } from "emotion";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import { withRouter } from "react-router-dom";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import DragSortNotes from "./DragSortNotes";
import DeleteNoteAlert from "./DeleteNoteAlert";
import { linkStyles } from "./linkStyles";
import EndorsementRowSort from "./EndorsementRowSort";
import getPAStateId from "./getPAStateId";

function Endorsements({ history, match, searchResults }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;
  const searchUnderwriters =
    searchResults &&
    searchResults
      .map(r => ({
        key: r.underwriterId,
        value: r.underwriterId,
        text: r.underwriterName
      }))
      .reduce((p, b) => {
        const foundValue = p.find(v => v.value === b.value);
        if (foundValue) {
          return p;
        }
        return [
          ...p,
          {
            key: b.key,
            value: b.value,
            text: b.text
          }
        ];
      }, []);
  const [endorsements, setEndorsements] = useState(searchResults || []);
  const [state, setState] = useState({
    underwriters: searchUnderwriters || [{}],
    endorsementToDelete: null,
    selectedUnderwriter: null
  });
  const [endorsementNotes, setEndorsementNotes] = useState([]);
  const [noteToDelete, setNoteToDelete] = useState(null);

  let stateId = selectedState;

  async function getPolicyEndorsementNotesByState() {
    const result = await getRequest("/policyEndorsement/getPolicyEndorsementNotesByState", {
      stateId,
      underwriterId: state.selectedUnderwriter
    });
    if (result.success) {
      setEndorsementNotes(result.data);
    }
  }

  async function getStateUnderwriters() {
    const result = await getRequest(`/underwriter/getStateUnderwriters?id=${+match.params.state || 31}`);
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data.map(u => ({
          key: u.id,
          value: u.id,
          text: u.name
        }))
      }));
    }
  }

  async function getEndorsementsByState() {
    const result = await getRequest(`/policyEndorsement/getEndorsementsByState`, {
      stateId
    });
    if (result.success) {
      setEndorsements(result.data);
    }
  }

  async function handleDeleteNote() {
    const result = await postRequest(`/policyEndorsement/deletePolicyEndorsementNote?id=${noteToDelete.id}`);
    if (result.success) {
      setEndorsementNotes(notes => notes.filter(n => n.id != noteToDelete.id));
      setNoteToDelete(null);
    }
  }

  useEffect(() => {
    if (!searchResults) {
      getStateUnderwriters();
      getEndorsementsByState();
      setState({
        ...state,
        initialLoading: !selectedState
      });
    }
  }, [match.params, state.selectedUnderwriter]);

  useEffect(() => {
    getPolicyEndorsementNotesByState();
  }, [state.selectedUnderwriter]);

  async function deleteEndorsement(id) {
    const result = await postRequest(`/policyEndorsement/deleteEndorsement?id=${id}`, id);
    if (result.success) {
      setEndorsements(endorsements => endorsements.filter(e => e.id !== id));
      setState(state => ({
        ...state,
        endorsementToDelete: null
      }));
    }
  }

  const setSelectedUnderwriter = () => {
    if (searchResults) {
      setState({
        ...state,
        selectedUnderwriter: searchUnderwriters[0].value
      });
      return;
    }
    const paramUnderwriter = +match.params.underwriterId;
    const paramUndewriterExists = state.underwriters.some(u => u.value === paramUnderwriter);
    const stewartExists = state.underwriters.some(u => u.text === "Stewart");
    if (paramUndewriterExists) {
      setState({
        ...state,
        selectedUnderwriter: paramUnderwriter
      });
    } else if (stewartExists) {
      var stewartId = state.underwriters.find(u => u.text === "Stewart").value;
      setState({ ...state, selectedUnderwriter: stewartId });
    } else {
      setState({ ...state, selectedUnderwriter: state.underwriters[0]?.value });
    }
  };

  useEffect(() => {
    if (state.underwriters.length > 0) {
      setSelectedUnderwriter();
    }
  }, [match.params.underwriterId, state.underwriters]);

  const filteredEndorsements = endorsements.filter(e => e.underwriterId === +state.selectedUnderwriter);

  return (
    <React.Fragment>
      <Grid style={{ marginBottom: 17 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column
            floated="left"
            className={css({
              textAlign: "left",
              color: "#47517B",
              fontSize: 20,
              fontFamily: "Fakt-Bold"
            })}
          >
            Endorsements{" "}
            <Dropdown
              size="large"
              selection
              placeholder="Select Underwriter"
              style={{ fontSize: 16, marginLeft: 16 }}
              options={state.underwriters}
              value={state.selectedUnderwriter}
              onChange={(_, { value }) =>
                history.push(`/policy-instructions/states/${selectedState}/sections/endorsements/underwriters/${value}`)
              }
            />
          </Grid.Column>
          {userCanEdit && !searchResults && (
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <span
                className={linkStyles}
                onClick={() =>
                  history.push(
                    `/policy-instructions/states/${selectedState}/underwriters/${state.selectedUnderwriter}/new-policy-endorsement-note`
                  )
                }
              >
                Add Note
              </span>
              <Button
                icon
                className="blueButton"
                onClick={() =>
                  history.push(
                    `/policy-instructions/states/${selectedState}/underwriters/${state.selectedUnderwriter}/new-endorsement`
                  )
                }
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() =>
                  history.push(
                    `/policy-instructions/states/${selectedState}/underwriters/${state.selectedUnderwriter}/new-endorsement`
                  )
                }
              >
                New Endorsement
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <DragSortNotes
        items={endorsementNotes.map(n => ({
          ...n,
          files: n.files
        }))}
        downloadURI="/policyEndorsement/downloadPolicyEndorsementNoteFile"
        saveSortPath="/policyEndorsement/sortNotes"
        draggable={userCanEdit}
        deleteAction={value => setNoteToDelete(value)}
        userCanEdit={userCanEdit}
        editNotePath={`/policy-instructions/edit-policy-endorsement-note`}
      />
      <EndorsementRowSort
        endorsements={filteredEndorsements}
        searchResults={searchResults}
        setEndorsements={endorsements => setEndorsements(endorsements)}
        deleteEndorsement={e => setState(state => ({ ...state, endorsementToDelete: e }))}
      />
      {state.endorsementToDelete && (
        <Modal
          size={"tiny"}
          open={state.endorsementToDelete !== null}
          onClose={() => setState(state => ({ ...state, endorsementToDelete: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Delete Endorsement</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete <strong>{state.endorsementToDelete.name}</strong>?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => setState(state => ({ ...state, endorsementToDelete: null }))}
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteEndorsement(state.endorsementToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {noteToDelete && <DeleteNoteAlert onClose={() => setNoteToDelete(null)} deleteNote={handleDeleteNote} />}
    </React.Fragment>
  );
}

export default withRouter(Endorsements);
