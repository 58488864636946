import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import {
  Button,
  Grid,
  Loader,
  Modal,
  Table,
  Message,
  Checkbox,
  Form,
  Radio
} from "semantic-ui-react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../User";
import { StateContext } from "../State";
import StateDropdown from "../StateDropdown";
import CustomTable from "../CustomTable";
import LinesEllipsis from "react-lines-ellipsis";
import { convertToFileDownload } from "../../helpers/ConvertToFileDownload";
import { Highlight } from "../search/Highlight";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { Popup } from "../Popup";

function TrainingMedia({ history, match: { params } }) {
  const { user } = useContext(UserContext);
  const { addToast } = useToasts();
  const [state, setState] = useState({
    trainingMedia: [],
    initialLoading: true,
    noResults: false,
    trainingMediaToDelete: null,
    downloading: false,
    deleteConfirmationError: false
  });

  async function getTrainingMedias() {
    const result = await getRequest("/TrainingMedia/getTrainingMedias");
    if (result.success) {
      setState((state) => ({
        ...state,
        trainingMedia: result.data,
        initialLoading: false,
        noResults: result.data.length < 1
      }));
    }
  }

  async function download(id) {
    const result = await getRequest(
      `/trainingMedia/downloadTrainingMedia?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  async function deleteTrainingMedia(id) {
    const result = await postRequest(
      `/trainingMedia/deleteTrainingMedia?id=${id}`
    );
    if (result.success) {
      const filteredTrainingMedias = state.trainingMedia.filter(
        (t) => t.id !== id
      );
      setState((state) => ({
        ...state,
        trainingMedia: filteredTrainingMedias,
        trainingMediaToDelete: null
      }));
    }
    if (!result.success) {
      setState((state) => ({
        ...state,
        deleteConfirmationError: true
      }));
    }
  }

  useEffect(() => {
    getTrainingMedias();
  }, []);

  return (
    <div>
      <React.Fragment>
        <div className={css({ marginBottom: 40 })}>
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
              <Grid.Column floated="left">
                <div
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 20,
                    color: "#000e48"
                  })}
                >
                  Training Media
                </div>
              </Grid.Column>
              {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
                <Grid.Column
                  floated="right"
                  className={css({ textAlign: "right" })}
                >
                  <Button
                    icon
                    className="blueButton"
                    onClick={() => history.push(`/training/new-training-media`)}
                  >
                    <i className="fal fa-plus" />
                  </Button>
                  <span
                    className={css({
                      display: "inline-block",
                      fontFamily: "Fakt-Bold",
                      fontSize: 14,
                      color: "#000",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#00b8d4"
                      }
                    })}
                    onClick={() => history.push(`/training/new-training-media`)}
                  >
                    Add Upload
                  </span>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </div>
      </React.Fragment>
      <React.Fragment>
        {state.trainingMedia.length > 0 && (
          <CustomTable
            customStyles={{
              "& tr th": {
                paddingLeft: "18px !important",
                paddingTop: "25px !important",
                paddingBottom: "25px !important"
              },
              "& tr td:first-of-type": {
                paddingLeft: "40.5px !important"
              },
              "& tr td": {
                paddingTop: "18px !important",
                paddingBottom: "18px !important"
              },
              "& th": {
                position: "sticky",
                top: 0,
                zIndex: 1
              },
              "& tr th:not(:first-of-type), tr td:not(:first-of-type)": {
                borderLeft: "none !important"
              },
              "& tr th:first-of-type, tr td:first-of-type": {
                paddingLeft: "60px !important"
              },
              marginBottom: "40px !important"
            }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
                <Table.HeaderCell
                  textAlign="left"
                  style={{ paddingLeft: "0px !important" }}
                >
                  Notes
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {state.trainingMedia.map((t) => (
                <Table.Row verticalAlign="middle" key={t.id}>
                  <Table.Cell
                    className={css({
                      textAlign: "left !important",
                      border: "none !important",
                      position: "relative !important"
                    })}
                  >
                    <span className={css({ fontFamily: "Fakt-Bold" })}>
                      <Highlight children={t.name} />
                    </span>
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "left"
                    }}
                  >
                    {t.note && (
                      <Popup
                        on={["hover", "focus"]}
                        header={t.name}
                        content={t.note}
                        trigger={
                          <CopyToClipboard
                            text={t.note}
                            onCopy={() =>
                              addToast("Copied!", {
                                appearance: "success",
                                autoDismiss: true
                              })
                            }
                          >
                            <div
                              className={css({
                                cursor: "pointer"
                              })}
                            >
                              <LinesEllipsis
                                text={t.note}
                                maxLine="2"
                                ellipsis="..."
                              />
                            </div>
                          </CopyToClipboard>
                        }
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "right"
                    }}
                  >
                    <Button
                      icon
                      onClick={() => download(t.id)}
                      disabled={!t.filePath}
                    >
                      <i className="fal fa-arrow-to-bottom" />
                    </Button>
                    {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
                      <React.Fragment>
                        <Button
                          icon
                          onClick={() =>
                            history.push(
                              `/training/edit-training-media/${t.id}`
                            )
                          }
                        >
                          {" "}
                          <i className="fal fa-pencil" />
                        </Button>
                        <Button
                          icon
                          onClick={() =>
                            setState((state) => ({
                              ...state,
                              trainingMediaToDelete: t
                            }))
                          }
                        >
                          <i className="fal fa-trash-alt" />
                        </Button>
                      </React.Fragment>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
              {state.trainingMediaToDelete && (
                <Modal
                  size={"tiny"}
                  open={state.trainingMediaToDelete !== null}
                  onClose={() =>
                    setState((state) => ({
                      ...state,
                      trainingMediaToDelete: null
                    }))
                  }
                  dimmer="inverted"
                >
                  <Modal.Header>Delete TrainingMedia</Modal.Header>
                  <Modal.Content>
                    <p>
                      Are you sure you want to delete{" "}
                      <strong>{state.trainingMediaToDelete.name}</strong> ?
                    </p>
                  </Modal.Content>
                  <Modal.Actions>
                    {!state.deleteConfirmationError ? (
                      <React.Fragment>
                        <Button
                          negative
                          onClick={() =>
                            setState((state) => ({
                              ...state,
                              trainingMediaToDelete: null
                            }))
                          }
                          style={{
                            backgroundColor: "#fff",
                            border: "solid 1px #e5e5ea",
                            color: "rgba(0, 0, 0, 0.87)"
                          }}
                        >
                          No
                        </Button>
                        <Button
                          positive
                          onClick={() =>
                            deleteTrainingMedia(state.trainingMediaToDelete.id)
                          }
                          style={{ backgroundColor: "#1f2b5e" }}
                        >
                          Yes
                        </Button>
                      </React.Fragment>
                    ) : (
                      <Button
                        positive
                        onClick={() =>
                          setState((state) => ({
                            ...state,
                            trainingMediaToDelete: null,
                            deleteConfirmationError: false
                          }))
                        }
                        style={{ backgroundColor: "#1f2b5e" }}
                        data-automation="okDeleteAlert"
                      >
                        Ok
                      </Button>
                    )}
                  </Modal.Actions>
                </Modal>
              )}
            </Table.Body>
          </CustomTable>
        )}
        {state.trainingMedia.length < 1 && (
          <div className={css({ marginTop: 40 })}>
            <Message size="big">
              No <strong>training media</strong> found
            </Message>
          </div>
        )}
      </React.Fragment>
    </div>
  );
}

export default withRouter(React.memo(TrainingMedia));
