import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Input, Dropdown } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddGeneralInfoNote({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    note: {},
    editMode: match.path.includes("edit"),
    noteCategories: [],
    newCategory: undefined,
    errorList: [],
    files: [],
    filesToAdd: [],
    filesToDelete: []
  });

  async function getNoteCategories() {
    const result = await getRequest(`/commitmentGeneralInfo/getNoteCategories`);
    if (result.success) {
      setState(state => ({
        ...state,
        noteCategories: [
          {
            key: null,
            value: null,
            text: "None"
          },
          ...result.data.map(c => ({
            key: c.id,
            text: c.name,
            value: c.id
          }))
        ]
      }));
    }
  }

  async function getCommitmentGeneralInfoNoteById(id) {
    const result = await getRequest(`/commitmentGeneralInfo/getCommitmentGeneralInfoNoteById`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        note: result.data
      }));
    }
  }

  useEffect(() => {
    getNoteCategories();
    if (state.editMode) {
      getCommitmentGeneralInfoNoteById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    setState(state => ({
      ...state,
      submitting: true
    }));
    const result = await postRequest(`/commitmentGeneralInfo/editAddGeneralInfoNote`, {
      commitmentGeneralInfoNote: {
        ...state.note,
        stateId: state.editMode ? state.note.stateId : +match.params.state
      },
      newCategory: state.newCategory,
      filesToAdd: await Promise.all(
        state.filesToAdd.map(async f => ({
          base64File: await readFileAsBase64(f),
          fileName: f.name
        }))
      ),
      filesToDelete: state.filesToDelete
    });
    if (result.success) {
      logEvent(null, state.editMode ? "Edited" : "Added", "General Info");
      history.goBack();
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} General Info Note`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Category</label>
                <Dropdown
                  size="large"
                  options={state.noteCategories}
                  placeholder="Choose Category"
                  search
                  selection
                  allowAdditions
                  style={{ fontSize: 16 }}
                  value={state.note.commitmentGeneralInfoNoteCategoryId}
                  onAddItem={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      newCategory: value,
                      noteCategories: [
                        ...state.noteCategories.filter(c => c.value !== 0),
                        {
                          key: 0,
                          text: value,
                          value: 0
                        }
                      ],
                      note: {
                        ...state.note,
                        commitmentGeneralInfoNoteCategoryId: 0
                      }
                    }))
                  }
                  onChange={(e, { value, text }) => {
                    setState({
                      ...state,
                      note: {
                        ...state.note,
                        commitmentGeneralInfoNoteCategoryId: value
                      },
                      newCategory: text
                    });
                  }}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.note.subject}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      note: {
                        ...state.note,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.note.note}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      note: {
                        ...state.note,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.filesToAdd}
                  setFileToApp={filesToAdd =>
                    setState(state => ({
                      ...state,
                      filesToAdd: [...state.filesToAdd, ...filesToAdd]
                    }))
                  }
                  deleteFileToApp={fileName =>
                    setState(state => ({
                      ...state,
                      filesToAdd: [...state.filesToAdd.filter(f => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.note.files &&
                state.note.files.map(f => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        note: {
                          ...state.note,
                          files: [...state.note.files.filter(nf => nf.id !== f.id)]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i className="fal fa-trash-alt" style={{ marginRight: 8 }} />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <Button size="large" className="cancelButton" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button size="large" className="saveButton" onClick={() => saveChanges()}>
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddGeneralInfoNote);
