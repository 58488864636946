import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Input,
  Dropdown,
  Message,
  Checkbox,
  Modal
} from "semantic-ui-react";
import shortid from "shortid";
import { readFileAsBase64 } from "../../helpers/ReadFileAsBase64";
import FileUpload from "../FileUpload";
import { logEvent } from "../../helpers/analyticsLogger";
import { eAndODepartments } from "./eAndODepartments";

export function EditAddCompany({ match, history }) {
  const defaultCompany = {
    id: 0,
    name: null,
    address1: null,
    address2: null,
    city: null,
    departmentId: +match.params.department,
    email: null,
    fax: null,
    note: null,
    phone: null,
    state: null,
    customTag: null,
    underwriterId: null,
    website: null,
    zip: null,
    companyStates: [{ companyId: 0, stateId: +match.params.state }],
    isPrimary: null,
    confirmSaveAsPrimary: false,
    saveAsPrimaryConfirmed: false
  };

  const [state, setState] = useState({
    company: defaultCompany,
    departments: [{}],
    underwriters: [{}],
    states: [{}],
    submitAttempted: false,
    errorList: [],
    submitting: false,
    submitted: false,
    editMode: match.path === "/contacts/edit-company/:id",
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    contactTags: [],
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    files: [],
    filesToDelete: []
  });

  async function getContactTags() {
    const result = await getRequest(`/company/getContactTags`);
    if (result.success) {
      setState((state) => ({
        ...state,
        contactTags: [
          {
            key: null,
            value: null,
            text: "None"
          },
          ...result.data.map((c) => ({
            key: c.id,
            text: c.name,
            value: c.id
          }))
        ]
      }));
    }
  }

  async function getCompanyById(companyId) {
    const result = await getRequest("/company/GetCompanyById", {
      companyId
    });
    if (result.success) {
      setState((state) => {
        if (result.data.eAndODate) {
          result.data.eAndODate = result.data.eAndODate.split("T")[0];
        }
        return {
          ...state,
          company: {
            ...result.data
          }
        };
      });
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allLicensedStatesList: result.data
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function getDepartments() {
    const result = await getRequest("/general/getDepartments");
    if (result.success) {
      setState((state) => ({
        ...state,
        departments: result.data.map((d) => ({
          key: d.id,
          value: d.id,
          text: d.name
        }))
      }));
    }
  }

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState((state) => ({
        ...state,
        underwriters: result.data
          .filter((u) => u.name !== "All Underwriters")
          .map((u) => ({
            key: u.id,
            value: u.id,
            text: u.name
          }))
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true
      }));
      if (!state.errorList.length > 0) {
        setState((state) => ({ ...state, submitting: true }));
        const result = await postRequest(
          `/company/${state.editMode ? "editCompany" : "addCompany"}`,
          {
            ...state.company,
            files: await Promise.all(
              state.files.map(async (f) => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            filesToDelete: state.filesToDelete
          }
        );
        if (result.success) {
          logEvent(null, state.editMode ? "Edited" : "Added", "Company");
          setState(
            (state) => ({
              ...state,
              company: state.editMode ? state.company : result.data,
              submitting: false,
              submitted: true
            }),
            history.goBack()
          );
        }
      }
    }
  }

  async function checkIfStateHasPrimaryContact() {
    const result = await getRequest("/company/checkIfStateHasPrimaryContact", {
      companyId: state.company.id,
      states: state.company.companyStates.map((cs) => cs.stateId),
      departmentId: state.company.departmentId,
      underwriterId: state.company.underwriterId
    });
    if (result.success) {
      if (result.data.length < 1) {
        saveChanges();
      } else {
        setState((state) => ({
          ...state,
          statesWithPrimaryContact: result.data,
          confirmSaveAsPrimary: result.data
        }));
      }
    }
  }

  useEffect(() => {
    getContactTags();
    getDepartments();
    getUnderwriters();
    getStates();
    getAllStatesList();
    getAllLicensedStatesList();
    getAllUnlicensedStatesList();
    state.editMode && getCompanyById(match.params.id);
  }, []);

  useEffect(() => {
    if (state.editMode) {
      let checkIfContainsAll = (arr, target) =>
        target.every((v) => arr.includes(v));
      setState((state) => ({
        ...state,
        allStates: checkIfContainsAll(
          state.company.companyStates.length > 0
            ? state.company.companyStates.map((cs) => cs.stateId)
            : [],
          state.allStatesList
        ),
        allLicensedStates:
          checkIfContainsAll(
            state.company.companyStates.length > 0
              ? state.company.companyStates.map((cs) => cs.stateId)
              : [],
            state.allLicensedStatesList
          ) &&
          state.company.companyStates.length ===
            state.allLicensedStatesList.length,
        allUnlicensedStates:
          checkIfContainsAll(
            state.company.companyStates.length > 0
              ? state.company.companyStates.map((cs) => cs.stateId)
              : [],
            state.allUnlicensedStatesList
          ) &&
          state.company.companyStates.length ===
            state.allUnlicensedStatesList.length
      }));
    } else {
      return;
    }
  }, [
    state.company.id,
    state.allStatesList,
    state.allLicensedStatesList,
    state.allUnlicensedStatesList
  ]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      customStates:
        !state.allLicensedStates &&
        !state.allUnlicensedStates &&
        !state.allStates
    }));
  }, [state.allStates, state.allUnlicensedStates, state.allLicensedStates]);

  function getErrorList() {
    let list = [];
    if (state.company.name === null || state.company.name === "") {
      list.push("You must provide a name.");
    }
    if (state.company.departmentId === null) {
      list.push("You must select a department.");
    }
    if (
      state.company.underwriterId === null &&
      (state.company.departmentId === 1 ||
        state.company.departmentId === 7 ||
        state.company.departmentId === 11)
    ) {
      list.push("You must select an underwriter type.");
    }
    if (!state.company.companyStates.length > 0) {
      list.push("You must select at least one servicing state.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [
    state.company.name,
    state.company.departmentId,
    state.company.underwriterId,
    state.company.companyStates,
    state.submitAttempted
  ]);

  function handleInputChange(value, prop) {
    setState((state) => ({
      ...state,
      company: { ...state.company, [prop]: value }
    }));
  }

  function buildCompanyStates(value) {
    return value.map((v) => ({
      companyId: 0,
      stateId: v
    }));
  }

  return (
    <React.Fragment>
      <div className={css({ width: 605, paddingBottom: 34 })}>
        <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
          {`${state.editMode ? "Edit" : "New"} Contact`}
        </Header>
        {!state.editMode && (
          <div
            className={css({
              padding: 8,
              backgroundColor: "#f7f7f9",
              borderRadius: 5,
              display: "inline-block",
              marginBottom: 32
            })}
          >
            <Button
              onClick={() =>
                history.replace(
                  `/contacts/new-contact/states/${+match.params
                    .state}/departments/${+match.params.department}`
                )
              }
              size="large"
              className={css({
                backgroundColor: "transparent !important",
                color: "#444444 !important",
                "&:hover": { opacity: 0.75 }
              })}
              data-automation="newContact"
            >
              Individual
            </Button>
            <Button
              onClick={() =>
                history.replace(
                  `/contacts/new-company/states/${+match.params
                    .state}/departments/${+match.params.department}`
                )
              }
              size="large"
              className={css({
                backgroundColor: "#000e48 !important",
                color: "#fff !important"
              })}
              data-automation="newCompany"
            >
              Company
            </Button>
          </div>
        )}
        <Form>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field required>
                  <label>Name</label>
                  <Input
                    size="large"
                    value={state.company.name}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "name")
                    }
                    error={
                      state.submitAttempted &&
                      (state.company.name === "" || state.company.name === null)
                    }
                    autoComplete="new-password"
                    data-automation="name"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>Address</label>
                  <Input
                    size="large"
                    value={state.company.address1}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "address1")
                    }
                    autoComplete="new-password"
                    data-automation="address1"
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    size="large"
                    placeholder="Attention, suite, unit, building, floor, etc."
                    value={state.company.address2}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "address2")
                    }
                    autoComplete="new-password"
                    data-automation="address2"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>City</label>
                  <Input
                    size="large"
                    value={state.company.city}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "city")
                    }
                    autoComplete="new-password"
                    data-automation="city"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>State</label>
                  <Input
                    size="large"
                    value={state.company.state}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "state")
                    }
                    autoComplete="new-password"
                    data-automation="state"
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label>Zip</label>
                  <Input
                    size="large"
                    value={state.company.zip}
                    onChange={(_, { value }) => handleInputChange(value, "zip")}
                    autoComplete="new-password"
                    data-automation="zip"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>Website</label>
                  <Input
                    size="large"
                    value={state.company.website}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "website")
                    }
                    autoComplete="new-password"
                    data-automation="website"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Phone</label>
                  <Input
                    size="large"
                    value={state.company.phone}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "phone")
                    }
                    autoComplete="new-password"
                    data-automation="phone"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Fax</label>
                  <Input
                    size="large"
                    value={state.company.fax}
                    onChange={(_, { value }) => handleInputChange(value, "fax")}
                    autoComplete="new-password"
                    data-automation="fax"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Email</label>
                  <Input
                    size="large"
                    value={state.company.email}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "email")
                    }
                    autoComplete="new-password"
                    data-automation="email"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <Checkbox
                    label="All states"
                    checked={state.allStates}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        allStates: true,
                        allLicensedStates: false,
                        allUnlicensedStates: false,
                        customStates: false,
                        company: {
                          ...state.company,
                          companyStates: buildCompanyStates(
                            state.allStatesList
                          ),
                          departmentId:
                            state.company.departmentId === 19
                              ? null
                              : state.company.departmentId
                        }
                      }))
                    }
                    data-automation="allStates"
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="All licensed states"
                    checked={state.allLicensedStates}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        allStates: false,
                        allLicensedStates: true,
                        allUnlicensedStates: false,
                        customStates: false,
                        company: {
                          ...state.company,
                          companyStates: buildCompanyStates(
                            state.allLicensedStatesList
                          ),
                          departmentId:
                            state.company.departmentId === 19
                              ? null
                              : state.company.departmentId
                        }
                      }))
                    }
                    data-automation="allLicensedStates"
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="All unlicensed states"
                    checked={state.allUnlicensedStates}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        allStates: false,
                        allLicensedStates: false,
                        allUnlicensedStates: true,
                        customStates: false,
                        company: {
                          ...state.company,
                          companyStates: buildCompanyStates(
                            state.allUnlicensedStatesList
                          ),
                          departmentId:
                            state.company.departmentId === 19
                              ? null
                              : state.company.departmentId
                        }
                      }))
                    }
                    data-automation="allUnlicensedStates"
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="Custom (choose your own state selection)"
                    checked={state.customStates}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        allStates: false,
                        allLicensedStates: false,
                        allUnlicensedStates: false,
                        customStates: true
                      }))
                    }
                    data-automation="customStates"
                  />
                </Form.Field>
                {state.customStates && (
                  <Form.Field required>
                    <label>Servicing States</label>
                    <Dropdown
                      size="large"
                      fluid
                      multiple
                      selection
                      placeholder="Select States"
                      style={{ fontSize: 16 }}
                      options={state.states}
                      value={
                        state.company.companyStates
                          ? state.company.companyStates.map((cs) => cs.stateId)
                          : null
                      }
                      onChange={(_, { value }) => {
                        setState((state) => ({
                          ...state,
                          company: {
                            ...state.company,
                            companyStates:
                              value[value.length - 1] === 52
                                ? buildCompanyStates([52])
                                : buildCompanyStates(
                                    value.filter((v) => v !== 52)
                                  ),
                            departmentId:
                              value[value.length - 1] === 52
                                ? 19
                                : state.company.departmentId === 19
                                ? null
                                : state.company.departmentId
                          }
                        }));
                      }}
                      error={
                        state.submitAttempted &&
                        !state.company.companyStates.length > 0
                      }
                      data-automation="servicingStates"
                    />
                  </Form.Field>
                )}
              </Grid.Column>
            </Grid.Row>
            {!state.company.companyStates.some((cs) => cs.stateId === 52) && (
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field required>
                    <label>Department</label>
                    <Dropdown
                      size="large"
                      fluid
                      selection
                      placeholder="Select Department"
                      style={{ fontSize: 16 }}
                      options={state.departments}
                      value={state.company.departmentId}
                      onChange={(_, { value }) =>
                        setState((state) => ({
                          ...state,
                          company: { ...state.company, departmentId: value }
                        }))
                      }
                      error={
                        state.submitAttempted &&
                        state.company.departmentId === null
                      }
                      data-automation="departments"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            )}
            {state.company.departmentId === 1 ||
            state.company.departmentId === 7 ||
            state.company.departmentId === 11 ? (
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field required>
                    <label>Underwriter</label>
                    <Dropdown
                      size="large"
                      fluid
                      selection
                      placeholder="Select Underwriter"
                      style={{ fontSize: 16 }}
                      options={state.underwriters}
                      value={state.company.underwriterId}
                      onChange={(_, { value }) =>
                        setState((state) => ({
                          ...state,
                          company: { ...state.company, underwriterId: value }
                        }))
                      }
                      error={
                        state.submitAttempted &&
                        state.company.underwriterId === null
                      }
                      data-automation="underwriters"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {eAndODepartments.some((d) => d === state.company.departmentId) && (
              <React.Fragment>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <Form.Field>
                      <label>E & O</label>
                      <Checkbox
                        label="Y"
                        checked={state.company.eAndO === "Y"}
                        size="large"
                        onClick={() =>
                          handleInputChange(
                            state.company.eAndO === "Y" ? null : "Y",
                            "eAndO"
                          )
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field>
                      <Checkbox
                        label="N"
                        checked={state.company.eAndO === "N"}
                        size="large"
                        onClick={() =>
                          handleInputChange(
                            state.company.eAndO === "N" ? null : "N",
                            "eAndO"
                          )
                        }
                      />
                    </Form.Field>
                  </Grid.Column>
                  {(state.company.departmentId === 6 ||
                    state.company.departmentId === 20) && (
                    <Grid.Column>
                      <Form.Field>
                        <Checkbox
                          label="N/A"
                          checked={state.company.eAndO === "N/A"}
                          size="large"
                          onClick={() =>
                            handleInputChange(
                              state.company.eAndO === "N/A" ? null : "N/A",
                              "eAndO"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field>
                      <label>E & O Date</label>
                      <Input
                        size="large"
                        type="date"
                        value={state.company.eAndODate}
                        onChange={(_, { value }) => {
                          handleInputChange(value, "eAndODate");
                        }}
                        autoComplete="new-password"
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
                {(state.company.departmentId === 6 ||
                  state.company.departmentId === 20) && (
                  <React.Fragment>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Form.Field>
                          <label>Liability Disclaimer on Search</label>
                          <Checkbox
                            label="Y"
                            checked={
                              state.company.liabilityDisclaimerOnSearch === "Y"
                            }
                            size="large"
                            onClick={() =>
                              handleInputChange(
                                state.company.liabilityDisclaimerOnSearch ===
                                  "Y"
                                  ? null
                                  : "Y",
                                "liabilityDisclaimerOnSearch"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <Checkbox
                            label="N"
                            checked={
                              state.company.liabilityDisclaimerOnSearch === "N"
                            }
                            size="large"
                            onClick={() =>
                              handleInputChange(
                                state.company.liabilityDisclaimerOnSearch ===
                                  "N"
                                  ? null
                                  : "N",
                                "liabilityDisclaimerOnSearch"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Form.Field>
                          <label>Signed Agreement</label>
                          <Checkbox
                            label="Y"
                            checked={state.company.signedAgreement === "Y"}
                            size="large"
                            onClick={() =>
                              handleInputChange(
                                state.company.signedAgreement === "Y"
                                  ? null
                                  : "Y",
                                "signedAgreement"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <Checkbox
                            label="N"
                            checked={state.company.signedAgreement === "N"}
                            size="large"
                            onClick={() =>
                              handleInputChange(
                                state.company.signedAgreement === "N"
                                  ? null
                                  : "N",
                                "signedAgreement"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <Checkbox
                            label="D (declined)"
                            checked={state.company.signedAgreement === "D"}
                            size="large"
                            onClick={() =>
                              handleInputChange(
                                state.company.signedAgreement === "D"
                                  ? null
                                  : "D",
                                "signedAgreement"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Form.Field>
                          <Checkbox
                            label="N/A"
                            checked={state.company.signedAgreement === "N/A"}
                            size="large"
                            onClick={() =>
                              handleInputChange(
                                state.company.signedAgreement === "N/A"
                                  ? null
                                  : "N/A",
                                "signedAgreement"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                    </Grid.Row>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>Tag</label>
                  <Dropdown
                    size="large"
                    options={state.contactTags}
                    placeholder="Choose Tag"
                    search
                    selection
                    allowAdditions
                    style={{ fontSize: 16 }}
                    value={state.company.contactTagId}
                    onAddItem={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        newTag: value,
                        contactTags: [
                          ...state.contactTags.filter((c) => c.value !== 0),
                          {
                            key: 0,
                            text: value,
                            value: 0
                          }
                        ],
                        company: { ...state.company, contactTagId: 0 }
                      }))
                    }
                    onChange={(e, { value, text }) => {
                      setState({
                        ...state,
                        company: {
                          ...state.company,
                          contactTagId: value,
                          isPrimary: value === 1,
                          isGlobal: value === 2,
                          isLastOption: value === 3
                        },
                        newTag: text
                      });
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>Custom Tag</label>
                  <Input
                    size="large"
                    placeholder="Custom Tag"
                    value={state.company.customTag}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "customTag")
                    }
                    autoComplete="new-password"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Lookup Code</label>
                  <Input
                    size="large"
                    value={state.company.lookupCode}
                    onChange={(_, { value }) =>
                      handleInputChange(value, "lookupCode")
                    }
                    autoComplete="new-password"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field required>
                  <FileUpload
                    fileList={state.files}
                    setFileToApp={(files) =>
                      setState((state) => ({
                        ...state,
                        files: [...state.files, ...files]
                      }))
                    }
                    deleteFileToApp={(fileName) =>
                      setState((state) => ({
                        ...state,
                        files: [
                          ...state.files.filter((f) => f.name !== fileName)
                        ]
                      }))
                    }
                    multiple={true}
                  />
                </Form.Field>
                {state.company.companyFiles &&
                  state.company.companyFiles.map((f) => (
                    <p
                      key={shortid.generate()}
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          filesToDelete: [...state.filesToDelete, f.id],
                          company: {
                            ...state.company,
                            companyFiles: [
                              ...state.company.companyFiles.filter(
                                (cf) => cf.id !== f.id
                              )
                            ]
                          }
                        }))
                      }
                      className={css({
                        "&:hover": { color: "#707898" },
                        cursor: "pointer"
                      })}
                    >
                      <i
                        className="fal fa-trash-alt"
                        style={{ marginRight: 8 }}
                      />
                      {f.fileName}
                    </p>
                  ))}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <TextArea
                    placeholder="Note"
                    style={{ minHeight: 192 }}
                    value={state.company.note}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        company: { ...state.company, note: value }
                      }))
                    }
                    data-automation="note"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <Message
                  visible={state.submitAttempted && state.errorList.length > 0}
                  error
                  header="There was some errors with your submission"
                  list={state.errorList}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                <Button
                  size="large"
                  className="cancelButton"
                  onClick={() => history.goBack()}
                  data-automation="cancel"
                >
                  Cancel
                </Button>
                <Button
                  size="large"
                  className="saveButton"
                  onClick={() => {
                    if (state.company.isPrimary) {
                      checkIfStateHasPrimaryContact();
                    } else {
                      saveChanges();
                    }
                  }}
                  data-automation="save"
                >
                  {state.submitting ? "Submitting..." : "Save"}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
      {state.confirmSaveAsPrimary && (
        <Modal
          size={"tiny"}
          open={state.confirmSaveAsPrimary !== null}
          onClose={() =>
            setState((state) => ({ ...state, saveAsPrimaryConfirmed: null }))
          }
          dimmer="inverted"
          data-automation="confirmModal"
        >
          <Modal.Header style={{ fontWeight: "normal" }}>
            Another{" "}
            <strong>
              primary{" "}
              {state.departments
                .find((d) => d.value === state.company.departmentId)
                .text.toLowerCase()}
            </strong>{" "}
            exists for the following&nbsp;states!
          </Modal.Header>
          <Modal.Content>
            <p>
              {state.states
                .filter((s) =>
                  state.statesWithPrimaryContact.some((spc) => spc === s.value)
                )
                .map((s) => (
                  <li key={shortid.generate()}>{s.text}</li>
                ))}
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({
                  ...state,
                  confirmSaveAsPrimary: false,
                  saveAsPrimaryConfirmed: false
                }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
              data-automation="confirmCancel"
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={() => {
                setState((state) => ({
                  ...state,
                  saveAsPrimaryConfirmed: true,
                  confirmSaveAsPrimary: false
                }));
                saveChanges();
              }}
              style={{ backgroundColor: "#1f2b5e" }}
              data-automation="confirmContinue"
            >
              Continue
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}
