import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Modal, Table } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import CustomTable from "../../CustomTable";
import { paragraphStyles } from "../../styles/paragraphStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { Popup } from "../../Popup";

function HelpfulWordings({ history, match: { params } }) {
  const { user } = useContext(UserContext);
  const { addToast } = useToasts();
  const { selectedState } = useContext(StateContext);

  const [state, setState] = useState({
    helpfulWordings: [],
    noResults: false,
    initialLoaded: false,
    wordingToDelete: null,
    helpfulWordingCategories: [],
    selectedCategory: +params.category || 1
  });

  async function getHelpfulWordings() {
    const result = await getRequest(
      "/surveyHelpfulWording/getSurveyHelpfulWordings"
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWordings: result.data,
        initialLoaded: true
      }));
    }
  }

  async function getHelpfulWordingCategories() {
    const result = await getRequest(
      "/surveyHelpfulWording/getSurveyHelpfulWordingCategories"
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWordingCategories: result.data
      }));
    }
  }

  async function deleteHelpfulWording(id) {
    const result = await postRequest(
      `/surveyHelpfulWording/deleteSurveyHelpfulWording?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWordings: state.helpfulWordings.filter((w) => w.id !== id),
        wordingToDelete: null
      }));
    }
  }

  useEffect(() => {
    getHelpfulWordingCategories();
    getHelpfulWordings();
  }, []);

  useEffect(() => {
    setState((state) => ({ ...state, selectedCategory: +params.category }));
  }, [params]);

  return (
    <React.Fragment>
      <Grid>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column floated="left" width={12}>
            <div
              className={css({
                fontFamily: "Fakt-Bold",
                fontSize: 20,
                lineHeight: 1.2,
                color: "#47517b",
                display: "inline-block"
              })}
            >
              <span className={css({ marginRight: 18 })}>Helpful Wordings</span>
              {state.helpfulWordingCategories?.map((c) => (
                <Button
                  key={c.id}
                  className={css({
                    backgroundColor:
                      +state.selectedCategory === c.id
                        ? "#47517B !important"
                        : "#ebecf1 !important",
                    color:
                      +state.selectedCategory === c.id
                        ? "#ffffff !important"
                        : "rgba(0, 0, 0, 0.87) !important"
                  })}
                  onClick={() =>
                    history.push(
                      `/surveys/states/${selectedState}/tabs/helpful-wordings/categories/${c.id}`
                    )
                  }
                >
                  {c.name}:
                </Button>
              ))}
            </div>
          </Grid.Column>
          {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
            <Grid.Column
              floated="right"
              width={4}
              className={css({ textAlign: "right" })}
            >
              <Button
                icon
                className="blueButton"
                onClick={() => history.push(`/surveys/new-helpful-wording`)}
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() => history.push(`/surveys/new-helpful-wording`)}
              >
                New Wording
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <CustomTable
        customStyles={{
          "& tr td:first-of-type": {
            paddingLeft: "40.5px !important"
          },
          "& tr td": {
            paddingTop: "18px !important",
            paddingBottom: "18px !important"
          },
          "& th": {
            position: "sticky",
            top: 0,
            zIndex: 1
          },
          "& tr th:not(:first-of-type), tr td:not(:first-of-type)": {
            borderLeft: "none !important"
          },
          "& tr th:first-of-type, tr td:first-of-type": {
            paddingLeft: "60px !important"
          },
          marginBottom: "40px !important",
          marginTop: "29px !important"
        }}
      >
        <Table.Header>
          <Table.Row verticalAlign="top">
            <Table.HeaderCell width={8} textAlign="left">
              Subject
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {state.helpfulWordings.length < 1 && (
            <Table.Row>
              <Table.Cell
                className={css({
                  textAlign: "left !important",
                  border: "none !important",
                  position: "relative !important"
                })}
              >
                No wordings available
              </Table.Cell>
            </Table.Row>
          )}
          {state.helpfulWordings
            .filter(
              (w) => w.surveyHelpfulWordingCategoryId === state.selectedCategory
            )
            .sort((a, b) => a.subject.localeCompare(b.subject))
            .map((w) => (
              <Table.Row verticalAlign="middle" key={w.id}>
                <Table.Cell
                  className={css({
                    textAlign: "left !important",
                    border: "none !important",
                    position: "relative !important"
                  })}
                >
                  <Popup
                    on={["hover", "focus"]}
                    header={w.subject}
                    content={w.body}
                    trigger={
                      <span className={css({ fontFamily: "Fakt-Bold" })}>
                        {w.subject}
                        <br />
                        <p className={paragraphStyles}>{w.note}</p>
                      </span>
                    }
                  />
                </Table.Cell>
                <Table.Cell
                  style={{
                    textAlign: "right"
                  }}
                  verticalAlign="top"
                >
                  <CopyToClipboard
                    text={w.body}
                    onCopy={() =>
                      addToast("Copied!", {
                        appearance: "success",
                        autoDismiss: true
                      })
                    }
                  >
                    <Button icon>
                      <i className="fal fa-clone" />
                    </Button>
                  </CopyToClipboard>
                  <a
                    href={`mailto:?subject=${encodeURIComponent(
                      w.subject
                    )}&body=${encodeURIComponent(w.body || "")}`}
                  >
                    <Button icon>
                      <i className="fal fa-envelope-open" />
                    </Button>
                  </a>
                  {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
                    <React.Fragment>
                      <Button
                        icon
                        onClick={() =>
                          setState((state) => ({
                            ...state,
                            wordingToDelete: w
                          }))
                        }
                      >
                        <i className="fal fa-trash-alt" />
                      </Button>
                      <Button
                        icon
                        onClick={() =>
                          history.push(`/surveys/edit-helpful-wording/${w.id}`)
                        }
                      >
                        <i className="fal fa-pencil" />
                      </Button>
                    </React.Fragment>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </CustomTable>
      {state.wordingToDelete && (
        <Modal
          size={"tiny"}
          open={state.wordingToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, wordingToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Wording</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete <br />
              <strong>{state.wordingToDelete.subject}</strong> ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, wordingToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteHelpfulWording(state.wordingToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(HelpfulWordings);
