import React, { useState, useEffect } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { Table, Icon } from "semantic-ui-react";
import { css } from "emotion";
import { postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import CustomTable from "../../CustomTable";
import LinesEllipsis from "react-lines-ellipsis";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { Highlight } from "../../search/Highlight";
import { Popup } from "../../Popup";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  fontWeight: 300,
  cursor: "pointer",
});

const DragHandle = SortableHandle(() => (
  <span
    className={css({
      position: "absolute",
      left: 16,
      color: "#c3c3c3",
      cursor: "grab",
    })}
  >
    <i className="fal fa-sort" />
  </span>
));

function DragSurveySortCodes({
  items,
  saveSortPath,
  history,
  draggable,
  deleteAction,
  searchMode,
}) {
  const { addToast } = useToasts();
  const [state, setState] = useState({
    items: items,
    sorted: false,
  });

  async function saveSort() {
    postRequest(saveSortPath, state.items);
  }

  const SortableItem = SortableElement(({ value }) => (
    <Table.Row key={value.id} className={value.highlight ? "highlight" : null}>
      <Table.Cell
        style={{
          textAlign: "left",
          position: "relative",
        }}
      >
        {draggable && (
          <React.Fragment>
            <DragHandle />
            <span
              className={css({
                "&:hover i": { color: "#00b8d4" },
                cursor: "pointer",
                verticalAlign: "top",
                display: "inline-block",
                position: "absolute",
                left: 36,
              })}
              onClick={() =>
                history.push(`/surveys/edit-survey-exception-code/${value.id}`)
              }
            >
              <Icon style={{ paddingRight: 32.5 }} className="fal fa-pen" />
            </span>
            <span
              className={css({
                "&:hover i": { color: "#00b8d4" },
                cursor: "pointer",
                display: "inline-block",
                position: "absolute",
                left: 56,
              })}
              onClick={() => deleteAction(value)}
            >
              <Icon style={{ paddingRight: 32.5 }} className="fal fa-trash" />
            </span>
          </React.Fragment>
        )}
        <React.Fragment>
          <div
            className={css({
              display: "inline-block",
            })}
          >
            <span
              className={css({
                fontFamily: value.scheduleSectionId === 1 ? null : "Fakt-Bold",
                textAlign: "left",
              })}
            >
              <span className={paragraphStyles}>
                {value.caseScenario && !searchMode ? (
                  <Popup
                    on={["hover", "focus"]}
                    header={value.code}
                    content={value.caseScenario}
                    trigger={
                      <CopyToClipboard
                        text={value.caseScenario}
                        onCopy={() =>
                          addToast("Copied!", {
                            appearance: "success",
                            autoDismiss: true,
                          })
                        }
                      >
                        <div
                          className={css({
                            cursor: "pointer",
                            paddingLeft: 8,
                          })}
                        >
                          <LinesEllipsis
                            text={value.caseScenario}
                            maxLine="2"
                            ellipsis="..."
                            style={{ textAlign: "left" }}
                          />
                        </div>
                      </CopyToClipboard>
                    }
                  />
                ) : (
                  <Highlight children={value.caseScenario} />
                )}
              </span>
            </span>
          </div>
        </React.Fragment>
      </Table.Cell>
      {value.surveyExceptionSectionId === 1 && (
        <Table.Cell className={css({ textAlign: "left !important" })}>
          <span className={css({ fontFamily: "Fakt-Bold" })}>
            <Highlight children={value.code} />
          </span>
        </Table.Cell>
      )}
      <Table.Cell
        style={{
          textAlign: "left",
        }}
      >
        <span className={paragraphStyles}>
          {value.wording && !searchMode ? (
            <Popup
              on={["hover", "focus"]}
              header={value.code}
              content={value.wording}
              trigger={
                <CopyToClipboard
                  text={value.wording}
                  onCopy={() =>
                    addToast("Copied!", {
                      appearance: "success",
                      autoDismiss: true,
                    })
                  }
                >
                  <div
                    className={css({
                      cursor: "pointer",
                    })}
                  >
                    <LinesEllipsis
                      text={value.wording}
                      maxLine="2"
                      ellipsis="..."
                      style={{ textAlign: "left" }}
                    />
                  </div>
                </CopyToClipboard>
              }
            />
          ) : (
            <CopyToClipboard
              text={value.wording}
              onCopy={() =>
                addToast("Copied!", {
                  appearance: "success",
                  autoDismiss: true,
                })
              }
            >
              <Highlight children={value.wording} />
            </CopyToClipboard>
          )}
        </span>
      </Table.Cell>
      <Table.Cell
        style={{
          textAlign: "left",
        }}
      >
        <span className={paragraphStyles}>
          {value.note && !searchMode ? (
            <Popup
              on={["hover", "focus"]}
              header={value.code}
              content={value.note}
              trigger={
                <CopyToClipboard
                  text={value.note}
                  onCopy={() =>
                    addToast("Copied!", {
                      appearance: "success",
                      autoDismiss: true,
                    })
                  }
                >
                  <div
                    className={css({
                      cursor: "pointer",
                    })}
                  >
                    <LinesEllipsis
                      text={value.note}
                      maxLine="2"
                      ellipsis="..."
                      style={{ textAlign: "left" }}
                    />
                  </div>
                </CopyToClipboard>
              }
            />
          ) : (
            <CopyToClipboard
              text={value.note}
              onCopy={() =>
                addToast("Copied!", {
                  appearance: "success",
                  autoDismiss: true,
                })
              }
            >
              <Highlight children={value.note} />
            </CopyToClipboard>
          )}
        </span>
      </Table.Cell>
    </Table.Row>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div
        className={css({
          "& .highlight, & .highlight:nth-of-type(2n)": {
            backgroundColor: "#00b7d326 !important",
          },
        })}
      >
        {items.length > 0 && (
          <div
            className={css({
              width: "100%",
              overflowX: "auto",
              borderRadius: "0.28571429rem",
              marginTop: 16,
              marginBottom: "24px !important",
            })}
          >
            <CustomTable
              mediumCompact
              customStyles={{
                width: "100%",
                "& th": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                },
                marginBottom: "24px !important",
              }}
            >
              <Table.Header>
                <Table.Row>
                  <React.Fragment>
                    <Table.HeaderCell width={3} style={{ textAlign: "left" }}>
                      Case Scenario
                    </Table.HeaderCell>
                    {items.some(
                      (item) => item.surveyExceptionSectionId === 1
                    ) && (
                      <Table.HeaderCell width={2} style={{ textAlign: "left" }}>
                        Code
                      </Table.HeaderCell>
                    )}
                  </React.Fragment>
                  <Table.HeaderCell style={{ textAlign: "left" }} width={3}>
                    Wording
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ textAlign: "left" }} width={3}>
                    Notes
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {items.map((value, index) => (
                  <SortableItem
                    key={`item-${value.id}`}
                    index={index}
                    value={value}
                  />
                ))}
              </Table.Body>
            </CustomTable>
          </div>
        )}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState((state) => ({
      ...state,
      sorted: true,
      items: arrayMove(state.items, oldIndex, newIndex).map((n, i) => ({
        ...n,
        sortNum: i,
      })),
    }));
  };

  useEffect(() => {
    if (draggable) {
      saveSort(state.items);
    }
  }, [state.items]);

  useEffect(() => {
    setState((state) => ({ ...state, items: items }));
  }, [items]);

  return (
    <SortableList items={state.items} onSortEnd={onSortEnd} useDragHandle />
  );
}

export default withRouter(DragSurveySortCodes);
