import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Dropdown,
  Message,
  Input
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import FileUpload from "../../FileUpload";
import { StateContext } from "../../State";
import { logEvent } from "../../../helpers/analyticsLogger";

export function AddQuoteTemplate({ history }) {
  const { selectedState } = useContext(StateContext);

  const defaultQuoteTemplate = {
    stateId: selectedState,
    name: undefined
  };

  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    states: [],
    quoteTemplate: defaultQuoteTemplate,
    file: null
  });

  function getErrorList() {
    let list = [];
    if (!state.quoteTemplate.stateId && state.quoteTemplate.stateId !== 0) {
      list.push("You must select a State.");
    }
    if (!state.quoteTemplate.name) {
      list.push("You must provide a template name.");
    }
    if (!state.file) {
      list.push("You must select a file.");
    }
    return list;
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  useEffect(() => {
    getStates();
  }, []);

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.quoteTemplate, state.file]);

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result;
        resolve(result.substr(result.indexOf(",") + 1));
      };
      reader.readAsDataURL(file);
    });
  };

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const base64File = await readFileAsBase64(state.file);
        const fileExtension = state.file.name.split(".").pop();
        const result = await postRequest(`/quoteTemplate/addQuoteTemplate`, {
          ...state.quoteTemplate,
          base64File,
          fileExtension
        });
        if (result.success) {
          logEvent(null, "Added", "Quote Template");
          setTimeout(function() {
            history.goBack();
          }, 1000);
        }
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        New Quote Template
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>State</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select State"
                  style={{ fontSize: 16 }}
                  options={state.states}
                  value={state.quoteTemplate.stateId}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      quoteTemplate: {
                        ...state.quoteTemplate,
                        stateId: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    state.quoteTemplate.stateId === null
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Template Name</label>
                <Input
                  size="large"
                  value={state.quoteTemplate.name}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      quoteTemplate: { ...state.quoteTemplate, name: value }
                    }))
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  setFileToApp={(file) =>
                    setState((state) => ({ ...state, file }))
                  }
                  fileList={state.file ? [state.file] : []}
                  deleteFileToApp={() =>
                    setState((state) => ({
                      ...state,
                      file: null
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(AddQuoteTemplate);
