import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  TextArea,
  Dropdown
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import FileUpload from "../../FileUpload";
import shortid from "shortid";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import { logEvent } from "../../../helpers/analyticsLogger";

const sectionHeaderStyles = css({
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  marginBottom: 17,
  lineHeight: 1.71
});

export function EditAddSearchGuideline({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    searchGuideline: {},
    editMode: match.path.includes("edit"),
    errorList: [],
    underwriters: [{}],
    purchaseFiles: [],
    refinanceFiles: [],
    searchGuidelinePurchaseFilesToDelete: [],
    searchGuidelineRefinanceFilesToDelete: []
  });

  async function getSearchGuidelineById(id) {
    const result = await getRequest(`/searchGuideline/getSearchGuidelineById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        searchGuideline: result.data
      }));
    }
  }

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState((state) => ({
        ...state,
        underwriters: result.data
          .filter((u) => u.name !== "All Underwriters")
          .map((u) => ({
            key: u.id,
            value: u.id,
            text: u.name
          }))
      }));
    }
  }

  useEffect(() => {
    getUnderwriters();
    if (state.editMode) {
      getSearchGuidelineById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/searchGuideline/editAddSearchGuideline`,
          {
            ...state.searchGuideline,
            stateId: state.editMode
              ? state.searchGuideline.stateId
              : selectedState,
            purchaseFiles: await Promise.all(
              state.purchaseFiles.map(async (f) => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            refinanceFiles: await Promise.all(
              state.refinanceFiles.map(async (f) => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            searchGuidelinePurchaseFilesToDelete:
              state.searchGuidelinePurchaseFilesToDelete,
            searchGuidelineRefinanceFilesToDelete:
              state.searchGuidelineRefinanceFilesToDelete
          }
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Search Guideline"
          );
          setTimeout(function() {
            history.goBack();
          }, 1000);
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.searchGuideline.underwriterId) {
      list.push("You must select a underwriter.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.searchGuideline]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Search Guideline`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1} style={{ marginBottom: 32 }}>
            <Grid.Column>
              <Form.Field required>
                <label>Underwriter</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select Underwriter"
                  style={{ fontSize: 16 }}
                  options={state.underwriters}
                  value={state.searchGuideline.underwriterId}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuideline: {
                        ...state.searchGuideline,
                        underwriterId: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    state.searchGuideline.underwriterId === null
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <div className={sectionHeaderStyles}>Purchase</div>
                <label>Full Search</label>
                <TextArea
                  placeholder="Full Search"
                  style={{ minHeight: 96 }}
                  value={state.searchGuideline.purchaseFullSearch}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuideline: {
                        ...state.searchGuideline,
                        purchaseFullSearch: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Prior OP</label>
                <TextArea
                  placeholder="Prior OP"
                  style={{ minHeight: 96 }}
                  value={state.searchGuideline.purchasePriorOP}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuideline: {
                        ...state.searchGuideline,
                        purchasePriorOP: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Prior LP</label>
                <TextArea
                  placeholder="Prior LP"
                  style={{ minHeight: 96 }}
                  value={state.searchGuideline.purchasePriorLP}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuideline: {
                        ...state.searchGuideline,
                        purchasePriorLP: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.purchaseFiles}
                  setFileToApp={(purchaseFiles) =>
                    setState((state) => ({
                      ...state,
                      purchaseFiles: [...state.purchaseFiles, ...purchaseFiles]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      purchaseFiles: [
                        ...state.purchaseFiles.filter(
                          (f) => f.name !== fileName
                        )
                      ]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.searchGuideline.searchGuidelinePurchaseFiles &&
                state.searchGuideline.searchGuidelinePurchaseFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        searchGuidelinePurchaseFilesToDelete: [
                          ...state.searchGuidelinePurchaseFilesToDelete,
                          f.id
                        ],
                        searchGuideline: {
                          ...state.searchGuideline,
                          searchGuidelinePurchaseFiles: [
                            ...state.searchGuideline.searchGuidelinePurchaseFiles.filter(
                              (rf) => rf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1} style={{ marginTop: 32 }}>
            <Grid.Column>
              <Form.Field>
                <div className={sectionHeaderStyles}>Refinance</div>
                <label>Full Search</label>
                <TextArea
                  placeholder="Full Search"
                  style={{ minHeight: 96 }}
                  value={state.searchGuideline.refinanceFullSearch}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuideline: {
                        ...state.searchGuideline,
                        refinanceFullSearch: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Prior OP</label>
                <TextArea
                  placeholder="Prior OP"
                  style={{ minHeight: 96 }}
                  value={state.searchGuideline.refinancePriorOP}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuideline: {
                        ...state.searchGuideline,
                        refinancePriorOP: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Prior LP</label>
                <TextArea
                  placeholder="Prior LP"
                  style={{ minHeight: 96 }}
                  value={state.searchGuideline.refinancePriorLP}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuideline: {
                        ...state.searchGuideline,
                        refinancePriorLP: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.refinanceFiles}
                  setFileToApp={(refinanceFiles) =>
                    setState((state) => ({
                      ...state,
                      refinanceFiles: [
                        ...state.refinanceFiles,
                        ...refinanceFiles
                      ]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      refinanceFiles: [
                        ...state.refinanceFiles.filter(
                          (f) => f.name !== fileName
                        )
                      ]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.searchGuideline.searchGuidelineRefinanceFiles &&
                state.searchGuideline.searchGuidelineRefinanceFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        searchGuidelineRefinanceFilesToDelete: [
                          ...state.searchGuidelineRefinanceFilesToDelete,
                          f.id
                        ],
                        searchGuideline: {
                          ...state.searchGuideline,
                          searchGuidelineRefinanceFiles: [
                            ...state.searchGuideline.searchGuidelineRefinanceFiles.filter(
                              (rf) => rf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddSearchGuideline);
