import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  TextArea,
  Input,
  Divider
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../../State";
import { logEvent } from "../../../../helpers/analyticsLogger";

const sectionHeaderStyles = css({
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  marginBottom: 17,
  lineHeight: 1.71
});

export function EditAddRemittanceTargets({ match, history }) {
  const { states } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    stateUnderwriters: [],
    targetsToAdd: [],
    targetsToEdit: [],
    editMode: match.path === "/info/edit-remittance-targets/:id",
    errorList: [],
    totalTargets: null,
    loading: true,
    additionalTargetsToAdd: [],
    additionalTargetsToEdit: [],
    additionalTotalTargets: null,
    additionalErrorList: [],
    additionalOverTenMTargetsToAdd: [],
    additionalOverTenMTargetsToEdit: [],
    additionalOverTenMTotalTargets: null,
    additionalOverTenMErrorList: [],
    additionalOverFifteenMTargetsToAdd: [],
    additionalOverFifteenMTargetsToEdit: [],
    additionalOverFifteenMTotalTargets: null,
    additionalOverFifteenMErrorList: [],
    additionalOverTwentyFiveMTargetsToAdd: [],
    additionalOverTwentyFiveMTargetsToEdit: [],
    additionalOverTwentyFiveMTotalTargets: null,
    additionalOverTwentyFiveMErrorList: []
  });

  async function getRemittanceTargetData(id) {
    if (match.params.id !== "44") {
      const underwriterResults = await getRequest(
        `/underwriter/getStateUnderwriters`,
        {
          id
        }
      );
      const remittanceTargetResult = await getRequest(
        `/remittanceTarget/getRemittanceTargetsByState`,
        {
          id
        }
      );
      if (underwriterResults.success && remittanceTargetResult.success) {
        setState(state => ({
          ...state,
          stateUnderwriters: underwriterResults.data,
          targetsToEdit: remittanceTargetResult.data,
          loading: false
        }));
      }
    } else {
      const underwriterResults = await getRequest(
        `/underwriter/getStateUnderwriters`,
        {
          id
        }
      );
      const remittanceTargetResult = await getRequest(
        `/remittanceTarget/getRemittanceTargetsByState`,
        {
          id
        }
      );
      const additionalRemittanceTargetResult = await getRequest(
        `/additionalRemittanceTarget/getAdditionalRemittanceTargetsByState`,
        {
          id
        }
      );
      const additionalOverTenMRemittanceTargetResult = await getRequest(
        `/additionalOverTenMRemittanceTarget/getAdditionalOverTenMRemittanceTargetsByState`,
        {
          id
        }
      );
      const additionalOverFifteenMRemittanceTargetResult = await getRequest(
        `/additionalOverFifteenMRemittanceTarget/getAdditionalOverFifteenMRemittanceTargetsByState`,
        {
          id
        }
      );
      const additionalOverTwentyFiveMRemittanceTargetResult = await getRequest(
        `/additionalOverTwentyFiveMRemittanceTarget/getAdditionalOverTwentyFiveMRemittanceTargetsByState`,
        {
          id
        }
      );
      if (
        underwriterResults.success &&
        remittanceTargetResult.success &&
        additionalRemittanceTargetResult.success &&
        additionalOverTenMRemittanceTargetResult.success &&
        additionalOverFifteenMRemittanceTargetResult.success &&
        additionalOverTwentyFiveMRemittanceTargetResult.success
      ) {
        setState(state => ({
          ...state,
          stateUnderwriters: underwriterResults.data,
          targetsToEdit: remittanceTargetResult.data,
          additionalTargetsToEdit: additionalRemittanceTargetResult.data,
          additionalOverTenMTargetsToEdit:
            additionalOverTenMRemittanceTargetResult.data,
          additionalOverFifteenMTargetsToEdit:
            additionalOverFifteenMRemittanceTargetResult.data,
          additionalOverTwentyFiveMTargetsToEdit:
            additionalOverTwentyFiveMRemittanceTargetResult.data,
          loading: false
        }));
      }
    }
  }

  function setupTargetsToAdd() {
    if (!state.loading) {
      setState(state => ({
        ...state,
        targetsToAdd: state.stateUnderwriters
          .filter(su =>
            state.targetsToEdit.length > 0
              ? !state.targetsToEdit.some(t => t.underwriterId === su.id)
              : true
          )
          .map(t => ({
            stateId: match.params.id,
            underwriterId: t.id,
            note: undefined,
            percentageTarget: undefined,
            underwriter: { name: t.name }
          }))
      }));
    }
  }

  useEffect(() => {
    getRemittanceTargetData(match.params.id);
  }, []);

  useEffect(() => {
    setState(state => ({
      ...state,
      totalTargets:
        state.targetsToEdit
          .map(tte => tte.percentageTarget || 0)
          .reduce((a, b) => a + b, 0) +
        state.targetsToAdd
          .map(tta => tta.percentageTarget || 0)
          .reduce((a, b) => a + b, 0)
    }));
  }, [state.targetsToEdit, state.targetsToAdd]);

  useEffect(() => {
    if (state.targetsToAdd.length < 1) {
      setupTargetsToAdd();
    }
  }, [state.targetsToEdit, state.stateUnderwriters]);

  async function saveChanges() {
    if (!state.submitting) {
      if (match.params.id === "44") {
        let additionalTargetResult;
        setState(state => ({
          ...state,
          submitAttempted: true,
          submitting:
            !state.errorList.length > 0 &&
            !state.additionalErrorList.length > 0 &&
            !state.additionalOverTenMErrorList.length > 0 &&
            !state.additionalOverFifteenMErrorList.length > 0 &&
            !state.additionalOverTwentyFiveMErrorList.length > 0
        }));
        if (!state.errorList.length > 0) {
          const targetResult = await postRequest(
            `/remittanceTarget/editAddRemittanceTargets`,
            [
              ...state.targetsToEdit,
              ...state.targetsToAdd.map(tta => ({
                stateId: tta.stateId,
                underwriterId: tta.underwriterId,
                note: tta.note,
                percentageTarget: tta.percentageTarget
              }))
            ]
          );
          if (!state.additionalErrorList.length > 0) {
            additionalTargetResult = await postRequest(
              `/additionalRemittanceTarget/editAddAdditionalRemittanceTargets`,
              [
                ...state.additionalTargetsToEdit,
                ...state.additionalTargetsToAdd.map(tta => ({
                  stateId: tta.stateId,
                  underwriterId: tta.underwriterId,
                  note: tta.note,
                  percentageTarget: tta.percentageTarget
                }))
              ]
            );
          }
          if (!state.additionalOverTenMErrorList.length > 0) {
            const additionalOverTenMTargetResult = await postRequest(
              `/additionalOverTenMRemittanceTarget/editAddAdditionalOverTenMRemittanceTargets`,
              [
                ...state.additionalOverTenMTargetsToEdit,
                ...state.additionalOverTenMTargetsToAdd.map(tta => ({
                  stateId: tta.stateId,
                  underwriterId: tta.underwriterId,
                  note: tta.note,
                  percentageTarget: tta.percentageTarget
                }))
              ]
            );
            if (
              targetResult.success &&
              additionalTargetResult.success &&
              additionalOverTenMTargetResult.success
            ) {
              history.goBack();
            }
          }
          if (!state.additionalOverFifteenMErrorList.length > 0) {
            const additionalOverFifteenMTargetResult = await postRequest(
              `/additionalOverFifteenMRemittanceTarget/editAddAdditionalOverFifteenMRemittanceTargets`,
              [
                ...state.additionalOverFifteenMTargetsToEdit,
                ...state.additionalOverFifteenMTargetsToAdd.map(tta => ({
                  stateId: tta.stateId,
                  underwriterId: tta.underwriterId,
                  note: tta.note,
                  percentageTarget: tta.percentageTarget
                }))
              ]
            );
            if (
              targetResult.success &&
              additionalTargetResult.success &&
              additionalOverFifteenMTargetResult.success
            ) {
              history.goBack();
            }
          }
          if (!state.additionalOverTwentyFiveMErrorList.length > 0) {
            const additionalOverTwentyFiveMTargetResult = await postRequest(
              `/additionalOverTwentyFiveMRemittanceTarget/editAddAdditionalOverTwentyFiveMRemittanceTargets`,
              [
                ...state.additionalOverTwentyFiveMTargetsToEdit,
                ...state.additionalOverTwentyFiveMTargetsToAdd.map(tta => ({
                  stateId: tta.stateId,
                  underwriterId: tta.underwriterId,
                  note: tta.note,
                  percentageTarget: tta.percentageTarget
                }))
              ]
            );
            if (
              targetResult.success &&
              additionalTargetResult.success &&
              additionalOverTwentyFiveMTargetResult.success
            ) {
              history.goBack();
            }
          }
        }
      } else {
        setState(state => ({
          ...state,
          submitAttempted: true,
          submitting: !state.errorList.length > 0
        }));
        if (!state.errorList.length > 0) {
          const targetResult = await postRequest(
            `/remittanceTarget/editAddRemittanceTargets`,
            [
              ...state.targetsToEdit,
              ...state.targetsToAdd.map(tta => ({
                stateId: tta.stateId,
                underwriterId: tta.underwriterId,
                note: tta.note,
                percentageTarget: tta.percentageTarget
              }))
            ]
          );
          if (targetResult.success) {
            logEvent(
              null,
              state.editMode ? "Edited" : "Added",
              "Remittance Targets"
            );
            history.goBack();
          }
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (state.totalTargets !== 100) {
      list.push(
        `Please make sure the combined target equals 100% the total is currently ${state.totalTargets}% .`
      );
    }
    if (
      (state.targetsToEdit.length > 0 &&
        !state.targetsToEdit.every(t => t.percentageTarget >= 0)) ||
      (state.targetsToAdd.length > 0 &&
        !state.targetsToAdd.every(t => t.percentageTarget >= 0))
    ) {
      list.push(`Please provide a percentage target for each underwriter.`);
    }
    return list;
  }

  useEffect(() => {
    setState(state => ({ ...state, errorList: getErrorList() }));
  }, [state.totalTargets, state.targetsToAdd, state.targetsToEdit]);

  function setupAdditionalTargetsToAdd() {
    if (!state.loading) {
      setState(state => ({
        ...state,
        additionalTargetsToAdd: state.stateUnderwriters
          .filter(su =>
            state.additionalTargetsToEdit.length > 0
              ? !state.additionalTargetsToEdit.some(
                  t => t.underwriterId === su.id
                )
              : true
          )
          .map(t => ({
            stateId: match.params.id,
            underwriterId: t.id,
            note: undefined,
            percentageTarget: undefined,
            underwriter: { name: t.name }
          }))
      }));
    }
  }

  function setupAdditionalOverTenMTargetsToAdd() {
    if (!state.loading) {
      setState(state => ({
        ...state,
        additionalOverTenMTargetsToAdd: state.stateUnderwriters
          .filter(su =>
            state.additionalOverTenMTargetsToEdit.length > 0
              ? !state.additionalOverTenMTargetsToEdit.some(
                  t => t.underwriterId === su.id
                )
              : true
          )
          .map(t => ({
            stateId: match.params.id,
            underwriterId: t.id,
            note: undefined,
            percentageTarget: undefined,
            underwriter: { name: t.name }
          }))
      }));
    }
  }

  function setupAdditionalOverFifteenMTargetsToAdd() {
    if (!state.loading) {
      setState(state => ({
        ...state,
        additionalOverFifteenMTargetsToAdd: state.stateUnderwriters
          .filter(su =>
            state.additionalOverFifteenMTargetsToEdit.length > 0
              ? !state.additionalOverFifteenMTargetsToEdit.some(
                  t => t.underwriterId === su.id
                )
              : true
          )
          .map(t => ({
            stateId: match.params.id,
            underwriterId: t.id,
            note: undefined,
            percentageTarget: undefined,
            underwriter: { name: t.name }
          }))
      }));
    }
  }

  function setupAdditionalOverTwentyFiveMTargetsToAdd() {
    if (!state.loading) {
      setState(state => ({
        ...state,
        additionalOverTwentyFiveMTargetsToAdd: state.stateUnderwriters
          .filter(su =>
            state.additionalOverTwentyFiveMTargetsToEdit.length > 0
              ? !state.additionalOverTwentyFiveMTargetsToEdit.some(
                  t => t.underwriterId === su.id
                )
              : true
          )
          .map(t => ({
            stateId: match.params.id,
            underwriterId: t.id,
            note: undefined,
            percentageTarget: undefined,
            underwriter: { name: t.name }
          }))
      }));
    }
  }

  useEffect(() => {
    if (match.params.id === "44") {
      setState(state => ({
        ...state,
        additionalTotalTargets:
          state.additionalTargetsToEdit
            .map(tte => tte.percentageTarget || 0)
            .reduce((a, b) => a + b, 0) +
          state.additionalTargetsToAdd
            .map(tta => tta.percentageTarget || 0)
            .reduce((a, b) => a + b, 0),
        additionalOverTenMTotalTargets:
          state.additionalOverTenMTargetsToEdit
            .map(tte => tte.percentageTarget || 0)
            .reduce((a, b) => a + b, 0) +
          state.additionalOverTenMTargetsToAdd
            .map(tta => tta.percentageTarget || 0)
            .reduce((a, b) => a + b, 0),
        additionalOverFifteenMTotalTargets:
          state.additionalOverFifteenMTargetsToEdit
            .map(tte => tte.percentageTarget || 0)
            .reduce((a, b) => a + b, 0) +
          state.additionalOverFifteenMTargetsToAdd
            .map(tta => tta.percentageTarget || 0)
            .reduce((a, b) => a + b, 0),
        additionalOverTwentyFiveMTotalTargets:
          state.additionalOverTwentyFiveMTargetsToEdit
            .map(tte => tte.percentageTarget || 0)
            .reduce((a, b) => a + b, 0) +
          state.additionalOverTwentyFiveMTargetsToAdd
            .map(tta => tta.percentageTarget || 0)
            .reduce((a, b) => a + b, 0)
      }));
    }
  }, [
    state.additionalTargetsToEdit,
    state.additionalTargetsToAdd,
    state.additionalOverTenMTargetsToEdit,
    state.additionalOverTenMTargetsToAdd,
    state.additionalOverFifteenMTargetsToEdit,
    state.additionalOverFifteenMTargetsToAdd,
    state.additionalOverTwentyFiveMTargetsToEdit,
    state.additionalOverTwentyFiveMTargetsToAdd
  ]);

  useEffect(() => {
    if (match.params.id === "44") {
      if (state.additionalTargetsToAdd.length < 1) {
        setupAdditionalTargetsToAdd();
      }
      if (state.additionalOverTenMTargetsToAdd.length < 1) {
        setupAdditionalOverTenMTargetsToAdd();
      }
      if (state.additionalOverFifteenMTargetsToAdd.length < 1) {
        setupAdditionalOverFifteenMTargetsToAdd();
      }
      if (state.additionalOverTwentyFiveMTargetsToAdd.length < 1) {
        setupAdditionalOverTwentyFiveMTargetsToAdd();
      }
    }
  }, [
    state.additionalTargetsToEdit,
    state.additionalOverTenMTargetsToEdit,
    state.additionalOverFifteenMTargetsToEdit,
    state.additionalOverTwentyFiveMTargetsToEdit,
    state.stateUnderwriters
  ]);

  function getAdditionalErrorList() {
    let list = [];
    if (state.additionalTotalTargets !== 100) {
      list.push(
        `Please make sure the combined target equals 100% the total is currently ${state.additionalTotalTargets}% .`
      );
    }
    if (
      (state.additionalTargetsToEdit.length > 0 &&
        !state.additionalTargetsToEdit.every(t => t.percentageTarget >= 0)) ||
      (state.additionalTargetsToAdd.length > 0 &&
        !state.additionalTargetsToAdd.every(t => t.percentageTarget >= 0))
    ) {
      list.push(`Please provide a percentage target for each underwriter.`);
    }
    return list;
  }

  function getAdditionalOverTenMErrorList() {
    let list = [];
    if (state.additionalOverTenMTotalTargets !== 100) {
      list.push(
        `Please make sure the combined target equals 100% the total is currently ${state.additionalOverTenMTotalTargets}% .`
      );
    }
    if (
      (state.additionalOverTenMTargetsToEdit.length > 0 &&
        !state.additionalOverTenMTargetsToEdit.every(
          t => t.percentageTarget >= 0
        )) ||
      (state.additionalOverTenMTargetsToAdd.length > 0 &&
        !state.additionalOverTenMTargetsToAdd.every(
          t => t.percentageTarget >= 0
        ))
    ) {
      list.push(`Please provide a percentage target for each underwriter.`);
    }
    return list;
  }

  function getAdditionalOverFifteenMErrorList() {
    let list = [];
    if (state.additionalOverFifteenMTotalTargets !== 100) {
      list.push(
        `Please make sure the combined target equals 100% the total is currently ${state.additionalOverFifteenMTotalTargets}% .`
      );
    }
    if (
      (state.additionalOverFifteenMTargetsToEdit.length > 0 &&
        !state.additionalOverFifteenMTargetsToEdit.every(
          t => t.percentageTarget >= 0
        )) ||
      (state.additionalOverFifteenMTargetsToAdd.length > 0 &&
        !state.additionalOverFifteenMTargetsToAdd.every(
          t => t.percentageTarget >= 0
        ))
    ) {
      list.push(`Please provide a percentage target for each underwriter.`);
    }
    return list;
  }

  function getAdditionalOverTwentyFiveMErrorList() {
    let list = [];
    if (state.additionalOverTwentyFiveMTotalTargets !== 100) {
      list.push(
        `Please make sure the combined target equals 100% the total is currently ${state.additionalOverTwentyFiveMTotalTargets}% .`
      );
    }
    if (
      (state.additionalOverTwentyFiveMTargetsToEdit.length > 0 &&
        !state.additionalOverTwentyFiveMTargetsToEdit.every(
          t => t.percentageTarget >= 0
        )) ||
      (state.additionalOverTwentyFiveMTargetsToAdd.length > 0 &&
        !state.additionalOverTwentyFiveMTargetsToAdd.every(
          t => t.percentageTarget >= 0
        ))
    ) {
      list.push(`Please provide a percentage target for each underwriter.`);
    }
    return list;
  }

  useEffect(() => {
    setState(state => ({ ...state, errorList: getErrorList() }));
  }, [state.totalTargets, state.targetsToAdd, state.targetsToEdit]);

  useEffect(() => {
    setState(state => ({
      ...state,
      additionalErrorList: getAdditionalErrorList(),
      additionalOverTenMErrorList: getAdditionalOverTenMErrorList(),
      additionalOverFifteenMErrorList: getAdditionalOverFifteenMErrorList(),
      additionalOverTwentyFiveMErrorList: getAdditionalOverTwentyFiveMErrorList()
    }));
  }, [
    state.additionalTotalTargets,
    state.additionalTargetsToAdd,
    state.additionalTargetsToEdit,
    state.additionalOverTenMTotalTargets,
    state.additionalOverTenMTargetsToAdd,
    state.additionalOverTenMTargetsToEdit,
    state.additionalOverFifteenMTotalTargets,
    state.additionalOverFifteenMTargetsToAdd,
    state.additionalOverFifteenMTargetsToEdit,
    state.additionalOverTwentyFiveMTotalTargets,
    state.additionalOverTwentyFiveMTargetsToAdd,
    state.additionalOverTwentyFiveMTargetsToEdit
  ]);

  console.log(state.errorList.length);

  return (
    <div
      className={css({
        width: match.params.id !== "44" ? 605 : 1000,
        paddingBottom: 34
      })}
    >
      <React.Fragment>
        <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
          Setup/Edit Remittance Targets -{" "}
          {states.length && states.find(s => s.id == match.params.id).name}
        </Header>
        {match.params.id === "44" ? (
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                {(state.targetsToEdit.length > 0 ||
                  state.targetsToAdd.length > 0) && (
                  <Form>
                    <Divider horizontal>
                      <Header as="h4">0-5 M</Header>
                    </Divider>
                    <Grid>
                      {state.targetsToEdit.map(t => (
                        <React.Fragment key={t.underwriterId}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      targetsToEdit: [
                                        ...state.targetsToEdit.map(tte =>
                                          tte.underwriterId === t.underwriterId
                                            ? {
                                                ...tte,
                                                percentageTarget: parseInt(
                                                  value
                                                )
                                              }
                                            : tte
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      targetsToEdit: [
                                        ...state.targetsToEdit.map(tte =>
                                          tte.underwriterId === t.underwriterId
                                            ? { ...tte, note: value }
                                            : tte
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                      {state.targetsToAdd.map(t => (
                        <React.Fragment key={t.underwriterId + "toAdd"}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      targetsToAdd: [
                                        ...state.targetsToAdd.map(tta =>
                                          tta.underwriterId === t.underwriterId
                                            ? {
                                                ...tta,
                                                percentageTarget: parseInt(
                                                  value
                                                )
                                              }
                                            : tta
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      targetsToAdd: [
                                        ...state.targetsToAdd.map(tta =>
                                          tta.underwriterId === t.underwriterId
                                            ? { ...tta, note: value }
                                            : tta
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Form>
                )}
              </Grid.Column>
              <Grid.Column>
                {(state.additionalTargetsToEdit.length > 0 ||
                  state.additionalTargetsToAdd.length > 0) && (
                  <Form>
                    <Divider horizontal>
                      <Header as="h4">5-10 M</Header>
                    </Divider>
                    <Grid>
                      {state.additionalTargetsToEdit.map(t => (
                        <React.Fragment key={t.underwriterId}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalTargetsToEdit: [
                                        ...state.additionalTargetsToEdit.map(
                                          tte =>
                                            tte.underwriterId ===
                                            t.underwriterId
                                              ? {
                                                  ...tte,
                                                  percentageTarget: parseInt(
                                                    value
                                                  )
                                                }
                                              : tte
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalTargetsToEdit: [
                                        ...state.additionalTargetsToEdit.map(
                                          tte =>
                                            tte.underwriterId ===
                                            t.underwriterId
                                              ? { ...tte, note: value }
                                              : tte
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                      {state.additionalTargetsToAdd.map(t => (
                        <React.Fragment key={t.underwriterId + "toAdd"}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalTargetsToAdd: [
                                        ...state.additionalTargetsToAdd.map(
                                          tta =>
                                            tta.underwriterId ===
                                            t.underwriterId
                                              ? {
                                                  ...tta,
                                                  percentageTarget: parseInt(
                                                    value
                                                  )
                                                }
                                              : tta
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalTargetsToAdd: [
                                        ...state.additionalTargetsToAdd.map(
                                          tta =>
                                            tta.underwriterId ===
                                            t.underwriterId
                                              ? { ...tta, note: value }
                                              : tta
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Form>
                )}
              </Grid.Column>
              <Grid.Column className={css({ paddingTop: 32 })}>
                {(state.additionalOverTenMTargetsToEdit.length > 0 ||
                  state.additionalOverTenMTargetsToAdd.length > 0) && (
                  <Form>
                    <Divider horizontal>
                      <Header as="h4">10-15 M</Header>
                    </Divider>
                    <Grid>
                      {state.additionalOverTenMTargetsToEdit.map(t => (
                        <React.Fragment key={t.underwriterId}>
                          <Grid.Row columns={2}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverTenMTargetsToEdit: [
                                        ...state.additionalOverTenMTargetsToEdit.map(
                                          tte =>
                                            tte.underwriterId ===
                                            t.underwriterId
                                              ? {
                                                  ...tte,
                                                  percentageTarget: parseInt(
                                                    value
                                                  )
                                                }
                                              : tte
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverTenMTargetsToEdit: [
                                        ...state.additionalOverTenMTargetsToEdit.map(
                                          tte =>
                                            tte.underwriterId ===
                                            t.underwriterId
                                              ? { ...tte, note: value }
                                              : tte
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                      {state.additionalOverTenMTargetsToAdd.map(t => (
                        <React.Fragment key={t.underwriterId + "toAdd"}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverTenMTargetsToAdd: [
                                        ...state.additionalOverTenMTargetsToAdd.map(
                                          tta =>
                                            tta.underwriterId ===
                                            t.underwriterId
                                              ? {
                                                  ...tta,
                                                  percentageTarget: parseInt(
                                                    value
                                                  )
                                                }
                                              : tta
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverTenMTargetsToAdd: [
                                        ...state.additionalOverTenMTargetsToAdd.map(
                                          tta =>
                                            tta.underwriterId ===
                                            t.underwriterId
                                              ? { ...tta, note: value }
                                              : tta
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Form>
                )}
              </Grid.Column>
              <Grid.Column className={css({ paddingTop: 32 })}>
                {(state.additionalOverFifteenMTargetsToEdit.length > 0 ||
                  state.additionalOverFifteenMTargetsToAdd.length > 0) && (
                  <Form>
                    <Divider horizontal>
                      <Header as="h4">15-25 M</Header>
                    </Divider>
                    <Grid>
                      {state.additionalOverFifteenMTargetsToEdit.map(t => (
                        <React.Fragment key={t.underwriterId}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverFifteenMTargetsToEdit: [
                                        ...state.additionalOverFifteenMTargetsToEdit.map(
                                          tte =>
                                            tte.underwriterId ===
                                            t.underwriterId
                                              ? {
                                                  ...tte,
                                                  percentageTarget: parseInt(
                                                    value
                                                  )
                                                }
                                              : tte
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverFifteenMTargetsToEdit: [
                                        ...state.additionalOverFifteenMTargetsToEdit.map(
                                          tte =>
                                            tte.underwriterId ===
                                            t.underwriterId
                                              ? { ...tte, note: value }
                                              : tte
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                      {state.additionalOverFifteenMTargetsToAdd.map(t => (
                        <React.Fragment key={t.underwriterId + "toAdd"}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverFifteenMTargetsToAdd: [
                                        ...state.additionalOverFifteenMTargetsToAdd.map(
                                          tta =>
                                            tta.underwriterId ===
                                            t.underwriterId
                                              ? {
                                                  ...tta,
                                                  percentageTarget: parseInt(
                                                    value
                                                  )
                                                }
                                              : tta
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverFifteenMTargetsToAdd: [
                                        ...state.additionalOverFifteenMTargetsToAdd.map(
                                          tta =>
                                            tta.underwriterId ===
                                            t.underwriterId
                                              ? { ...tta, note: value }
                                              : tta
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                    </Grid>
                  </Form>
                )}
              </Grid.Column>
              <Grid.Column className={css({ paddingTop: 32 })}>
                {(state.additionalOverTwentyFiveMTargetsToEdit.length > 0 ||
                  state.additionalOverTwentyFiveMTargetsToAdd.length > 0) && (
                  <Form>
                    <Divider horizontal>
                      <Header as="h4">25+ M</Header>
                    </Divider>
                    <Grid>
                      {state.additionalOverTwentyFiveMTargetsToEdit.map(t => (
                        <React.Fragment key={t.underwriterId}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverTwentyFiveMTargetsToEdit: [
                                        ...state.additionalOverTwentyFiveMTargetsToEdit.map(
                                          tte =>
                                            tte.underwriterId ===
                                            t.underwriterId
                                              ? {
                                                  ...tte,
                                                  percentageTarget: parseInt(
                                                    value
                                                  )
                                                }
                                              : tte
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverTwentyFiveMTargetsToEdit: [
                                        ...state.additionalOverTwentyFiveMTargetsToEdit.map(
                                          tte =>
                                            tte.underwriterId ===
                                            t.underwriterId
                                              ? { ...tte, note: value }
                                              : tte
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                      {state.additionalOverTwentyFiveMTargetsToAdd.map(t => (
                        <React.Fragment key={t.underwriterId + "toAdd"}>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field required>
                                <div className={sectionHeaderStyles}>
                                  {t.underwriter.name}
                                </div>
                                <label>Percentage Target</label>
                                <Input
                                  type="number"
                                  placeholder="Target"
                                  value={t.percentageTarget}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverTwentyFiveMTargetsToAdd: [
                                        ...state.additionalOverTwentyFiveMTargetsToAdd.map(
                                          tta =>
                                            tta.underwriterId ===
                                            t.underwriterId
                                              ? {
                                                  ...tta,
                                                  percentageTarget: parseInt(
                                                    value
                                                  )
                                                }
                                              : tta
                                        )
                                      ]
                                    }))
                                  }
                                  error={
                                    state.submitAttempted &&
                                    (t.percentageTarget === "" ||
                                      t.percentageTarget === null)
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row columns={1}>
                            <Grid.Column>
                              <Form.Field>
                                <label>Note</label>
                                <TextArea
                                  placeholder="Note"
                                  style={{ minHeight: 116 }}
                                  value={t.note}
                                  onChange={(_, { value }) =>
                                    setState(state => ({
                                      ...state,
                                      additionalOverTwentyFiveMTargetsToAdd: [
                                        ...state.additionalOverTwentyFiveMTargetsToAdd.map(
                                          tta =>
                                            tta.underwriterId ===
                                            t.underwriterId
                                              ? { ...tta, note: value }
                                              : tta
                                        )
                                      ]
                                    }))
                                  }
                                />
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </React.Fragment>
                      ))}
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Message
                            visible={
                              !state.submitting &&
                              state.submitAttempted &&
                              state.errorList.length > 0
                            }
                            error
                            header={
                              match.params.id === "44"
                                ? "0-5 M"
                                : "There was some errors with your submission"
                            }
                            list={state.errorList}
                            data-automation="message"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Message
                            visible={
                              !state.submitting &&
                              state.submitAttempted &&
                              state.additionalErrorList.length > 0
                            }
                            error
                            header={
                              match.params.id === "44"
                                ? "5-10 M"
                                : "There was some errors with your submission"
                            }
                            list={state.additionalErrorList}
                            data-automation="message"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Message
                            visible={
                              !state.submitting &&
                              state.submitAttempted &&
                              state.additionalOverTenMErrorList.length > 0
                            }
                            error
                            header={
                              match.params.id === "44"
                                ? "10-15 M"
                                : "There was some errors with your submission"
                            }
                            list={state.additionalOverTenMErrorList}
                            data-automation="message"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Message
                            visible={
                              !state.submitting &&
                              state.submitAttempted &&
                              state.additionalOverFifteenMErrorList.length > 0
                            }
                            error
                            header={
                              match.params.id === "44"
                                ? "15-25 M"
                                : "There was some errors with your submission"
                            }
                            list={state.additionalOverFifteenMErrorList}
                            data-automation="message"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Message
                            visible={
                              !state.submitting &&
                              state.submitAttempted &&
                              state.additionalOverTwentyFiveMErrorList.length >
                                0
                            }
                            error
                            header={
                              match.params.id === "44"
                                ? "25+ M"
                                : "There was some errors with your submission"
                            }
                            list={state.additionalOverTwentyFiveMErrorList}
                            data-automation="message"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column
                          floated="right"
                          className={css({ textAlign: "right" })}
                        >
                          <Button
                            size="large"
                            className="cancelButton"
                            onClick={() => history.goBack()}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="large"
                            className="saveButton"
                            onClick={() => saveChanges()}
                          >
                            {state.submitting ? "Submitting..." : "Save"}
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Form>
            <Grid>
              {state.targetsToEdit.map(t => (
                <React.Fragment key={t.underwriterId}>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Form.Field required>
                        <div className={sectionHeaderStyles}>
                          {t.underwriter.name}
                        </div>
                        <label>Percentage Target</label>
                        <Input
                          type="number"
                          placeholder="Target"
                          value={t.percentageTarget}
                          onChange={(_, { value }) =>
                            setState(state => ({
                              ...state,
                              targetsToEdit: [
                                ...state.targetsToEdit.map(tte =>
                                  tte.underwriterId === t.underwriterId
                                    ? {
                                        ...tte,
                                        percentageTarget: parseInt(value)
                                      }
                                    : tte
                                )
                              ]
                            }))
                          }
                          error={
                            state.submitAttempted &&
                            (t.percentageTarget === "" ||
                              t.percentageTarget === null)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Note</label>
                        <TextArea
                          placeholder="Note"
                          style={{ minHeight: 116 }}
                          value={t.note}
                          onChange={(_, { value }) =>
                            setState(state => ({
                              ...state,
                              targetsToEdit: [
                                ...state.targetsToEdit.map(tte =>
                                  tte.underwriterId === t.underwriterId
                                    ? { ...tte, note: value }
                                    : tte
                                )
                              ]
                            }))
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </React.Fragment>
              ))}
              {state.targetsToAdd.map(t => (
                <React.Fragment key={t.underwriterId + "toAdd"}>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Form.Field required>
                        <div className={sectionHeaderStyles}>
                          {t.underwriter.name}
                        </div>
                        <label>Percentage Target</label>
                        <Input
                          type="number"
                          placeholder="Target"
                          value={t.percentageTarget}
                          onChange={(_, { value }) =>
                            setState(state => ({
                              ...state,
                              targetsToAdd: [
                                ...state.targetsToAdd.map(tta =>
                                  tta.underwriterId === t.underwriterId
                                    ? {
                                        ...tta,
                                        percentageTarget: parseInt(value)
                                      }
                                    : tta
                                )
                              ]
                            }))
                          }
                          error={
                            state.submitAttempted &&
                            (t.percentageTarget === "" ||
                              t.percentageTarget === null)
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Form.Field>
                        <label>Note</label>
                        <TextArea
                          placeholder="Note"
                          style={{ minHeight: 116 }}
                          value={t.note}
                          onChange={(_, { value }) =>
                            setState(state => ({
                              ...state,
                              targetsToAdd: [
                                ...state.targetsToAdd.map(tta =>
                                  tta.underwriterId === t.underwriterId
                                    ? { ...tta, note: value }
                                    : tta
                                )
                              ]
                            }))
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </React.Fragment>
              ))}
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Message
                    visible={
                      !state.submitting &&
                      state.submitAttempted &&
                      state.additionalErrorList.length > 0
                    }
                    error
                    header="There was some errors with your submission"
                    list={state.additionalErrorList}
                    data-automation="message"
                  />
                </Grid.Column>
              </Grid.Row>
              {(state.targetsToEdit.length > 0 ||
                state.targetsToAdd.length > 0) && (
                <Grid.Row columns={1}>
                  <Grid.Column
                    floated="right"
                    className={css({ textAlign: "right" })}
                  >
                    <Button
                      size="large"
                      className="cancelButton"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="large"
                      className="saveButton"
                      onClick={() => saveChanges()}
                    >
                      {state.submitting ? "Submitting..." : "Save"}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
          </Form>
        )}
      </React.Fragment>
      {!state.loading &&
        !state.targetsToEdit.length > 0 &&
        !state.targetsToAdd.length > 0 && (
          <Message size="big">
            Please add Underwriters to the State of{" "}
            {states.length > 20 &&
              states.find(s => s.id == match.params.id).name}{" "}
            first.
            <br />
            <Button
              style={{ marginTop: 24 }}
              onClick={() => history.push("/admin/underwriters")}
            >
              Underwriters
            </Button>
          </Message>
        )}
    </div>
  );
}

withRouter(EditAddRemittanceTargets);
