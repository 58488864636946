import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { css } from "emotion";
import { getRequest, postRequest } from "../../helpers/ApiHelpers.js";
import { StateContext } from "../State";
import { SearchBar } from "./SearchBar";
import Results from "./Results";
import { SearchWordsContext } from "./SearchWords";
import { Message } from "semantic-ui-react";
import ls from "local-storage";
import { logEvent } from "../../helpers/analyticsLogger";

const defaultResults = {
  contacts: { value: [] },
  companies: { value: [] },
  calculators: { value: [] },
  withholdingTax: { value: [] },
  withholdingTaxFiles: { value: [] },
  mortgageTaxes: { value: [] },
  mortgageTaxFiles: { value: [] },
  withholdingTaxes: { value: [] },
  transferTaxes: { value: [] },
  transferTaxFiles: { value: [] },
  liens: { value: [] },
  waterSewerFiles: { value: [] },
  personalPropertyTaxesFiles: { value: [] },
  realEstatePropertyTaxesFiles: { value: [] },
  mechanicsLienConstructionFiles: { value: [] },
  additionalLiens: { value: [] },
  additionalLienFiles: { value: [] },
  loanPolicies: { value: [] },
  ownersPolicies: { value: [] },
  recordings: { value: [] },
  stateIdiosyncrasies: { value: [] },
  modifications: { value: [] },
  invoiceCharges: { value: [] },
  manuals: { value: [] },
  forms: { value: [] },
  quoteTemplates: { value: [] },
  scheduleBICodes: { value: [] },
  scheduleBIICodes: { value: [] },
  scheduleBINotes: { value: [] },
  scheduleBIINotes: { value: [] },
  policyGeneralInfoNotes: { value: [] },
  policyHelpfulWordings: { value: [] },
  policyEndorsements: { value: [] },
  policySurveyNotes: { value: [] },
  surveyLegalDescriptionNotes: { value: [] },
  surveyExceptionCodes: { value: [] },
  scheduleANotes: { value: [] },
  commitmentGeneralInfoNotes: { value: [] },
  highLiabilityApprovals: { value: [] }
};

function Search({ history, match: { params } }) {
  const { selectedState, noState } = useContext(StateContext);
  const { updateSearchWords } = useContext(SearchWordsContext);
  const [state, setState] = useState({
    initialLoad: true,
    searchResults: defaultResults,
    resultData: {},
    contactIds: [],
    companyIds: [],
    formIds: [],
    invoiceChargeIds: [],
    searchWord: "",
    wordsTyped: "",
    touched: params.word && params.word !== "",
    loading: false,
    error: false,
    containsResults: undefined,
    filter: setFilter()
  });

  function setFilter() {
    var previousFilter = ls.get("filter");
    if (params.filter) {
      return params.filter;
    } else if (previousFilter) {
      return previousFilter;
    } else {
      ls.set("filter", "Contacts");
      return "Contacts";
    }
  }

  if (!params.state) {
    history.replace(`search/states/${selectedState || "no-state"}/filter/${state.filter}`);
  }

  async function getContactIdsByState() {
    const result = await getRequest("/contact/getContactIdsByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({ ...state, contactIds: result.data }));
    }
  }

  async function getCompanyIdsByState() {
    const result = await getRequest("/company/getCompanyIdsByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({ ...state, companyIds: result.data }));
    }
  }

  async function getFormIdsByState() {
    const result = await getRequest("/form/getFormIdsByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({ ...state, formIds: result.data }));
    }
  }

  async function getInvoiceChargeIdsByState() {
    const result = await getRequest("/invoiceCharge/getInvoiceChargeIdsByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({ ...state, invoiceChargeIds: result.data }));
    }
  }

  async function setContacts() {
    if (state.filter === "Contacts" || state.filter === "All") {
      let idsAndScore;
      if (noState) {
        idsAndScore = state.searchResults.contacts.value.map(v => ({
          id: +v.document.id,
          score: v["@search.score"]
        }));
      } else {
        idsAndScore = state.searchResults.contacts.value
          .filter(v => state.contactIds.includes(+v.document.id))
          .map(v => ({
            id: +v.document.id,
            score: v["@search.score"]
          }));
      }
      const contactResults = await getRequest("/contact/getContactsByIds", {
        ids: idsAndScore.map(is => is.id)
      });
      if (contactResults.success) {
        return contactResults.data.map(c => ({
          score: idsAndScore.find(is => is.id === c.id).score,
          ...c
        }));
      }
    }
  }

  async function setData() {
    const contacts = state.filter === "Contacts" || state.filter === "All" ? await setContacts() : [];
    const companies = state.filter === "Contacts" || state.filter === "All" ? await setCompanies() : [];
    const invoiceCharges =
      state.filter === "Rates" || state.filter === "All" || state.filter === "Commitment"
        ? await setInvoiceCharges()
        : [];
    const withholdingTaxes = state.filter === "Rates" || state.filter === "All" ? await setWithholdingTax() : [];
    const mortgageTaxes = state.filter === "Rates" || state.filter === "All" ? await setMortgageTax() : [];
    const transferTaxes = state.filter === "Rates" || state.filter === "All" ? await setTransferTax() : [];
    const endorsements = state.filter === "Rates" || state.filter === "All" ? await setEndorsements() : [];
    const manuals = state.filter === "Rates" || state.filter === "All" ? setManuals() : [];
    const forms = state.filter === "Forms" || state.filter === "All" ? await setForms() : [];
    const quoteTemplates = state.filter === "Rates" || state.filter === "All" ? setQuoteTemplates() : [];
    const liens = state.filter === "Info" || state.filter === "All" ? await setLiens() : [];
    const additionalLiens = state.filter === "Info" || state.filter === "All" ? await setAdditionalLiens() : [];
    const calculators = state.filter === "Rates" || state.filter === "All" ? setPremiumCalculators() : [];
    const recordings = state.filter === "Info" || state.filter === "All" ? await setRecordings() : [];
    const stateIdiosyncrasies = state.filter === "Info" || state.filter === "All" ? await setStateIdiosyncrasies() : [];
    const modifications = state.filter === "Info" || state.filter === "All" ? await setModifications() : [];
    const highLiabilityApprovals =
      state.filter === "Info" || state.filter === "All" ? await setHighLiabilityApprovals() : [];
    const scheduleBICodes = state.filter === "Commitment" || state.filter === "All" ? setScheduleBICodes() : [];
    const commitmentScheduleBIICodes =
      state.filter === "Commitment" || state.filter === "All" ? setCommitmentScheduleBIICodes() : [];
    const policyScheduleBICodes = state.filter === "Policy" || state.filter === "All" ? setPolicyScheduleBICodes() : [];
    const scheduleBINotes = state.filter === "Commitment" || state.filter === "All" ? setScheduleBINotes() : [];
    const scheduleBIINotes = state.filter === "Commitment" || state.filter === "All" ? setScheduleBIINotes() : [];
    const policyGeneralInfoNotes =
      state.filter === "Policy" || state.filter === "All" ? setPolicyGeneralInfoNotes() : [];
    const policySurveyNotes = state.filter === "Surveys" || state.filter === "All" ? setPolicySurveyNotes() : [];
    const surveyLegalDescriptionNotes =
      state.filter === "Surveys" || state.filter === "All" ? setSurveyLegalDescriptionNotes() : [];
    const surveyExceptionCodes = state.filter === "Surveys" || state.filter === "All" ? setSurveyExceptionCodes() : [];
    const policyHelpfulWordings = state.filter === "Policy" || state.filter === "All" ? setPolicyHelpfulWordings() : [];
    const policyEndorsements = state.filter === "Policy" || state.filter === "All" ? setPolicyEndorsements() : [];
    const scheduleANotes = state.filter === "Commitment" || state.filter === "All" ? setScheduleANotes() : [];
    const commitmentGeneralInfoNotes =
      state.filter === "Commitment" || state.filter === "All" ? setCommitmentGeneralInfoNotes() : [];
    setState(state => ({
      ...state,
      resultData: {
        contacts,
        companies,
        invoiceCharges,
        withholdingTaxes,
        mortgageTaxes,
        transferTaxes,
        endorsements,
        manuals,
        forms,
        quoteTemplates,
        liens,
        additionalLiens,
        calculators,
        recordings,
        stateIdiosyncrasies,
        modifications,
        highLiabilityApprovals,
        scheduleANotes,
        scheduleBICodes,
        commitmentScheduleBIICodes,
        policyScheduleBICodes,
        scheduleBINotes,
        scheduleBIINotes,
        commitmentGeneralInfoNotes,
        policyGeneralInfoNotes,
        policyHelpfulWordings,
        policyEndorsements,
        policySurveyNotes,
        surveyLegalDescriptionNotes,
        surveyExceptionCodes
      }
    }));
  }

  useEffect(() => {
    setData();
  }, [state.searchResults]);

  async function setCompanies() {
    if (state.filter === "Contacts" || state.filter === "All") {
      let idsAndScore;
      if (noState) {
        idsAndScore = state.searchResults.companies.value.map(v => ({
          id: +v.document.id,
          score: v["@search.score"]
        }));
      } else {
        idsAndScore = state.searchResults.companies.value
          .filter(v => state.companyIds.includes(+v.document.id))
          .map(v => ({
            id: +v.document.id,
            score: v["@search.score"]
          }));
      }
      const companyResults = await getRequest("/company/getCompaniesByIds", {
        ids: idsAndScore.map(is => is.id)
      });
      if (companyResults.success) {
        return companyResults.data.map(c => ({
          score: idsAndScore.find(is => is.id === c.id).score,
          ...c
        }));
      }
    }
  }

  async function setWithholdingTax() {
    if (state.searchResults.withholdingTax) {
      return (
        await getRequest("/tax/getWithholdingTaxByIds", {
          ids: [
            ...state.searchResults.withholdingTax.value.map(v => +v.document.id),
            ...state.searchResults.withholdingTaxFiles.value.map(v => +v.document.withholdingTaxId)
          ],
          stateId: selectedState,
          noState: noState
        })
      ).data;
    }
  }

  async function setMortgageTax() {
    if (state.searchResults.mortgageTaxes) {
      return (
        await getRequest("/tax/getMortgageTaxByIds", {
          ids: [
            ...state.searchResults.mortgageTaxes.value.map(v => +v.document.id),
            ...state.searchResults.mortgageTaxFiles.value.map(v => +v.document.mortgageTaxId)
          ],
          stateId: selectedState,
          noState: noState
        })
      ).data;
    }
  }

  async function setTransferTax() {
    if (state.searchResults.transferTaxes) {
      return (
        await getRequest("/tax/getTransferTaxByIds", {
          ids: [
            ...state.searchResults.transferTaxes.value.map(v => +v.document.id),
            ...state.searchResults.transferTaxFiles.value.map(v => +v.document.transferTaxId)
          ],
          stateId: selectedState,
          noState: noState
        })
      ).data;
    }
  }

  async function setEndorsements() {
    const opResults = state.searchResults.ownersPolicies
      ? state.searchResults.ownersPolicies.value.filter(v =>
          noState ? v["@search.score"] > 0.5 : v.document.stateId === selectedState && v["@search.score"] > 0.5
        )
      : [];

    const lpResults = state.searchResults.loanPolicies
      ? state.searchResults.loanPolicies.value.filter(v =>
          noState ? v["@search.score"] > 0.5 : v.document.stateId === selectedState && v["@search.score"] > 0.5
        )
      : [];

    const states = [...new Set([...opResults.map(r => r.document.stateId), ...lpResults.map(r => r.document.stateId)])];
    const endorsementsByStates = states.map(s => ({
      stateId: s,
      oPEndorsementIds: [...opResults.filter(r => r.document.stateId === s)].map(e => +e.document.id),
      lPEndorsementIds: [...lpResults.filter(r => r.document.stateId === s)].map(e => +e.document.id)
    }));
    return (await postRequest("/endorsement/getEndorsementsByStates", endorsementsByStates)).data;
  }

  function setPremiumCalculators() {
    if (state.searchResults.calculators) {
      const uniqueNames = [...new Set(state.searchResults.calculators.value.map(c => c.document.name))];
      const filteredCalculators = [];
      uniqueNames.forEach(n =>
        state.filteredCalculators.push(
          state.searchResults.calculators.value
            .filter(c => c.document.name === n)
            .sort(c => c.document.id)
            .pop()
        )
      );
      return filteredCalculators.filter(v => v["@search.score"] > 0.1).map(c => c.document);
    }
  }

  async function setInvoiceCharges() {
    if (state.searchResults.invoiceCharges) {
      const idsAndScore = state.searchResults.invoiceCharges.value.map(v => ({
        id: +v.document.id,
        score: v["@search.score"]
      }));
      const invoiceChargeResults = await getRequest("/invoiceCharge/getInvoiceChargesByIds", {
        ids: noState
          ? state.searchResults.invoiceCharges.value.map(v => +v.document.id)
          : state.searchResults.invoiceCharges.value
              .map(v => +v.document.id)
              .filter(i => state.invoiceChargeIds.some(ici => ici === i))
      });
      if (invoiceChargeResults.success) {
        return invoiceChargeResults.data.map(c => ({
          score: idsAndScore.find(is => is.id === c.id).score,
          ...c
        }));
      }
    }
  }

  function setManuals() {
    if (state.searchResults.manuals) {
      const results = state.searchResults.manuals.value
        .filter(v =>
          noState ? v["@search.score"] > 0.1 : v.document.stateId === selectedState && v["@search.score"] > 0.1
        )
        .map(v => v.document);
      return [...new Set(results.map(m => m.stateId))]
        .sort((a, b) => {
          return a - b;
        })
        .map(s => results.filter(m => m.stateId === s));
    }
  }

  async function setForms() {
    if (state.searchResults.forms) {
      const idsAndScore = state.searchResults.forms.value.map(v => ({
        id: +v.document.id,
        score: v["@search.score"]
      }));
      const formResults = await getRequest("/form/getFormsByIds", {
        ids: noState
          ? state.searchResults.forms.value.map(v => +v.document.id)
          : state.searchResults.forms.value.map(v => +v.document.id).filter(i => state.formIds.some(fi => fi === i))
      });
      if (formResults.success) {
        return formResults.data.map(c => ({
          score: idsAndScore.find(is => is.id === c.id).score,
          ...c
        }));
      }
    }
  }

  function setQuoteTemplates() {
    if (state.searchResults.quoteTemplates) {
      const results = state.searchResults.quoteTemplates.value
        .filter(v =>
          noState ? v["@search.score"] > 0.1 : v.document.stateId === selectedState && v["@search.score"] > 0.1
        )
        .map(v => v.document);

      return [...new Set(results.map(q => q.stateId))]
        .sort((a, b) => {
          return a - b;
        })
        .map(s => results.filter(q => q.stateId === s));
    }
  }

  async function setLiens() {
    if (state.searchResults.liens) {
      const results = await getRequest("/lien/getLiensByIds", {
        ids: noState
          ? [
              ...state.searchResults.liens.value.map(v => +v.document.id),
              ...state.searchResults.waterSewerFiles.value.map(v => +v.document.lienId),
              ...state.searchResults.personalPropertyTaxesFiles.value.map(v => +v.document.lienId),
              ...state.searchResults.realEstatePropertyTaxesFiles.value.map(v => +v.document.lienId),
              ...state.searchResults.mechanicsLienConstructionFiles.value.map(v => +v.document.lienId)
            ]
          : [
              ...state.searchResults.liens.value
                .filter(v => v.document.stateId === selectedState)
                .map(v => +v.document.id),
              ...state.searchResults.waterSewerFiles.value.map(v => +v.document.lienId),
              ...state.searchResults.personalPropertyTaxesFiles.value.map(v => +v.document.lienId),
              ...state.searchResults.realEstatePropertyTaxesFiles.value.map(v => +v.document.lienId),
              ...state.searchResults.mechanicsLienConstructionFiles.value.map(v => +v.document.lienId)
            ],
        stateId: selectedState,
        noState: noState
      });
      if (results.success) {
        return results.data;
      }
    }
  }

  async function setAdditionalLiens() {
    if (state.searchResults.additionalLiens) {
      const result = await getRequest("/additionalLien/getAdditionalLiensByIds", {
        ids: noState
          ? [
              ...state.searchResults.additionalLiens.value.map(v => +v.document.id),
              ...state.searchResults.additionalLienFiles.value.map(v => +v.document.additionalLienId)
            ]
          : [
              ...state.searchResults.additionalLiens.value
                .filter(v => v.document.stateId === selectedState)
                .map(v => +v.document.id),
              ...state.searchResults.additionalLienFiles.value.map(v => +v.document.additionalLienId)
            ],
        stateId: selectedState,
        noState: noState
      });
      if (result.success) {
        if (noState) {
          const states = [...new Set([...result.data.map(r => r.stateId)])];
          return states.map(s => ({
            stateId: s,
            additionalLiens: result.data.filter(r => r.stateId === s)
          }));
        } else {
          return result.data;
        }
      }
    }
  }

  async function setRecordings() {
    if (state.searchResults.recordings) {
      let idsAndScore;
      if (noState) {
        idsAndScore = state.searchResults.recordings.value.map(v => ({
          id: +v.document.id,
          score: v["@search.score"]
        }));
      } else {
        idsAndScore = state.searchResults.recordings.value
          .filter(r => +r.document.stateId === selectedState)
          .map(v => ({
            id: +v.document.id,
            score: v["@search.score"]
          }));
      }
      let recordingResults = await getRequest("/recording/getRecordingsByIds", {
        ids: idsAndScore.map(is => is.id)
      });
      if (recordingResults.success) {
        if (noState) {
          const states = [...new Set([...recordingResults.data.map(r => r.stateId)])];
          return states.map(s => ({
            stateId: s,
            recordings: recordingResults.data
              .filter(r => r.stateId === s)
              .map(r => ({
                score: idsAndScore.find(is => is.id === r.id).score,
                ...r
              }))
          }));
        } else {
          return recordingResults.data.map(r => ({
            score: idsAndScore.find(is => is.id === r.id).score,
            ...r
          }));
        }
      }
    }
  }

  async function setStateIdiosyncrasies() {
    if (state.searchResults.stateIdiosyncrasies) {
      let idsAndScore;
      if (noState) {
        idsAndScore = state.searchResults.stateIdiosyncrasies.value.map(v => ({
          id: +v.document.id,
          score: v["@search.score"]
        }));
      } else {
        idsAndScore = state.searchResults.stateIdiosyncrasies.value
          .filter(r => +r.document.stateId === selectedState)
          .map(v => ({
            id: +v.document.id,
            score: v["@search.score"]
          }));
      }
      let stateIdiosyncrasiesResults = await getRequest("/stateIdiosyncrasy/getStateIdiosyncrasyByIds", {
        ids: idsAndScore.map(is => is.id)
      });
      if (stateIdiosyncrasiesResults.success) {
        if (noState) {
          const states = [...new Set([...stateIdiosyncrasiesResults.data.map(r => r.stateId)])];
          return states.map(s => ({
            stateId: s,
            stateIdiosyncrasies: stateIdiosyncrasiesResults.data
              .filter(r => r.stateId === s)
              .map(r => ({
                score: idsAndScore.find(is => is.id === r.id).score,
                ...r
              }))
          }));
        } else {
          return stateIdiosyncrasiesResults.data.map(r => ({
            score: idsAndScore.find(is => is.id === r.id).score,
            ...r
          }));
        }
      }
    }
  }

  async function setModifications() {
    if (state.searchResults.modifications) {
      let idsAndScore;
      if (noState) {
        idsAndScore = state.searchResults.modifications.value.map(v => ({
          id: +v.document.id,
          score: v["@search.score"]
        }));
      } else {
        idsAndScore = state.searchResults.modifications.value
          .filter(r => +r.document.stateId === selectedState)
          .map(v => ({
            id: +v.document.id,
            score: v["@search.score"]
          }));
      }
      let modificationsResults = await getRequest("/modification/getModificationByIds", {
        ids: idsAndScore.map(is => is.id)
      });
      if (modificationsResults.success) {
        if (noState) {
          const states = [...new Set([...modificationsResults.data.map(r => r.stateId)])];
          return states.map(s => ({
            stateId: s,
            modifications: modificationsResults.data
              .filter(r => r.stateId === s)
              .map(r => ({
                score: idsAndScore.find(is => is.id === r.id).score,
                ...r
              }))
          }));
        } else {
          return modificationsResults.data.map(r => ({
            score: idsAndScore.find(is => is.id === r.id).score,
            ...r
          }));
        }
      }
    }
  }

  async function setHighLiabilityApprovals() {
    if (state.searchResults.highLiabilityApprovals) {
      let idsAndScore;
      if (noState) {
        idsAndScore = state.searchResults.highLiabilityApprovals.value.map(v => ({
          id: +v.document.id,
          score: v["@search.score"]
        }));
      } else {
        idsAndScore = state.searchResults.highLiabilityApprovals.value
          .filter(r => +r.document.stateId === selectedState)
          .map(v => ({
            id: +v.document.id,
            score: v["@search.score"]
          }));
      }
      let highLiabilityApprovalResults = await getRequest("/highLiabilityApproval/getHighLiabilityApprovalsByIds", {
        ids: idsAndScore.map(is => is.id)
      });
      if (highLiabilityApprovalResults.success) {
        if (noState) {
          const states = [...new Set([...highLiabilityApprovalResults.data.map(r => r.stateId)])];
          return states.map(s => ({
            stateId: s,
            highLiabilityApprovals: highLiabilityApprovalResults.data
              .filter(r => r.stateId === s)
              .map(r => ({
                score: idsAndScore.find(is => is.id === r.id).score,
                ...r
              }))
          }));
        } else {
          return highLiabilityApprovalResults.data.map(r => ({
            score: idsAndScore.find(is => is.id === r.id).score,
            ...r
          }));
        }
      }
    }
  }

  function setScheduleBICodes() {
    if (state.searchResults.scheduleBICodes) {
      if (noState) {
        var states = [...new Set(state.searchResults.scheduleBICodes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          scheduleBICodes: state.searchResults.scheduleBICodes.value.filter(c => c.document.stateId === s)
        }));
      } else {
        return state.searchResults.scheduleBICodes.value.filter(c => +c.document.stateId === selectedState);
      }
    }
  }

  function setCommitmentScheduleBIICodes() {
    if (state.searchResults.scheduleBIICodes) {
      let codes = state.searchResults.scheduleBIICodes.value.filter(c => c.document.isOtherException !== true);
      if (noState) {
        var states = [...new Set(codes.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          scheduleBIICodes: codes.filter(c => c.document.stateId === s)
        }));
      } else {
        return codes;
      }
    }
  }

  function setPolicyScheduleBICodes() {
    if (state.searchResults.scheduleBIICodes) {
      let codes = state.searchResults.scheduleBIICodes.value;
      if (noState) {
        var states = [...new Set(codes.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          codes: codes.filter(c => c.document.stateId === s)
        }));
      } else {
        return codes;
      }
    }
  }

  function setScheduleBINotes() {
    if (state.searchResults.scheduleBINotes) {
      if (noState) {
        var states = [...new Set(state.searchResults.scheduleBINotes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          scheduleBINotes: state.searchResults.scheduleBINotes.value.filter(c => c.document.stateId === s)
        }));
      } else {
        return state.searchResults.scheduleBINotes.value.map(v => v.document);
      }
    }
  }

  function setScheduleBIINotes() {
    if (state.searchResults.scheduleBIINotes) {
      if (noState) {
        var states = [...new Set(state.searchResults.scheduleBIINotes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          scheduleBIINotes: state.searchResults.scheduleBIINotes.value.filter(c => c.document.stateId === s)
        }));
      } else {
        return state.searchResults.scheduleBIINotes.value.map(v => v.document);
      }
    }
  }

  function setPolicyGeneralInfoNotes() {
    if (state.searchResults.policyGeneralInfoNotes) {
      if (noState) {
        var states = [...new Set(state.searchResults.policyGeneralInfoNotes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          policyGeneralInfoNotes: state.searchResults.policyGeneralInfoNotes.value.filter(c => c.document.stateId === s)
        }));
      } else {
        return state.searchResults.policyGeneralInfoNotes.value.filter(c => +c.document.stateId === selectedState);
      }
    }
  }

  function setPolicySurveyNotes() {
    if (state.searchResults.policySurveyNotes) {
      if (noState) {
        var states = [...new Set(state.searchResults.policySurveyNotes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          policySurveyNotes: state.searchResults.policySurveyNotes.value.filter(c => c.document.stateId === s)
        }));
      } else {
        return state.searchResults.policySurveyNotes.value;
      }
    }
  }

  function setSurveyLegalDescriptionNotes() {
    if (state.searchResults.surveyLegalDescriptionNotes) {
      if (noState) {
        var states = [...new Set(state.searchResults.surveyLegalDescriptionNotes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          surveyLegalDescriptionNotes: state.searchResults.surveyLegalDescriptionNotes.value.filter(
            c => c.document.stateId === s
          )
        }));
      } else {
        return state.searchResults.surveyLegalDescriptionNotes.value;
      }
    }
  }

  function setSurveyExceptionCodes() {
    if (state.searchResults.surveyExceptionCodes) {
      if (noState) {
        var states = [...new Set(state.searchResults.surveyExceptionCodes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          surveyExceptionCodes: state.searchResults.surveyExceptionCodes.value.filter(c => c.document.stateId === s)
        }));
      } else {
        return state.searchResults.surveyExceptionCodes.value;
      }
    }
  }

  function setPolicyHelpfulWordings() {
    if (state.searchResults.policyHelpfulWordings) {
      return state.searchResults.policyHelpfulWordings.value;
    }
  }

  function setPolicyEndorsements() {
    if (state.searchResults.policyEndorsements) {
      if (noState) {
        var states = [...new Set(state.searchResults.policyEndorsements.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          policyEndorsements: state.searchResults.policyEndorsements.value.filter(c => c.document.stateId === s)
        }));
      } else {
        return state.searchResults.policyEndorsements.value;
      }
    }
  }

  function setScheduleANotes() {
    if (state.searchResults.scheduleANotes) {
      if (noState) {
        var states = [...new Set(state.searchResults.scheduleANotes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          scheduleANotes: state.searchResults.scheduleANotes.value.filter(c => c.document.stateId === s)
        }));
      } else {
        return state.searchResults.scheduleANotes.value;
      }
    }
  }

  function setCommitmentGeneralInfoNotes() {
    if (state.searchResults.commitmentGeneralInfoNotes) {
      if (noState) {
        var states = [...new Set(state.searchResults.commitmentGeneralInfoNotes.value.map(c => c.document.stateId))];
        return states.map(s => ({
          stateId: s,
          commitmentGeneralInfoNotes: state.searchResults.commitmentGeneralInfoNotes.value.filter(
            c => c.document.stateId === s
          )
        }));
      } else {
        return state.searchResults.commitmentGeneralInfoNotes.value;
      }
    }
  }

  const search = async () => {
    setState(state => ({
      ...state,
      loading: true,
      error: false,
      searchResults: defaultResults
    }));
    const data = {
      wordsTyped: state.wordsTyped,
      filter: state.filter,
      state: params.state !== "no_state" ? params.state : null
    };
    const result = await getRequest("/azureSearch/search", data, false);
    if (result.success) {
      logEvent(null, "Searched", data.wordsTyped);
      setState(state => ({
        ...state,
        searchResults: result.data,
        loading: false
      }));
    }
  };

  useEffect(() => {
    if (selectedState) {
      getContactIdsByState();
      getCompanyIdsByState();
      getFormIdsByState();
      getInvoiceChargeIdsByState();
    }
  }, [selectedState]);

  useEffect(() => {
    if (params.word && params.word !== "") {
      const words = params.word.replace(/%20/g, " ");
      setState(state => ({
        ...state,
        wordsTyped: words,
        searchWord: words,
        touched: true
      }));
    }
  }, [params.word, state.contactIds, state.companyIds]);

  useEffect(() => {
    if (state.searchWord !== "") {
      search();
    }
  }, [state.searchWord, selectedState, noState, state.filter]);

  useEffect(() => {
    setState(state => ({ ...state, filter: params.filter }));
  }, [params.filter]);

  useEffect(() => {
    if (state.searchWord) {
      const words = [state.searchWord, ...state.searchWord.replace('"', "").split(" ")];
      updateSearchWords(words);
    }
  }, [state.searchWord]);

  const checkIfContainsResults = () => {
    const contains =
      (state.resultData.contacts && state.resultData.contacts.length > 0) ||
      (state.resultData.companies && state.resultData.companies.length > 0) ||
      (state.resultData.invoiceCharges && state.resultData.invoiceCharges.length > 0) ||
      (state.resultData.withholdingTaxes &&
        state.resultData.withholdingTaxes.length +
          (state.resultData.mortgageTaxes && state.resultData.mortgageTaxes.length) +
          (state.resultData.transferTaxes && state.resultData.transferTaxes.length) >
          0) ||
      (state.resultData.endorsements && state.resultData.endorsements.length > 0) ||
      (state.resultData.manuals && state.resultData.manuals.length > 0) ||
      (state.resultData.forms && state.resultData.forms.length > 0) ||
      (state.resultData.quoteTemplates && state.resultData.quoteTemplates.length > 0) ||
      (state.resultData.liens && state.resultData.liens.length > 0) ||
      (state.resultData.additionalLiens && state.resultData.additionalLiens.length > 0) ||
      (state.resultData.calculators && state.resultData.calculators.length > 0) ||
      (state.resultData.stateIdiosyncrasies && state.resultData.stateIdiosyncrasies.length > 0) ||
      (state.resultData.modifications && state.resultData.modifications.length > 0) ||
      (state.resultData.recordings && state.resultData.recordings.length > 0) ||
      (state.resultData.highLiabilityApprovals && state.resultData.highLiabilityApprovals.length > 0) ||
      (state.resultData.commitmentScheduleBIICodes && state.resultData.commitmentScheduleBIICodes.length > 0) ||
      (state.resultData.scheduleBICodes && state.resultData.scheduleBICodes.length > 0) ||
      (state.resultData.policyScheduleBICodes && state.resultData.policyScheduleBICodes.length > 0) ||
      (state.resultData.scheduleBINotes && state.resultData.scheduleBINotes.length > 0) ||
      (state.resultData.scheduleBIINotes && state.resultData.scheduleBIINotes.length > 0) ||
      (state.resultData.policyGeneralInfoNotes && state.resultData.policyGeneralInfoNotes.length > 0) ||
      (state.resultData.policyHelpfulWordings && state.resultData.policyHelpfulWordings.length > 0) ||
      (state.resultData.policyEndorsements && state.resultData.policyEndorsements.length > 0) ||
      (state.resultData.policySurveyNotes && state.resultData.policySurveyNotes.length > 0) ||
      (state.resultData.surveyLegalDescriptionNotes && state.resultData.surveyLegalDescriptionNotes.length > 0) ||
      (state.resultData.surveyExceptionCodes && state.resultData.surveyExceptionCodes.length > 0) ||
      (state.resultData.scheduleANotes && state.resultData.scheduleANotes.length > 0) ||
      (state.resultData.commitmentGeneralInfoNotes && state.resultData.commitmentGeneralInfoNotes.length > 0);
    if (state.initialLoad) {
      setState(state => ({
        ...state,
        initialLoad: !contains,
        containsResults: contains
      }));
    } else {
      setState(state => ({
        ...state,
        containsResults: contains
      }));
    }
  };

  useEffect(() => {
    checkIfContainsResults();
  }, [state.resultData]);

  const buildTaxes = (withholdingTaxes, mortgageTaxes, transferTaxes) => {
    if (withholdingTaxes || mortgageTaxes || transferTaxes) {
      const taxStates = [withholdingTaxes, mortgageTaxes, transferTaxes]
        .flatMap(objs => objs && objs.map(obj => obj.stateId))
        .filter(obj => obj);
      const uniqueStates = [...new Set(taxStates)];

      return uniqueStates.map(s => ({
        stateId: s,
        withholdingTax: withholdingTaxes && withholdingTaxes.find(t => t.stateId === s),
        mortgageTax: mortgageTaxes && mortgageTaxes.find(t => t.stateId === s),
        transferTax: transferTaxes && transferTaxes.find(t => t.stateId === s)
      }));
    }
  };

  return (
    <React.Fragment>
      {!state.touched && (
        <div
          className={css({
            width: "calc(100vw - 160px)",
            position: "relative",
            top: "calc(35%)",
            transform: "translateY(-50%)",
            textAlign: "center",
            display: "table"
          })}
        >
          <div
            className={css({
              fontFamily: "FaktSlab-Bold",
              fontSize: 48,
              color: "#000e48",
              lineHeight: "normal",
              width: "100%",
              marginBottom: 40
            })}
          >
            Madison Intel
          </div>
          <SearchBar
            history={history}
            wordsTyped={state.wordsTyped}
            searchWord={state.searchWord}
            setWordsTyped={wordsTyped => setState(state => ({ ...state, wordsTyped }))}
            setTouched={touched => setState(state => ({ ...state, touched }))}
            loading={state.loading}
            filter={state.filter}
          />
        </div>
      )}
      {state.touched && (
        <React.Fragment>
          <SearchBar
            history={history}
            wordsTyped={state.wordsTyped}
            searchWord={state.searchWord}
            setWordsTyped={wordsTyped => setState(state => ({ ...state, wordsTyped }))}
            setTouched={touched => setState(state => ({ ...state, touched }))}
            loading={state.loading}
            filter={state.filter}
          />
          <div className={css({ marginTop: 40, paddingBottom: 40 })}>
            {state.containsResults && !state.loading ? (
              <Results
                noState={noState}
                loading={state.loading}
                contacts={state.resultData.contacts}
                companies={state.resultData.companies}
                invoiceCharges={state.resultData.invoiceCharges}
                taxes={buildTaxes(
                  state.resultData.withholdingTaxes,
                  state.resultData.mortgageTaxes,
                  state.resultData.transferTaxes
                )}
                endorsements={state.resultData.endorsements}
                calculators={state.resultData.calculators}
                manuals={state.resultData.manuals}
                forms={state.resultData.forms}
                quoteTemplates={state.resultData.quoteTemplates}
                liens={state.resultData.liens}
                additionalLiens={state.resultData.additionalLiens}
                recordings={state.resultData.recordings}
                stateIdiosyncrasies={state.resultData.stateIdiosyncrasies}
                modifications={state.resultData.modifications}
                highLiabilityApprovals={state.resultData.highLiabilityApprovals}
                scheduleANotes={state.resultData.scheduleANotes}
                scheduleBICodes={state.resultData.scheduleBICodes}
                commitmentScheduleBIICodes={state.resultData.commitmentScheduleBIICodes}
                policyScheduleBICodes={state.resultData.policyScheduleBICodes}
                scheduleBINotes={state.resultData.scheduleBINotes}
                scheduleBIINotes={state.resultData.scheduleBIINotes}
                commitmentGeneralInfoNotes={state.resultData.commitmentGeneralInfoNotes}
                policyGeneralInfoNotes={state.resultData.policyGeneralInfoNotes}
                policyHelpfulWordings={state.resultData.policyHelpfulWordings}
                policyEndorsements={state.resultData.policyEndorsements}
                policySurveyNotes={state.resultData.policySurveyNotes}
                surveyLegalDescriptionNotes={state.resultData.surveyLegalDescriptionNotes}
                surveyExceptionCodes={state.resultData.surveyExceptionCodes}
                filter={state.filter}
              />
            ) : (
              !state.loading && !state.error && <Message size="big">No matches found...</Message>
            )}
            {state.error && (
              <Message size="big" style={{ color: "red" }}>
                Please try searching again. There was an state.error.
              </Message>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default withRouter(Search);
