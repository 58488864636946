import React, { useState, useEffect } from "react";
import { runWithAdal } from "react-adal";
import { authContext, graphApiFetch } from "../adalConfig";
import { getRequest } from "../helpers/ApiHelpers";

const UserContext = React.createContext({});

function UserContextProvider({ children }) {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState({});

  // prompt for authentication
  useEffect(() => runWithAdal(authContext, () => setIsAuth(true), false), []);

  async function setRole() {
    const result = await getRequest("/user/checkIfAdmin");
    if (result.success) {
      setUser((user) => ({
        ...user,
        isAdmin: result.data.isAdmin,
        isOwner: result.data.isOwner,
        isOwnerPlus: result.data.isOwnerPlus,
        isRemittanceTargets: result.data.isRemittanceTargets,
      }));
    }
  }

  // load user data
  useEffect(() => {
    if (!isAuth) return setUser({});
    graphApiFetch("https://graph.microsoft.com/v1.0/me")
      .then((user) => setUser(user))
      .then(() => setRole())
      .catch(() => setUser({}));
  }, [isAuth]);

  const value = { user, logout: () => authContext.logOut() };
  return (
    <UserContext.Provider value={value}>
      {isAuth && children}
    </UserContext.Provider>
  );
}

export { UserContextProvider, UserContext };
