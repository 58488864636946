import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Dropdown, Checkbox, Input, Modal } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddOtherException({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    guidelinesToOmit: {
      underwriterId: +match.params.underwriterId,
      guidelinesToOmit: undefined,
      scheduleBIICodeId: +match.params.scheduleBIICodeId || 0
    },
    scheduleBIICode: { id: 0, stateId: +match.params.state },
    originalCodeName: null,
    codeStates: [+match.params.state],
    underwriters: [],
    selectedUnderwriters: [+match.params.underwriterId],
    editMode: match.path.includes("edit"),
    errorList: [],
    confirmEdit: null,
    allUnderwriters: false,
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    states: [],
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    exceptionStates: [+match.params.state],
    editAllStates: false,
    editUnderwriter: false,
    editCode: false,
    editWording: false,
    editGuidelinesToOmit: false,
    editCaseScenario: false,
    editHighlight: false
  });

  async function getOtherExceptionById(scheduleBIICodeId) {
    const result = await getRequest(`/bIGuidelinesToOmit/getOtherExceptionById`, {
      scheduleBIICodeId
    });
    if (result.success) {
      if (result.data) {
        setState(state => ({
          ...state,
          guidelinesToOmit: result.data,
          scheduleBIICode: result.data.scheduleBIICode,
          selectedUnderwriters: [result.data.underwriterId],
          originalCodeName: result.data.scheduleBIICode.code
        }));
      }
    }
  }

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data
          .filter(u => u.name !== "All Underwriters")
          .map(u => ({
            key: u.id,
            value: u.id,
            text: u.name
          }))
      }));
    }
  }

  async function getStateUnderwriters() {
    const result = await getRequest(`/underwriter/getStateUnderwriters?id=${+match.params.state || 31}`);
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data.map(u => ({
          key: u.id,
          value: u.id,
          text: u.name
        }))
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState(state => ({
        ...state,
        states: result.data.map(s => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allLicensedStatesList: [52, ...result.data]
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function getCodeStates(id) {
    const result = await getRequest(`/scheduleBII/getCodeStates`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        codeStates: result.data
      }));
    }
  }

  useEffect(() => {
    getStates();
    if (match.params.state != "52") {
      getStateUnderwriters();
    }
    if (state.editMode) {
      getCodeStates(match.params.scheduleId);
      getOtherExceptionById(match.params.scheduleId);
    } else {
      getAllStatesList();
      getAllLicensedStatesList();
      getAllUnlicensedStatesList();
    }
  }, []);

  async function saveAddChanges() {
    setState(state => ({
      ...state,
      submitting: true
    }));
    const result = await postRequest(`/bIGuidelinesToOmit/addOtherExceptions`, {
      bIGuidelinesToOmit: state.guidelinesToOmit,
      scheduleBIICode: state.scheduleBIICode,
      underwriterIds: state.selectedUnderwriters,
      stateIds: state.exceptionStates
    });
    if (result.success) {
      logEvent(null, state.editMode ? "Edited" : "Added", "Other Exceptions");
      history.goBack();
    }
  }

  async function saveEditChanges() {
    setState(state => ({
      ...state,
      submitting: true
    }));
    const result = await postRequest(`/bIGuidelinesToOmit/editOtherExceptions`, {
      bIGuidelinesToOmit: state.guidelinesToOmit,
      scheduleBIICode: state.scheduleBIICode,
      editAllStates: state.editAllStates,
      editCode: state.editCode,
      editNote: state.editNote,
      editWording: state.editWording,
      editGuidelinesToOmit: state.editGuidelinesToOmit,
      editCaseScenario: state.editCaseScenario,
      editHighlight: state.editHighlight
    });
    if (result.success) {
      history.goBack();
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Guidelines to Omit`}
        {state.editMode && (
          <h3>{state.underwriters.find(u => u.value === state.guidelinesToOmit?.underwriterId)?.text}</h3>
        )}
      </Header>
      <Form>
        <Grid>
          {state.editMode && (
            <>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field required>
                    <label>State</label>
                    <Dropdown
                      size="large"
                      fluid
                      selection
                      placeholder="Select State"
                      style={{ fontSize: 16 }}
                      options={state.states}
                      value={state.scheduleBIICode.stateId}
                      onChange={(_, { value }) =>
                        setState(state => ({
                          ...state,
                          scheduleBIICode: {
                            ...state.scheduleBIICode,
                            stateId: value
                          }
                        }))
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          {+match.params.state !== 52 && !state.editMode && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <Checkbox
                    checked={state.allUnderwriters}
                    onChange={() =>
                      setState(state => ({
                        ...state,
                        allUnderwriters: !state.allUnderwriters,
                        selectedUnderwriters: !state.allUnderwriters
                          ? state.underwriters.map(u => u.value)
                          : state.selectedUnderwriters
                      }))
                    }
                    label="Select all Underwriters"
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Underwriters</label>
                  <Dropdown
                    size="large"
                    fluid
                    multiple
                    selection
                    placeholder="Select Underwriters"
                    style={{ fontSize: 16 }}
                    options={state.underwriters}
                    value={state.selectedUnderwriters}
                    onChange={(_, { value }) => {
                      setState(state => ({
                        ...state,
                        selectedUnderwriters: value
                      }));
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Case Scenario</label>
                <Input
                  placeholder="Case Scenario"
                  size="large"
                  value={state.scheduleBIICode.caseScenario}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      editCaseScenario: true,
                      scheduleBIICode: {
                        ...state.scheduleBIICode,
                        caseScenario: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Exception Code</label>
                <Input
                  placeholder="Exception Code"
                  size="large"
                  value={state.scheduleBIICode.code}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      editCode: true,
                      scheduleBIICode: {
                        ...state.scheduleBIICode,
                        code: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Exception Wording</label>
                <Input
                  placeholder="Exception Wording"
                  size="large"
                  value={state.scheduleBIICode.wording}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      editWording: true,
                      scheduleBIICode: {
                        ...state.scheduleBIICode,
                        wording: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Guidelines to Omit</label>
                <TextArea
                  placeholder="Guidelines to Omit"
                  style={{ minHeight: 292 }}
                  value={state.guidelinesToOmit.guidelinesToOmit}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      editGuidelinesToOmit: true,
                      guidelinesToOmit: {
                        ...state.guidelinesToOmit,
                        guidelinesToOmit: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {!state.editMode && (
            <React.Fragment>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field>
                    <Checkbox
                      label="All states"
                      checked={state.allStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: true,
                          allLicensedStates: false,
                          allUnlicensedStates: false,
                          customStates: false,
                          exceptionStates: state.allStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="All licensed states"
                      checked={state.allLicensedStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: true,
                          allUnlicensedStates: false,
                          customStates: false,
                          exceptionStates: state.allLicensedStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="All unlicensed states"
                      checked={state.allUnlicensedStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: false,
                          allUnlicensedStates: true,
                          customStates: false,
                          exceptionStates: state.allUnlicensedStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="Custom (choose your own state selection)"
                      checked={state.customStates}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: false,
                          allUnlicensedStates: false,
                          customStates: true
                        }))
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Highlight Row
                  <Checkbox
                    value={!state.guidelinesToOmit?.highlight}
                    checked={state.guidelinesToOmit?.highlight}
                    onChange={() => {
                      setState(state => ({
                        ...state,
                        editHighlight: true,
                        guidelinesToOmit: {
                          ...state.guidelinesToOmit,
                          highlight: !state.guidelinesToOmit?.highlight
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <Button size="large" className="cancelButton" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  setState(state => ({
                    ...state,
                    submitAttempted: true
                  }));
                  if (state.editMode && state.codeStates.length > 1 && state.errorList.length < 1) {
                    setState(state => ({ ...state, confirmEdit: true }));
                  } else {
                    state.editMode ? saveEditChanges() : saveAddChanges();
                  }
                }}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      {state.confirmEdit && (
        <Modal
          size={"tiny"}
          open={state.confirmEdit !== null}
          onClose={() => setState(state => ({ ...state, confirmEdit: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Edit Options</Modal.Header>
          <Modal.Content>
            <p className={css({ fontFamily: "Fakt-Bold" })}>
              Code{" "}
              <span
                className={css({
                  color: "#767676",
                  textDecoration: "underline"
                })}
              >
                {state.originalCodeName}
              </span>{" "}
              found in the following states.
            </p>
            {state.codeStates.map(s => s).join(", ")}
            <Form.Field className={css({ marginTop: 16 })}>
              <Checkbox
                label={"Edit " + state.scheduleBIICode.state.name}
                checked={!state.editAllStates}
                size="large"
                onClick={() =>
                  setState(state => ({
                    ...state,
                    editAllStates: false
                  }))
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label={"Edit All States"}
                checked={state.editAllStates}
                size="large"
                onClick={() =>
                  setState(state => ({
                    ...state,
                    editAllStates: true
                  }))
                }
              />
            </Form.Field>
            {state.editAllStates && (
              <div className={css({ marginTop: 16 })}>
                <Form.Field>
                  <Checkbox
                    label={"Case Scenario"}
                    checked={state.editCaseScenario}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editCaseScenario: !state.editCaseScenario
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Exception Code"}
                    checked={state.editCode}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editCode: !state.editCode
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Exception Wording"}
                    checked={state.editWording}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editWording: !state.editWording
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Guideline To Omit"}
                    checked={state.editGuidelinesToOmit}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editGuidelinesToOmit: !state.editGuidelinesToOmit
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Highlight"}
                    checked={state.editHighlight}
                    size="large"
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        editHighlight: !state.editHighlight
                      }))
                    }
                  />
                </Form.Field>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <React.Fragment>
              <Button
                negative
                onClick={() =>
                  setState(state => ({
                    ...state,
                    confirmEdit: null
                  }))
                }
                style={{
                  backgroundColor: "#fff",
                  border: "solid 1px #e5e5ea",
                  color: "rgba(0, 0, 0, 0.87)"
                }}
              >
                Cancel
              </Button>
              <Button
                positive
                onClick={() => saveEditChanges()}
                style={{ backgroundColor: "#1f2b5e" }}
                data-automation="confirmDelete"
              >
                Submit
              </Button>
            </React.Fragment>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

withRouter(EditAddOtherException);
