import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Input,
  Dropdown,
  Message
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../State";
import FileUpload from "../FileUpload";
import { readFileAsBase64 } from "../../helpers/ReadFileAsBase64";
import shortid from "shortid";
import { logEvent } from "../../helpers/analyticsLogger";

export function EditAddForm({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const defaultForm = {
    name: undefined,
    note: undefined,
    fileName: undefined,
    filePath: undefined,
    formCategoryId: undefined,
    formStates: [
      {
        id: undefined,
        formId: undefined,
        stateId: selectedState,
        state: undefined
      }
    ],
    sortIndex: undefined
  };
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    form: defaultForm,
    formCategories: [],
    states: [],
    file: null,
    filesToDelete: [],
    editMode: match.path === "/forms/edit-form/:id",
    errorList: []
  });

  async function getFormById(formId) {
    const result = await getRequest(`/form/getFormById`, {
      formId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        form: result.data
      }));
    }
  }

  async function getFormCategories() {
    const result = await getRequest(`/form/getFormCategories`);
    if (result.success) {
      setState((state) => ({
        ...state,
        formCategories: result.data.map((d) => ({
          key: d.id,
          value: d.id,
          text: d.name
        }))
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  useEffect(() => {
    getFormCategories();
    getStates();
    state.editMode && getFormById(match.params.id);
  }, []);

  function buildFormStates(value) {
    return value.map((v) => ({
      formId: 0,
      stateId: v
    }));
  }

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const base64File =
          (state.file && state.file.length > 0) ||
          (state.file && state.file.path)
            ? await readFileAsBase64(state.file)
            : null;
        const fileExtension = state.file
          ? state.file.name.split(".").pop()
          : "";
        const result = await postRequest(
          `/form/${state.editMode ? "editForm" : "addForm"}`,
          {
            ...state.form,
            fileName:
              (state.file && state.file.length > 0) ||
              (state.file && state.file.path)
                ? state.file.name
                : state.form.fileName,
            base64File,
            fileExtension
          }
        );
        if (result.success) {
          logEvent(null, state.editMode ? "Edited" : "Added", "Forms");
          setTimeout(function() {
            history.goBack();
          }, 1100);
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.form.name) {
      list.push("You must provide a name.");
    }
    if (!state.form.formStates.length > 0) {
      list.push("You must select a state.");
    }
    if (!state.form.formCategoryId) {
      list.push("You must select a form category.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.form]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Form`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Name</label>
                <Input
                  placeholder="Name"
                  size="large"
                  value={state.form.name}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      form: { ...state.form, name: value }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    (state.form.name === "" || state.form.name === undefined)
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>States</label>
                <Dropdown
                  size="large"
                  fluid
                  multiple
                  selection
                  placeholder="Select States"
                  style={{ fontSize: 16 }}
                  options={state.states}
                  value={
                    state.form.formStates
                      ? state.form.formStates.map((cs) => cs.stateId)
                      : null
                  }
                  onChange={(_, { value }) => {
                    setState((state) => ({
                      ...state,
                      form: {
                        ...state.form,
                        formStates:
                          value[value.length - 1] === 52
                            ? buildFormStates([52])
                            : buildFormStates(value.filter((v) => v !== 52))
                      }
                    }));
                  }}
                  error={
                    state.submitAttempted && !state.form.formStates.length > 0
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Category</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select Category"
                  style={{ fontSize: 16 }}
                  options={state.formCategories}
                  value={state.form.formCategoryId}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      form: { ...state.form, formCategoryId: value }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    state.form.formCategoryId === undefined
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.form.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      form: {
                        ...state.form,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Sort Number</label>
                <Input
                  placeholder="Sort Number"
                  size="large"
                  type="number"
                  value={state.form.sortIndex}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      form: { ...state.form, sortIndex: value }
                    }))
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <FileUpload
                  setFileToApp={(file) =>
                    setState((state) => ({ ...state, file }))
                  }
                  fileList={state.file ? [state.file] : []}
                  deleteFileToApp={() =>
                    setState((state) => ({
                      ...state,
                      file: null
                    }))
                  }
                />
              </Form.Field>
              {state.editMode && state.form.fileName && (
                <p
                  key={shortid.generate()}
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      form: { ...state.form, fileName: undefined },
                      file: undefined
                    }))
                  }
                  className={css({
                    "&:hover": { color: "#707898" },
                    cursor: "pointer"
                  })}
                >
                  <i className="fal fa-trash-alt" style={{ marginRight: 8 }} />
                  {state.form.fileName}
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddForm);
