import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import FileUpload from "../../FileUpload";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import shortid from "shortid";
import { logEvent } from "../../../helpers/analyticsLogger";

const defaultTransferTax = {
  isApplicable: null,
  isApplicableToLease: null,
  isApplicableToLeaseAssignment: null,
  isApplicableToMembershipInterestTransfers: null,
  isExemptOfPersonalPropertyTransfer: null
};

export function EditTransferTax({ history }) {
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    transferTax: defaultTransferTax,
    files: [],
    filesToDelete: []
  });

  async function getTransferTaxByState(stateId) {
    const result = await getRequest(`/tax/getTransferTaxByState`, {
      stateId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        transferTax: result.data
      }));
    }
  }

  useEffect(() => {
    getTransferTaxByState(selectedState);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({ ...state, submitting: true }));
      const result = await postRequest("/tax/editTransferTax", {
        ...state.transferTax,
        stateId: selectedState,
        files: await Promise.all(
          state.files.map(async (f) => ({
            base64File: await readFileAsBase64(f),
            fileName: f.name
          }))
        ),
        filesToDelete: state.filesToDelete
      });
      if (result.success) {
        logEvent(null, "Edited", "Transfer Tax");
        setTimeout(() => history.goBack(), 1000);
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        Transfer Tax
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Is it applicable?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.transferTax.isApplicable}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicable: !state.transferTax.isApplicable
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.transferTax.isApplicable === false ? true : false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicable:
                          state.transferTax.isApplicable === false
                            ? true
                            : false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.transferTax.isApplicableNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Is it applicable to Leases?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.transferTax.isApplicableToLease}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToLease: !state.transferTax
                          .isApplicableToLease
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.transferTax.isApplicableToLease === false
                      ? true
                      : false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToLease:
                          state.transferTax.isApplicableToLease === false
                            ? true
                            : false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.transferTax.isApplicableToLeaseNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToLeaseNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Is it applicable to Assignment of Leases?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.transferTax.isApplicableToLeaseAssignment}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToLeaseAssignment: !state.transferTax
                          .isApplicableToLeaseAssignment
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.transferTax.isApplicableToLeaseAssignment === false
                      ? true
                      : false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToLeaseAssignment:
                          state.transferTax.isApplicableToLeaseAssignment ===
                          false
                            ? true
                            : false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.transferTax.isApplicableToLeaseAssignmentNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToLeaseAssignmentNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Is it applicable to Membership Interest Transfers?
                </label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={
                    state.transferTax.isApplicableToMembershipInterestTransfers
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToMembershipInterestTransfers: !state
                          .transferTax.isApplicableToMembershipInterestTransfers
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.transferTax
                      .isApplicableToMembershipInterestTransfers === false
                      ? true
                      : false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToMembershipInterestTransfers:
                          state.transferTax
                            .isApplicableToMembershipInterestTransfers === false
                            ? true
                            : false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={
                    state.transferTax
                      .isApplicableToMembershipInterestTransfersNote
                  }
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isApplicableToMembershipInterestTransfersNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Is the transfer of personal property exempt?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.transferTax.isExemptOfPersonalPropertyTransfer}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isExemptOfPersonalPropertyTransfer: !state.transferTax
                          .isExemptOfPersonalPropertyTransfer
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.transferTax.isExemptOfPersonalPropertyTransfer ===
                    false
                      ? true
                      : false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isExemptOfPersonalPropertyTransfer:
                          state.transferTax
                            .isExemptOfPersonalPropertyTransfer === false
                            ? true
                            : false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={
                    state.transferTax.isExemptOfPersonalPropertyTransferNote
                  }
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      transferTax: {
                        ...state.transferTax,
                        isExemptOfPersonalPropertyTransferNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={(files) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files, ...files]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files.filter((f) => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.transferTax.transferTaxFiles &&
                state.transferTax.transferTaxFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        transferTax: {
                          ...state.transferTax,
                          transferTaxFiles: [
                            ...state.transferTax.transferTaxFiles.filter(
                              (ttf) => ttf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditTransferTax);
