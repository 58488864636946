import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Input,
  Checkbox,
  Message
} from "semantic-ui-react";
import { logEvent } from "../../../helpers/analyticsLogger";

css({
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  marginBottom: 17,
  lineHeight: 1.71
});
export function EditAddSurveyExceptionCode({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    surveyExceptionSections: [],
    code: { surveyExceptionSectionId: +match.params.section, code: null },
    editMode: match.path.includes("edit")
  });

  async function getSurveyExceptionCodeById(id) {
    const result = await getRequest(`/survey/getSurveyExceptionCodeById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        code: result.data
      }));
    }
  }
  async function getSurveyExceptionSections() {
    const result = await getRequest("/survey/getSurveyExceptionSections");
    if (result.success) {
      setState((state) => ({
        ...state,
        surveyExceptionSections: result.data
      }));
    }
  }

  function getErrorList() {
    let list = [];
    if (
      state.code.code === "" ||
      (state.code.code === null && state.code.surveyExceptionSectionId === 1)
    ) {
      list.push("You must provide a code");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.code.code, state.submitAttempted]);

  useEffect(() => {
    getSurveyExceptionSections();
    if (state.editMode) {
      getSurveyExceptionCodeById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true
      }));
      let result;
      if (state.editMode) {
        result = await postRequest(
          "/survey/EditSurveyExceptionCode",
          state.code
        );
      } else {
        result = await postRequest(
          "/survey/AddSurveyExceptionCode",
          state.code
        );
      }
      if (result.success) {
        logEvent(null, state.editMode ? "Edited" : "Added", "Exception Code");
        history.goBack();
      }
    }
  }
  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {state.editMode ? "Edit" : "New"} Code
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                {state.surveyExceptionSections.map((s) => (
                  <Form.Field key={"section" + s.id}>
                    <Checkbox
                      label={s.name}
                      checked={state.code.surveyExceptionSectionId === s.id}
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          code: {
                            ...state.code,
                            surveyExceptionSectionId: s.id
                          }
                        }))
                      }
                    />
                  </Form.Field>
                ))}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {state.code.surveyExceptionSectionId === 1 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field required>
                  <label>Code</label>
                  <Input
                    placeholder="Code"
                    size="large"
                    value={state.code.code}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        code: {
                          ...state.code,
                          code: value
                        }
                      }))
                    }
                    error={
                      state.submitAttempted &&
                      (state.code.code === "" || state.code.code === null)
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Case Scenario</label>
                <TextArea
                  placeholder="Case Scenario"
                  size="large"
                  value={state.code.caseScenario}
                  onChange={(_, { value }) =>
                    setState(() => ({
                      ...state,
                      code: {
                        ...state.code,
                        caseScenario: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Wording</label>
                <TextArea
                  placeholder="Wording"
                  style={{ minHeight: 196 }}
                  value={state.code.wording}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      code: {
                        ...state.code,
                        wording: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 196 }}
                  value={state.code.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      code: {
                        ...state.code,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Highlight Row
                  <Checkbox
                    value={!state.code.highlight}
                    checked={state.codehighlight}
                    onChange={() => {
                      setState(() => ({
                        ...state,
                        code: {
                          ...state.code,
                          highlight: !state.code.highlight
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There are some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  setState((state) => ({
                    ...state,
                    submitAttempted: true,
                    submitting: true
                  }));
                  saveChanges();
                }}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}
