import React, { useContext } from "react";
import { Icon, Table } from "semantic-ui-react";
import { css } from "emotion";
import { withRouter } from "react-router-dom";
import { Highlight } from "../../search/Highlight";
import { postRequest, getRequest } from "../../../helpers/ApiHelpers";
import { paragraphStyles } from "./paragraphStyles";
import { UserContext } from "../../User";
import LinesEllipsis from "react-lines-ellipsis";
import { Popup } from "../../Popup";
import CustomTable from "../../CustomTable";
import { ClickToView } from "../../ClickToView";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";

function EndorsementRowSort({
  history,
  match: { params },
  endorsements,
  setEndorsements,
  searchResults,
  deleteEndorsement
}) {
  const { user } = useContext(UserContext);
  const userCanEdit = user.isAdmin || user.isOwner || user.isOwnerPlus;

  async function saveSort(endorsements) {
    postRequest("/policyEndorsement/sortEndorsements", endorsements);
  }

  async function download(id) {
    const result = await getRequest(
      `/policyEndorsement/downloadEndorsement?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  const DragHandle = SortableHandle(() => (
    <span
      className={css({
        marginRight: 8,
        color: "#c3c3c3",
        cursor: "grab"
      })}
    >
      <i className="fal fa-sort" />
    </span>
  ));

  const SortableItem = SortableElement(({ value: e }) => {
    return (
      <Table.Row key={e.id}>
        <Table.Cell
          style={{
            textAlign: "left",
            position: "relative"
          }}
        >
          <React.Fragment>
            {userCanEdit && (
              <div
                className={css({
                  display: "inline-block",
                  position: "absolute",
                  left: 8
                })}
              >
                <div
                  className={css({
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  })}
                >
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer"
                    })}
                  >
                    <DragHandle />
                  </span>
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer"
                    })}
                    onClick={() =>
                      history.push(
                        `/policy-instructions/edit-endorsement/${e.id}`
                      )
                    }
                  >
                    <Icon className="fal fa-pen" />
                  </span>
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer"
                    })}
                    onClick={() => deleteEndorsement(e)}
                  >
                    <Icon
                      style={{ paddingRight: 32.5 }}
                      className="fal fa-trash"
                    />
                  </span>
                </div>
              </div>
            )}
            <div
              className={css({
                display: "inline-block",
                width: "calc(100% - 100px)"
              })}
            >
              <span className={css({ fontFamily: "Fakt-Bold" })}>
                {e.filePath && e.fileName ? (
                  <a
                    onClick={() => download(e.id)}
                    className={css({
                      textDecoration: "underline",
                      cursor: "pointer",
                      "&:hover": {
                        textDecoration: "underline"
                      }
                    })}
                  >
                    <Highlight children={e.name} />
                  </a>
                ) : (
                  <Highlight children={e.name} />
                )}
              </span>
            </div>
          </React.Fragment>
        </Table.Cell>
        <Table.Cell>
          <span className={paragraphStyles}>
            {e.explanation && !searchResults ? (
              <Popup
                on={["click"]}
                header={e.name}
                content={e.explanation}
                trigger={
                  <LinesEllipsis
                    text={e.explanation}
                    maxLine="2"
                    ellipsis="..."
                    style={{
                      textAlign: "left",
                      paddingRight: 24
                    }}
                  />
                }
              />
            ) : (
              <Highlight children={e.explanation} />
            )}
          </span>
        </Table.Cell>
        <Table.Cell>
          <span className={paragraphStyles}>
            {e.opGuideline && !searchResults ? (
              <Popup
                on={["click"]}
                header={e.name}
                content={e.opGuideline}
                trigger={
                  <LinesEllipsis
                    text={e.opGuideline}
                    maxLine="2"
                    ellipsis="..."
                    style={{
                      textAlign: "left",
                      paddingRight: 24
                    }}
                  />
                }
              />
            ) : (
              <Highlight children={e.opGuideline} />
            )}
          </span>
        </Table.Cell>
        <Table.Cell>
          <span className={paragraphStyles}>
            {e.lpGuideline && !searchResults ? (
              <Popup
                on={["click"]}
                header={e.name}
                content={e.lpGuideline}
                trigger={
                  <LinesEllipsis
                    text={e.lpGuideline}
                    maxLine="2"
                    ellipsis="..."
                    style={{
                      textAlign: "left",
                      paddingRight: 24
                    }}
                  />
                }
              />
            ) : (
              <Highlight children={e.lpGuideline} />
            )}
          </span>
        </Table.Cell>
      </Table.Row>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <CustomTable
        customStyles={{
          "& tr td:first-of-type, & tr th:first-of-type": {
            paddingLeft: "64px !important"
          },
          "& tr td": {
            padding: "24px !important"
          },
          "& th": {
            position: "sticky",
            top: 0,
            zIndex: 1
          },
          marginBottom: "24px !important"
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell style={{ textAlign: "left" }}>
              Endorsement
            </Table.HeaderCell>
            <Table.HeaderCell>
              Explanation {!searchResults && <ClickToView />}
            </Table.HeaderCell>
            <Table.HeaderCell>
              Guidelines for Issuing / OP {!searchResults && <ClickToView />}
            </Table.HeaderCell>
            <Table.HeaderCell>
              Guidelines for Issuing / LP {!searchResults && <ClickToView />}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items.map((value, index) => {
            return (
              <SortableItem
                key={`item-${value.id}`}
                index={index}
                value={value}
              />
            );
          })}
        </Table.Body>
      </CustomTable>
    );
  });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const sortedItems = arrayMove(endorsements, oldIndex, newIndex).map(
      (e, i) => ({
        ...e,
        sortNum: i
      })
    );
    saveSort(sortedItems);
    setEndorsements(sortedItems);
  };

  return (
    <SortableList items={endorsements} onSortEnd={onSortEnd} useDragHandle />
  );
}

export default withRouter(EndorsementRowSort);
