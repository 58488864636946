import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Modal, Button, Accordion, Icon, Divider, Header } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import DragSortCodes from "./DragSortCodes";

function Codes({ match: { params }, type, section, searchResults, downloadUrl }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  const [state, setState] = useState({
    initialLoading: true,
    noResults: false,
    codes: [],
    submitting: false,
    codeToDelete: null,
    codeCategories: []
  });

  const [activeIndexes, setActiveIndexes] = useState([]);
  const [showAll, setShowAll] = useState(false);

  async function getCodeCategories() {
    const result = await getRequest(`/codeCategory/getCodeCategories`);
    if (result.success) {
      setState(state => ({
        ...state,
        codeCategories: result.data.concat({ id: null, name: "Other" })
      }));
    }
  }

  async function getScheduleBICodesByState() {
    const result = await getRequest(`/scheduleBI/getScheduleBICodesByState`, {
      stateId: selectedState || 31
    });
    if (result.success) {
      setState(state => ({
        ...state,
        codes: result.data
      }));
    }
  }

  async function getScheduleBIICodesByState() {
    const result = await getRequest(`/scheduleBII/getScheduleBIICodesByState`, {
      stateId: selectedState || 31
    });
    if (result.success) {
      setState(state => ({
        ...state,
        codes: result.data
      }));
    }
  }

  useEffect(() => {
    if (!searchResults) {
      getCodeCategories();
    }
  }, []);

  useEffect(() => {
    if (!searchResults) {
      if (type === "BI") {
        getScheduleBICodesByState();
      } else {
        getScheduleBIICodesByState();
      }
      setState({
        ...state,
        initialLoading: !selectedState
      });
    }
  }, [params.state, selectedState]);

  async function deleteCode(id) {
    const result = await postRequest(`/schedule${type}/deleteSchedule${type}Code?id=${id}`, id);
    if (result.success) {
      setState(state => ({
        ...state,
        codes: state.codes.filter(c => c.id !== id),
        codeToDelete: null
      }));
    }
  }

  const handleClick = newIndex => {
    if (activeIndexes.some(i => i === newIndex)) {
      const removedIndexList = activeIndexes.filter(i => i !== newIndex);
      setActiveIndexes(removedIndexList);
    } else {
      const addedIndexList = [...activeIndexes, newIndex];
      setActiveIndexes(addedIndexList);
    }
  };

  const codes = searchResults ? searchResults : state.codes;

  const filteredCodes = searchResults || codes.filter(c => c.scheduleSectionId === section);

  const filteredCategories = state.codeCategories.filter(c => filteredCodes.some(code => code.codeCategoryId === c.id));

  useEffect(() => {
    if (showAll) {
      const indexes = filteredCategories.map(c => c.id);
      setActiveIndexes(indexes);
    } else {
      setActiveIndexes([]);
    }
  }, [showAll]);

  const isExpandable = section === 1 && selectedState === 52;

  const hasCategories = filteredCategories.some(c => c.id);

  return (
    <React.Fragment>
      {filteredCodes.length > 0 && (
        <React.Fragment>
          {!searchResults && (
            <React.Fragment>
              {codes.length > 0 && (
                <Divider horizontal>
                  <Header as="h4">Codes</Header>
                </Divider>
              )}
              {codes.length > 0 && isExpandable && (
                <Button basic onClick={() => setShowAll(showAll => !showAll)} style={{ marginBottom: 32 }}>
                  {showAll ? "Collapse All" : "Open All"}
                </Button>
              )}
              {filteredCategories.map(c => (
                <React.Fragment key={c.id}>
                  {!isExpandable && (
                    <>
                      {hasCategories && (
                        <span
                          className={css({
                            textAlign: "left",
                            color: "#47517B",
                            fontSize: 16,
                            fontFamily: "Fakt-Bold"
                          })}
                        >
                          {c.name}
                        </span>
                      )}
                      <DragSortCodes
                        items={filteredCodes.filter(code => code.codeCategoryId === c.id)}
                        saveSortPath={`/schedule${type}/sortCodes`}
                        draggable={userCanEdit && !searchResults}
                        type={type}
                        deleteAction={value =>
                          setState(state => ({
                            ...state,
                            codeToDelete: value
                          }))
                        }
                        section={section}
                        searchMode={searchResults}
                        downloadUrl={downloadUrl}
                      />
                    </>
                  )}
                  {isExpandable && (
                    <Accordion>
                      <Accordion.Title
                        active={activeIndexes.some(i => i === c.id)}
                        index={0}
                        onClick={() => handleClick(c.id)}
                        key={c.id}
                      >
                        <Icon name="dropdown" />
                        {c.name}
                      </Accordion.Title>
                      <Accordion.Content active={activeIndexes.some(i => i === c.id)}>
                        <>
                          <DragSortCodes
                            items={filteredCodes.filter(code => code.codeCategoryId === c.id)}
                            saveSortPath={`/schedule${type}/sortCodes`}
                            draggable={userCanEdit && !searchResults}
                            type={type}
                            deleteAction={value =>
                              setState(state => ({
                                ...state,
                                codeToDelete: value
                              }))
                            }
                            section={section}
                            searchMode={searchResults}
                            downloadUrl={downloadUrl}
                          />
                        </>
                      </Accordion.Content>
                    </Accordion>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
          {searchResults && (
            <DragSortCodes
              items={filteredCodes}
              saveSortPath={`/schedule${type}/sortCodes`}
              draggable={userCanEdit && !searchResults}
              type={type}
              deleteAction={value => setState(state => ({ ...state, codeToDelete: value }))}
              section={section}
              searchMode={searchResults}
              downloadUrl={downloadUrl}
            />
          )}
          {state.codeToDelete && (
            <Modal
              size={"tiny"}
              open={state.codeToDelete !== null}
              onClose={() => setState(state => ({ ...state, codeToDelete: null }))}
              dimmer="inverted"
            >
              <Modal.Header>Delete Code</Modal.Header>
              <Modal.Content>
                <p>
                  Are you sure you want to delete code <strong>{state.codeToDelete.code}</strong>?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  negative
                  onClick={() => setState(state => ({ ...state, codeToDelete: null }))}
                  style={{
                    backgroundColor: "#fff",
                    border: "solid 1px #e5e5ea",
                    color: "rgba(0, 0, 0, 0.87)"
                  }}
                >
                  No
                </Button>
                <Button
                  positive
                  onClick={() => deleteCode(state.codeToDelete.id)}
                  style={{ backgroundColor: "#1f2b5e" }}
                >
                  Yes
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default withRouter(React.memo(Codes));
