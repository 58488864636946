import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { cache, css } from "emotion";
import { Icon, Menu, Segment, Sidebar, Popup, Button } from "semantic-ui-react";
import Colors from "./Colors";
import LogoIcon from "../components/icons/LogoIcon";
import { withRouter } from "react-router-dom";
import { UserContext } from "./User";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import ScrollButton from "./ScrollButton";

const activeStyles = {
  backgroundColor: "#00b8d4 !important",
  color: "#fff !important",
  "&&": {
    "&:before": {
      backgroundColor: "#00b8d4 !important",
      border: `1px solid #00b8d4`,
      borderRadius: 5,
      content: '""',
      height: 40,
      left: -16,
      position: "absolute",
      width: 3
    }
  }
};

const labelStyles = css({
  position: "absolute",
  fontSize: 9,
  right: 4,
  bottom: 4
});

function Layout({ children, location, history, match: { params } }) {
  const { user, logout } = useContext(UserContext);
  const { addToast, removeAllToasts, toastStack } = useToasts();
  useEffect(() => {
    document.querySelector("#top").scrollTop = 0;
  }, [location.pathname]);

  async function fecthLatestVersion() {
    const result = await axios.get(`/?&timestamp=${new Date().getTime()}`);
    var latest = result.data.match(/\/static\/js\/*.*.js/g);
    var previous = document.documentElement.innerHTML.match(/\/static\/js\/*.*.js/g);
    var needsRefresh = latest && latest[0] !== previous[0];
    if (needsRefresh) {
      if (toastStack.length === 0) {
        removeAllToasts();
        addToast(
          <div style={{ textAlign: "center" }}>
            New updates are available <br />
            click here to refresh
            <br />
            <Button onClick={() => window.location.reload()} style={{ marginTop: 8 }}>
              Refresh
            </Button>
          </div>,
          {
            appearance: "info",
            autoDismiss: false
          }
        );
      }
    }
  }

  useEffect(() => {
    fecthLatestVersion();
  }, [location.pathname]);

  //reroute underwriter PA States when not on policy-instructions or commitment-instructions to regular PA state
  const getPathStatesValue = path => {
    const regex = /\/states\/(\d+)\//;
    const match = path.match(regex);
    if (match) {
      return match[1]; // States value found
    } else {
      return null; // States value not found
    }
  };

  const handleUnderwriterPAStateReroute = (pathname, history) => {
    console.log("Intercepting router...");
    const pennsylvaniaIds = ["55", "56", "57", "58", "59"];
    const regularPaStateId = "39";
    const currentState = getPathStatesValue(pathname);
    if (
      pennsylvaniaIds.includes(currentState) &&
      !pathname.includes("commitment-instructions") &&
      !pathname.includes("search")
    ) {
      const newUrl = pathname.replace(`/${currentState}/`, `/${regularPaStateId}/`);
      history.replace(newUrl);
    }
  };

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      handleUnderwriterPAStateReroute(location.pathname, history);
    });

    return () => {
      unlisten();
    };
  }, []);
  ////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="tooltipBoundary">
      <div
        className={css({
          maxHeight: "100vh",
          overflow: "auto",
          ".ui.menu .item:before": {
            backgroundColor: "transparent"
          },
          ".ui.left.visible.sidebar, .ui.right.visible.sidebar": {
            boxShadow: "none",
            borderTop: "none",
            borderBottom: "none",
            borderLeft: "none"
          },
          ".ui.labeled.icon.menu .item": {
            marginBottom: "8px"
          }
        })}
      >
        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            icon="labeled"
            vertical
            visible
            width="thin"
            className={css({
              backgroundColor: `${Colors.E8E8EA} !important`,
              paddingTop: 17
            })}
          >
            <Menu.Item
              as={NavLink}
              to={`/`}
              className={css({
                backgroundColor: "#e0f7fa !important",
                paddingTop: 10,
                marginBottom: "24px !important",
                "&:hover": {
                  opacity: 0.8
                }
              })}
            >
              <Icon as={LogoIcon} />
            </Menu.Item>
            <Popup
              content="Search"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/search`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-search" />
                </Menu.Item>
              }
            />
            <Popup
              content="Info"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/info`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-info" />
                </Menu.Item>
              }
            />
            <Popup
              content="Contacts"
              size="mini"
              trigger={
                <Menu.Item id="contacts" as={NavLink} to={`/contacts`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-users" />
                </Menu.Item>
              }
            />
            <Popup
              content="Order Entry Instructions"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/order-entry-instructions`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-tag" />
                  <span className={labelStyles}>OE</span>
                </Menu.Item>
              }
            />
            <Popup
              content="Commitment Instructions"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/commitment-instructions`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-handshake" />
                  <span className={labelStyles}>CI</span>
                </Menu.Item>
              }
            />
            <Popup
              content="Policy Instructions"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/policy-instructions`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-shield-check" />
                  <span className={labelStyles}>PI</span>
                </Menu.Item>
              }
            />
            <Popup
              content="Surveys"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/surveys`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-poll-h" />
                  <span className={labelStyles}>S</span>
                </Menu.Item>
              }
            />
            <Popup
              content="Forms"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/forms`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-folder" />
                </Menu.Item>
              }
            />
            <Popup
              content="Rates"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/rates`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-dollar-sign" />
                </Menu.Item>
              }
            />
            <Popup
              content="Websites"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/websites`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-desktop" />
                </Menu.Item>
              }
            />
            <Popup
              content="Training Media"
              size="mini"
              trigger={
                <Menu.Item as={NavLink} to={`/training`} activeClassName={css(activeStyles)}>
                  <Icon className="fal fa-video" />
                </Menu.Item>
              }
            />
            {(user.isOwner || user.isOwnerPlus) && (
              <Popup
                content="Admin"
                size="mini"
                trigger={
                  <Menu.Item as={NavLink} to={`/admin`} activeClassName={css(activeStyles)}>
                    <Icon className="fal fa-tools" />
                  </Menu.Item>
                }
              />
            )}
            <Popup
              content="Index"
              size="mini"
              trigger={
                <Menu.Item
                  as={NavLink}
                  to={`/index`}
                  activeClassName={css(activeStyles)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <span style={{ fontSize: 12 }}>Index</span>
                </Menu.Item>
              }
            />
            <Menu.Item style={{ bottom: 0, position: "absolute", marginBottom: 16 }}>
              <div
                className={css({
                  color: "#999fb6",
                  fontFamily: "FaktSlab-Bold",
                  paddingTop: 13
                })}
              >
                {user.givenName && user.givenName.charAt(0)}
                {user.surname && user.surname.charAt(0)}
              </div>
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher>
            <div
              id="top"
              className={css({
                width: "calc(100vw - 73px)",
                overflowY: "auto"
              })}
            >
              <div
                style={{
                  float: "right",
                  marginRight: 40,
                  marginTop: 40
                }}
              >
                <Button style={{ marginRight: 0 }} onClick={() => logout()}>
                  Logout
                </Button>
              </div>

              <div
                className={css({
                  height: "100vh",
                  padding: 40,
                  maxWidth: "1400px"
                })}
              >
                <div
                  className={css({
                    height: "100vh",
                    padding: 40,
                    maxWidth: "1400px"
                  })}
                >
                  {children}
                  <div style={{ paddingBottom: 20 }}></div>
                </div>
              </div>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <ScrollButton />
      </div>
    </div>
  );
}

export default withRouter(Layout);
