import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, Input, Message } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../helpers/analyticsLogger";

export function EditAddTitleCompany({ match, history }) {
  const defaultTitleCompany = {
    name: undefined
  };
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    titleCompany: defaultTitleCompany,
    editMode: match.path === "/admin/edit-title-company/:id",
    errorList: []
  });

  async function getTitleCompanyById(id) {
    const result = await getRequest(`/titleCompany/getTitleCompanyById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        titleCompany: result.data
      }));
    }
  }

  useEffect(() => {
    state.editMode && getTitleCompanyById(match.params.id);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/titleCompany/${
            state.editMode ? "editTitleCompany" : "addTitleCompany"
          }`,
          {
            ...state.titleCompany
          }
        );
        if (result.success) {
          logEvent(null, state.editMode ? "Edited" : "Added", "Title Company");
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.titleCompany.name) {
      list.push("You must provide a name.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.titleCompany]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Title Company`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Name</label>
                <Input
                  placeholder="Name"
                  size="large"
                  value={state.titleCompany.name}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      titleCompany: { ...state.titleCompany, name: value }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    (state.titleCompany.name === "" ||
                      state.titleCompany.name === undefined)
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddTitleCompany);
