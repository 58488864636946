import React, { useState, useEffect } from "react";
import { Menu } from "semantic-ui-react";
import Underwriters from "./Underwriters";
import TitleCompanies from "./TitleCompanies";

export default function Admin({ history, match: { path } }) {
  const [state, setState] = useState({
    selectedSection: null,
    initialLoading: true,
    noResults: false,
    sections: [
      { name: "Underwriters", path: "underwriters" },
      { name: "Title Companies", path: "title-companies" }
    ]
  });

  useEffect(() => {
    const selectedSection = path.split("/")[2];
    setState({
      ...state,
      selectedSection: selectedSection || state.sections[0].path,
      initialLoading: false
    });
  }, [path]);

  return (
    <React.Fragment>
      <Menu tabular style={{ marginTop: 55, marginBottom: 32 }}>
        <Menu.Item
          style={{ marginLeft: 21 }}
          name="Underwriters"
          active={state.selectedSection === "underwriters"}
          onClick={() => history.push(`/admin/underwriters`)}
        />
        <Menu.Item
          name="Title Companies"
          active={state.selectedSection === "title-companies"}
          onClick={() => history.push(`/admin/title-companies`)}
        />
      </Menu>
      {state.selectedSection === "underwriters" && <Underwriters />}
      {state.selectedSection === "title-companies" && <TitleCompanies />}
    </React.Fragment>
  );
}
