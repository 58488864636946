import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Menu } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import ScheduleA from "./ScheduleA";
import ScheduleBI from "./ScheduleBI";
import ScheduleBII from "./ScheduleBII";
import GeneralInfo from "./GeneralInfo";
import pathToRegexp from "path-to-regexp";
import StateDropdown from "../../StateDropdown";

export function CommitmentInstructions({ history, match: { params }, match }) {
  const { selectedState } = useContext(StateContext);
  if (!params.state || params.state === "no_state") {
    history.replace(
      `/commitment-instructions/states/${selectedState ||
        31}/sections/${params.section || "schedule-a"}`
    );
  }
  const [state, setState] = useState({
    selectedSection: params.section || "schedule-a",
  });

  useEffect(() => {
    setState({
      ...state,
      selectedSection: params.section || "schedule-a",
      initialLoading: false,
    });
  }, [params.section]);

  return (
    <div className={css({ marginBottom: 40 })}>
      <StateDropdown
        pushHistory={(value) => {
          const toPath = pathToRegexp.compile(match.path);
          const newPath = toPath({ ...match.params, state: value });
          history.push(newPath);
        }}
      />
      <Menu tabular style={{ marginTop: 55, marginBottom: 32 }}>
        <Menu.Item
          style={{ marginLeft: 21 }}
          name="Schedule A"
          active={state.selectedSection === "schedule-a"}
          onClick={() =>
            history.push(
              `/commitment-instructions/states/${selectedState}/sections/schedule-a`
            )
          }
        />
        {selectedState !== 44 && (
          <React.Fragment>
            <Menu.Item
              name="Schedule BI"
              active={state.selectedSection === "schedule-bi"}
              onClick={() =>
                history.push(
                  `/commitment-instructions/states/${selectedState}/sections/schedule-bi`
                )
              }
            />
            <Menu.Item
              name="Schedule BII"
              active={state.selectedSection === "schedule-bii"}
              onClick={() =>
                history.push(
                  `/commitment-instructions/states/${selectedState}/sections/schedule-bii`
                )
              }
            />
          </React.Fragment>
        )}
        {selectedState === 44 && (
          <React.Fragment>
            <Menu.Item
              name="Schedule B"
              active={state.selectedSection === "schedule-bii"}
              onClick={() =>
                history.push(
                  `/commitment-instructions/states/${selectedState}/sections/schedule-bii`
                )
              }
            />
            <Menu.Item
              name="Schedule C"
              active={state.selectedSection === "schedule-bi"}
              onClick={() =>
                history.push(
                  `/commitment-instructions/states/${selectedState}/sections/schedule-bi`
                )
              }
            />
          </React.Fragment>
        )}
        <Menu.Item
          name="General Info"
          active={state.selectedSection === "general-info"}
          onClick={() =>
            history.push(
              `/commitment-instructions/states/${selectedState}/sections/general-info`
            )
          }
        />
      </Menu>
      {state.selectedSection === "schedule-a" && <ScheduleA />}
      {state.selectedSection === "schedule-bi" && <ScheduleBI />}
      {state.selectedSection === "schedule-bii" && <ScheduleBII />}
      {state.selectedSection === "general-info" && <GeneralInfo />}
    </div>
  );
}

export default withRouter(CommitmentInstructions);
