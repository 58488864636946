import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Input,
  Message,
  TextArea
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import shortid from "shortid";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddStateIdiosyncrasy({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const defaultIdiosyncrasy = {
    subject: undefined,
    note: undefined,
    reasonForEdit: undefined
  };
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    idiosyncrasy: defaultIdiosyncrasy,
    editMode: match.path === "/info/edit-state-idiosyncrasy/:id",
    files: [],
    filesToDelete: [],
    errorList: []
  });

  async function getStateIdiosyncrasyById(id) {
    const result = await getRequest(
      `/stateIdiosyncrasy/getStateIdiosyncrasyById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        idiosyncrasy: result.data
      }));
    }
  }

  useEffect(() => {
    state.editMode && getStateIdiosyncrasyById(match.params.id);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/stateIdiosyncrasy/${
            state.editMode ? "editStateIdiosyncrasy" : "addStateIdiosyncrasy"
          }`,
          {
            ...state.idiosyncrasy,
            stateId: selectedState,
            files: await Promise.all(
              state.files.map(async (f) => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            filesToDelete: state.filesToDelete
          }
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "State Idiosyncrasies"
          );
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.idiosyncrasy.note) {
      list.push("You must provide a note.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.idiosyncrasy]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} State Idiosyncrasy`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  size="large"
                  value={state.idiosyncrasy.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      idiosyncrasy: { ...state.idiosyncrasy, subject: value }
                    }))
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  value={state.idiosyncrasy.note}
                  style={{ minHeight: 192 }}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      idiosyncrasy: {
                        ...state.idiosyncrasy,
                        note: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    (state.idiosyncrasy.subject === "" ||
                      state.idiosyncrasy.subject === undefined)
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={(files) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files, ...files]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files.filter((f) => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.idiosyncrasy.stateIdiosyncrasyFiles &&
                state.idiosyncrasy.stateIdiosyncrasyFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        idiosyncrasy: {
                          ...state.idiosyncrasy,
                          stateIdiosyncrasyFiles: [
                            ...state.idiosyncrasy.stateIdiosyncrasyFiles.filter(
                              (rf) => rf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          {state.editMode && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>Reason for edit</label>
                  <TextArea
                    placeholder="Reason for edit"
                    value={state.idiosyncrasy.reasonForEdit}
                    style={{ minHeight: 96 }}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        idiosyncrasy: {
                          ...state.idiosyncrasy,
                          reasonForEdit: value
                        }
                      }))
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddStateIdiosyncrasy);
