import React from "react";
import TaxCard from "./TaxCard";
import { css } from "emotion";
import { Highlight } from "../../search/Highlight";
import { getRequest } from "../../../helpers/ApiHelpers";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import { getAnswer } from "../../../helpers/GetAnswer";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap"
});
const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 14,
  marginTop: 24
});

const checkIfEmpty = (bool, string) => {
  return (
    bool === true ||
    bool === false ||
    (string !== undefined && string !== null && string.length > 0)
  );
};

const dashNote = (bool, string) => {
  return (
    (bool === true || bool === false) &&
    string !== undefined &&
    string !== null &&
    string.length > 0
  );
};

async function download(id) {
  const result = await getRequest(`/tax/downloadMortgageTaxFile?id=${id}`);
  if (result.success) {
    convertToFileDownload(result.data.base64File, result.data.name);
  }
}

function MortgageTax({ tax, editRoute }) {
  return (
    <TaxCard
      title="Mortgage Tax"
      isApplicable={tax.isApplicable}
      editRoute={editRoute}
    >
      <p className={paragraphStyles}>
        <Highlight children={tax.isApplicableNote} />
      </p>
      {checkIfEmpty(
        tax.isExemptOfAssumptionOfMortgage,
        tax.isExemptOfAssumptionOfMortgageNote
      ) && (
        <React.Fragment>
          <div className={labelStyles}>
            Is the Assumption of Mortgage exempt from tax?
          </div>
          <p className={paragraphStyles}>
            {getAnswer(tax.isExemptOfAssumptionOfMortgage)}
            {dashNote(
              tax.isExemptOfAssumptionOfMortgage,
              tax.isExemptOfAssumptionOfMortgageNote
            ) && " - "}
            <Highlight children={tax.isExemptOfAssumptionOfMortgageNote} />
          </p>
        </React.Fragment>
      )}
      {checkIfEmpty(
        tax.isExemptOfAssignmentOfMortgage,
        tax.isExemptOfAssignmentOfMortgageNote
      ) && (
        <React.Fragment>
          <div className={labelStyles}>
            Is the Assignment of Mortgage exempt from tax?
          </div>
          <p className={paragraphStyles}>
            {getAnswer(tax.isExemptOfAssignmentOfMortgage)}
            {dashNote(
              tax.isExemptOfAssignmentOfMortgage,
              tax.isExemptOfAssignmentOfMortgageNote
            ) && " - "}
            <Highlight children={tax.isExemptOfAssignmentOfMortgageNote} />
          </p>
        </React.Fragment>
      )}
      {tax.mortgageTaxFiles && tax.mortgageTaxFiles.length > 0 && (
        <div className={labelStyles}>Attachments</div>
      )}
      {tax.mortgageTaxFiles &&
        tax.mortgageTaxFiles.map(f => (
          <p className={paragraphStyles} key={f.id}>
            <a
              onClick={() => download(f.id)}
              className={css({
                "&:hover": { textDecoration: "underline", color: "#00b8d4" }
              })}
            >
              <i
                className="fal fa-paperclip"
                style={{ color: "#00b8d4", marginRight: 8 }}
              />
              <Highlight children={f.fileName} />
            </a>
          </p>
        ))}
    </TaxCard>
  );
}

export default React.memo(MortgageTax);
