import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Input,
  Dropdown,
  Message,
  Radio
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import { logEvent } from "../../../helpers/analyticsLogger";

const defaultNote = {
  subject: null,
  scheduleANoteTypeId: null
};

export function EditAddScheduleANote({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    vestingType: match.path.includes("edit")
      ? null
      : "Vesting: Full vesting on Schedule A",
    scheduleANoteTypes: [],
    scheduleANote: defaultNote,
    editMode: match.path.includes("edit"),
    errorList: [],
    files: [],
    filesToAdd: [],
    filesToDelete: []
  });

  async function getScheduleANoteById(id) {
    const result = await getRequest(`/scheduleA/getScheduleANoteById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        scheduleANote: result.data
      }));
    }
  }

  async function getScheduleANoteTypes() {
    const result = await getRequest(`/scheduleA/getScheduleANoteTypes`);
    if (result.success) {
      setState((state) => ({
        ...state,
        scheduleANoteTypes: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  function getErrorList() {
    let list = [];
    if (
      state.scheduleANote.scheduleANoteTypeId !== 2 &&
      (state.scheduleANote.subject === null ||
        state.scheduleANote.subject === "")
    ) {
      list.push("You must provide a subject.");
    }
    if (
      state.scheduleANote.scheduleANoteTypeId === null ||
      state.scheduleANote.scheduleANoteTypeId === ""
    ) {
      list.push("You must provide a note type.");
    }
    return list;
  }

  useEffect(() => {
    getScheduleANoteTypes();
    if (state.editMode) {
      getScheduleANoteById(match.params.id);
    }
  }, []);

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [
    state.scheduleANote.subject,
    state.scheduleANote.scheduleANoteTypeId,
    state.submitAttempted
  ]);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true
      }));
      if (!state.errorList.length > 0) {
        setState((state) => ({ ...state, submitting: true }));
        const result = await postRequest(`/scheduleA/editAddScheduleANote`, {
          ...state.scheduleANote,
          stateId: state.editMode
            ? state.scheduleANote.stateId
            : +match.params.state,
          subject:
            state.scheduleANote.scheduleANoteTypeId === 2
              ? state.vestingType
              : state.scheduleANote.subject,
          filesToAdd: await Promise.all(
            state.filesToAdd.map(async (f) => ({
              base64File: await readFileAsBase64(f),
              fileName: f.name
            }))
          ),
          filesToDelete: state.filesToDelete
        });
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Schedule A Note"
          );
          history.goBack();
        }
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Schedule A Note`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Type</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select Type"
                  style={{ fontSize: 16 }}
                  options={state.scheduleANoteTypes}
                  value={state.scheduleANote.scheduleANoteTypeId}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      scheduleANote: {
                        ...state.scheduleANote,
                        scheduleANoteTypeId: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    state.scheduleANote.scheduleANoteTypeId === null
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {state.scheduleANote.scheduleANoteTypeId === 2 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <Radio
                    label="Vesting: Full vesting on Schedule A"
                    name="radioGroup"
                    value="this"
                    checked={
                      state.vestingType ===
                        "Vesting: Full vesting on Schedule A" ||
                      state.scheduleANote.subject ===
                        "Vesting: Full vesting on Schedule A"
                    }
                    onChange={() =>
                      setState((state) => ({
                        ...state,
                        vestingType: "Vesting: Full vesting on Schedule A",
                        scheduleANote: {
                          ...state.scheduleANote,
                          subject: null
                        }
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label="Vesting: Name only on Schedule A"
                    name="radioGroup"
                    value="that"
                    checked={
                      state.vestingType ===
                        "Vesting: Name only on Schedule A" ||
                      state.scheduleANote.subject ===
                        "Vesting: Name only on Schedule A"
                    }
                    onChange={() =>
                      setState((state) => ({
                        ...state,
                        vestingType: "Vesting: Name only on Schedule A",
                        scheduleANote: {
                          ...state.scheduleANote,
                          subject: null
                        }
                      }))
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          {state.scheduleANote.scheduleANoteTypeId !== 2 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field required>
                  <label>Subject</label>
                  <Input
                    placeholder="Subject"
                    value={state.scheduleANote.subject}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        scheduleANote: {
                          ...state.scheduleANote,
                          subject: value
                        }
                      }))
                    }
                    error={
                      state.submitAttempted &&
                      state.scheduleANote.subject === null
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.scheduleANote.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      scheduleANote: {
                        ...state.scheduleANote,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.filesToAdd}
                  setFileToApp={(filesToAdd) =>
                    setState((state) => ({
                      ...state,
                      filesToAdd: [...state.filesToAdd, ...filesToAdd]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      filesToAdd: [
                        ...state.filesToAdd.filter((f) => f.name !== fileName)
                      ]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.scheduleANote.files &&
                state.scheduleANote.files.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        scheduleANote: {
                          ...state.scheduleANote,
                          files: [
                            ...state.scheduleANote.files.filter(
                              (nf) => nf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddScheduleANote);
