import React from "react";
import { css } from "emotion";

export const ClickToView = () => {
  return (
    <div className={css({ color: "red", fontSize: 12 })}>
      (Click text to view)
    </div>
  );
};
