import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  TextArea,
  Input,
  Checkbox
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddRemittanceTargetNote({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    remittanceTargetNote: {},
    editMode: match.path.includes("edit"),
    additional: match.path.includes("split"),
    under5M: !match.path.includes("additional"),
    over5M: match.path.includes("additional-split"),
    over10M: match.path.includes("edit-additional-over-ten-m"),
    over15M: match.path.includes("edit-additional-over-fifteen-m"),
    over25M: match.path.includes("edit-additional-over-twenty-five-m"),
    errorList: []
  });

  async function getRemittanceTargetNoteById(id) {
    const result = await getRequest(
      `/remittanceTarget/getRemittanceTargetNoteById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargetNote: result.data
      }));
    }
  }

  async function getAdditionalRemittanceTargetNoteById(id) {
    const result = await getRequest(
      `/additionalRemittanceTarget/getAdditionalRemittanceTargetNoteById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargetNote: result.data
      }));
    }
  }

  async function getAdditionalOverTenMRemittanceTargetNoteById(id) {
    const result = await getRequest(
      `/additionalOverTenMRemittanceTarget/getAdditionalOverTenMRemittanceTargetNoteById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargetNote: result.data
      }));
    }
  }

  async function getAdditionalOverFifteenMRemittanceTargetNoteById(id) {
    const result = await getRequest(
      `/additionalOverFifteenMRemittanceTarget/getAdditionalOverFifteenMRemittanceTargetNoteById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargetNote: result.data
      }));
    }
  }

  async function getAdditionalOverTwentyFiveMRemittanceTargetNoteById(id) {
    const result = await getRequest(
      `/additionalOverTwentyFiveMRemittanceTarget/getAdditionalOverTwentyFiveMRemittanceTargetNoteById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargetNote: result.data
      }));
    }
  }

  useEffect(() => {
    if (state.editMode) {
      state.additional
        ? state.over5M
          ? getAdditionalRemittanceTargetNoteById(match.params.id)
          : state.over10M
          ? getAdditionalOverTenMRemittanceTargetNoteById(match.params.id)
          : state.over15M
          ? getAdditionalOverFifteenMRemittanceTargetNoteById(match.params.id)
          : state.over25M
          ? getAdditionalOverTwentyFiveMRemittanceTargetNoteById(
              match.params.id
            )
          : getRemittanceTargetNoteById(match.params.id)
        : getRemittanceTargetNoteById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          state.additional
            ? state.over5M
              ? `/additionalRemittanceTarget/editAddAdditionalRemittanceTargetNote`
              : state.over10M
              ? `/additionalOverTenMRemittanceTarget/editAddAdditionalOverTenMRemittanceTargetNote`
              : state.over15M
              ? `/additionalOverFifteenMRemittanceTarget/editAddAdditionalOverFifteenMRemittanceTargetNote`
              : state.over25M
              ? `/additionalOverTwentyFiveMRemittanceTarget/editAddAdditionalOverTwentyFiveMRemittanceTargetNote`
              : `/remittanceTarget/editAddRemittanceTargetNote`
            : `/remittanceTarget/editAddRemittanceTargetNote`,
          {
            ...state.remittanceTargetNote,
            stateId: match.params.id
          }
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Remittance Target Note"
          );
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.remittanceTargetNote.note) {
      list.push("You must provide a note.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.remittanceTargetNote]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Remittance Target Note`}
      </Header>
      <Form>
        <Grid>
          {state.additional && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <Checkbox
                    label="0-5 M"
                    checked={state.under5M}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        under5M: true,
                        over5M: false,
                        over10M: false,
                        over15M: false,
                        over25M: false
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="5-10 M"
                    checked={state.over5M}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        under5M: false,
                        over5M: true,
                        over10M: false,
                        over15M: false,
                        over25M: false
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="10-15 M"
                    checked={state.over10M}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        under5M: false,
                        over5M: false,
                        over10M: true,
                        over15M: false,
                        over25M: false
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="15-25 M"
                    checked={state.over15M}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        under5M: false,
                        over5M: false,
                        over10M: false,
                        over15M: true,
                        over25M: false
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="25+ M"
                    checked={state.over25M}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        under5M: false,
                        over5M: false,
                        over10M: false,
                        over15M: false,
                        over25M: true
                      }))
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.remittanceTargetNote.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      remittanceTargetNote: {
                        ...state.remittanceTargetNote,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.remittanceTargetNote.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      remittanceTargetNote: {
                        ...state.remittanceTargetNote,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddRemittanceTargetNote);
