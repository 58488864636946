import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  Input,
  TextArea,
  Checkbox,
  Dropdown
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddHelpfulWording({ match, history }) {
  const [productTypes, setProductTypes] = useState([]);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    helpfulWording: {
      helpfulWordingCategoryId: 1,
      helpfulWordingStates:
        match.path !== "/order-entry-instructions/edit-helpful-wording/:id"
          ? [
              {
                helpfulWordingId: 0,
                stateId: +match.params.id
              }
            ]
          : []
    },
    editMode:
      match.path === "/order-entry-instructions/edit-helpful-wording/:id",
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    errorList: [],
    helpfulWordingCategories: [],
    productType: []
  });

  async function getHelpfulWordingById(id) {
    const result = await getRequest(`/helpfulWording/getHelpfulWordingById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWording: result.data
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allLicensedStatesList: result.data
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function getHelpfulWordingCategories() {
    const result = await getRequest(
      "/helpfulWording/getHelpfulWordingCategories"
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWordingCategories: result.data
      }));
    }
  }

  async function getProductTypes() {
    const result = await getRequest("/helpfulWording/getProductTypes");
    if (result.success) {
      setProductTypes(
        result.data.map((value) => ({
          text: value,
          value
        }))
      );
    }
  }

  useEffect(() => {
    getHelpfulWordingCategories();
    getStates();
    getAllStatesList();
    getAllLicensedStatesList();
    getAllUnlicensedStatesList();
    if (state.editMode) {
      getHelpfulWordingById(match.params.id);
    }
    getProductTypes();
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/helpfulWording/${
            state.editMode ? "editHelpfulWording" : "addHelpfulWording"
          }`,
          state.helpfulWording
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Helpful Wordings"
          );
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.helpfulWording.subject) {
      list.push("You must provide a subject.");
    }
    if (state.helpfulWording.helpfulWordingStates.length < 1) {
      list.push("You must select at least one state.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.helpfulWording, state.helpfulWording.helpfulWordingStates]);

  useEffect(() => {
    if (state.editMode && state.helpfulWording.id) {
      let checkIfContainsAll = (arr, target) =>
        target.every((v) => arr.includes(v));
      setState((state) => ({
        ...state,
        allStates: checkIfContainsAll(
          state.helpfulWording.helpfulWordingStates.length > 0
            ? state.helpfulWording.helpfulWordingStates.map((ws) => ws.stateId)
            : [],
          state.allStatesList
        ),
        allLicensedStates:
          checkIfContainsAll(
            state.helpfulWording.helpfulWordingStates.length > 0
              ? state.helpfulWording.helpfulWordingStates.map(
                  (ws) => ws.stateId
                )
              : [],
            state.allLicensedStatesList
          ) &&
          state.helpfulWording.helpfulWordingStates.length ===
            state.allLicensedStatesList.length,
        allUnlicensedStates:
          checkIfContainsAll(
            state.helpfulWording.helpfulWordingStates.length > 0
              ? state.helpfulWording.helpfulWordingStates.map(
                  (ws) => ws.stateId
                )
              : [],
            state.allUnlicensedStatesList
          ) &&
          state.helpfulWording.helpfulWordingStates.length ===
            state.allUnlicensedStatesList.length
      }));
    } else {
      return;
    }
  }, [
    state.helpfulWording.id,
    state.allStatesList,
    state.allLicensedStatesList,
    state.allUnlicensedStatesList
  ]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      customStates:
        !state.allLicensedStates &&
        !state.allUnlicensedStates &&
        !state.allStates
    }));
  }, [state.allStates, state.allUnlicensedStates, state.allLicensedStates]);

  function buildHelpfulWordingStates(value) {
    return value.map((v) => ({
      helpfulWordingId: 0,
      stateId: v
    }));
  }

  const categoryIsSearch = state.helpfulWording.helpfulWordingCategoryId === 4;

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Helpful Wording`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                {state.helpfulWordingCategories.map((c) => (
                  <Form.Field>
                    <Checkbox
                      label={c.name}
                      checked={
                        state.helpfulWording.helpfulWordingCategoryId === c.id
                      }
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          helpfulWording: {
                            ...state.helpfulWording,
                            helpfulWordingCategoryId: c.id
                          }
                        }))
                      }
                    />
                  </Form.Field>
                ))}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {categoryIsSearch && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field required>
                  <label>Product Types</label>
                  <Dropdown
                    size="large"
                    fluid
                    selection
                    placeholder="Select Product"
                    style={{ fontSize: 16 }}
                    options={productTypes}
                    value={state.helpfulWording.productType}
                    onChange={(_, { value }) => {
                      setState({
                        ...state,
                        helpfulWording: {
                          ...state.helpfulWording,
                          productType: value
                        }
                      });
                    }}
                    error={
                      state.submitAttempted && !state.helpfulWording.productType
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>{categoryIsSearch ? "Search Name" : "Subject"}</label>
                <Input
                  placeholder={categoryIsSearch ? "Search Name" : "Subject"}
                  value={state.helpfulWording.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>{categoryIsSearch ? "Instructions" : "Body"}</label>
                <TextArea
                  placeholder={categoryIsSearch ? "Instructions" : "Body"}
                  style={{ minHeight: 292 }}
                  value={state.helpfulWording.body}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        body: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 192 }}
                  value={state.helpfulWording.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="All states"
                  checked={state.allStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: true,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: false,
                      helpfulWording: {
                        ...state.helpfulWording,
                        helpfulWordingStates: buildHelpfulWordingStates(
                          state.allStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All licensed states"
                  checked={state.allLicensedStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: true,
                      allUnlicensedStates: false,
                      customStates: false,
                      helpfulWording: {
                        ...state.helpfulWording,
                        helpfulWordingStates: buildHelpfulWordingStates(
                          state.allLicensedStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All unlicensed states"
                  checked={state.allUnlicensedStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: true,
                      customStates: false,
                      helpfulWording: {
                        ...state.helpfulWording,
                        helpfulWordingStates: buildHelpfulWordingStates(
                          state.allUnlicensedStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Custom (choose your own state selection)"
                  checked={state.customStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: true
                    }))
                  }
                />
              </Form.Field>
              {state.customStates && (
                <Form.Field required>
                  <label>States</label>
                  <Dropdown
                    size="large"
                    fluid
                    multiple
                    selection
                    placeholder="Select States"
                    style={{ fontSize: 16 }}
                    options={state.states}
                    value={
                      state.helpfulWording.helpfulWordingStates
                        ? state.helpfulWording.helpfulWordingStates.map(
                            (ws) => ws.stateId
                          )
                        : null
                    }
                    onChange={(_, { value }) => {
                      setState((state) => ({
                        ...state,
                        helpfulWording: {
                          ...state.helpfulWording,
                          helpfulWordingStates:
                            value[value.length - 1] === 52
                              ? buildHelpfulWordingStates([52])
                              : buildHelpfulWordingStates(
                                  value.filter((v) => v !== 52)
                                )
                        }
                      }));
                    }}
                    error={
                      state.submitAttempted &&
                      !state.helpfulWording.helpfulWordingStates.length > 0
                    }
                  />
                </Form.Field>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddHelpfulWording);
