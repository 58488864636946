import React, { useState, useEffect, useContext } from "react";
import { Menu } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { css } from "emotion";
import { StateContext } from "../../State";
import pathToRegexp from "path-to-regexp";
import StateDropdown from "../../StateDropdown";
import PolicySurveyNotes from "./PolicySurveyNotes";
import SurveyScheduleB from "./SurveyScheduleB";
import SurveyLegalDescriptionNotes from "./SurveyLegalDescriptionNotes";
import HelpfulWordings from "./HelpfulWordings";

export function Surveys({ history, match: { params }, match }) {
  const { selectedState } = useContext(StateContext);
  if (
    !params.state ||
    params.state === "no_state" ||
    (params.state != "52" && params.tab)
  ) {
    history.replace(`/surveys/states/${selectedState || 31}`);
  }
  if (params.state === "52" && !params.tab) {
    history.replace(`/surveys/states/${selectedState || 31}/tabs/general-info`);
  }

  const [state, setState] = useState({
    selectedSection: params.tab || "general-info",
    selectedUnderwriter: params.underwriterId || 1
  });

  useEffect(() => {
    setState({
      ...state,
      selectedSection: params.tab
    });
  }, [params.tab]);

  useEffect(() => {
    setState({
      ...state,
      selectedUnderwriter: params.underwriterId || 1
    });
  }, [params.underwriterId]);

  return (
    <React.Fragment>
      <div className={css({ marginBottom: 48 })}>
        <StateDropdown
          pushHistory={(value) => {
            const toPath = pathToRegexp.compile(match.path);
            const newPath = toPath({ ...match.params, state: value });
            history.push(newPath);
          }}
        />
        {match.params.state === "52" && (
          <Menu tabular style={{ marginTop: 55, marginBottom: 32 }}>
            <Menu.Item
              style={{ marginLeft: 21 }}
              name="General Info"
              active={state.selectedSection === "general-info"}
              onClick={() =>
                history.push(
                  `/surveys/states/${selectedState}/tabs/general-info`
                )
              }
            />
            <Menu.Item
              name="Legal Description"
              active={state.selectedSection === "legal-descriptions"}
              onClick={() =>
                history.push(
                  `/surveys/states/${selectedState}/tabs/legal-descriptions`
                )
              }
            />
            <Menu.Item
              name="Schedule B"
              active={state.selectedSection === "schedule-b"}
              onClick={() =>
                history.push(`/surveys/states/${selectedState}/tabs/schedule-b`)
              }
            />
            <Menu.Item
              name="Helpful Wordings"
              active={state.selectedSection === "helpful-wordings"}
              onClick={() =>
                history.push(
                  `/surveys/states/${selectedState}/tabs/helpful-wordings/categories/1`
                )
              }
            />
          </Menu>
        )}
        {state.selectedSection === "general-info" && <PolicySurveyNotes />}
        {state.selectedSection === "schedule-b" && <SurveyScheduleB />}
        {state.selectedSection === "legal-descriptions" && (
          <SurveyLegalDescriptionNotes />
        )}
        {state.selectedSection === "helpful-wordings" && <HelpfulWordings />}
      </div>
    </React.Fragment>
  );
}

withRouter(Surveys);
