import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  TextArea,
  Input,
  Checkbox,
  Dropdown
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddAgencyAgreementNote({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    agencyAgreementNote: {
      agencyAgreementNoteStates: [
        { agencyAgreementNoteId: 0, stateId: +match.params.state }
      ]
    },
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    editMode: match.path === "/rates/edit-agency-agreement-note/:id",
    errorList: []
  });

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allLicensedStatesList: result.data
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getNoteById(id) {
    const result = await getRequest(`/agencyAgreement/getNoteById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        agencyAgreementNote: result.data
      }));
    }
  }

  useEffect(() => {
    getStates();
    getAllStatesList();
    getAllLicensedStatesList();
    getAllUnlicensedStatesList();
    if (state.editMode) {
      getNoteById(+match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/agencyAgreement/${state.editMode ? "editNote" : "addNote"}`,
          {
            ...state.agencyAgreementNote,
            stateId: selectedState
          }
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Agency Agreement"
          );
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.agencyAgreementNote.agencyAgreementNoteStates.length > 0) {
      list.push("You must select at least one state.");
    }
    return list;
  }

  useEffect(() => {
    if (state.editMode) {
      let checkIfContainsAll = (arr, target) =>
        target.every((v) => arr.includes(v));
      setState((state) => ({
        ...state,
        allStates: checkIfContainsAll(
          state.agencyAgreementNote.agencyAgreementNoteStates.length > 0
            ? state.agencyAgreementNote.agencyAgreementNoteStates.map(
                (cs) => cs.stateId
              )
            : [],
          state.allStatesList
        ),
        allLicensedStates:
          checkIfContainsAll(
            state.agencyAgreementNote.agencyAgreementNoteStates.length > 0
              ? state.agencyAgreementNote.agencyAgreementNoteStates.map(
                  (cs) => cs.stateId
                )
              : [],
            state.allLicensedStatesList
          ) &&
          state.agencyAgreementNote.agencyAgreementNoteStates.length ===
            state.allLicensedStatesList.length,
        allUnlicensedStates:
          checkIfContainsAll(
            state.agencyAgreementNote.agencyAgreementNoteStates.length > 0
              ? state.agencyAgreementNote.agencyAgreementNoteStates.map(
                  (cs) => cs.stateId
                )
              : [],
            state.allUnlicensedStatesList
          ) &&
          state.agencyAgreementNote.agencyAgreementNoteStates.length ===
            state.allUnlicensedStatesList.length
      }));
    } else {
      return;
    }
  }, [
    state.agencyAgreementNote.id,
    state.allStatesList,
    state.allLicensedStatesList,
    state.allUnlicensedStatesList
  ]);

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [
    state.agencyAgreementNote.note,
    state.agencyAgreementNote.agencyAgreementNoteStates,
    state.submitAttempted
  ]);

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.agencyAgreementNote]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      customStates:
        !state.allLicensedStates &&
        !state.allUnlicensedStates &&
        !state.allStates
    }));
  }, [state.allStates, state.allUnlicensedStates, state.allLicensedStates]);

  function buildNoteStates(value) {
    return value.map((v) => ({
      agencyAgreementId: 0,
      stateId: v
    }));
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Agency Agreement Note`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.agencyAgreementNote.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreementNote: {
                        ...state.agencyAgreementNote,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.agencyAgreementNote.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      agencyAgreementNote: {
                        ...state.agencyAgreementNote,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="All states"
                  checked={state.allStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: true,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: false,
                      agencyAgreementNote: {
                        ...state.agencyAgreementNote,
                        agencyAgreementNoteStates: buildNoteStates(
                          state.allStatesList
                        )
                      }
                    }))
                  }
                  data-automation="allStates"
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All licensed states"
                  checked={state.allLicensedStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: true,
                      allUnlicensedStates: false,
                      customStates: false,
                      agencyAgreementNote: {
                        ...state.agencyAgreementNote,
                        agencyAgreementNoteStates: buildNoteStates(
                          state.allLicensedStatesList
                        )
                      }
                    }))
                  }
                  data-automation="allLicensedStates"
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All unlicensed states"
                  checked={state.allUnlicensedStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: true,
                      customStates: false,
                      agencyAgreementNote: {
                        ...state.agencyAgreementNote,
                        agencyAgreementNoteStates: buildNoteStates(
                          state.allUnlicensedStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Custom (choose your own state selection)"
                  checked={state.customStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: true
                    }))
                  }
                />
              </Form.Field>
              {state.customStates && (
                <Form.Field required>
                  <label>States</label>
                  <Dropdown
                    size="large"
                    fluid
                    multiple
                    selection
                    placeholder="Select States"
                    style={{ fontSize: 16 }}
                    options={state.states}
                    value={
                      state.agencyAgreementNote.agencyAgreementNoteStates
                        ? state.agencyAgreementNote.agencyAgreementNoteStates.map(
                            (ass) => ass.stateId
                          )
                        : null
                    }
                    onChange={(_, { value }) => {
                      setState((state) => ({
                        ...state,
                        agencyAgreementNote: {
                          ...state.agencyAgreementNote,
                          agencyAgreementNoteStates:
                            value[value.length - 1] === 52
                              ? buildNoteStates([52])
                              : buildNoteStates(value.filter((v) => v !== 52))
                        }
                      }));
                    }}
                    error={
                      state.submitAttempted &&
                      !state.agencyAgreementNote.agencyAgreementNoteStates
                        .length > 0
                    }
                  />
                </Form.Field>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddAgencyAgreementNote);
