import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Message, Modal } from "semantic-ui-react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../User";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { convertToFileDownload } from "../../helpers/ConvertToFileDownload";
import "reactjs-popup/dist/index.css";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap"
});
const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 14,
  marginTop: 24
});

function SystemTips({ history, match: { params } }) {
  const { user } = useContext(UserContext);
  const [systemTips, setSystemTips] = useState([]);
  const [systemTipToDelete, setSystemTipToDelete] = useState();
  const [initialLoaded, setInitialLoaded] = useState(false);

  const getAndSetSystemTips = async () => {
    const result = await getRequest("/systemTips/getAll");
    if (result.success) {
      setSystemTips(result.data);
    }
  };

  async function deleteSystemTip(id) {
    const result = await postRequest(`/systemTips/delete?id=${id}`);
    if (result.success) {
      setSystemTips(systemTips.filter((t) => t.id !== id));
      setSystemTipToDelete(null);
    }
  }

  async function download(id) {
    const result = await getRequest(
      `/systemTips/downloadSystemTipFile?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  useEffect(() => {
    getAndSetSystemTips();
    setInitialLoaded(true);
  }, []);

  return (
    <React.Fragment>
      <Grid>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column floated="left">
            <div
              className={css({
                fontFamily: "Fakt-Bold",
                fontSize: 20,
                color: "#000e48"
              })}
            >
              System Tips
            </div>
          </Grid.Column>
          {(user.isOwner || user.isOwnerPlus) && (
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                icon
                className="blueButton"
                onClick={() => history.push(`/training/new-system-tip`)}
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() => history.push(`/training/new-system-tip`)}
              >
                Add System Tips
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      <div
        className={css({
          marginBottom: 19.75,
          marginTop: 40
        })}
      >
        {systemTips &&
          systemTips.map((t) => (
            <Card fluid style={{ padding: 28 }} key={t.id}>
              <Card.Content>
                {(user.isOwner || user.isOwnerPlus) && (
                  <React.Fragment>
                    <i
                      onClick={() => setSystemTipToDelete(t)}
                      className="fal fa-trash"
                      style={{
                        position: "absolute",
                        right: 56,
                        cursor: "pointer"
                      }}
                    />
                    <i
                      onClick={() =>
                        history.push(`/training/edit-system-tip/${t.id}`)
                      }
                      className="fal fa-pencil"
                      style={{
                        position: "absolute",
                        right: 28,
                        cursor: "pointer"
                      }}
                    />
                  </React.Fragment>
                )}
                <Card.Header content={t.subject} />
                <Card.Description
                  style={{ whiteSpace: "pre-wrap" }}
                  content={t.note}
                />
                {t.files && t.files.length > 0 && (
                  <div className={labelStyles}>Attachments</div>
                )}
                {t.files &&
                  t.files.map((f) => (
                    <p className={paragraphStyles} key={f.id}>
                      <a
                        onClick={() => download(f.id)}
                        className={css({
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#00b8d4"
                          }
                        })}
                      >
                        <i
                          className="fal fa-paperclip"
                          style={{ color: "#00b8d4", marginRight: 8 }}
                        />
                        {f.fileName}
                      </a>
                    </p>
                  ))}
              </Card.Content>
            </Card>
          ))}
        {systemTips.length < 1 && initialLoaded && (
          <div className={css({ marginTop: 40 })}>
            <Message size="big">
              No <strong>system tips</strong> found
            </Message>
          </div>
        )}
      </div>
      {systemTipToDelete && (
        <Modal
          size={"tiny"}
          open={systemTipToDelete !== null}
          onClose={() => setSystemTipToDelete(null)}
          dimmer="inverted"
        >
          <Modal.Header>Delete System Tip</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => setSystemTipToDelete(null)}
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteSystemTip(systemTipToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(SystemTips);
