import React, { useState, useEffect } from "react";
import { SortableContainer, SortableElement, SortableHandle, arrayMove } from "react-sortable-hoc";
import { Table, Icon } from "semantic-ui-react";
import { css } from "emotion";
import { postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import CustomTable from "../../CustomTable";
import LinesEllipsis from "react-lines-ellipsis";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { Highlight } from "../../search/Highlight";
import { Popup } from "../../Popup";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import { getRequest } from "../../../helpers/ApiHelpers";
import { ClickToView } from "../../ClickToView";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  fontWeight: 300,
  cursor: "pointer"
});

const DragHandle = SortableHandle(() => (
  <span
    className={css({
      position: "absolute",
      left: 16,
      color: "#c3c3c3",
      cursor: "grab"
    })}
  >
    <i className="fal fa-sort" />
  </span>
));

function DragSortCodes({ items, saveSortPath, history, draggable, type, deleteAction, searchMode, downloadUrl }) {
  const { addToast } = useToasts();
  const [state, setState] = useState({
    items: items,
    sorted: false
  });
  const [initialized, setInitialized] = useState(false);

  async function saveSort() {
    postRequest(saveSortPath, state.items);
  }

  async function download(id) {
    const result = await getRequest(`${downloadUrl}?id=${id}`);
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  const SortableItem = SortableElement(({ value }) => (
    <Table.Row key={value.id} className={value.highlight ? "highlight" : null}>
      <Table.Cell
        style={{
          textAlign: "left",
          position: "relative"
        }}
      >
        {draggable && (
          <React.Fragment>
            <DragHandle />
            <span
              className={css({
                "&:hover i": { color: "#00b8d4" },
                cursor: "pointer",
                verticalAlign: "top",
                display: "inline-block",
                position: "absolute",
                left: 36
              })}
              onClick={() =>
                history.push(`/commitment-instructions/edit-schedule-${type === "BI" ? "bi" : "bii"}-code/${value.id}`)
              }
            >
              <Icon style={{ paddingRight: 32.5 }} className="fal fa-pen" />
            </span>
            <span
              className={css({
                "&:hover i": { color: "#00b8d4" },
                cursor: "pointer",
                display: "inline-block",
                position: "absolute",
                left: 56
              })}
              onClick={() => deleteAction(value)}
            >
              <Icon style={{ paddingRight: 32.5 }} className="fal fa-trash" />
            </span>
          </React.Fragment>
        )}
        <React.Fragment>
          <div
            className={css({
              display: "inline-block",
              width: value.scheduleSectionId === 1 ? null : "calc(100% - 70px)"
            })}
          >
            <span
              className={css({
                fontFamily: value.scheduleSectionId === 1 ? null : "Fakt-Bold",
                textAlign: "left"
              })}
            >
              {value.scheduleSectionId === 1 ? (
                <span className={paragraphStyles}>
                  {value.caseScenario && <Highlight children={value.caseScenario} />}
                </span>
              ) : (
                value.code
              )}
            </span>
          </div>
        </React.Fragment>
      </Table.Cell>
      {value.scheduleSectionId === 1 && (
        <Table.Cell className={css({ textAlign: "left !important" })}>
          <span className={css({ fontFamily: "Fakt-Bold" })}>
            <Highlight children={value.code} />
          </span>
        </Table.Cell>
      )}
      <Table.Cell style={{ position: "relative" }}>
        {value.wording && (
          <CopyToClipboard
            style={{
              position: "absolute",
              top: 16,
              right: 10,
              cursor: "pointer"
            }}
            text={value.wording}
            onCopy={() =>
              addToast("Copied!", {
                appearance: "success",
                autoDismiss: true
              })
            }
          >
            <span className={css({ "&:hover i": { color: "#00b8d4" } })}>
              <Icon className="fal fa-copy" />
            </span>
          </CopyToClipboard>
        )}
        <span className={paragraphStyles}>
          {value.wording && !searchMode ? (
            <Popup
              on={["click"]}
              header={value.code}
              content={value.wording}
              trigger={
                <LinesEllipsis
                  text={value.wording}
                  maxLine="2"
                  ellipsis="..."
                  style={{ textAlign: "left", paddingRight: 24 }}
                />
              }
            />
          ) : (
            <Highlight children={value.wording} />
          )}
        </span>
      </Table.Cell>
      <Table.Cell
        style={{
          textAlign: "left",
          position: "relative"
        }}
      >
        {value.note && (
          <CopyToClipboard
            style={{
              position: "absolute",
              top: 16,
              right: 10,
              cursor: "pointer"
            }}
            text={value.note}
            onCopy={() =>
              addToast("Copied!", {
                appearance: "success",
                autoDismiss: true
              })
            }
          >
            <span className={css({ "&:hover i": { color: "#00b8d4" } })}>
              <Icon className="fal fa-copy" />
            </span>
          </CopyToClipboard>
        )}
        <span className={paragraphStyles}>
          {value.note && !searchMode ? (
            <Popup
              on={["click"]}
              header={value.code}
              content={value.note}
              trigger={
                <LinesEllipsis
                  text={value.note}
                  maxLine="2"
                  ellipsis="..."
                  style={{ textAlign: "left", paddingRight: 24 }}
                />
              }
            />
          ) : (
            <Highlight children={value.note} />
          )}
        </span>
        {value.files && value.files.length > 0 && (
          <React.Fragment>
            {value.files &&
              value.files.map(f => (
                <p className={paragraphStyles} key={f.id}>
                  <a
                    onClick={() => download(f.id)}
                    className={css({
                      "&:hover": {
                        textDecoration: "underline",
                        color: "#00b8d4"
                      }
                    })}
                  >
                    <i
                      className="fal fa-paperclip"
                      style={{
                        color: "#00b8d4",
                        marginRight: 8
                      }}
                    />
                    <Highlight children={f.fileName} />
                  </a>
                </p>
              ))}
          </React.Fragment>
        )}
      </Table.Cell>
      {type === "BI" && (
        <Table.Cell
          style={{
            textAlign: "left"
          }}
        >
          <span className={paragraphStyles}>
            {value.instructionsToOmit && !searchMode ? (
              <Popup
                on={["click"]}
                header={value.code}
                content={value.instructionsToOmit}
                trigger={
                  <LinesEllipsis
                    text={value.instructionsToOmit}
                    maxLine="2"
                    ellipsis="..."
                    style={{ textAlign: "left" }}
                  />
                }
              />
            ) : (
              <Highlight children={value.instructionsToOmit} />
            )}
          </span>
        </Table.Cell>
      )}
      <Table.Cell
        style={{
          textAlign: "left"
        }}
      >
        <span className={paragraphStyles}>
          {value.taskText && !searchMode ? (
            <Popup
              on={["click"]}
              header={value.code}
              content={value.taskText}
              trigger={<LinesEllipsis text={value.taskText} maxLine="2" ellipsis="..." style={{ textAlign: "left" }} />}
            />
          ) : (
            <Highlight children={value.taskText} />
          )}
        </span>
      </Table.Cell>
    </Table.Row>
  ));

  const SortableList = SortableContainer(({ items }) => {
    const codesWithCaseStudies = items.filter(i => i.scheduleSectionId === 1);
    const codesWithoutCaseStudies = items.filter(i => i.scheduleSectionId !== 1);
    return (
      <div
        className={css({
          "& .highlight, & .highlight:nth-of-type(2n)": {
            backgroundColor: "#00b7d326 !important"
          }
        })}
      >
        {codesWithCaseStudies.length > 0 && (
          <div
            className={css({
              width: "calc(100vw - 200px)",
              overflowX: "auto",
              borderRadius: "0.28571429rem",
              marginTop: 16,
              marginBottom: "24px !important"
            })}
          >
            <CustomTable
              fixed={true}
              width={3000}
              mediumCompact
              customStyles={{
                width: "1700px !important",
                "& tr td:first-of-type, & tr th:first-of-type": {
                  paddingLeft: `${draggable ? 80 : 64}px !important`
                },
                "& th": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1
                },
                marginBottom: type === "BI" ? "8px !important" : "24px !important"
              }}
            >
              <Table.Header>
                <Table.Row>
                  <React.Fragment>
                    <Table.HeaderCell width={4} style={{ textAlign: "left" }}>
                      Case Scenario
                    </Table.HeaderCell>
                    <Table.HeaderCell width={2} style={{ textAlign: "left" }}>
                      Code
                    </Table.HeaderCell>
                  </React.Fragment>
                  <Table.HeaderCell width={4}>
                    Wording <ClickToView />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={4}>
                    Notes <ClickToView />
                  </Table.HeaderCell>
                  {type === "BI" && (
                    <Table.HeaderCell width={4}>
                      Instructions to Omit <ClickToView />
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell width={4}>
                    Task Text <ClickToView />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {codesWithCaseStudies.map((value, index) => (
                  <SortableItem key={`item-${value.id}`} index={index} value={value} />
                ))}
              </Table.Body>
            </CustomTable>
          </div>
        )}
        {codesWithoutCaseStudies.length > 0 && (
          <div
            className={css({
              width: "calc(100vw - 240px)",
              overflowX: "auto",
              borderRadius: "0.28571429rem",
              marginTop: 16,
              marginBottom: type === "BII" ? 0 : "24px !important"
            })}
          >
            <CustomTable
              fixed={true}
              width={3000}
              mediumCompact
              customStyles={{
                width: type === "BI" ? "1700px !important" : null,
                "& tr td:first-of-type, & tr th:first-of-type": {
                  paddingLeft: `${draggable ? 80 : 64}px !important`
                },
                "& th": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1
                },
                marginBottom: type === "BI" ? "8px !important" : "24px !important"
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={6} style={{ textAlign: "left" }}>
                    Code
                  </Table.HeaderCell>
                  <Table.HeaderCell width={6}>
                    Wording <ClickToView />
                  </Table.HeaderCell>
                  <Table.HeaderCell width={6}>
                    Notes <ClickToView />
                  </Table.HeaderCell>
                  {type === "BI" && (
                    <Table.HeaderCell width={6}>
                      Instructions to Omit <ClickToView />
                    </Table.HeaderCell>
                  )}
                  <Table.HeaderCell width={6}>
                    Task Text <ClickToView />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {codesWithoutCaseStudies.map((value, index) => (
                  <SortableItem key={`item-${value.id}`} index={index} value={value} />
                ))}
              </Table.Body>
            </CustomTable>
          </div>
        )}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState(state => ({
      ...state,
      sorted: true,
      items: arrayMove(state.items, oldIndex, newIndex).map((n, i) => ({
        ...n,
        sortNum: i
      }))
    }));
  };

  useEffect(() => {
    if (draggable && initialized && state.sorted) {
      saveSort(state.items);
    }
  }, [state.items, initialized]);

  useEffect(() => {
    setState(state => ({ ...state, items: items }));
    setInitialized(true);
  }, [items]);

  return <SortableList items={state.items} onSortEnd={onSortEnd} useDragHandle />;
}

export default withRouter(DragSortCodes);
