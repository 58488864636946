import React from "react";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Card,
  Label,
  Table,
  Message,
  Popup
} from "semantic-ui-react";
import { Unavailable } from "../Unavailable";
import { withRouter } from "react-router-dom";
import { SectionTitle } from "../customElements/SectionTitle";
import { ActionButton } from "../customElements/ActionButton";
import shortid from "shortid";
import { Highlight } from "../search/Highlight";
import { getRequest } from "../../helpers/ApiHelpers";
import { convertToFileDownload } from "../../helpers/ConvertToFileDownload";
import { eAndODepartments } from "./eAndODepartments";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  marginBottom: "0px !important"
});
const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 8
});

const eAndOLabelStyles = css({
  fontFamily: "Fakt-Normal",
  fontSize: 14,
  color: "#707898",
  marginBottom: 8,
  marginTop: 24
});

async function download(id) {
  const result = await getRequest(`/company/downloadCompanyFile?id=${id}`);
  if (result.success) {
    convertToFileDownload(result.data.base64File, result.data.name);
  }
}

async function downloadContactFile(id) {
  const result = await getRequest(`/contact/downloadContactFile?id=${id}`);
  if (result.success) {
    convertToFileDownload(result.data.base64File, result.data.name);
  }
}

function CompanyCard({
  company,
  history,
  deleteCompany,
  deleteContact,
  isAdmin,
  states,
  noState,
  draggable,
  dragHandle
}) {
  const { contacts } = company;

  const formatContact = (contact) => {
    return `${
      contact.firstName || contact.lastName
        ? "Name: " +
          (contact.firstName || "") +
          " " +
          (contact.lastName || "") +
          "\n"
        : ""
    }${contact.firmName ? "Firm: " + contact.firmName + "\n" : ""}${
      contact.address1 || contact.city || contact.state || contact.zip
        ? "Address: " +
          (contact.address1 ? contact.address1 + " " : "") +
          (contact.address2 ? contact.address2 + " " : "") +
          (contact.city ? contact.city + " " : "") +
          (contact.state ? contact.state + " " : "") +
          (contact.zip ? contact.zip + " " : "") +
          "\n"
        : ""
    }${contact.phoneDirect ? "Phone: " + contact.phoneDirect + "\n" : ""}${
      contact.cell ? "Cell: " + contact.cell + "\n" : ""
    }${contact.faxDirect ? "Fax: " + contact.faxDirect + "\n" : ""}${
      contact.email ? "Email: " + contact.email : ""
    }`;
  };

  var formattedContacts = contacts
    ? contacts.map((contact) => "\n\n" + formatContact(contact)).join("")
    : [];

  var formattedBody = `${
    company.name ? "Company: " + company.name + "\n" : ""
  }${
    company.address1 || company.city || company.state || company.zip
      ? "Address: " +
        (company.address1 ? company.address1 + " " : "") +
        (company.address2 ? company.address2 + " " : "") +
        (company.city ? company.city + " " : "") +
        (company.state ? company.state + " " : "") +
        (company.zip ? company.zip + " " : "") +
        "\n"
      : ""
  }${company.phone ? "Phone: " + company.phone + "\n" : ""}${
    company.fax ? "Fax: " + company.fax + "\n" : ""
  }${company.email ? "Email: " + company.email : ""} ${formattedContacts}`;

  const EAndAInfo = ({ label, info }) => {
    return (
      <div className={css({ marginRight: 24 })}>
        <div className={eAndOLabelStyles}>
          {label}:{" "}
          <span
            className={css({
              fontFamily: "Fakt-Normal",
              color: "#000"
            })}
          >
            {info}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Card.Group itemsPerRow={1}>
      <Card>
        <Card.Content>
          {draggable && dragHandle()}
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              <Grid.Column floated="left">
                <Header
                  as="h3"
                  className={css({
                    color: "#47517b !important",
                    fontSize: "20px !important"
                  })}
                >
                  <Highlight children={company.name} />{" "}
                  {company.contactTag && (
                    <React.Fragment>
                      {company.contactTag.name === "Primary Contact" && (
                        <Label className="primaryLabel">Primary Contact</Label>
                      )}
                      {company.contactTag.name === "Global Contact" && (
                        <Label className="thirdlyLabel">Global</Label>
                      )}
                      {company.contactTag.name === "Last Option" && (
                        <Label className="secondaryLabel">Last Option</Label>
                      )}
                      {company.contactTagId > 3 && (
                        <Label className="customLabel">
                          {company.contactTag.name}
                        </Label>
                      )}
                    </React.Fragment>
                  )}
                  {company.customTag !== "" && company.customTag !== null && (
                    <Label className="customLabel">{company.customTag}</Label>
                  )}
                  {noState &&
                    company.companyStates &&
                    states &&
                    company.companyStates.slice(0, 5).map((cs) => (
                      <Label className="secondaryLabel" key={cs.stateId}>
                        {states.find((s) => s.id === cs.stateId) &&
                          states.find((s) => s.id === cs.stateId).name}
                      </Label>
                    ))}
                  {noState &&
                    company.companyStates &&
                    company.companyStates.length > 5 && (
                      <Popup
                        trigger={
                          <a
                            className={css({
                              fontSize: 14,
                              textDecoration: "underline",
                              marginLeft: 16,
                              "&:hover": {
                                textDecoration: "underline !important"
                              }
                            })}
                          >
                            {company.companyStates.length - 5} more states
                          </a>
                        }
                        content={company.companyStates
                          .slice(5, 54)
                          .map(
                            (cs) =>
                              states.find((s) => s.id === cs.stateId) &&
                              states.find((s) => s.id === cs.stateId).name + " "
                          )}
                        size="mini"
                      />
                    )}
                </Header>
              </Grid.Column>
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                {isAdmin && (
                  <React.Fragment>
                    <Button
                      icon
                      onClick={() =>
                        history.push(`/contacts/edit-company/${company.id}`)
                      }
                      data-automation={`edit-${company.name}`}
                    >
                      <i className="fal fa-pencil" />
                    </Button>
                    <Button
                      icon
                      onClick={deleteCompany}
                      data-automation={`delete-${company.name}`}
                    >
                      <i className="fal fa-trash-alt" />
                    </Button>
                  </React.Fragment>
                )}
                <a
                  href={`mailto:?subject=${encodeURIComponent(
                    `${company.name || ""}`
                  )}'s%20info&body=${encodeURIComponent(formattedBody)}`}
                >
                  <Button icon>
                    <i className="fal fa-share" />
                  </Button>
                </a>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              columns={1}
              className={css({ paddingTop: "0 !important" })}
            >
              <Grid.Column>
                <Table
                  className={css({
                    "&.ui.table": {
                      border: "none"
                    },
                    "&.ui.table td": {
                      padding: "0px 11px 0px 0px !important"
                    },
                    "&.ui.table thead th": {
                      border: "none",
                      backgroundColor: "transparent !important",
                      paddingLeft: "0",
                      paddingBottom: 8
                    },
                    "&.ui.table thead tr:first-of-type > th:first-of-type": {
                      paddingLeft: 0
                    }
                  })}
                  fixed
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Address</Table.HeaderCell>
                      <Table.HeaderCell>Phone</Table.HeaderCell>
                      <Table.HeaderCell>Fax</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row verticalAlign="top">
                      <Table.Cell>
                        <Highlight children={company.address1} />{" "}
                        {company.address1 && <br />}
                        <Highlight children={company.address2} />{" "}
                        {company.address2 && <br />}
                        <Highlight children={company.city} />
                        {company.city && company.state && ", "}
                        <Highlight children={company.state} />{" "}
                        <Highlight children={company.zip} />
                      </Table.Cell>
                      <Table.Cell>
                        <a href={`tel:${company.phone}`}>
                          <Highlight children={company.phone} />
                        </a>
                      </Table.Cell>
                      <Table.Cell>
                        <Highlight children={company.fax} />
                      </Table.Cell>
                      <Table.Cell>
                        <a href={`mailto:${company.email}`}>
                          <Highlight children={company.email} />
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                {company.companyFiles && company.companyFiles.length > 0 && (
                  <div className={labelStyles}>Attachments</div>
                )}
                {company.companyFiles &&
                  company.companyFiles.map((f) => (
                    <p className={paragraphStyles} key={f.id}>
                      <a
                        onClick={() => download(f.id)}
                        className={css({
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#00b8d4"
                          }
                        })}
                      >
                        <i
                          className="fal fa-paperclip"
                          style={{ color: "#00b8d4", marginRight: 8 }}
                        />
                        <Highlight children={f.fileName} />
                      </a>
                    </p>
                  ))}
                {contacts && contacts.length > 0 && (
                  <React.Fragment>
                    <SectionTitle
                      text="Contacts"
                      style={{ marginBottom: -7 }}
                    />
                    {contacts.map((c) => (
                      <React.Fragment key={c.id}>
                        <Table
                          key={shortid.generate()}
                          className={css({
                            "&.ui.table": { border: "none" },
                            "&.ui.table thead th": { border: "none" },
                            "&.ui.table tr td": {
                              border: "none",
                              paddingLeft: 0
                            },

                            "&.ui.table tr:first-of-type td": {
                              paddingTop: 24,
                              paddingLeft: 16
                            },
                            "&.ui.table tr td:first-of-type": {
                              paddingLeft: 16
                            },
                            "&.ui.table tr:last-child td": {
                              paddingBottom: !c.eAndO ? 24 : 60
                            },
                            backgroundColor: "#f7f7f9 !important"
                          })}
                          fixed
                        >
                          <Table.Body>
                            <Table.Row verticalAlign="top">
                              <Table.Cell style={{ overflow: "visible" }}>
                                <span
                                  className={css({
                                    fontFamily: "Fakt-Bold",
                                    width: 900,
                                    display: "inline-block"
                                  })}
                                >
                                  <Highlight
                                    children={`${c.firstName ||
                                      ""} ${c.lastName || ""} `}
                                  />
                                  {c.contactTag && (
                                    <React.Fragment>
                                      {c.contactTag.name ===
                                        "Primary Contact" && (
                                        <Label
                                          className="primaryLabel smallLabel"
                                          style={{ marginLeft: ".5rem" }}
                                        >
                                          Primary Contact
                                        </Label>
                                      )}
                                      {c.contactTag.name ===
                                        "Global Contact" && (
                                        <Label
                                          className="thirdlyLabel smallLabel"
                                          style={{ marginLeft: ".5rem" }}
                                        >
                                          Global
                                        </Label>
                                      )}
                                      {c.contactTag.name === "Last Option" && (
                                        <Label
                                          className="secondaryLabel smallLabel"
                                          style={{ marginLeft: ".5rem" }}
                                        >
                                          Last Option
                                        </Label>
                                      )}
                                      {c.contactTagId > 3 && (
                                        <Label
                                          className="customLabel smallLabel"
                                          style={{ marginLeft: ".5rem" }}
                                        >
                                          {c.contactTag.name}
                                        </Label>
                                      )}
                                    </React.Fragment>
                                  )}
                                  {c.customTag !== "" && c.customTag !== null && (
                                    <Label
                                      className="customLabel smallLabel"
                                      style={{ marginLeft: ".5rem" }}
                                    >
                                      {c.customTag}
                                    </Label>
                                  )}
                                </span>
                                {c.position && (
                                  <div
                                    className={css({
                                      opacity: 0.6,
                                      fontSize: 12
                                    })}
                                  >
                                    <Highlight children={c.position} />
                                  </div>
                                )}
                              </Table.Cell>
                              <Table.Cell></Table.Cell>
                              <Table.Cell></Table.Cell>
                              <Table.Cell
                                textAlign="right"
                                style={{ paddingRight: 32 }}
                              >
                                {isAdmin && (
                                  <React.Fragment>
                                    <ActionButton
                                      onClick={() =>
                                        history.push(
                                          `/contacts/edit-contact/${c.id}`
                                        )
                                      }
                                      text="Edit"
                                      dataAutomation={`edit-${c.firstName}-${c.lastName}`}
                                    />
                                    <ActionButton
                                      style={{ marginLeft: 8 }}
                                      onClick={() =>
                                        deleteContact(c, "individual")
                                      }
                                      text="Delete"
                                      dataAutomation={`delete-${c.firstName}-${c.lastName}`}
                                    />
                                  </React.Fragment>
                                )}
                                <a
                                  href={`mailto:?subject=${c.firstName}%20${
                                    c.lastName
                                  }'s%20info&body=${encodeURIComponent(
                                    formatContact(c)
                                  )}`}
                                >
                                  <ActionButton
                                    style={{ marginLeft: 8 }}
                                    text="Share"
                                  />
                                </a>
                              </Table.Cell>
                            </Table.Row>
                            <Table.Row verticalAlign="top">
                              <Table.Cell>
                                <Highlight children={c.address1} />{" "}
                                {c.address1 && <br />}
                                <Highlight children={c.address2} />{" "}
                                {c.address2 && <br />}
                                <Highlight children={c.city} />
                                {c.city && c.state && ", "}
                                <Highlight children={c.state} />{" "}
                                <Highlight children={c.zip} />
                              </Table.Cell>
                              <Table.Cell>
                                <a href={`tel:${c.phoneDirect}`}>
                                  <Highlight children={c.phoneDirect} />
                                </a>
                                {c.phoneDirect && (
                                  <span>
                                    <span
                                      className={css({
                                        marginLeft: 8,
                                        display: "inline-block"
                                      })}
                                    />
                                    <Label basic horizontal size="mini">
                                      Direct
                                    </Label>
                                    <br />
                                  </span>
                                )}
                                <a href={`tel:${c.cell}`}>
                                  <Highlight children={c.cell} />
                                </a>
                                {c.cell && (
                                  <span>
                                    <span
                                      className={css({
                                        marginLeft: 8,
                                        display: "inline-block"
                                      })}
                                    />
                                    <Label basic horizontal size="mini">
                                      Cell
                                    </Label>
                                    <br />
                                  </span>
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                <Highlight children={c.faxDirect} />
                                {c.faxDirect && (
                                  <span>
                                    <span
                                      className={css({
                                        marginLeft: 8,
                                        display: "inline-block"
                                      })}
                                    />
                                    <Label basic horizontal size="mini">
                                      Direct
                                    </Label>
                                    <br />
                                  </span>
                                )}
                              </Table.Cell>
                              <Table.Cell style={{ paddingRight: 32 }}>
                                <a href={`mailto:${c.email}`}>
                                  <Highlight children={c.email} />
                                </a>
                              </Table.Cell>
                            </Table.Row>
                            {c.contactFiles && c.contactFiles.length > 0 && (
                              <Table.Row>
                                <Table.Cell>
                                  <div className={labelStyles}>Attachments</div>
                                  {c.contactFiles &&
                                    c.contactFiles.map((f) => (
                                      <p className={paragraphStyles} key={f.id}>
                                        <a
                                          onClick={() =>
                                            downloadContactFile(f.id)
                                          }
                                          className={css({
                                            "&:hover": {
                                              textDecoration: "underline",
                                              color: "#00b8d4"
                                            }
                                          })}
                                        >
                                          <i
                                            className="fal fa-paperclip"
                                            style={{
                                              color: "#00b8d4",
                                              marginRight: 8
                                            }}
                                          />
                                          <Highlight children={f.fileName} />
                                        </a>
                                      </p>
                                    ))}
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                        {eAndODepartments.some((d) => d === c.departmentId) && (
                          <React.Fragment>
                            <div
                              className={css({
                                display: "flex",
                                flexDirection: "row-reverse",
                                position: "relative",
                                top: -72,
                                height: 0
                              })}
                            >
                              {c.signedAgreement && (
                                <EAndAInfo
                                  label="Signed Agreement"
                                  info={c.signedAgreement}
                                />
                              )}
                              {c.liabilityDisclaimerOnSearch && (
                                <EAndAInfo
                                  label="Liability Disclaimer on Search"
                                  info={c.liabilityDisclaimerOnSearch}
                                />
                              )}
                              {c.eAndODate && (
                                <EAndAInfo
                                  label="E&O Date"
                                  info={new Date(
                                    c.eAndODate
                                  ).toLocaleDateString("en-US")}
                                />
                              )}
                              {c.eAndO && (
                                <EAndAInfo label="E&O" info={c.eAndO} />
                              )}
                            </div>
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
                {company.note !== null && company.note !== "" && (
                  <React.Fragment>
                    <SectionTitle text="Notes" />
                    <Message
                      className={css({
                        boxShadow: "none !important",
                        whiteSpace: "pre-line"
                      })}
                    >
                      {company.note ? (
                        <Highlight children={company.note} />
                      ) : (
                        <Unavailable />
                      )}
                    </Message>
                  </React.Fragment>
                )}
                {eAndODepartments.some((d) => d === company.departmentId) && (
                  <React.Fragment>
                    <div
                      className={css({
                        display: "flex",
                        flexDirection: "row-reverse"
                      })}
                    >
                      {company.signedAgreement && (
                        <EAndAInfo
                          label="Signed Agreement"
                          info={company.signedAgreement}
                        />
                      )}
                      {company.liabilityDisclaimerOnSearch && (
                        <EAndAInfo
                          label="Liability Disclaimer on Search"
                          info={company.liabilityDisclaimerOnSearch}
                        />
                      )}
                      {company.eAndODate && (
                        <EAndAInfo
                          label="E&O Date"
                          info={new Date(company.eAndODate).toLocaleDateString(
                            "en-US"
                          )}
                        />
                      )}
                      {company.eAndO && (
                        <EAndAInfo label="E&O" info={company.eAndO} />
                      )}
                    </div>
                  </React.Fragment>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </Card.Group>
  );
}

export default withRouter(CompanyCard);
