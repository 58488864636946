import React, { useState, useEffect, useContext } from "react";
import { getRequest } from "../../../helpers/ApiHelpers";
import { StateContext } from "../../State";
import { css } from "emotion";
import { Button } from "semantic-ui-react";
import NoteAccordion from "../../NoteAccordion";

const header = (title) => {
  return (
    <div
      className={css({
        textAlign: "left",
        color: "#47517B",
        fontSize: 20,
        fontFamily: "Fakt-Bold",
        marginTop: 32,
        marginBottom: 8
      })}
    >
      {title}
    </div>
  );
};

export default function GeneralInfoNotes({ deleteAction }) {
  const { selectedState } = useContext(StateContext);
  const [generalInfos, setGeneralInfos] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const getAndSetGeneralInfos = async () => {
    const { data } = await getRequest(
      "/policyGeneralInfo/getGeneralInfoNotesByState",
      {
        stateId: selectedState
      }
    );
    setGeneralInfos(data);
  };

  useEffect(() => {
    getAndSetGeneralInfos();
  }, [selectedState]);

  const transactionTypes = generalInfos
    .filter((d) => d.policyGeneralInfoNoteTypeId === 1)
    .map((n) => ({ ...n, files: n.policyGeneralInfoNoteFiles }));
  const scheduleA = generalInfos
    .filter((d) => d.policyGeneralInfoNoteTypeId === 2)
    .map((n) => ({ ...n, files: n.policyGeneralInfoNoteFiles }));
  const miscellaneous = generalInfos
    .filter(
      (d) =>
        d.policyGeneralInfoNoteTypeId === 3 ||
        d.policyGeneralInfoNoteTypeId === null
    )
    .map((n) => ({ ...n, files: n.policyGeneralInfoNoteFiles }));
  const surveyInfos = generalInfos
    .filter((d) => d.policyGeneralInfoNoteTypeId === 4)
    .map((n) => ({ ...n, files: n.policyGeneralInfoNoteFiles }));
  const propertyTypes = generalInfos
    .filter((d) => d.policyGeneralInfoNoteTypeId === 5)
    .map((n) => ({ ...n, files: n.policyGeneralInfoNoteFiles }));

  return (
    <>
      <Button basic onClick={() => setShowAll((showAll) => !showAll)}>
        {showAll ? "Collapse All" : "Open All"}
      </Button>
      {transactionTypes.length > 0 && (
        <>
          {header("Transaction Types")}
          <NoteAccordion
            notes={transactionTypes}
            setNotes={setGeneralInfos}
            deleteAction={deleteAction}
            showAll={showAll}
            downloadURL="/policyGeneralInfo/downloadPolicyGeneralInfoNoteFile"
            saveSortURL="/policyGeneralInfo/sortNotes"
            editURL="/policy-instructions/edit-general-info-note"
          />
        </>
      )}
      {propertyTypes.length > 0 && (
        <>
          {header("Property Types")}
          <NoteAccordion
            notes={propertyTypes}
            setNotes={setGeneralInfos}
            deleteAction={deleteAction}
            showAll={showAll}
            downloadURL="/policyGeneralInfo/downloadPolicyGeneralInfoNoteFile"
            saveSortURL="/policyGeneralInfo/sortNotes"
            editURL="/policy-instructions/edit-general-info-note"
          />
        </>
      )}
      {scheduleA.length > 0 && (
        <>
          {header("Schedule A")}
          <NoteAccordion
            notes={scheduleA}
            setNotes={setGeneralInfos}
            deleteAction={deleteAction}
            showAll={showAll}
            downloadURL="/policyGeneralInfo/downloadPolicyGeneralInfoNoteFile"
            saveSortURL="/policyGeneralInfo/sortNotes"
            editURL="/policy-instructions/edit-general-info-note"
          />
        </>
      )}
      {surveyInfos.length > 0 && (
        <>
          {header("Survey Info")}
          <NoteAccordion
            notes={surveyInfos}
            setNotes={setGeneralInfos}
            deleteAction={deleteAction}
            showAll={showAll}
            downloadURL="/policyGeneralInfo/downloadPolicyGeneralInfoNoteFile"
            saveSortURL="/policyGeneralInfo/sortNotes"
            editURL="/policy-instructions/edit-general-info-note"
          />
        </>
      )}
      {miscellaneous.length > 0 && (
        <>
          {header("Miscellaneous")}
          <NoteAccordion
            notes={miscellaneous}
            setNotes={setGeneralInfos}
            deleteAction={deleteAction}
            showAll={showAll}
            downloadURL="/policyGeneralInfo/downloadPolicyGeneralInfoNoteFile"
            saveSortURL="/policyGeneralInfo/sortNotes"
            editURL="/policy-instructions/edit-general-info-note"
          />
        </>
      )}
    </>
  );
}
