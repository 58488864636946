import React, { useState } from "react";
import { css, cx } from "emotion";
import { Button, Table, Icon, Modal, Message } from "semantic-ui-react";
import CustomTable from "../../../CustomTable";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import Label from "semantic-ui-react/dist/commonjs/elements/Label";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { paragraphStyles } from "../../../styles/paragraphStyles";
import { Popup } from "../../../Popup";

const boldRowStyles = {
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  color: "#000"
};

const addNotelinkStyles = css({
  fontSize: 14,
  lineHeight: 1.71,
  color: "#00b8d4",
  cursor: "pointer",
  position: "absolute",
  right: 0,
  bottom: -26,
  "&:hover": {
    textDecoration: "underline"
  }
});

function getTotalPercentage(totalRemittances, remittances) {
  return Math.round(
    100 - ((totalRemittances - remittances) / totalRemittances) * 100
  );
}

function getPercentageDiscrepancy(total, percentageTarget) {
  const discrepancy = total - percentageTarget;
  return discrepancy >= 1 ? `+${discrepancy}` : discrepancy;
}

function RemittanceTargetTable({
  history,
  remittances,
  remittanceTargets,
  addRemittance,
  editRemittance,
  deleteRemittance,
  remittanceToAdd,
  remittanceToEdit,
  remittanceTargetNotes,
  remittanceToDelete,
  deleteRemittanceTargetNote,
  noteToDelete,
  initialLoading,
  setState,
  selectedState,
  user,
  additional,
  additionalOverTenM,
  additionalOverFifteenM,
  additionalOverTwentyFiveM
}) {
  const [showAllRemittances, setShowAllRemittances] = useState(false);

  function compare(a, b) {
    if (a.id > b.id) {
      return -1;
    }
    if (a.id < b.id) {
      return 1;
    }
    return 0;
  }

  function getRemittances(
    t,
    additional,
    additionalOverTenM,
    additionalOverFifteenM,
    additionalOverTwentyFiveM
  ) {
    if (additional) {
      return showAllRemittances
        ? t.additionalRemittances.sort(compare)
        : t.additionalRemittances.sort(compare).slice(0, 5);
    }
    if (additionalOverTenM) {
      return showAllRemittances
        ? t.additionalOverTenMRemittances.sort(compare)
        : t.additionalOverTenMRemittances.sort(compare).slice(0, 5);
    }
    if (additionalOverFifteenM) {
      return showAllRemittances
        ? t.additionalOverFifteenMRemittances.sort(compare)
        : t.additionalOverFifteenMRemittances.sort(compare).slice(0, 5);
    }
    if (additionalOverTwentyFiveM) {
      return showAllRemittances
        ? t.additionalOverTwentyFiveMRemittances.sort(compare)
        : t.additionalOverTwentyFiveMRemittances.sort(compare).slice(0, 5);
    } else {
      return showAllRemittances
        ? t.remittances.sort(compare)
        : t.remittances.sort(compare).slice(0, 5);
    }
  }

  function showExpand() {
    if (additional) {
      return remittanceTargets.some(t => t.additionalRemittances.length > 5);
    } else if (additionalOverTenM) {
      return remittanceTargets.some(
        t => t.additionalOverTenMRemittances.length > 5
      );
    } else if (additionalOverFifteenM) {
      return remittanceTargets.some(
        t => t.additionalOverFifteenMRemittances.length > 5
      );
    } else if (additionalOverTwentyFiveM) {
      return remittanceTargets.some(
        t => t.additionalOverTwentyFiveMRemittances.length > 5
      );
    } else {
      return remittanceTargets.some(t => t.remittances.length > 5);
    }
  }

  return (
    <React.Fragment>
      {remittanceTargetNotes &&
        remittanceTargetNotes.map(n => (
          <Card.Group itemsPerRow={1} key={n.id}>
            <Card style={{ marginLeft: 0, marginRight: 0 }}>
              <Card.Content>
                <label
                  className={css({
                    color: "#707898",
                    fontSize: 14,
                    fontFamily: "Fakt-Bold",
                    lineHeight: "17px"
                  })}
                >
                  {n.subject ? n.subject : "Note"}
                </label>
                <p style={{ marginTop: 5 }} className={paragraphStyles}>
                  {n.note}
                </p>
              </Card.Content>
              {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
                <React.Fragment>
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer",
                      position: "absolute",
                      right: 24,
                      top: 31
                    })}
                    onClick={() => {
                      if (selectedState === 44) {
                        if (additional) {
                          history.push(
                            `/order-entry-instructions/edit-additional-split-remittance-target-note/${n.id}`
                          );
                        } else if (additionalOverTenM) {
                          history.push(
                            `/order-entry-instructions/edit-additional-over-ten-m-split-remittance-target-note/${n.id}`
                          );
                        } else if (additionalOverFifteenM) {
                          history.push(
                            `/order-entry-instructions/edit-additional-over-fifteen-m-split-remittance-target-note/${n.id}`
                          );
                        } else if (additionalOverTwentyFiveM) {
                          history.push(
                            `/order-entry-instructions/edit-additional-over-twenty-five-m-split-remittance-target-note/${n.id}`
                          );
                        } else {
                          history.push(
                            `/order-entry-instructions/edit-split-remittance-target-note/${n.id}`
                          );
                        }
                      } else {
                        history.push(
                          `/order-entry-instructions/edit-remittance-target-note/${n.id}`
                        );
                      }
                    }}
                  >
                    <Icon className="fal fa-pen" />
                  </span>
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer",
                      position: "absolute",
                      right: 48,
                      top: 31
                    })}
                    onClick={() => {
                      if (additional) {
                        setState(state => ({
                          ...state,
                          additionalNoteToDelete: n
                        }));
                      } else if (additionalOverTenM) {
                        setState(state => ({
                          ...state,
                          additionalOverTenMNoteToDelete: n
                        }));
                      } else if (additionalOverFifteenM) {
                        setState(state => ({
                          ...state,
                          additionalOverFifteenMNoteToDelete: n
                        }));
                      } else if (additionalOverTwentyFiveM) {
                        setState(state => ({
                          ...state,
                          additionalOverTwentyFiveMNoteToDelete: n
                        }));
                      } else {
                        setState(state => ({ ...state, noteToDelete: n }));
                      }
                    }}
                  >
                    <Icon className="fal fa-trash" />
                  </span>
                </React.Fragment>
              )}
            </Card>
          </Card.Group>
        ))}
      {remittanceTargets.length > 0 && (
        <div
          className={css({
            position: "relative",
            marginBottom: 19.75,
            marginTop: remittanceTargetNotes.length > 0 ? 24 : 0,
            "& span th.greyedOutStyles:not(:first-of-type), & span td.greyedOutStyles:not(:first-of-type)": {
              borderLeft: "1px solid #929292 !important",
              opacity: "0.5 !important"
            }
          })}
        >
          <span>
            <CustomTable
              customStyles={{
                "& th": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1
                },
                "& .fileNum": {
                  paddingRight: "0px !important",
                  paddingLeft: "0px !important",
                  verticalAlign: "top",
                  textAlign: "center",
                  fontFamily: "Fakt-Normal",
                  fontSize: 14,
                  height: 40,
                  "& input": {
                    height: 24,
                    textAlign: "center !important",
                    fontSize: 14,
                    color: "#000"
                  },
                  "& .ui.input": {
                    width: "60%",
                    maxWidth: 158
                  }
                }
              }}
              extraCompact
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  {remittanceTargets.map(t => (
                    <Table.HeaderCell
                      key={t.id}
                      className={
                        !t.percentageTarget > 0 ? "greyedOutStyles" : null
                      }
                    >
                      {t.underwriter.name}{" "}
                      {t.note && (
                        <Popup
                          on={["hover", "focus"]}
                          header={t.underwriter.name}
                          content={t.note}
                          trigger={
                            <i
                              style={{
                                marginLeft: 16,
                                top: 2,
                                position: "relative",
                                color: "#c2c5d3",
                                cursor: "pointer",
                                verticalAlign: "top"
                              }}
                              className="fas fa-comment-alt-lines noteTrigger"
                            />
                          }
                        />
                      )}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row
                  className={css({
                    "& td": {
                      backgroundColor: "#E0F7FA"
                    }
                  })}
                >
                  <Table.Cell
                    className={css({
                      fontFamily: "FaktSlab-Normal",
                      color: "#707898",
                      fontSize: 16
                    })}
                  >
                    Discrepancy
                  </Table.Cell>
                  {remittanceTargets.map(t => (
                    <Table.Cell
                      key={t.id}
                      className={
                        !t.percentageTarget > 0 ? "greyedOutStyles" : null
                      }
                      style={boldRowStyles}
                    >
                      {getPercentageDiscrepancy(
                        getTotalPercentage(
                          remittances.length,
                          additional
                            ? t.additionalRemittances.length
                            : additionalOverTenM
                            ? t.additionalOverTenMRemittances.length
                            : additionalOverFifteenM
                            ? t.additionalOverFifteenMRemittances.length
                            : additionalOverTwentyFiveM
                            ? t.additionalOverTwentyFiveMRemittances.length
                            : t.remittances.length
                        ),
                        t.percentageTarget
                      ) || 0}
                      %
                    </Table.Cell>
                  ))}
                </Table.Row>
                <Table.Row
                  className={css({
                    "& td": {
                      backgroundColor: "#D8D8D8"
                    }
                  })}
                >
                  <Table.Cell
                    className={css({
                      fontFamily: "FaktSlab-Normal",
                      color: "#707898",
                      fontSize: 16
                    })}
                  >
                    Target
                  </Table.Cell>
                  {remittanceTargets.map(t => (
                    <Table.Cell
                      key={t.id}
                      className={
                        !t.percentageTarget > 0 ? "greyedOutStyles" : null
                      }
                      style={boldRowStyles}
                    >
                      {t.percentageTarget}%
                    </Table.Cell>
                  ))}
                </Table.Row>
                <Table.Row
                  className={css({
                    "& td": {
                      backgroundColor: "#E5E5EA"
                    }
                  })}
                >
                  <Table.Cell
                    className={css({
                      fontFamily: "FaktSlab-Normal",
                      color: "#707898",
                      fontSize: 16
                    })}
                  >
                    Total
                  </Table.Cell>
                  {remittanceTargets.map(t => (
                    <Table.Cell
                      key={t.id}
                      className={
                        !t.percentageTarget > 0 ? "greyedOutStyles" : null
                      }
                      style={boldRowStyles}
                    >
                      {getTotalPercentage(
                        remittances.length,
                        additional
                          ? t.additionalRemittances.length
                          : additionalOverTenM
                          ? t.additionalOverTenMRemittances.length
                          : additionalOverFifteenM
                          ? t.additionalOverFifteenMRemittances.length
                          : additionalOverTwentyFiveM
                          ? t.additionalOverTwentyFiveMRemittances.length
                          : t.remittances.length
                      ) || 0}
                      %{" "}
                      <Label
                        className={css({ marginLeft: "16px !important" })}
                        circular
                        color={"#fff"}
                        key={"#000"}
                      >
                        {additional
                          ? t.additionalRemittances.length
                          : additionalOverTenM
                          ? t.additionalOverTenMRemittances.length
                          : additionalOverFifteenM
                          ? t.additionalOverFifteenMRemittances.length
                          : additionalOverTwentyFiveM
                          ? t.additionalOverTwentyFiveMRemittances.length
                          : t.remittances.length}
                      </Label>
                    </Table.Cell>
                  ))}
                </Table.Row>
                <Table.Row>
                  <Table.Cell />
                  {remittanceTargets.map(t => (
                    <Table.Cell
                      key={t.id}
                      className={cx(
                        !t.percentageTarget > 0 ? "greyedOutStyles" : null,
                        "fileNum"
                      )}
                      style={boldRowStyles}
                    >
                      <div style={{ position: "relative" }}>
                        <Input
                          value={
                            (additional
                            ? remittanceToAdd.additionalRemittanceTargetId ===
                              t.id
                            : additionalOverTenM
                            ? remittanceToAdd.additionalOverTenMRemittanceTargetId ===
                              t.id
                            : additionalOverFifteenM
                            ? remittanceToAdd.additionalOverFifteenMRemittanceTargetId ===
                              t.id
                            : additionalOverTwentyFiveM
                            ? remittanceToAdd.additionalOverTwentyFiveMRemittanceTargetId ===
                              t.id
                            : remittanceToAdd.remittanceTargetId === t.id)
                              ? remittanceToAdd.fileNumber
                              : ""
                          }
                          onChange={(_, { value }) =>
                            additional
                              ? setState(state => ({
                                  ...state,
                                  additionalRemittanceToAdd: {
                                    additionalRemittanceTargetId: t.id,
                                    fileNumber: value
                                  }
                                }))
                              : additionalOverTenM
                              ? setState(state => ({
                                  ...state,
                                  additionalOverTenMRemittanceToAdd: {
                                    additionalOverTenMRemittanceTargetId: t.id,
                                    fileNumber: value
                                  }
                                }))
                              : additionalOverFifteenM
                              ? setState(state => ({
                                  ...state,
                                  additionalOverFifteenMRemittanceToAdd: {
                                    additionalOverFifteenMRemittanceTargetId:
                                      t.id,
                                    fileNumber: value
                                  }
                                }))
                              : additionalOverTwentyFiveM
                              ? setState(state => ({
                                  ...state,
                                  additionalOverTwentyFiveMRemittanceToAdd: {
                                    additionalOverTwentyFiveMRemittanceTargetId:
                                      t.id,
                                    fileNumber: value
                                  }
                                }))
                              : setState(state => ({
                                  ...state,
                                  remittanceToAdd: {
                                    remittanceTargetId: t.id,
                                    fileNumber: value
                                  }
                                }))
                          }
                        />
                        {(additional
                          ? remittanceToAdd.additionalRemittanceTargetId ===
                            t.id
                          : additionalOverTenM
                          ? remittanceToAdd.additionalOverTenMRemittanceTargetId ===
                            t.id
                          : additionalOverFifteenM
                          ? remittanceToAdd.additionalOverFifteenMRemittanceTargetId ===
                            t.id
                          : additionalOverTwentyFiveM
                          ? remittanceToAdd.additionalOverTwentyFiveMRemittanceTargetId ===
                            t.id
                          : remittanceToAdd.remittanceTargetId === t.id &&
                            remittanceToAdd.fileNumber !== "") && (
                          <span
                            className={css({
                              "&:hover i": { color: "#00b8d4" },
                              cursor: "pointer",
                              position: "absolute",
                              top: "2px",
                              right: "4px"
                            })}
                            onClick={() => addRemittance()}
                          >
                            <Icon className="fal fa-check" />
                          </span>
                        )}
                      </div>
                    </Table.Cell>
                  ))}
                </Table.Row>

                {remittanceTargets.length > 0 && (
                  <Table.Row>
                    {remittanceTargets.map((t, i) => (
                      <React.Fragment key={t.id}>
                        {i === 0 && (
                          <Table.Cell
                            className={css({
                              verticalAlign: "top",
                              fontFamily: "FaktSlab-Normal",
                              color: "#707898",
                              fontSize: 16
                            })}
                          >
                            File Number
                          </Table.Cell>
                        )}
                        <Table.Cell
                          className={cx(
                            !t.percentageTarget > 0 ? "greyedOutStyles" : null,
                            "fileNum"
                          )}
                        >
                          {getRemittances(
                            t,
                            additional,
                            additionalOverTenM,
                            additionalOverFifteenM,
                            additionalOverTwentyFiveM
                          ).map(r => (
                            <div
                              key={r.id}
                              className={css({
                                "&:hover span": {
                                  visibility: "visible"
                                },
                                position: "relative"
                              })}
                            >
                              {remittanceToEdit.id !== r.id &&
                              (additional
                                ? t.id === r.additionalRemittanceTargetId
                                : additionalOverTenM
                                ? r.additionalOverTenMRemittanceTargetId
                                : additionalOverFifteenM
                                ? r.additionalOverFifteenMRemittanceTargetId
                                : additionalOverTwentyFiveM
                                ? r.additionalOverTwentyFiveMRemittanceTargetId
                                : t.id === r.remittanceTargetId)
                                ? r.fileNumber
                                : ""}
                              {(additional
                                ? remittanceToEdit.additionalRemittanceTargetId ===
                                  t.id
                                : additionalOverTenM
                                ? remittanceToEdit.additionalOverTenMRemittanceTargetId ===
                                  t.id
                                : additionalOverFifteenM
                                ? remittanceToEdit.additionalOverFifteenMRemittanceTargetId ===
                                  t.id
                                : additionalOverTwentyFiveM
                                ? remittanceToEdit.additionalOverTwentyFiveMRemittanceTargetId ===
                                  t.id
                                : remittanceToEdit.remittanceTargetId ===
                                  t.id) &&
                                r.id === remittanceToEdit.id && (
                                  <div
                                    style={{
                                      position: "relative",
                                      width: "100%"
                                    }}
                                  >
                                    <Input
                                      value={remittanceToEdit.fileNumber}
                                      onChange={(_, { value }) =>
                                        additional
                                          ? setState(state => ({
                                              ...state,
                                              additionalRemittanceToEdit: {
                                                ...r,
                                                fileNumber: value
                                              }
                                            }))
                                          : additionalOverTenM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverTenMRemittanceToEdit: {
                                                ...r,
                                                fileNumber: value
                                              }
                                            }))
                                          : additionalOverFifteenM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverFifteenMRemittanceToEdit: {
                                                ...r,
                                                fileNumber: value
                                              }
                                            }))
                                          : additionalOverTwentyFiveM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverTwentyFiveMRemittanceToEdit: {
                                                ...r,
                                                fileNumber: value
                                              }
                                            }))
                                          : setState(state => ({
                                              ...state,
                                              remittanceToEdit: {
                                                ...r,
                                                fileNumber: value
                                              }
                                            }))
                                      }
                                    />
                                    {(additional
                                      ? remittanceToEdit.additionalRemittanceTargetId ===
                                        t.id
                                      : additionalOverTenM
                                      ? remittanceToEdit.additionalOverTenMRemittanceTargetId ===
                                        t.id
                                      : additionalOverFifteenM
                                      ? remittanceToEdit.additionalOverFifteenMRemittanceTargetId ===
                                        t.id
                                      : additionalOverTwentyFiveM
                                      ? remittanceToEdit.additionalOverTwentyFiveMRemittanceTargetId ===
                                        t.id
                                      : remittanceToEdit.remittanceTargetId ===
                                        t.id) &&
                                      remittanceToEdit.fileNumber !== "" && (
                                        <span
                                          className={css({
                                            "&:hover i": { color: "#00b8d4" },
                                            cursor: "pointer",
                                            position: "absolute",
                                            top: "4px",
                                            right: 4
                                          })}
                                          onClick={() => editRemittance()}
                                        >
                                          <Icon className="fal fa-check" />
                                        </span>
                                      )}
                                  </div>
                                )}
                              {(additional
                                ? t.id === r.additionalRemittanceTargetId
                                : additionalOverTenM
                                ? t.id ===
                                  r.additionalOverTenMRemittanceTargetId
                                : additionalOverFifteenM
                                ? t.id ===
                                  r.additionalOverFifteenMRemittanceTargetId
                                : additionalOverTwentyFiveM
                                ? t.id ===
                                  r.additionalOverTwentyFiveMRemittanceTargetId
                                : t.id === r.remittanceTargetId) &&
                                r.id !== remittanceToEdit.id && (
                                  <React.Fragment>
                                    <span
                                      className={css({
                                        "&:hover i": { color: "#00b8d4" },
                                        "&:hover": { visibility: "visible" },
                                        cursor: "pointer",
                                        position: "absolute",
                                        visibility: "hidden",
                                        right: 18
                                      })}
                                      onClick={() =>
                                        additional
                                          ? setState(state => ({
                                              ...state,
                                              additionalRemittanceToDelete: r
                                            }))
                                          : additionalOverTenM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverTenMRemittanceToDelete: r
                                            }))
                                          : additionalOverFifteenM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverFifteenMRemittanceToDelete: r
                                            }))
                                          : additionalOverTwentyFiveM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverTwentyFiveMRemittanceToDelete: r
                                            }))
                                          : setState(state => ({
                                              ...state,
                                              remittanceToDelete: r
                                            }))
                                      }
                                    >
                                      <Icon className="fal fa-trash" />
                                    </span>
                                    <span
                                      className={css({
                                        "&:hover i": { color: "#00b8d4" },
                                        "&:hover": { visibility: "visible" },
                                        cursor: "pointer",
                                        position: "absolute",
                                        visibility: "hidden",
                                        right: 2
                                      })}
                                      onClick={() =>
                                        additional
                                          ? setState(state => ({
                                              ...state,
                                              additionalRemittanceToEdit: r
                                            }))
                                          : additionalOverTenM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverTenMRemittanceToEdit: r
                                            }))
                                          : additionalOverFifteenM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverFifteenMRemittanceToEdit: r
                                            }))
                                          : additionalOverTwentyFiveM
                                          ? setState(state => ({
                                              ...state,
                                              additionalOverTwentyFiveMRemittanceToEdit: r
                                            }))
                                          : setState(state => ({
                                              ...state,
                                              remittanceToEdit: r
                                            }))
                                      }
                                    >
                                      <Icon className="fal fa-pen" />
                                    </span>
                                  </React.Fragment>
                                )}
                            </div>
                          ))}
                        </Table.Cell>
                      </React.Fragment>
                    ))}
                  </Table.Row>
                )}
              </Table.Body>
            </CustomTable>
            {showExpand() && (
              <span
                className={addNotelinkStyles}
                onClick={() => setShowAllRemittances(!showAllRemittances)}
              >
                {showAllRemittances ? (
                  <i className="fal fa-compress-alt" />
                ) : (
                  <i className="fal fa-expand-alt" />
                )}
              </span>
            )}
          </span>
        </div>
      )}
      {!initialLoading && !remittanceTargets.length > 0 && (
        <Message size="big" style={{ marginTop: 32 }}>
          No remittance targets set.
          <br />
          {user.isOwnerPlus && (
            <Button
              style={{ marginTop: 24 }}
              onClick={() =>
                history.push(
                  `/order-entry-instructions/edit-remittance-targets/${selectedState}`
                )
              }
            >
              Set Remittance Targets
            </Button>
          )}
        </Message>
      )}
      {noteToDelete && (
        <Modal
          size={"tiny"}
          open={noteToDelete !== null}
          onClose={() =>
            additional
              ? setState(state => ({
                  ...state,
                  additionalNoteToDelete: null
                }))
              : additionalOverTenM
              ? setState(state => ({
                  ...state,
                  additionalOverTenMNoteToDelete: null
                }))
              : additionalOverFifteenM
              ? setState(state => ({
                  ...state,
                  additionalOverFifteenMNoteToDelete: null
                }))
              : additionalOverTwentyFiveM
              ? setState(state => ({
                  ...state,
                  additionalOverTwentyFiveMNoteToDelete: null
                }))
              : setState(state => ({ ...state, noteToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              {noteToDelete.subject && <strong>{noteToDelete.subject}</strong>}?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                additional
                  ? setState(state => ({
                      ...state,
                      additionalNoteToDelete: null
                    }))
                  : additionalOverTenM
                  ? setState(state => ({
                      ...state,
                      additionalOverTenMNoteToDelete: null
                    }))
                  : additionalOverFifteenM
                  ? setState(state => ({
                      ...state,
                      additionalOverFifteenMNoteToDelete: null
                    }))
                  : additionalOverTwentyFiveM
                  ? setState(state => ({
                      ...state,
                      additionalOverTwentyFiveMNoteToDelete: null
                    }))
                  : setState(state => ({ ...state, noteToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteRemittanceTargetNote(noteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {remittanceToDelete && (
        <Modal
          size={"tiny"}
          open={remittanceToDelete !== null}
          onClose={() =>
            additional
              ? setState(state => ({
                  ...state,
                  additionalRemittanceToDelete: null
                }))
              : additionalOverTenM
              ? setState(state => ({
                  ...state,
                  additionalOverTenMRemittanceToDelete: null
                }))
              : additionalOverFifteenM
              ? setState(state => ({
                  ...state,
                  additionalOverFifteenMRemittanceToDelete: null
                }))
              : additionalOverTwentyFiveM
              ? setState(state => ({
                  ...state,
                  additionalOverTwentyFiveMRemittanceToDelete: null
                }))
              : setState(state => ({ ...state, remittanceToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete File Number</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              <strong>{remittanceToDelete.fileNumber}</strong>?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                additional
                  ? setState(state => ({
                      ...state,
                      additionalRemittanceToDelete: null
                    }))
                  : additionalOverTenM
                  ? setState(state => ({
                      ...state,
                      additionalOverTenMRemittanceToDelete: null
                    }))
                  : setState(state => ({
                      ...state,
                      remittanceToDelete: null
                    }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteRemittance(remittanceToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default React.memo(RemittanceTargetTable);
