import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Input } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddLPEndorsementNote({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    note: {},
    editMode: match.path.includes("edit"),
    errorList: []
  });

  async function getLPEndorsementNoteById(id) {
    const result = await getRequest(`/endorsement/getLPEndorsementNoteById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        note: result.data
      }));
    }
  }

  useEffect(() => {
    if (state.editMode) {
      getLPEndorsementNoteById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    const result = await postRequest(`/endorsement/editAddLPEndorsementNote`, {
      ...state.note,
      stateId: state.editMode ? state.note.stateId : +match.params.state
    });
    if (result.success) {
      logEvent(
        null,
        state.editMode ? "Edited" : "Added",
        "LP Endorsement Note"
      );
      history.goBack();
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Note`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.note.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      note: {
                        ...state.note,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.note.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      note: {
                        ...state.note,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddLPEndorsementNote);
