import { css } from "emotion";

export let paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  fontFamily: "calibri,sans-serif",
  fontWeight: 300,
  fontStyle: "italic",
  cursor: "pointer"
});
