import React, { useContext } from "react";
import { Button, Table } from "semantic-ui-react";
import { css } from "emotion";
import { withRouter } from "react-router-dom";
import { Highlight } from "../../search/Highlight";
import { postRequest, getRequest } from "../../../helpers/ApiHelpers";
import { paragraphStyles } from "./paragraphStyles";
import { UserContext } from "../../User";
import { Popup } from "../../Popup";
import CustomTable from "../../CustomTable";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";

import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";

function HelpfulWordingRowSort({
  history,
  match: { params },
  helpfulWordings,
  setHelpfulWordings,
  searchResults,
  deleteHelpfulWording
}) {
  const { addToast } = useToasts();
  const { user } = useContext(UserContext);
  const userCanEdit = user.isAdmin || user.isOwner || user.isOwnerPlus;

  async function saveSort(helpfulWordings) {
    postRequest("/policyHelpfulWording/sortHelpfulWordings", helpfulWordings);
  }

  async function download(id) {
    const result = await getRequest(
      `/policyHelpfulWording/downloadHelpfulWording?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  const DragHandle = SortableHandle(() => (
    <span
      className={css({
        marginRight: 8,
        color: "#c3c3c3",
        cursor: "grab"
      })}
    >
      <i className="fal fa-sort" />
    </span>
  ));

  const SortableItem = SortableElement(({ value: w }) => {
    return (
      <Table.Row verticalAlign="middle" key={w.id}>
        <Table.Cell
          className={css({
            textAlign: "left !important",
            border: "none !important",
            position: "relative !important"
          })}
        >
          <Popup
            on={["hover", "focus"]}
            header={w.subject}
            content={w.body}
            trigger={
              <>
                <span className={css({ fontFamily: "Fakt-Bold" })}>
                  <Highlight children={w.subject} />
                </span>
                <br />
                <p className={paragraphStyles} style={{ marginTop: 16 }}>
                  <Highlight children={w.note} />
                </p>
              </>
            }
          />
        </Table.Cell>
        <Table.Cell
          style={{
            textAlign: "right"
          }}
          verticalAlign="top"
        >
          <CopyToClipboard
            text={w.body}
            onCopy={() =>
              addToast("Copied!", {
                appearance: "success",
                autoDismiss: true
              })
            }
          >
            <Button icon>
              <i className="fal fa-clone" />
            </Button>
          </CopyToClipboard>
          <a
            href={`mailto:?subject=${encodeURIComponent(
              w.subject
            )}&body=${encodeURIComponent(w.body || "")}`}
          >
            <Button icon>
              <i className="fal fa-envelope-open" />
            </Button>
          </a>
          {userCanEdit && (
            <>
              <Button icon onClick={() => deleteHelpfulWording(w)}>
                <i className="fal fa-trash-alt" />
              </Button>
              <Button
                icon
                onClick={() =>
                  history.push(
                    `/policy-instructions/edit-helpful-wording/${w.id}`
                  )
                }
              >
                <i className="fal fa-pencil" />
              </Button>
              <DragHandle />
            </>
          )}
        </Table.Cell>
      </Table.Row>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    return (
      <CustomTable
        customStyles={{
          "& tr td:first-of-type": {
            paddingLeft: "40.5px !important"
          },
          "& tr td": {
            paddingTop: "18px !important",
            paddingBottom: "18px !important"
          },
          "& th": {
            position: "sticky",
            top: 0,
            zIndex: 1
          },
          "& tr th:not(:first-of-type), tr td:not(:first-of-type)": {
            borderLeft: "none !important"
          },
          "& tr th:first-of-type, tr td:first-of-type": {
            paddingLeft: "60px !important"
          },
          marginBottom: "40px !important",
          marginTop: !searchResults ? "29px !important" : 0
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="left">Subject</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((value, index) => {
            return (
              <SortableItem
                key={`item-${value.id}`}
                index={index}
                value={value}
              />
            );
          })}
        </Table.Body>
      </CustomTable>
    );
  });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const sortedItems = arrayMove(helpfulWordings, oldIndex, newIndex).map(
      (e, i) => ({
        ...e,
        sortNum: i
      })
    );
    saveSort(sortedItems);
    setHelpfulWordings(sortedItems);
  };

  return (
    <SortableList items={helpfulWordings} onSortEnd={onSortEnd} useDragHandle />
  );
}

export default withRouter(HelpfulWordingRowSort);
