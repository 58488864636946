import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  TextArea,
  Input,
  Checkbox
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddSearchGuidelineNote({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    searchGuidelineNote: {},
    editMode:
      match.path === "/order-entry-instructions/edit-search-guideline-note/:id",
    errorList: []
  });

  async function getSearchGuidelineNoteById(id) {
    const result = await getRequest(
      `/searchGuideline/getSearchGuidelineNoteById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        searchGuidelineNote: result.data
      }));
    }
  }

  useEffect(() => {
    if (state.editMode) {
      getSearchGuidelineNoteById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/searchGuideline/${
            state.editMode
              ? "editSearchGuidelineNote"
              : "addSearchGuidelineNote"
          }`,
          {
            ...state.searchGuidelineNote,
            stateId: selectedState
          }
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Search Guideline Note"
          );
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.searchGuidelineNote.note) {
      list.push("You must provide a note.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.searchGuidelineNote]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Search Guideline Note`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.searchGuidelineNote.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuidelineNote: {
                        ...state.searchGuidelineNote,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.searchGuidelineNote.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      searchGuidelineNote: {
                        ...state.searchGuidelineNote,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Visible on Commitment Instructions
                  <Checkbox
                    checked={
                      state.searchGuidelineNote.visibleOnCommitmentInstructions
                    }
                    onChange={() => {
                      setState((state) => ({
                        ...state,
                        searchGuidelineNote: {
                          ...state.searchGuidelineNote,
                          visibleOnCommitmentInstructions: !state
                            .searchGuidelineNote.visibleOnCommitmentInstructions
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddSearchGuidelineNote);
