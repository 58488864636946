import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Divider, Grid, Header, Label, Modal } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { Unavailable } from "../../Unavailable";
import { Highlight } from "../../search/Highlight";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  marginTop: "16px !important"
});

const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 14,
  marginTop: 24
});

const templateParagraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  marginTop: "4px !important"
});

const templateLabelStyles = css({
  color: "#444444",
  fontFamily: "Fakt-Bold",
  fontSize: 14
});

function GeneralInfo({ history }) {
  const { user } = useContext(UserContext);
  const { selectedState, states } = useContext(StateContext);

  const isLiscensed =
    states.length > 0 && states.find((s) => s.id === selectedState)
      ? states.find((s) => s.id === selectedState).isLicensed
      : undefined;

  const [state, setState] = useState({
    generalInfo: {},
    generalRules: [],
    generalRuleToDelete: null,
    noResults: false,
    initialLoaded: false
  });

  async function getGeneralInfoByState() {
    const result = await getRequest("/generalInfo/getGeneralInfoByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        generalInfo: result.data,
        initialLoaded: true
      }));
    }
  }

  async function getGeneralRulesByState() {
    const result = await getRequest("/generalInfo/getGeneralRulesByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        generalRules: result.data,
        initialLoaded: true
      }));
    }
  }

  async function deleteGeneralRule(id) {
    const result = await postRequest(
      `/generalInfo/deleteGeneralRule?id=${id}`,
      id
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        generalRules: state.generalRules.filter((r) => r.id !== id),
        generalRuleToDelete: null
      }));
    }
  }

  useEffect(() => {
    if (selectedState) {
      getGeneralInfoByState();
      getGeneralRulesByState();
    }
  }, [selectedState]);

  async function download(id) {
    const result = await getRequest(
      `/generalInfo/downloadGeneralRuleFile?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  return (
    <div className={css({ paddingBottom: 40 })}>
      <Grid className={css({ marginBottom: "16px !important" })}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                icon
                className="blueButton"
                onClick={() =>
                  history.push(
                    `/order-entry-instructions/new-general-rule/${selectedState}`
                  )
                }
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() =>
                  history.push(
                    `/order-entry-instructions/new-general-rule/${selectedState}`
                  )
                }
              >
                New General Rule
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {selectedState !== 52 && (
        <Card.Group itemsPerRow={1}>
          <Card>
            <Card.Content>
              <Grid>
                <Grid.Row columns={2} verticalAlign="middle">
                  <Grid.Column floated="left">
                    {isLiscensed ? (
                      <Label
                        className="primaryLabel"
                        style={{ paddingLeft: 24, paddingRight: 24 }}
                      >
                        Licensed
                      </Label>
                    ) : (
                      <Label
                        className="secondaryLabel"
                        style={{ paddingLeft: 24, paddingRight: 24 }}
                      >
                        Unlicensed
                      </Label>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    floated="right"
                    className={css({ textAlign: "right" })}
                  >
                    {(user.isOwner || user.isOwnerPlus) && (
                      <Button
                        icon
                        onClick={() =>
                          history.push(
                            `/order-entry-instructions/states/${selectedState}/edit-general-info`
                          )
                        }
                      >
                        <i className="fal fa-pencil" />
                      </Button>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {state.generalInfo.licensedAndUnlicensed && (
                <p className={paragraphStyles}>
                  {state.generalInfo.licensedAndUnlicensed}
                </p>
              )}
              {(state.generalInfo.purchase ||
                state.generalInfo.refinance ||
                state.generalInfo.searchOnly) && (
                <React.Fragment>
                  <Header
                    as="h3"
                    className={css({
                      color: "#47517b !important",
                      fontSize: "20px !important",
                      marginBottom: "16px !important"
                    })}
                  >
                    Template(s)
                  </Header>
                  <Grid>
                    <Grid.Row columns={3}>
                      {state.generalInfo.purchase && (
                        <Grid.Column
                          className={css({ marginBottom: "0px !important" })}
                        >
                          <div className={templateLabelStyles}>Purchase</div>
                          <p className={templateParagraphStyles}>
                            {state.generalInfo.purchase}
                          </p>
                        </Grid.Column>
                      )}
                      {state.generalInfo.refinance && (
                        <Grid.Column
                          className={css({ marginBottom: "0px !important" })}
                        >
                          <div className={templateLabelStyles}>Refinance</div>
                          <p className={templateParagraphStyles}>
                            {state.generalInfo.refinance}
                          </p>
                        </Grid.Column>
                      )}
                      {state.generalInfo.searchOnly && (
                        <Grid.Column
                          className={css({ marginBottom: "0px !important" })}
                        >
                          <div className={templateLabelStyles}>Search Only</div>
                          <p className={templateParagraphStyles}>
                            {state.generalInfo.searchOnly}
                          </p>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                    {(state.generalInfo.residentialPurchaseCDF ||
                      state.generalInfo.residentialPurchaseHUD ||
                      state.generalInfo.residentialCashPurchase) && (
                      <Grid.Row columns={3}>
                        {state.generalInfo.residentialPurchaseCDF && (
                          <Grid.Column
                            className={css({ marginBottom: "0px !important" })}
                          >
                            <div className={templateLabelStyles}>
                              Residential Purchase (CDF)
                            </div>
                            <p className={templateParagraphStyles}>
                              {state.generalInfo.residentialPurchaseCDF}
                            </p>
                          </Grid.Column>
                        )}
                        {state.generalInfo.residentialPurchaseHUD && (
                          <Grid.Column
                            className={css({ marginBottom: "0px !important" })}
                          >
                            <div className={templateLabelStyles}>
                              Residential Purchase (HUD)
                            </div>
                            <p className={templateParagraphStyles}>
                              {state.generalInfo.residentialPurchaseHUD}
                            </p>
                          </Grid.Column>
                        )}
                        {state.generalInfo.residentialCashPurchase && (
                          <Grid.Column
                            className={css({ marginBottom: "0px !important" })}
                          >
                            <div className={templateLabelStyles}>
                              Residential Cash Purchase
                            </div>
                            <p className={templateParagraphStyles}>
                              {state.generalInfo.residentialCashPurchase}
                            </p>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    )}
                    {(state.generalInfo.residentialRefiCDF ||
                      state.generalInfo.residentialRefiHUD ||
                      state.generalInfo.residentialSearchOnly) && (
                      <Grid.Row columns={3}>
                        {state.generalInfo.residentialRefiCDF && (
                          <Grid.Column
                            className={css({ marginBottom: "0px !important" })}
                          >
                            <div className={templateLabelStyles}>
                              Residential Refi (CDF)
                            </div>
                            <p className={templateParagraphStyles}>
                              {state.generalInfo.residentialRefiCDF}
                            </p>
                          </Grid.Column>
                        )}
                        {state.generalInfo.residentialRefiHUD && (
                          <Grid.Column
                            className={css({ marginBottom: "0px !important" })}
                          >
                            <div className={templateLabelStyles}>
                              Residential Refi (HUD)
                            </div>
                            <p className={templateParagraphStyles}>
                              {state.generalInfo.residentialRefiHUD}
                            </p>
                          </Grid.Column>
                        )}
                        {state.generalInfo.residentialSearchOnly && (
                          <Grid.Column
                            className={css({ marginBottom: "0px !important" })}
                          >
                            <div className={templateLabelStyles}>
                              Residential Search Only
                            </div>
                            <p className={templateParagraphStyles}>
                              {state.generalInfo.residentialSearchOnly}
                            </p>
                          </Grid.Column>
                        )}
                      </Grid.Row>
                    )}
                  </Grid>
                </React.Fragment>
              )}
              {state.generalInfo.stateRules && (
                <React.Fragment>
                  <Header
                    as="h3"
                    className={css({
                      color: "#47517b !important",
                      fontSize: "20px !important",
                      marginTop: "32px !important",
                      marginBottom: "16px !important"
                    })}
                  >
                    State Specific Rules
                  </Header>
                  <p className={paragraphStyles}>
                    {state.generalInfo.stateRules ? (
                      state.generalInfo.stateRules
                    ) : (
                      <Unavailable />
                    )}
                  </p>
                </React.Fragment>
              )}
            </Card.Content>
          </Card>
        </Card.Group>
      )}
      {state.generalRules && state.generalRules.length > 0 && (
        <React.Fragment>
          <Divider
            horizontal
            className={css({
              marginTop: selectedState !== 52 ? "38px !important" : null
            })}
          >
            <Header as="h4">General Rules</Header>
          </Divider>
          {state.generalRules.map((r) => (
            <Card fluid style={{ padding: 28 }} key={r.id}>
              <Card.Content>
                {(user.isOwner || user.isOwnerPlus) && (
                  <React.Fragment>
                    <i
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          generalRuleToDelete: r
                        }))
                      }
                      className="fal fa-trash"
                      style={{
                        position: "absolute",
                        right: 56,
                        cursor: "pointer"
                      }}
                    />
                    <i
                      onClick={() =>
                        history.push(
                          `/order-entry-instructions/edit-general-rule/${r.id}`
                        )
                      }
                      className="fal fa-pencil"
                      style={{
                        position: "absolute",
                        right: 28,
                        cursor: "pointer"
                      }}
                    />
                  </React.Fragment>
                )}
                <Card.Header content={r.subject} />
                <Card.Description
                  className={paragraphStyles}
                  content={r.note}
                />
                {r.orderEntryGeneralRuleFiles &&
                  r.orderEntryGeneralRuleFiles.length > 0 && (
                    <div className={labelStyles}>Attachments</div>
                  )}
                {r.orderEntryGeneralRuleFiles &&
                  r.orderEntryGeneralRuleFiles.map((f) => (
                    <p
                      className={{
                        ...paragraphStyles,
                        marginTop: "0px !important"
                      }}
                      key={f.id}
                    >
                      <a
                        onClick={() => download(f.id)}
                        className={css({
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#00b8d4"
                          }
                        })}
                      >
                        <i
                          className="fal fa-paperclip"
                          style={{ color: "#00b8d4", marginRight: 8 }}
                        />
                        <Highlight children={f.fileName} />
                      </a>
                    </p>
                  ))}
              </Card.Content>
            </Card>
          ))}
        </React.Fragment>
      )}
      {state.generalRuleToDelete && (
        <Modal
          size={"tiny"}
          open={state.generalRuleToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, generalRuleToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Rule</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, generalRuleToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteGeneralRule(state.generalRuleToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

export default withRouter(GeneralInfo);
