import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Modal, Divider, Header } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import DragSortCharges from "./DragSortCharges";

function InvoiceCharges({
  history,
  match: { params },
  invoiceChargeSearchResults,
  editable,
  sortable
}) {
  const { user } = useContext(UserContext);
  const { selectedState, states } = useContext(StateContext);
  const userCanEdit = user.isOwner || user.isOwnerPlus;

  const [state, setState] = useState({
    selectedRateType: null,
    initialLoading: true,
    noResults: false,
    types: [
      { name: "Invoice Charges", path: "invoice-charges" },
      { name: "Taxes", path: "taxes" },
      { name: "Agency Agreements", path: "agency-agreements" },
      { name: "Premiums", path: "premiums" },
      { name: "Manuals", path: "manuals" }
    ],
    invoiceCharges: [],
    invoiceChargeSearchResults: invoiceChargeSearchResults,
    chargeToEdit: {},
    chargeToDelete: null,
    submitting: false
  });

  async function getInvoiceChargesByState() {
    const result = await getRequest("/invoiceCharge/getInvoiceChargesByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({
        ...state,
        invoiceCharges: result.data,
        chargeToEdit: {}
      }));
    }
  }

  async function deleteInvoiceCharge(id) {
    const result = await postRequest(
      `/invoiceCharge/deleteInvoiceCharge?id=${id}`
    );
    if (result.success) {
      setState(state => ({
        ...state,
        invoiceCharges: state.invoiceCharges.filter(w => w.id !== id),
        invoiceChargeSearchResults: state.invoiceChargeSearchResults
          ? state.invoiceChargeSearchResults.filter(w => w.id !== id)
          : null,
        chargeToDelete: null
      }));
    }
  }

  async function saveChanges() {
    setState(state => ({
      ...state,
      submitting: true
    }));
    const result = await postRequest(
      "/invoiceCharge/editInvoiceCharge",
      state.chargeToEdit
    );
    if (result.success) {
      setState(state => ({
        ...state,
        chargeToEdit: {},
        invoiceCharges: !invoiceChargeSearchResults
          ? state.invoiceCharges.map(ic =>
              ic.id === result.data.id ? result.data : ic
            )
          : [],
        invoiceChargeSearchResults: invoiceChargeSearchResults
          ? state.invoiceChargeSearchResults.map(ic =>
              +ic.id === result.data.id ? result.data : ic
            )
          : [],
        submitting: false
      }));
    }
  }

  useEffect(() => {
    if (!invoiceChargeSearchResults) {
      const selectedType = params.type;
      getInvoiceChargesByState();
      setState({
        ...state,
        selectedRateType: selectedType,
        initialLoading: !selectedState
      });
    }
  }, [params.state, params.type, selectedState]);

  let filteredInvoiceCharges = invoiceChargeSearchResults
    ? state.invoiceChargeSearchResults
    : state.invoiceCharges;

  if (!editable) {
    filteredInvoiceCharges = filteredInvoiceCharges.filter(
      c => c.visibleOnCommitmentInstructions
    );
  }

  return (
    <React.Fragment>
      {((state.invoiceCharges.length > 0 && !editable) || editable) && (
        <React.Fragment>
          {!editable && state.invoiceCharges.length > 0 && (
            <Divider horizontal>
              <Header as="h4">Invoice Charges</Header>
            </Divider>
          )}
          {!invoiceChargeSearchResults && editable && (
            <Grid>
              <Grid.Row columns={2} verticalAlign="middle">
                {userCanEdit && !invoiceChargeSearchResults && (
                  <Grid.Column
                    floated="right"
                    className={css({ textAlign: "right" })}
                  >
                    <Button
                      icon
                      className="blueButton"
                      onClick={() =>
                        history.push(
                          `/rates/new-invoice-charge/${selectedState}`
                        )
                      }
                      data-automation="addInvoiceCharge"
                    >
                      <i className="fal fa-plus" />
                    </Button>
                    <span
                      className={css({
                        fontFamily: "Fakt-Bold",
                        fontSize: 14,
                        color: "#000",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#00b8d4"
                        }
                      })}
                      onClick={() =>
                        history.push(
                          `/rates/new-invoice-charge/${selectedState}`
                        )
                      }
                    >
                      New Invoice Charge
                    </span>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          )}
          <div
            className={css({
              marginBottom: 19.75,
              marginTop: !invoiceChargeSearchResults && editable ? 40 : 0
            })}
          >
            {filteredInvoiceCharges.length > 0 && (
              <DragSortCharges
                items={filteredInvoiceCharges}
                saveSortPath={`/invoiceCharge/sortCharges`}
                draggable={userCanEdit && !invoiceChargeSearchResults}
                sortable={sortable}
                deleteAction={value =>
                  setState(state => ({ ...state, chargeToDelete: value }))
                }
                chargeToEdit={state.chargeToEdit}
                setState={setState}
                selectedState={selectedState}
                saveChanges={saveChanges}
                editable={editable}
              />
            )}
          </div>
          {state.chargeToDelete && (
            <Modal
              size={"tiny"}
              open={state.chargeToDelete !== null}
              onClose={() =>
                setState(state => ({ ...state, chargeToDelete: null }))
              }
              dimmer="inverted"
            >
              <Modal.Header>Delete Invoice Charge</Modal.Header>
              <Modal.Content>
                <p>
                  Are you sure you want to delete{" "}
                  <strong>{state.chargeToDelete.title}</strong>?
                </p>
                <p>
                  This action will delete it from all associated states below.
                  {state.chargeToDelete.invoiceChargeStates.map(ics => (
                    <li>{states.find(s => s.id === ics.stateId).name}</li>
                  ))}
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  negative
                  onClick={() =>
                    setState(state => ({ ...state, chargeToDelete: null }))
                  }
                  style={{
                    backgroundColor: "#fff",
                    border: "solid 1px #e5e5ea",
                    color: "rgba(0, 0, 0, 0.87)"
                  }}
                >
                  No
                </Button>
                <Button
                  positive
                  onClick={() => deleteInvoiceCharge(state.chargeToDelete.id)}
                  style={{ backgroundColor: "#1f2b5e" }}
                >
                  Yes
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default withRouter(React.memo(InvoiceCharges));
