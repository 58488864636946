import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { css } from "emotion";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { Button, Grid, Message, Modal } from "semantic-ui-react";
import { StateContext } from "../../State";
import ManualCard from "./ManualCard";
import { UserContext } from "../../User";
import shortid from "shortid";

function Manuals({ history, match: { params }, manualSearchResults }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    manuals: {},
    manualSearchResults: manualSearchResults,
    initialLoading: true,
    underwriters: [],
    manualToDelete: null
  });

  async function getManualsByState() {
    const result = await getRequest("/manual/getManualsByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({
        ...state,
        manuals: result.data
      }));
    }
  }

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data
      }));
    }
  }

  useEffect(() => {
    getUnderwriters();
    if (!manualSearchResults) {
      getManualsByState();
    }
  }, []);

  useEffect(() => {
    if (!manualSearchResults) {
      getManualsByState();
    }
  }, [selectedState]);

  async function deleteManual(id) {
    const result = await postRequest(`/manual/deleteManual?id=${id}`);
    if (result.success) {
      setState(state => ({
        ...state,
        manuals: !manualSearchResults
          ? state.manuals.filter(m => m.id !== id)
          : [],
        manualSearchResults: manualSearchResults
          ? state.manualSearchResults.filter(m => m.id !== id)
          : [],
        manualToDelete: null
      }));
    }
  }

  const filteredManuals = manualSearchResults
    ? state.manualSearchResults
    : state.manuals;

  return (
    <React.Fragment>
      {!manualSearchResults && (
        <Grid>
          <Grid.Row columns={2} verticalAlign="middle">
            {(user.isOwner || user.isOwnerPlus) && (
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                <Button
                  icon
                  className="blueButton"
                  onClick={() => history.push(`/rates/new-manual`)}
                  data-automation="addInvoiceCharge"
                >
                  <i className="fal fa-plus" />
                </Button>
                <span
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 14,
                    color: "#000",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00b8d4"
                    }
                  })}
                  onClick={() => history.push(`/rates/new-manual`)}
                >
                  New Manual
                </span>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
      <div
        className={css({
          marginBottom: 19.75,
          marginTop: !manualSearchResults ? 28 : 0
        })}
      >
        {filteredManuals.length > 0 ? (
          filteredManuals.map(m => (
            <ManualCard
              manual={m}
              key={shortid.generate()}
              deleteManual={() =>
                setState(state => ({
                  ...state,
                  manualToDelete: m
                }))
              }
            />
          ))
        ) : (
          <Message size="big">No available manuals...</Message>
        )}
      </div>
      {state.manualToDelete && (
        <Modal
          size={"tiny"}
          open={state.manualToDelete !== null}
          onClose={() =>
            setState(state => ({ ...state, manualToDelete: null }))
          }
          dimmer="inverted"
          data-automation="confirmDeleteModal"
        >
          <Modal.Header>Delete Manual</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              <strong>{state.manualToDelete.name}</strong>?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <React.Fragment>
              <Button
                negative
                onClick={() =>
                  setState(state => ({ ...state, manualToDelete: null }))
                }
                style={{
                  backgroundColor: "#fff",
                  border: "solid 1px #e5e5ea",
                  color: "rgba(0, 0, 0, 0.87)"
                }}
              >
                No
              </Button>
              <Button
                positive
                onClick={() => deleteManual(state.manualToDelete.id)}
                style={{ backgroundColor: "#1f2b5e" }}
              >
                Yes
              </Button>
            </React.Fragment>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(Manuals);
