import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import ExceptionCodes from "./ExceptionCodes";

function SurveyScheduleB({ history }) {
  const { user } = useContext(UserContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  return (
    <React.Fragment>
      {userCanEdit && (
        <Grid style={{ marginBottom: 17 }}>
          <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                icon
                className="blueButton"
                onClick={() =>
                  history.push(`/surveys/new-survey-exception-code/sections/1`)
                }
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() =>
                  history.push(`/surveys/new-survey-exception-code/sections/1`)
                }
              >
                New Code
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <ExceptionCodes />
    </React.Fragment>
  );
}
export default withRouter(SurveyScheduleB);
