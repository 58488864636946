import React, { useState, useEffect, useContext } from "react";
import { Menu } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import OPEndorsements from "./OPEndorsements";
import LPEndorsements from "./LPEndorsements";
import Calculators from "./Calculators";
import QuoteTemplates from "./QuoteTemplates";

function Premiums({ history, match: { params } }) {
  const { selectedState } = useContext(StateContext);

  const [state, setState] = useState({
    selectedSection: params.section || "op-endorsements"
  });

  useEffect(() => {
    setState({
      ...state,
      selectedSection: params.section || "op-endorsements",
      initialLoading: false
    });
  }, [params.section]);

  if (!selectedState) {
    return <div />;
  }
  
  return (
    <React.Fragment>
      <Menu tabular style={{ marginTop: 55, marginBottom: 32 }}>
        <Menu.Item
          style={{ marginLeft: 21 }}
          name="OP Endorsements"
          active={state.selectedSection === "op-endorsements"}
          onClick={() =>
            history.push(
              `/rates/states/${selectedState}/types/premiums/sections/op-endorsements`
            )
          }
        />
        <Menu.Item
          name="LP Endorsements"
          active={state.selectedSection === "lp-endorsements"}
          onClick={() =>
            history.push(
              `/rates/states/${selectedState}/types/premiums/sections/lp-endorsements`
            )
          }
        />
        <Menu.Item
          name="Premium Calculators"
          active={state.selectedSection === "calculators"}
          onClick={() =>
            history.push(
              `/rates/states/${selectedState}/types/premiums/sections/calculators`
            )
          }
        />
        <Menu.Item
          name="Quote Templates"
          active={state.selectedSection === "quote-templates"}
          onClick={() =>
            history.push(
              `/rates/states/${selectedState}/types/premiums/sections/quote-templates`
            )
          }
        />
      </Menu>
      {state.selectedSection === "op-endorsements" && <OPEndorsements />}
      {state.selectedSection === "lp-endorsements" && <LPEndorsements />}
      {state.selectedSection === "calculators" && <Calculators />}
      {state.selectedSection === "quote-templates" && <QuoteTemplates />}
    </React.Fragment>
  );
}

export default withRouter(Premiums);
