import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { css } from "emotion";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { Button, Grid, Message, Modal } from "semantic-ui-react";
import { UserContext } from "../../User";
import shortid from "shortid";
import QuoteTemplateCard from "./QuoteTemplateCard";
import { StateContext } from "../../State";

function QuoteTemplates({
  history,
  match: { params },
  quoteTemplateSearchResults
}) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    quoteTemplates: [],
    quoteTemplateSearchResults: quoteTemplateSearchResults,
    initialLoading: true,
    quoteTemplateToDelete: null
  });

  async function getQuoteTemplatesByState() {
    const result = await getRequest(`/quoteTemplate/getQuoteTemplatesByState`, {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({
        ...state,
        quoteTemplates: result.data
      }));
    }
  }

  async function deleteQuoteTemplate(id) {
    const result = await postRequest(
      `/quoteTemplate/deleteQuoteTemplate?id=${id}`
    );
    if (result.success) {
      setState(state => ({
        ...state,
        quoteTemplates: !quoteTemplateSearchResults
          ? state.quoteTemplates.filter(q => q.id !== id)
          : [],
        quoteTemplateSearchResults: quoteTemplateSearchResults
          ? state.quoteTemplateSearchResults.filter(q => q.id !== id)
          : [],
        quoteTemplateToDelete: null
      }));
    }
  }

  useEffect(() => {
    if (!quoteTemplateSearchResults) {
      getQuoteTemplatesByState();
    }
  }, []);

  useEffect(() => {
    if (!quoteTemplateSearchResults) {
      getQuoteTemplatesByState();
    }
  }, [selectedState]);

  const filteredQuoteTemplates = quoteTemplateSearchResults
    ? state.quoteTemplateSearchResults
    : state.quoteTemplates;

  return (
    <React.Fragment>
      {!quoteTemplateSearchResults && (
        <Grid>
          <Grid.Row columns={2} verticalAlign="middle">
            {user.isOwnerPlus && (
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                <Button
                  icon
                  className="blueButton"
                  onClick={() => history.push(`/rates/new-quote-template`)}
                >
                  <i className="fal fa-plus" />
                </Button>
                <span
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 14,
                    color: "#000",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00b8d4"
                    }
                  })}
                  onClick={() => history.push(`/rates/new-quote-template`)}
                >
                  Add Quote Template
                </span>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
      <div
        className={css({
          marginBottom: 19.75,
          marginTop: !quoteTemplateSearchResults ? 28 : 0
        })}
      >
        {filteredQuoteTemplates.length > 0 ? (
          filteredQuoteTemplates.map(qt => (
            <QuoteTemplateCard
              quoteTemplate={qt}
              deleteQuoteTemplate={() =>
                setState(state => ({
                  ...state,
                  quoteTemplateToDelete: qt
                }))
              }
              key={shortid.generate()}
            />
          ))
        ) : (
          <Message size="big">No available quote templates...</Message>
        )}
      </div>
      {state.quoteTemplateToDelete && (
        <Modal
          size={"tiny"}
          open={state.quoteTemplateToDelete !== null}
          onClose={() =>
            setState(state => ({ ...state, quoteTemplateToDelete: null }))
          }
          dimmer="inverted"
          data-automation="confirmDeleteModal"
        >
          <Modal.Header>Delete Quote Template</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              <strong>{state.quoteTemplateToDelete.name}</strong>?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <React.Fragment>
              <Button
                negative
                onClick={() =>
                  setState(state => ({ ...state, quoteTemplateToDelete: null }))
                }
                style={{
                  backgroundColor: "#fff",
                  border: "solid 1px #e5e5ea",
                  color: "rgba(0, 0, 0, 0.87)"
                }}
              >
                No
              </Button>
              <Button
                positive
                onClick={() =>
                  deleteQuoteTemplate(state.quoteTemplateToDelete.id)
                }
                style={{ backgroundColor: "#1f2b5e" }}
              >
                Yes
              </Button>
            </React.Fragment>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(QuoteTemplates);
