import React from "react";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Card,
  Label,
  Table,
  Message,
  Popup
} from "semantic-ui-react";
import { Unavailable } from "../Unavailable";
import { withRouter } from "react-router-dom";
import { SectionTitle } from "../customElements/SectionTitle";
import { Highlight } from "../search/Highlight";
import { getRequest } from "../../helpers/ApiHelpers";
import { convertToFileDownload } from "../../helpers/ConvertToFileDownload";
import { eAndODepartments } from "./eAndODepartments";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  marginBottom: "0px !important"
});
const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 8,
  marginTop: 24
});

const eAndOLabelStyles = css({
  fontFamily: "Fakt-Normal",
  fontSize: 14,
  color: "#707898",
  marginBottom: 8,
  marginTop: 24
});

async function download(id) {
  const result = await getRequest(`/contact/downloadContactFile?id=${id}`);
  if (result.success) {
    convertToFileDownload(result.data.base64File, result.data.name);
  }
}

function IndividualCard({
  contact,
  history,
  deleteContact,
  isAdmin,
  states,
  noState,
  draggable,
  dragHandle
}) {
  const formattedBody = `${
    contact.firstName || contact.lastName
      ? "Name: " +
        (contact.firstName || "") +
        " " +
        (contact.lastName || "") +
        "\n"
      : ""
  }${contact.firmName ? "Firm: " + contact.firmName + "\n" : ""}${
    contact.address1 || contact.city || contact.state || contact.zip
      ? "Address: " +
        (contact.address1 ? contact.address1 + " " : "") +
        (contact.address2 ? contact.address2 + " " : "") +
        (contact.city ? contact.city + " " : "") +
        (contact.state ? contact.state + " " : "") +
        (contact.zip ? contact.zip + " " : "") +
        "\n"
      : ""
  }${contact.phoneDirect ? "Phone: " + contact.phoneDirect + "\n" : ""}${
    contact.cell ? "Cell: " + contact.cell + "\n" : ""
  }${contact.faxDirect ? "Fax: " + contact.faxDirect + "\n" : ""}${
    contact.email ? "Email: " + contact.email + "\n" : ""
  }`;

  const EAndAInfo = ({ label, info }) => {
    return (
      <div className={css({ marginRight: 24 })}>
        <div className={eAndOLabelStyles}>
          {label}:{" "}
          <span
            className={css({
              fontFamily: "Fakt-Normal",
              color: "#000"
            })}
          >
            {info}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Card.Group itemsPerRow={1}>
      <Card>
        <Card.Content>
          {draggable && dragHandle()}
          <Grid>
            <Grid.Row columns={2} verticalAlign="middle">
              <Grid.Column floated="left" width={13}>
                <Header
                  as="h3"
                  className={css({
                    color: "#47517b !important",
                    fontSize: "20px !important",
                    marginBottom: "0px !important"
                  })}
                >
                  <Highlight
                    children={`${contact.firstName || ""} ${contact.lastName ||
                      ""} `}
                  />
                  {contact.contactTag && (
                    <React.Fragment>
                      {contact.contactTag.name === "Primary Contact" && (
                        <Label className="primaryLabel">Primary Contact</Label>
                      )}
                      {contact.contactTag.name === "Global Contact" && (
                        <Label className="thirdlyLabel">Global</Label>
                      )}
                      {contact.contactTag.name === "Last Option" && (
                        <Label className="secondaryLabel">Last Option</Label>
                      )}
                      {contact.contactTagId > 3 && (
                        <Label className="customLabel">
                          {contact.contactTag.name}
                        </Label>
                      )}
                    </React.Fragment>
                  )}
                  {contact.customTag !== "" && contact.customTag !== null && (
                    <Label className="customLabel">{contact.customTag}</Label>
                  )}
                  {noState &&
                    contact.contactStates &&
                    states &&
                    contact.contactStates.slice(0, 5).map((cs) => (
                      <Label className="secondaryLabel" key={cs.stateId}>
                        {states.find((s) => s.id === cs.stateId) &&
                          states.find((s) => s.id === cs.stateId).name}
                      </Label>
                    ))}
                  {noState &&
                    contact.contactStates &&
                    contact.contactStates.length > 5 && (
                      <Popup
                        trigger={
                          <a
                            className={css({
                              fontSize: 14,
                              textDecoration: "underline",
                              marginLeft: 16,
                              "&:hover": {
                                textDecoration: "underline !important"
                              }
                            })}
                          >
                            {contact.contactStates.length - 5} more states
                          </a>
                        }
                        content={contact.contactStates
                          .slice(5, 54)
                          .map(
                            (cs) =>
                              states.find((s) => s.id === cs.stateId) &&
                              states.find((s) => s.id === cs.stateId).name + " "
                          )}
                        size="mini"
                      />
                    )}
                </Header>
                {contact.position && (
                  <span
                    className={css({
                      fontSize: 14,
                      color: "#47517b",
                      lineHeight: 1.71,
                      marginTop: 9
                    })}
                  >
                    <Highlight children={contact.position} />
                    {contact.position && contact.firmName && ", "}
                  </span>
                )}
                {contact.firmName && (
                  <span
                    className={css({
                      fontSize: 14,
                      color: "#47517b",
                      lineHeight: 1.71,
                      marginTop: 9
                    })}
                  >
                    <Highlight children={contact.firmName} />
                  </span>
                )}
              </Grid.Column>
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
                width={3}
              >
                {isAdmin && (
                  <React.Fragment>
                    <Button
                      icon
                      onClick={() =>
                        history.push(`/contacts/edit-contact/${contact.id}`)
                      }
                      data-automation={`edit-${contact.firstName}-${contact.lastName}`}
                    >
                      <i className="fal fa-pencil" />
                    </Button>
                    <Button
                      icon
                      onClick={deleteContact}
                      data-automation={`delete-${contact.firstName}-${contact.lastName}`}
                    >
                      <i className="fal fa-trash-alt" />
                    </Button>
                  </React.Fragment>
                )}
                <a
                  href={`mailto:?subject=${encodeURIComponent(
                    `${contact.firstName || ""} ${contact.lastName || ""}`
                  )}'s%20info&body=${encodeURIComponent(formattedBody)}`}
                >
                  <Button icon>
                    <i className="fal fa-share" />
                  </Button>
                </a>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row
              columns={1}
              className={css({ paddingTop: "0 !important" })}
            >
              <Grid.Column>
                <Table
                  className={css({
                    "&.ui.table": {
                      border: "none"
                    },
                    "&.ui.table td": {
                      padding: "0px 11px 0px 0px !important"
                    },
                    "&.ui.table thead th": {
                      border: "none",
                      backgroundColor: "transparent !important",
                      paddingLeft: "0 !important",
                      paddingBottom: 8
                    }
                  })}
                  fixed
                >
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Address</Table.HeaderCell>
                      <Table.HeaderCell>Phone</Table.HeaderCell>
                      <Table.HeaderCell>Fax</Table.HeaderCell>
                      <Table.HeaderCell>Email</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    <Table.Row verticalAlign="top">
                      <Table.Cell>
                        <Highlight children={contact.address1} />{" "}
                        {contact.address1 && <br />}
                        <Highlight children={contact.address2} />{" "}
                        {contact.address2 && <br />}
                        <Highlight children={contact.city} />
                        {contact.city && contact.state && ", "}
                        <Highlight children={contact.state} />{" "}
                        <Highlight children={contact.zip} />
                      </Table.Cell>
                      <Table.Cell>
                        <a href={`tel:${contact.phoneDirect}`}>
                          <Highlight children={contact.phoneDirect} />
                        </a>
                        {contact.phoneDirect && (
                          <span>
                            <span
                              className={css({
                                marginLeft: 8,
                                display: "inline-block"
                              })}
                            />
                            <Label basic horizontal size="mini">
                              Direct
                            </Label>
                            <br />
                          </span>
                        )}
                        <a href={`tel:${contact.cell}`}>
                          {" "}
                          <Highlight children={contact.cell} />
                        </a>
                        {contact.cell && (
                          <span>
                            <span
                              className={css({
                                marginLeft: 8,
                                display: "inline-block"
                              })}
                            />
                            <Label basic horizontal size="mini">
                              Cell
                            </Label>
                            <br />
                          </span>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <Highlight children={contact.faxDirect} />
                        {contact.faxDirect && (
                          <span>
                            <span
                              className={css({
                                marginLeft: 8,
                                display: "inline-block"
                              })}
                            />
                            <Label basic horizontal size="mini">
                              Direct
                            </Label>
                            <br />
                          </span>
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        <a href={`mailto:${contact.email}`}>
                          <Highlight children={contact.email} />
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                {contact.contactFiles && contact.contactFiles.length > 0 && (
                  <div className={labelStyles}>Attachments</div>
                )}
                {contact.contactFiles &&
                  contact.contactFiles.map((f) => (
                    <p className={paragraphStyles} key={f.id}>
                      <a
                        onClick={() => download(f.id)}
                        className={css({
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#00b8d4"
                          }
                        })}
                      >
                        <i
                          className="fal fa-paperclip"
                          style={{ color: "#00b8d4", marginRight: 8 }}
                        />
                        <Highlight children={f.fileName} />
                      </a>
                    </p>
                  ))}
                {contact.note !== null && contact.note !== "" && (
                  <React.Fragment>
                    <SectionTitle text="Notes" />
                    <Message
                      className={css({
                        boxShadow: "none !important",
                        whiteSpace: "pre-line",
                        color: "#000 !important"
                      })}
                    >
                      {contact.note ? (
                        <Highlight children={contact.note} />
                      ) : (
                        <Unavailable />
                      )}
                    </Message>
                  </React.Fragment>
                )}
                {eAndODepartments.some((d) => d === contact.departmentId) && (
                  <React.Fragment>
                    <div
                      className={css({
                        display: "flex",
                        flexDirection: "row-reverse"
                      })}
                    >
                      {contact.signedAgreement && (
                        <EAndAInfo
                          label="Signed Agreement"
                          info={contact.signedAgreement}
                        />
                      )}
                      {contact.liabilityDisclaimerOnSearch && (
                        <EAndAInfo
                          label="Liability Disclaimer on Search"
                          info={contact.liabilityDisclaimerOnSearch}
                        />
                      )}
                      {contact.eAndODate && (
                        <EAndAInfo
                          label="E&O Date"
                          info={new Date(contact.eAndODate).toLocaleDateString(
                            "en-US"
                          )}
                        />
                      )}
                      {contact.eAndO && (
                        <EAndAInfo label="E&O" info={contact.eAndO} />
                      )}
                    </div>
                  </React.Fragment>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    </Card.Group>
  );
}

export default withRouter(IndividualCard);
