import React, { useState, useEffect } from "react";
import { getRequest } from "../helpers/ApiHelpers";

const UnderwriterContext = React.createContext({});

function UnderwriterContextProvider({ children }) {
  const [underwriters, setUnderwriters] = useState([]);

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setUnderwriters(result.data);
    }
  }

  useEffect(() => {
    getUnderwriters();
  }, []);

  return (
    <UnderwriterContext.Provider
      value={{
        underwriters
      }}
    >
      {children}
    </UnderwriterContext.Provider>
  );
}

export { UnderwriterContextProvider, UnderwriterContext };
