import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Loader } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../State";
import StateDropdown from "../StateDropdown";
import pathToRegexp from "path-to-regexp";
import shortid from "shortid";
import Liens from "./liens/LiensSection";
import StateIdiosyncrasies from "./stateIdiosyncrasies/StateIdiosyncrasies";
import Recordings from "./recordings/Recordings";
import HighLiabilityApprovals from "./highLiabilityApprovals/HighLiabilityApprovals";
import Modifications from "./modifications/Modifications";

function Info({ history, match: { params }, match }) {
  const { selectedState } = useContext(StateContext);
  if (!params.state || params.state === "no_state") {
    history.replace(
      `/info/states/${selectedState || 31}/types/state-idiosyncrasies`
    );
  }
  const [state, setState] = useState({
    selectedInfoType: null,
    initialLoading: true,
    noResults: false,
    types: [
      { name: "State Idiosyncrasies", path: "state-idiosyncrasies" },
      { name: "Liens", path: "liens/sections/liens" },
      { name: "Recordings", path: "recordings" },
      { name: "High Liability Approvals", path: "high-liability-approvals" },
      { name: "Modifications", path: "modifications" },
    ],
  });

  useEffect(() => {
    const selectedType = params.type;
    setState({
      ...state,
      selectedInfoType: selectedType || state.types[0].path,
      initialLoading: false,
    });
  }, [params.type]);

  return (
    <div>
      {state.initialLoading ? (
        <Loader active size="massive" />
      ) : (
        <div className={css({ paddingBottom: 40 })}>
          <StateDropdown
            pushHistory={(value) => {
              const toPath = pathToRegexp.compile(match.path);
              const newPath = toPath({ ...match.params, state: value });
              history.push(newPath);
            }}
          />
          <div className={css({ marginTop: 40, marginBottom: 40 })}>
            {state.types.map((t) => (
              <Button
                className="link"
                active={state.selectedInfoType === t.path.split("/")[0]}
                onClick={() =>
                  history.push(`/info/states/${selectedState}/types/${t.path}`)
                }
                key={shortid.generate()}
              >
                {t.name}
              </Button>
            ))}
          </div>
          {state.selectedInfoType === "state-idiosyncrasies" && (
            <StateIdiosyncrasies />
          )}
          {state.selectedInfoType === "liens" && <Liens />}
          {state.selectedInfoType === "recordings" && <Recordings />}
          {state.selectedInfoType === "high-liability-approvals" && (
            <HighLiabilityApprovals />
          )}
          {state.selectedInfoType === "modifications" && <Modifications />}
        </div>
      )}
    </div>
  );
}

export default withRouter(Info);
