import React, { useState, useEffect } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";
import { css } from "emotion";
import { postRequest } from "../../helpers/ApiHelpers";
import shortid from "shortid";
import { withRouter } from "react-router-dom";
import IndividualCard from "./IndividualCard";
import CompanyCard from "./CompanyCard";

const DragHandle = SortableHandle(() => (
  <span
    className={css({
      position: "absolute",
      right: 16,
      top: 31,
      color: "#c3c3c3",
      cursor: "grab",
      zIndex: 1
    })}
  >
    <i className="fal fa-sort" />
  </span>
));

function DragSortContacts({
  contacts,
  companies,
  saveContactSortPath,
  saveCompanySortPath,
  draggable,
  selectedState,
  isAdmin,
  states,
  noState,
  confirmDelete
}) {
  const [state, setState] = useState({
    items: [],
    sorted: false
  });

  async function saveSort() {
    setState(state => ({ ...state, sorted: false }));
    const contacts = [];
    const companies = [];
    state.items.forEach((c, i) => {
      if (c.type === "contact") {
        contacts.push({
          ...c.contactStates.find(c => c.stateId === selectedState),
          sortNum: i
        });
      } else {
        companies.push({
          ...c.companyStates.find(c => c.stateId === selectedState),
          sortNum: i
        });
      }
    });
    postRequest(saveContactSortPath, contacts);
    postRequest(saveCompanySortPath, companies);
  }

  const SortableItem = SortableElement(({ value }) => (
    <div>
      {value.type === "contact" ? (
        <IndividualCard
          dragHandle={() => <DragHandle />}
          draggable={draggable}
          contact={value}
          key={shortid.generate()}
          deleteContact={() => confirmDelete(value, "individual")}
          isAdmin={isAdmin}
          states={states}
          noState={noState}
        />
      ) : (
        <CompanyCard
          dragHandle={() => <DragHandle />}
          draggable={draggable}
          company={value}
          key={shortid.generate()}
          deleteContact={confirmDelete}
          deleteCompany={() => confirmDelete(value, "company")}
          isAdmin={isAdmin}
          states={states}
          noState={noState}
        />
      )}
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <div>
        {items.map((value, index) => (
          <SortableItem
            key={value.type + "-" + value.id}
            index={index}
            value={value}
          />
        ))}
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setState(state => ({
      ...state,
      sorted: true,
      items: arrayMove(state.items, oldIndex, newIndex).map((n, i) => ({
        ...n,
        sortNum: i
      }))
    }));
  };

  useEffect(() => {
    if (state.sorted) {
      saveSort(state.items);
    }
  }, [state.items]);

  useEffect(() => {
    setState(state => ({
      ...state,
      items: [
        ...contacts.map(c => ({
          ...c,
          type: "contact",
          sortNum: c.contactStates.find(s => s.stateId === selectedState)
            ? c.contactStates.find(s => s.stateId === selectedState).sortNum
            : null
        })),
        ...companies.map(c => ({
          ...c,
          type: "company",
          sortNum: c.companyStates.find(s => s.stateId === selectedState)
            ? c.companyStates.find(s => s.stateId === selectedState).sortNum
            : null
        }))
      ].sort((a, b) => a.sortNum - b.sortNum)
    }));
  }, [contacts, companies]);

  return (
    <SortableList items={state.items} onSortEnd={onSortEnd} useDragHandle />
  );
}

export default withRouter(DragSortContacts);
