import React, { useState, useEffect, useContext } from "react";
import { Menu } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import GeneralInfo from "./GeneralInfo";
import HelpfulWordings from "./HelpfulWordings";
import SearchGuidelines from "./SearchGuidelines";
import RemittanceTargets from "./remittanceTargets/RemittanceTargets";
import StateDropdown from "../../StateDropdown";
import pathToRegexp from "path-to-regexp";

function OrderEntryInstructions({ history, match: { params }, match }) {
  const { selectedState } = useContext(StateContext);
  if (!params.state || params.state === "no_state") {
    history.replace(
      `/order-entry-instructions/states/${selectedState ||
        31}/sections/${params.section || "general-info"}`
    );
  }
  const [state, setState] = useState({
    selectedSection: params.section || "general-info",
  });

  useEffect(() => {
    setState({
      ...state,
      selectedSection: params.section || "general-info",
      initialLoading: false,
    });
  }, [params.section]);

  return (
    <React.Fragment>
      <StateDropdown
        pushHistory={(value) => {
          const toPath = pathToRegexp.compile(match.path);
          const newPath = toPath({ ...match.params, state: value });
          history.push(newPath);
        }}
      />
      <Menu tabular style={{ marginTop: 55, marginBottom: 32 }}>
        <Menu.Item
          style={{ marginLeft: 21 }}
          name="General Info"
          active={state.selectedSection === "general-info"}
          onClick={() =>
            history.push(
              `/order-entry-instructions/states/${selectedState}/sections/general-info`
            )
          }
        />
        <Menu.Item
          name="Helpful Wordings"
          active={state.selectedSection === "helpful-wordings"}
          onClick={() =>
            history.push(
              `/order-entry-instructions/states/${selectedState}/sections/helpful-wordings/categories/1`
            )
          }
        />
        <Menu.Item
          name="Search Guidelines"
          active={state.selectedSection === "search-guidelines"}
          onClick={() =>
            history.push(
              `/order-entry-instructions/states/${selectedState}/sections/search-guidelines`
            )
          }
        />
        <Menu.Item
          name="Remittance Targets"
          active={state.selectedSection === "remittance-targets"}
          onClick={() =>
            history.push(
              `/order-entry-instructions/states/${selectedState}/sections/remittance-targets`
            )
          }
        />
      </Menu>
      {state.selectedSection === "general-info" && <GeneralInfo />}
      {state.selectedSection === "helpful-wordings" && <HelpfulWordings />}
      {state.selectedSection === "search-guidelines" && (
        <SearchGuidelines editable={true} />
      )}
      {state.selectedSection === "remittance-targets" && <RemittanceTargets />}
    </React.Fragment>
  );
}

export default withRouter(OrderEntryInstructions);
