import React, { useEffect, useState } from "react";
import { Button } from "semantic-ui-react";

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.querySelector("#top").scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    document.querySelector("#top").scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    document.querySelector("#top").addEventListener("scroll", toggleVisible);
  }, []);

  return (
    <Button
      circular
      onClick={scrollToTop}
      icon="arrow up"
      style={{
        display: visible ? "inline" : "none",
        position: "absolute",
        right: 40,
        bottom: 40,
        zIndex: 100
      }}
    />
  );
};

export default ScrollButton;
