import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  List,
  ListItem,
  ListHeader,
  ListContent,
  ListList
} from "semantic-ui-react";
import { css } from "emotion";
import { StateContext } from "../State";
import { getRequest } from "../../helpers/ApiHelpers";

const linkStyle = css({
  "&:hover": { textDecoration: "underline" },
  fontSize: "18px" // Larger font size
});

const listItemStyle = (depth) =>
  css({
    marginLeft: `${depth * 20}px`,
    fontSize: `${16 + depth * 2}px`,
    marginTop: depth === 0 ? "10px" : "0",
    marginBottom: depth === 0 ? "10px" : "0"
  });

const Index = () => {
  const { selectedState } = useContext(StateContext) || 31;
  const [departments, setDepartments] = useState([]);

  async function getDepartments() {
    const result = await getRequest("/general/getDepartments");
    if (result.success) {
      setDepartments(result.data);
    }
  }

  useEffect(() => {
    getDepartments();
  }, []);

  const contactList = departments.map((d) => ({
    header: d.name,
    link: `/contacts/states/${selectedState || 31}/departments/${d.id}`
  }));

  const indexData = [
    {
      header: "Info",
      link: "/info",
      children: [
        {
          header: "State Idiosyncrasies",
          link: `/info/states/${selectedState || 31}/types/state-idiosyncrasies`
        },
        {
          header: "Liens",
          link: `/info/states/${selectedState || 31}/types/liens`
        },
        {
          header: "Recordings",
          link: `/info/states/${selectedState || 31}/types/recordings`
        },
        {
          header: "High Liability Approvals",
          link: `/info/states/${selectedState ||
            31}/types/high-liability-approvals`
        },
        {
          header: "Modifications",
          link: `/info/states/${selectedState || 31}/types/modifications`
        }
      ]
    },
    {
      header: "Contacts",
      link: "/contacts",
      children: contactList
    },
    {
      header: "Order Entry Instructions",
      link: "/order-entry-instructions",
      children: [
        {
          header: "General Info",
          link: `/order-entry-instructions/states/${selectedState ||
            31}/sections/general-info`
        },
        {
          header: "Helpful Wordings",
          link: `/order-entry-instructions/states/${selectedState ||
            31}/sections/helpful-wordings/categories/1`,
          children: [
            {
              header: "For Client",
              link: `/order-entry-instructions/states/${selectedState ||
                31}/sections/helpful-wordings/categories/1`
            },
            {
              header: "For Searcher/Local",
              link: `/order-entry-instructions/states/${selectedState ||
                31}/sections/helpful-wordings/categories/2`
            },
            {
              header: "For Surveyor",
              link: `/order-entry-instructions/states/${selectedState ||
                31}/sections/helpful-wordings/categories/3`
            },
            {
              header: "Searches",
              link: `/order-entry-instructions/states/${selectedState ||
                31}/sections/helpful-wordings/categories/4`
            }
          ]
        },
        {
          header: "Search Guidelines",
          link: `/order-entry-instructions/states/${selectedState ||
            31}/sections/search-guidelines`
        },
        {
          header: "Remittance Targets",
          link: `/order-entry-instructions/states/${selectedState ||
            31}/sections/remittance-targets`
        }
      ]
    },
    {
      header: "Commitment Instructions",
      link: `/commitment-instructions`,
      children: [
        {
          header: "Schedule A",
          link: `/commitment-instructions/states/${selectedState ||
            31}/sections/schedule-a`
        },
        {
          header: "Schedule BI",
          link: `/commitment-instructions/states/${selectedState ||
            31}/sections/schedule-bi`,
          children: [
            {
              header: "Specifics",
              link: `/commitment-instructions/states/${selectedState ||
                31}/sections/schedule-bi/categories/1`
            },
            {
              header: "Template",
              link: `/commitment-instructions/states/${selectedState ||
                31}/sections/schedule-bi/categories/2`
            }
          ]
        },
        {
          header: "Schedule BII",
          link: `/commitment-instructions/states/${selectedState ||
            31}/sections/schedule-bii`,
          children: [
            {
              header: "Specifics",
              link: `/commitment-instructions/states/${selectedState ||
                31}/sections/schedule-bii/categories/1`
            },
            {
              header: "Template",
              link: `/commitment-instructions/states/${selectedState ||
                31}/sections/schedule-bii/categories/2`
            }
          ]
        },
        {
          header: "General Information",
          link: `/commitment-instructions/states/${selectedState ||
            31}/sections/general-info`
        }
      ]
    },
    {
      header: "Policy Instructions",
      link: `/policy-instructions`,
      children: [
        {
          header: "General Information",
          link: `/policy-instructions/states/${selectedState ||
            31}/sections/general-info`
        },
        {
          header: "BI",
          link: `policy-instructions/states/${selectedState ||
            31}/sections/bi/categories/1/underwriters/6`
        },
        {
          header: "Endorsements",
          link: `/policy-instructions/states/${selectedState ||
            31}/sections/endorsements/underwriters/1`
        },
        {
          header: "Helpful Wordings",
          link: `/order-entry-instructions/states/${selectedState ||
            31}/sections/helpful-wordings/categories/1`,
          children: [
            {
              header: "For Client",
              link: `/policy-instructions/states/${selectedState ||
                31}/sections/helpful-wordings/categories/1/underwriters/1`
            },
            {
              header: "For Searcher/Underwriter",
              link: `/policy-instructions/states/${selectedState ||
                31}/sections/helpful-wordings/categories/2/underwriters/1`
            },
            {
              header: "Affirmative Language",
              link: `/policy-instructions/states/${selectedState ||
                31}/sections/helpful-wordings/categories/3/underwriters/1`
            }
          ]
        }
      ]
    },
    {
      header: "Surveys",
      link: `surveys`
    },
    {
      header: "Forms",
      link: `forms`
    },
    {
      header: "Rates",
      link: `rates`,
      children: [
        {
          header: "Invoice Charges",
          link: `/rates/states/${selectedState || 31}/types/invoice-charges`
        },
        {
          header: "Taxes",
          link: `/rates/states/${selectedState || 31}/types/taxes`
        },
        {
          header: "Agency Agreements",
          link: `/rates/states/${selectedState || 31}/types/agency-agreements`
        },
        {
          header: "Premiums",
          link: `rates/states/${selectedState ||
            31}/types/premiums/sections/op-endorsements`,
          children: [
            {
              header: "OP Endorsements",
              link: `rates/states/${selectedState ||
                31}/types/premiums/sections/op-endorsements`
            },
            {
              header: "LP Endorsements",
              link: `rates/states/${selectedState ||
                31}/types/premiums/sections/lp-endorsements`
            },
            {
              header: "Premium Calculators",
              link: `/rates/states/${selectedState ||
                31}/types/premiums/sections/calculators`
            },
            {
              header: "Quote Templates",
              link: `/rates/states/${selectedState ||
                31}/types/premiums/sections/quote-templates`
            }
          ]
        },
        {
          header: "Manuals",
          link: `/rates/states/${selectedState || 31}/types/manuals`
        }
      ]
    },
    {
      header: "Websites",
      link: `/websites`
    },
    {
      header: "Training",
      link: `/training`,
      children: [
        {
          header: "Media",
          link: `/training`
        },
        {
          header: "System tips",
          link: `/training/system-tips`
        }
      ]
    }
  ];

  // Define a set of symbols for each level of nesting
  const valueSymbols = ["•", "◦", "▪︎", "■"];

  const renderListItems = (items, depth = 0) => {
    return items.map((item, index) => (
      <ListItem
        as="li"
        key={index}
        value={valueSymbols[depth] || "•"}
        className={listItemStyle(depth)}
      >
        <ListContent>
          <ListHeader href={item.link} className={linkStyle}>
            {item.header}
          </ListHeader>
          {item.children && item.children.length > 0 && (
            <ListList>{renderListItems(item.children, depth + 1)}</ListList>
          )}
        </ListContent>
      </ListItem>
    ));
  };

  return (
    <>
      <h1>Index</h1>
      {indexData.map((data, idx) => (
        <Grid.Column key={idx}>
          <List as="ol">{renderListItems([data])}</List>
        </Grid.Column>
      ))}
    </>
  );
};

export default Index;
