import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import {
  Dropdown,
  Button,
  Header,
  Grid,
  Loader,
  Message,
  Modal
} from "semantic-ui-react";
import IndividualCard from "./IndividualCard";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import CompanyCard from "./CompanyCard";
import { UserContext } from "../User";
import { StateContext } from "../State";
import StateDropdown from "../StateDropdown";
import shortid from "shortid";
import { LatestContactUpdatedContext } from "../LatestContactUpdated";
import { useToasts } from "react-toast-notifications";
import DragSortContacts from "./DragSortContacts";
import { convertToFileDownload } from "../../helpers/ConvertToFileDownload";
import ScrollToTop from "react-scroll-to-top";

const linkStyles = css({
  color: "#00B8D4",
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  cursor: "pointer",
  "&:hover": { opacity: 0.8 },
  marginRight: 16
});

function Contacts({
  history,
  match: { params },
  contactSearchResults,
  noState
}) {
  const { addToast } = useToasts();
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const {
    latestContactUpdated,
    alertedContactUpdated,
    updateLatestContactUpdated,
    updateAlertedContactUpdated
  } = useContext(LatestContactUpdatedContext);
  if (!contactSearchResults && (!params.state || params.state === "no_state")) {
    history.replace(`/contacts/states/${selectedState || 31}/departments/1`);
  }
  const [state, setState] = useState({
    states: [],
    districts: [],
    departments: [],
    contacts: [],
    companies: [],
    underwriters: [
      {
        id: null,
        name: null
      }
    ],
    selectedDepartment: null,
    selectedUnderwriter: {
      id: null,
      name: null
    },
    initialLoading: true,
    noResults: false,
    contactToDelete: null,
    deleteConfirmationError: false,
    allStatesList: []
  });

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getDepartments() {
    const result = await getRequest("/general/getDepartments");
    if (result.success) {
      setState((state) => ({ ...state, departments: result.data }));
    }
  }

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState((state) => ({
        ...state,
        underwriters: result.data
      }));
    }
  }

  async function getContactsFiltered() {
    if (
      state.states.length > 0 &&
      state.departments.length > 0 &&
      state.underwriters.length > 0
    ) {
      const result = await getRequest("/contact/getContactsFiltered", {
        stateId: selectedState,
        departmentId: state.selectedDepartment
      });
      if (result.success) {
        setState((state) => ({
          ...state,
          contacts: result.data,
          selectedUnderwriter: state.selectedUnderwriter,
          initialLoading: false,
          noResults: result.data.length < 1 && state.companies.length < 1
        }));
      }
    }
  }

  async function getCompaniesFiltered() {
    if (
      state.states.length > 0 &&
      state.departments.length > 0 &&
      state.underwriters.length > 0
    ) {
      const result = await getRequest("/company/getCompaniesFiltered", {
        stateId: selectedState,
        departmentId: state.selectedDepartment
      });
      if (result.success) {
        setState((state) => ({
          ...state,
          companies: result.data,
          selectedUnderwriter: state.selectedUnderwriter,
          initialLoading: false,
          noResults: result.data.length < 1 && state.contacts.length < 1
        }));
      }
    }
  }

  const notifyUpdatedContact = () => {
    if (latestContactUpdated === "error") {
      addToast(
        "There was an error syncing with Select. Please update contact manually.",
        {
          appearance: "error",
          onDismiss: () => {
            updateLatestContactUpdated(null);
            updateAlertedContactUpdated(null);
          }
        }
      );
    } else {
      if (
        latestContactUpdated &&
        latestContactUpdated !== alertedContactUpdated
      ) {
        addToast(
          `${latestContactUpdated}'s profile was updated in Select as well.`,
          {
            appearance: "info",
            autoDismiss: true,
            onDismiss: () => {
              updateLatestContactUpdated(null);
              updateAlertedContactUpdated(null);
            }
          }
        );
      }
      updateAlertedContactUpdated(latestContactUpdated);
    }
  };

  useEffect(() => {
    notifyUpdatedContact();
    getStates();
    if (!contactSearchResults) {
      getDepartments();
      getUnderwriters();
      getAllStatesList();
    }
  }, []);

  useEffect(() => {
    if (!contactSearchResults) {
      getContactsFiltered();
      getCompaniesFiltered();
    }
  }, [
    selectedState,
    state.selectedDepartment,
    state.states,
    state.departments,
    state.underwriters,
    state.selectedUnderwriter
  ]);

  useEffect(() => {
    if (!contactSearchResults) {
      const selectedDepartment = +params.state === 52 ? 19 : +params.department;
      const selectedUnderwriter = +params.underwriter;
      setState({
        ...state,
        selectedDepartment: selectedDepartment > 0 ? selectedDepartment : 1,
        selectedUnderwriter: {
          id: selectedUnderwriter > 0 ? selectedUnderwriter : 1,
          name: ""
        }
      });
    }
  }, [params.department, params.underwriter]);

  function confirmDelete(contact, type) {
    setState((state) => ({
      ...state,
      contactToDelete: { contact: contact, type: type }
    }));
  }

  async function deleteContact(contactId) {
    state.companies.forEach(
      (c) => (c.contacts = c.contacts.filter((c) => c.id !== contactId))
    );
    const result = await postRequest(
      `/contact/deleteContact?contactId=${contactId}`
    );
    if (result.success) {
      if (contactSearchResults) {
        window.location.reload();
      } else {
        setState((state) => ({
          ...state,
          contacts: state.contacts.filter((c) => c.id !== contactId),
          companies: state.companies,
          contactToDelete: null
        }));
      }
    }
  }

  async function deleteCompany(companyId) {
    const result = await postRequest(
      `/company/deleteCompany?companyId=${companyId}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        companies: state.companies.filter((c) => c.id !== companyId),
        contactToDelete: null
      }));
    }
    if (!result.success) {
      setState((state) => ({
        ...state,
        deleteConfirmationError: true
      }));
    }
  }

  async function downloadContactExport() {
    const departmentType = state.departments.find(
      (d) => d.id === state.selectedDepartment
    ).name;
    const result = await getRequest(
      `/contact/downloadContactExport?type=${departmentType}`
    );
    if (result.success) {
      convertToFileDownload(
        result.data.base64File,
        `${departmentType}_Contacts_${new Date().toLocaleDateString()}.xlsx`
      );
    }
  }

  async function downloadCompanyExport() {
    const departmentType = state.departments.find(
      (d) => d.id === state.selectedDepartment
    ).name;
    const result = await getRequest(
      `/company/downloadCompanyExport?type=${departmentType}`
    );
    if (result.success) {
      convertToFileDownload(
        result.data.base64File,
        `${departmentType}_Company_${new Date().toLocaleDateString()}.xlsx`
      );
    }
  }

  function checkIfIsUnderwriter(department) {
    return department === 1 || department === 7 || department === 11;
  }

  async function firstUnderwriter(stateId, departmentId, underwriterId) {
    const contactResults = await getRequest("/contact/getContactsFiltered", {
      stateId: stateId,
      departmentId: departmentId
    });
    const companyResults = await getRequest("/company/getCompaniesFiltered", {
      stateId: stateId,
      departmentId: departmentId
    });
    if (contactResults.success && companyResults.success) {
      const underwriters = contactResults.data
        .map((c) => c.underwriterId)
        .concat(companyResults.data.map((c) => c.underwriterId));
      if (underwriters.length > 0) {
        const firstUnderwriter = state.underwriters.find((u) =>
          underwriters.some((underwriter) => underwriter === u.id)
        ).id;
        history.push(
          `/contacts/states/${stateId}/departments/${departmentId}/underwriters/${firstUnderwriter}`
        );
      } else {
        history.push(
          `/contacts/states/${stateId}/departments/${departmentId}/underwriters/${state
            .selectedUnderwriter.id || 1}`
        );
      }
    }
  }

  const filteredContacts = contactSearchResults
    ? contactSearchResults
    : state.contacts;

  return (
    <div>
      <ScrollToTop smooth color="#6f00ff" />
      {!contactSearchResults && (
        <React.Fragment>
          {state.initialLoading ? (
            <Loader active size="massive" />
          ) : (
            <div className={css({ paddingBottom: 40 })}>
              <StateDropdown
                pushHistory={(value) =>
                  history.push(
                    `/contacts/states/${value}/departments/${
                      value !== 52
                        ? state.selectedDepartment === 19
                          ? 1
                          : state.selectedDepartment
                        : 19
                    }`
                  )
                }
              />
              {selectedState !== 52 && (
                <div className={css({ marginTop: 40, marginBottom: 40 })}>
                  <Dropdown
                    placeholder="Department"
                    button
                    floating
                    options={state.departments.map((d) => ({
                      key: d.id,
                      text: d.name,
                      value: d.id
                    }))}
                    onChange={(_, { value }) => {
                      if (checkIfIsUnderwriter(value)) {
                        firstUnderwriter(
                          selectedState,
                          value,
                          state.selectedUnderwriter.id
                        );
                      } else {
                        history.push(
                          `/contacts/states/${selectedState}/departments/${value}`
                        );
                      }
                    }}
                    value={state.selectedDepartment}
                    data-automation="departments"
                  />
                </div>
              )}
              {checkIfIsUnderwriter(state.selectedDepartment) ? (
                <div className={css({ marginTop: 40, marginBottom: 40 })}>
                  {state.underwriters
                    .filter(
                      (u) =>
                        state.contacts.some((c) => c.underwriterId === u.id) ||
                        state.companies.some((c) => c.underwriterId === u.id)
                    )
                    .map((u) => (
                      <Button
                        className="link"
                        active={state.selectedUnderwriter.id === u.id}
                        onClick={() =>
                          history.push(
                            `/contacts/states/${selectedState}/departments/${state.selectedDepartment}/underwriters/${u.id}`
                          )
                        }
                        key={shortid.generate()}
                        data-automation={u.name}
                      >
                        {u.name}
                      </Button>
                    ))}
                </div>
              ) : null}
              <Grid>
                <Grid.Row columns={2} verticalAlign="middle">
                  <Grid.Column floated="left">
                    <Header
                      as="h2"
                      className={css({
                        fontFamily: "FaktSlab-Bold !important"
                      })}
                    >
                      {checkIfIsUnderwriter(state.selectedDepartment)
                        ? state.selectedUnderwriter.name
                        : null}
                    </Header>
                  </Grid.Column>
                  {(user.isAdmin || user.isOwner || user.isOwnerPlus) && (
                    <Grid.Column
                      floated="right"
                      className={css({ textAlign: "right" })}
                    >
                      {selectedState !== 52 && (
                        <>
                          <span
                            className={linkStyles}
                            onClick={() => downloadContactExport()}
                          >
                            <i className="fal fa-download" /> Export contacts to
                            excel
                          </span>
                          <span
                            className={linkStyles}
                            onClick={() => downloadCompanyExport()}
                          >
                            <i className="fal fa-download" /> Export companies
                            to excel
                          </span>
                        </>
                      )}
                      <Button
                        icon
                        className="blueButton"
                        onClick={() =>
                          history.push(
                            `/contacts/new-contact/states/${selectedState}/departments/${state.selectedDepartment}`
                          )
                        }
                        data-automation="addContact"
                      >
                        <i className="fal fa-plus" />
                      </Button>
                      <span
                        className={css({
                          fontFamily: "Fakt-Bold",
                          fontSize: 14,
                          color: "#000",
                          cursor: "pointer",
                          "&:hover": {
                            color: "#00b8d4"
                          }
                        })}
                        onClick={() =>
                          history.push(
                            `/contacts/new-contact/states/${selectedState}/departments/${state.selectedDepartment}`
                          )
                        }
                      >
                        New contact
                      </span>
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
              <div className={css({ marginBottom: 19.75 })} />
              {state.contacts &&
              state.companies &&
              checkIfIsUnderwriter(state.selectedDepartment) ? (
                <DragSortContacts
                  contacts={state.contacts.filter(
                    (c) => c.underwriterId === state.selectedUnderwriter.id
                  )}
                  companies={state.companies.filter(
                    (c) => c.underwriterId === state.selectedUnderwriter.id
                  )}
                  confirmDelete={confirmDelete}
                  isAdmin={user.isAdmin || user.isOwner || user.isOwnerPlus}
                  draggable={user.isAdmin || user.isOwner || user.isOwnerPlus}
                  states={state.states}
                  noState={noState}
                  selectedState={selectedState}
                  saveContactSortPath="/contact/sortContacts"
                  saveCompanySortPath="/company/sortCompanies"
                />
              ) : (
                <DragSortContacts
                  contacts={state.contacts}
                  companies={state.companies}
                  confirmDelete={confirmDelete}
                  isAdmin={user.isAdmin || user.isOwner || user.isOwnerPlus}
                  draggable={user.isAdmin || user.isOwner || user.isOwnerPlus}
                  states={state.states}
                  noState={noState}
                  selectedState={selectedState}
                  saveContactSortPath="/contact/sortContacts"
                  saveCompanySortPath="/company/sortCompanies"
                />
              )}
              {state.noResults && (
                <Message size="big">
                  No{" "}
                  <strong>
                    {+params.state !== 52 &&
                      state.departments.length > 0 &&
                      state.departments.find(
                        (d) => d.id === state.selectedDepartment
                      )?.name}
                    {+params.state === 52 && "General"}
                  </strong>{" "}
                  contact found
                </Message>
              )}
            </div>
          )}
        </React.Fragment>
      )}
      {contactSearchResults && (
        <React.Fragment>
          {filteredContacts.map((c) =>
            c.type === "contact" ? (
              <IndividualCard
                contact={c.contact}
                key={shortid.generate()}
                deleteContact={() => confirmDelete(c.contact, "individual")}
                isAdmin={user.isAdmin || user.isOwner || user.isOwnerPlus}
                states={state.states}
                noState={noState}
              />
            ) : (
              <CompanyCard
                company={c.contact}
                key={shortid.generate()}
                deleteContact={confirmDelete}
                deleteCompany={() => confirmDelete(c.contact, "company")}
                isAdmin={user.isAdmin || user.isOwner || user.isOwnerPlus}
                states={state.states}
                noState={noState}
              />
            )
          )}
        </React.Fragment>
      )}
      {state.contactToDelete && (
        <Modal
          size={"tiny"}
          open={state.contactToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, contactToDelete: null }))
          }
          dimmer="inverted"
          data-automation="confirmDeleteModal"
        >
          <Modal.Header>Delete Contact</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              <strong>
                {state.contactToDelete.type === "individual" ? (
                  <React.Fragment>
                    {(state.contactToDelete.contact.firstName !== null &&
                      state.contactToDelete.contact.firstName !== "") ||
                    (state.contactToDelete.contact.lastName !== null &&
                      state.contactToDelete.contact.lastName !== "")
                      ? `${state.contactToDelete.contact.firstName} ${state.contactToDelete.contact.lastName}'s`
                      : "this"}
                  </React.Fragment>
                ) : (
                  state.contactToDelete.contact.name + "'s"
                )}
              </strong>{" "}
              profile?
            </p>
            {state.deleteConfirmationError && (
              <p className={css({ color: "#d01919 !important" })}>
                Cannot delete a company that has dependant contacts!
                <br />
                Please remove those contacts first.
              </p>
            )}
          </Modal.Content>
          <Modal.Actions>
            {!state.deleteConfirmationError ? (
              <React.Fragment>
                <Button
                  negative
                  onClick={() =>
                    setState((state) => ({ ...state, contactToDelete: null }))
                  }
                  style={{
                    backgroundColor: "#fff",
                    border: "solid 1px #e5e5ea",
                    color: "rgba(0, 0, 0, 0.87)"
                  }}
                >
                  No
                </Button>
                <Button
                  positive
                  onClick={() =>
                    state.contactToDelete.type === "individual"
                      ? deleteContact(state.contactToDelete.contact.id)
                      : deleteCompany(state.contactToDelete.contact.id)
                  }
                  style={{ backgroundColor: "#1f2b5e" }}
                  data-automation="confirmDelete"
                >
                  Yes
                </Button>
              </React.Fragment>
            ) : (
              <Button
                positive
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    contactToDelete: null,
                    deleteConfirmationError: false
                  }))
                }
                style={{ backgroundColor: "#1f2b5e" }}
                data-automation="okDeleteAlert"
              >
                Ok
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}

export default withRouter(React.memo(Contacts));
