import React, { useState } from "react";

const SearchWordsContext = React.createContext({});

function SearchWordsContextProvider({ children }) {
  const [searchWords, setSearchWords] = useState([]);

  return (
    <SearchWordsContext.Provider
      value={{
        searchWords: searchWords,
        updateSearchWords: newWords => setSearchWords(newWords)
      }}
    >
      {children}
    </SearchWordsContext.Provider>
  );
}

export { SearchWordsContextProvider, SearchWordsContext };
