import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { css } from "emotion";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import { withRouter } from "react-router-dom";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import Codes from "./Codes";
import NoteAccordion from "../../NoteAccordion";
import DragSortNotes from "./DragSortNotes";

const linkStyles = css({
  fontSize: 14,
  lineHeight: 1.71,
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
});

function ScheduleBII({ history, match }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  const [state, setState] = useState({
    scheduleSections: [],
    scheduleBIICodes: [],
    noteToDelete: null,
    selectedSection: +match.params.category || 1
  });

  const [showAll, setShowAll] = useState(false);
  const [scheduleBIINotesBeforeCodes, setScheduleBIINotesBeforeCodes] = useState([]);
  const [scheduleBIINotesAfterCodes, setScheduleBIINotesAfterCodes] = useState([]);

  async function getScheduleSections() {
    const result = await getRequest("/scheduleBII/getScheduleSections");
    if (result.success) {
      setState(state => ({
        ...state,
        scheduleSections: result.data
      }));
    }
  }

  async function getScheduleBIINotesByState(stateId) {
    const result = await getRequest(`/scheduleBII/getScheduleBIINotesByState`, {
      stateId
    });
    if (result.success) {
      setScheduleBIINotesBeforeCodes(result.data.filter(d => !d.isAfterCodes));
      setScheduleBIINotesAfterCodes(result.data.filter(d => d.isAfterCodes));
    }
  }

  useEffect(() => {
    getScheduleSections();
  }, []);

  useEffect(() => {
    setState(state => ({
      ...state,
      selectedSection: +match.params.category || 1
    }));
  }, [match.params.category]);

  useEffect(() => {
    getScheduleBIINotesByState(selectedState);
  }, [selectedState]);

  async function deleteScheduleBIINote(id) {
    const result = await postRequest(`/scheduleBII/deleteScheduleBIINote?id=${id}`);
    if (result.success) {
      setScheduleBIINotesBeforeCodes(scheduleBIINotesBeforeCodes.filter(n => n.id !== id));
      setScheduleBIINotesAfterCodes(scheduleBIINotesAfterCodes.filter(n => n.id !== id));
      setState(state => ({
        ...state,
        noteToDelete: null
      }));
    }
  }

  const filteredNotesBeforeCodes = scheduleBIINotesBeforeCodes.filter(
    n => n.scheduleSectionId === state.selectedSection
  );

  const filteredNotesAfterCodes = scheduleBIINotesAfterCodes.filter(n => n.scheduleSectionId === state.selectedSection);

  const isCollapsible = selectedState === 52;

  return (
    <React.Fragment>
      <Grid style={{ marginBottom: 17 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column
            floated="left"
            className={css({
              textAlign: "left",
              color: "#47517B",
              fontSize: 20,
              fontFamily: "Fakt-Bold"
            })}
          >
            <span className={css({ marginRight: 18, lineHeight: "36px" })}>
              {selectedState === 44 ? "Schedule B" : "Schedule BII"}
            </span>
            {state.scheduleSections.map(s => (
              <Button
                key={s.id}
                className={css({
                  backgroundColor: state.selectedSection === s.id ? "#47517B !important" : "#ebecf1!important    ",
                  color: state.selectedSection === s.id ? "#ffffff !important" : "rgba(0, 0, 0, 0.87) !important"
                })}
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/sections/schedule-bii/categories/${s.id}`
                  )
                }
              >
                {s.name}
              </Button>
            ))}
          </Grid.Column>
          {(user.isAdmin || user.isOwnerPlus) && (
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <span
                className={linkStyles}
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-schedule-bii-note/categories/${state.selectedSection}`
                  )
                }
              >
                Add Note
              </span>
              <Button
                icon
                className="blueButton"
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-schedule-bii-code/categories/${state.selectedSection}`
                  )
                }
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-schedule-bii-code/categories/${state.selectedSection}`
                  )
                }
              >
                New Code
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {(filteredNotesBeforeCodes.length > 0 || filteredNotesAfterCodes.length > 0) && isCollapsible && (
        <Button basic onClick={() => setShowAll(showAll => !showAll)} style={{ marginBottom: 32 }}>
          {showAll ? "Collapse All" : "Open All"}
        </Button>
      )}
      {filteredNotesBeforeCodes.length > 0 && (
        <div className={css({ marginBottom: 24 })}>
          {!isCollapsible && (
            <DragSortNotes
              items={filteredNotesBeforeCodes}
              saveSortPath="/scheduleBII/sortNotes"
              draggable={userCanEdit}
              type="BII"
              editNotePath={`/commitment-instructions/edit-schedule-bii-note`}
              editable={userCanEdit}
              downloadUrl={`/scheduleBII/downloadFile`}
              deleteAction={value => setState(state => ({ ...state, noteToDelete: value }))}
            />
          )}
          {isCollapsible && (
            <NoteAccordion
              notes={filteredNotesBeforeCodes}
              setNotes={setScheduleBIINotesBeforeCodes}
              deleteAction={value => setState(state => ({ ...state, noteToDelete: value }))}
              showAll={showAll}
              downloadURL="/scheduleBII/downloadFile"
              saveSortURL="/scheduleBII/sortNotes"
              editURL="/commitment-instructions/edit-schedule-bii-note"
            />
          )}
        </div>
      )}
      <Codes type="BII" section={state.selectedSection} downloadUrl={`/scheduleBII/downloadCodeFile`} />
      {!isCollapsible && filteredNotesAfterCodes.length > 0 && (
        <DragSortNotes
          items={filteredNotesAfterCodes}
          saveSortPath="/scheduleBII/sortNotes"
          draggable={userCanEdit}
          type="BII"
          editNotePath={`/commitment-instructions/edit-schedule-bii-note`}
          editable={userCanEdit}
          downloadUrl={`/scheduleBII/downloadFile`}
          deleteAction={value => setState(state => ({ ...state, noteToDelete: value }))}
        />
      )}
      {isCollapsible && filteredNotesAfterCodes.length > 0 && (
        <NoteAccordion
          notes={filteredNotesAfterCodes}
          setNotes={setScheduleBIINotesAfterCodes}
          deleteAction={value => setState(state => ({ ...state, noteToDelete: value }))}
          showAll={showAll}
          downloadURL="/scheduleBII/downloadFile"
          saveSortURL="/scheduleBII/sortNotes"
          editURL="/commitment-instructions/edit-schedule-bii-note"
        />
      )}
      {state.noteToDelete && (
        <Modal
          size={"tiny"}
          open={state.noteToDelete !== null}
          onClose={() => setState(state => ({ ...state, noteToDelete: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              {state.noteToDelete.subject && <strong>{state.noteToDelete.subject}</strong>}?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => setState(state => ({ ...state, noteToDelete: null }))}
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteScheduleBIINote(state.noteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(ScheduleBII);
