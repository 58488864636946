import { AuthenticationContext, adalFetch } from "react-adal";
import axios from "axios";

const redirectUri = typeof window !== "undefined" ? window.location.origin : "";
export const adalConfig = {
  ...JSON.parse(process.env.REACT_APP_AZUREAD_CONFIG),
  cacheLocation: "localStorage",
  redirectUri
};
export const authContext = new AuthenticationContext(adalConfig);

export const apiFetch = (url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, axios, url, options);

export function apiPost(url, data, options) {
  const postOptions = {
    method: "post",
    data,
    ...options
  };

  return apiFetch(url, postOptions);
}

export const graphApiFetch = async url => {
  return new Promise((resolve, reject) => {
    authContext.acquireToken(
      "https://graph.microsoft.com",
      async (error, fetchedToken) => {
        if (error) return reject(error);
        const res = await fetch(url, {
          headers: { Authorization: fetchedToken }
        });
        resolve(await res.json());
      }
    );
  });
};

export const adalLogout = () => authContext.logOut();
