import React from "react";
import { css } from "emotion";

export function ActionButton({ onClick, style, text, dataAutomation }) {
  return (
    <span
      onClick={onClick}
      style={style}
      className={css({
        color: "#00b8d4",
        fontSize: 14,
        fontFamily: "Fakt-Bold",
        cursor: "pointer",
        "&:hover": {
          color: "#1f2b5e"
        }
      })}
      data-automation={dataAutomation}
    >
      {text}
    </span>
  );
}
