import React, { useState } from "react";

const LatestContactUpdatedContext = React.createContext({});

function LatestContactUpdatedContextProvider({ children }) {
    const [latestContactUpdated, setLatestContactUpdated] = useState(null);
    const [alertedContactUpdated, setAlertedContactUpdated] = useState(null)

    return (
        <LatestContactUpdatedContext.Provider
            value={{
                latestContactUpdated: latestContactUpdated,
                alertedContactUpdated: alertedContactUpdated,
                updateLatestContactUpdated: value => setLatestContactUpdated(value),
                updateAlertedContactUpdated: value => setAlertedContactUpdated(value)
            }}
        >
            {children}
        </LatestContactUpdatedContext.Provider>
    );
}

export { LatestContactUpdatedContextProvider, LatestContactUpdatedContext };
