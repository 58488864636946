import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, Input, Message, TextArea, Checkbox, Dropdown } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { UserContext } from "../User";
import { StateContext } from "../State";
import { readFileAsBase64 } from "../../helpers/ReadFileAsBase64";
import FileUpload from "../FileUpload";
import shortid from "shortid";
import { logEvent } from "../../helpers/analyticsLogger";

export function EditAddWebsite({ match, history }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    website: {
      states: [
        {
          websiteId: undefined,
          stateId: selectedState || 31
        }
      ]
    },
    files: [],
    filesToDelete: [],
    editMode: match.path === "/websites/edit-website/:id",
    states: [{}],
    errorList: [],
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: []
  });

  const [titleCompanies, setTitleCompanies] = useState([]);

  async function getTitleCompanies() {
    const result = await getRequest("/titleCompany/getTitleCompanies");
    if (result.success) {
      var titleCompanies = [
        {
          key: null,
          value: null,
          text: "All"
        },
        ...result.data.map(t => ({
          key: t.id,
          value: t.id,
          text: t.name
        }))
      ];
      setTitleCompanies(titleCompanies);
    }
  }

  async function getWebsiteById(id) {
    const result = await getRequest(`/website/getWebsiteById`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        website: result.data
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState(state => ({
        ...state,
        states: result.data.map(s => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allLicensedStatesList: result.data
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState(state => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  useEffect(() => {
    getStates();
    getAllStatesList();
    getAllLicensedStatesList();
    getAllUnlicensedStatesList();
    getTitleCompanies();
    state.editMode && getWebsiteById(match.params.id);
  }, []);

  useEffect(() => {
    if (state.editMode) {
      let checkIfContainsAll = (arr, target) => target.every(v => arr.includes(v));
      setState(state => ({
        ...state,
        allStates: checkIfContainsAll(
          state.website.states.length > 0 ? state.website.states.map(ws => ws.stateId) : [],
          state.allStatesList
        ),
        allLicensedStates:
          checkIfContainsAll(
            state.website.states.length > 0 ? state.website.states.map(ws => ws.stateId) : [],
            state.allLicensedStatesList
          ) && state.website.states.length === state.allLicensedStatesList.length,
        allUnlicensedStates:
          checkIfContainsAll(
            state.website.states.length > 0 ? state.website.states.map(ws => ws.stateId) : [],
            state.allUnlicensedStatesList
          ) && state.website.states.length === state.allUnlicensedStatesList.length
      }));
    } else {
      return;
    }
  }, [state.website.id, state.allStatesList, state.allLicensedStatesList, state.allUnlicensedStatesList]);

  function buildstates(value) {
    return value.map(v => ({
      formId: 0,
      stateId: v
    }));
  }

  async function saveChanges() {
    if (!state.submitting) {
      setState(state => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(`/website/${state.editMode ? "editWebsite" : "addWebsite"}`, {
          ...state.website,
          editedBy: user.userPrincipalName,
          editedDate: new Date(),
          files: await Promise.all(
            state.files.map(async f => ({
              base64File: await readFileAsBase64(f),
              fileName: f.name
            }))
          ),
          filesToDelete: state.filesToDelete
        });
        if (result.success) {
          logEvent(null, state.editMode ? "Edited" : "Added", "Website");
          setTimeout(() => history.goBack(), 1000);
        }
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Website`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Madison CO/JV</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  clearable
                  placeholder="Select Company"
                  style={{ fontSize: 16 }}
                  options={titleCompanies}
                  value={state.website.titleCompanyId}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      website: {
                        ...state.website,
                        titleCompanyId: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Name</label>
                <Input
                  placeholder="Name"
                  size="large"
                  value={state.website.name}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      website: { ...state.website, name: value }
                    }))
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>URL</label>
                <Input
                  placeholder="URL"
                  size="large"
                  value={state.website.url}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      website: { ...state.website, url: value }
                    }))
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Username</label>
                <Input
                  placeholder="Username"
                  size="large"
                  value={state.website.username}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      website: { ...state.website, username: value }
                    }))
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Password</label>
                <Input
                  placeholder="Password"
                  size="large"
                  value={state.website.password}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      website: { ...state.website, password: value }
                    }))
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={files =>
                    setState(state => ({
                      ...state,
                      files: [...state.files, ...files]
                    }))
                  }
                  deleteFileToApp={fileName =>
                    setState(state => ({
                      ...state,
                      files: [...state.files.filter(f => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.website.websiteFiles &&
                state.website.websiteFiles.map(f => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        website: {
                          ...state.website,
                          websiteFiles: [...state.website.websiteFiles.filter(wf => wf.id !== f.id)]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i className="fal fa-trash-alt" style={{ marginRight: 8 }} />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Other Information</label>
                <TextArea
                  placeholder="Other Information"
                  style={{ minHeight: 192 }}
                  value={state.website.otherInformation}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      website: { ...state.website, otherInformation: value }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="All states"
                  checked={state.allStates}
                  size="large"
                  onClick={() =>
                    setState(state => ({
                      ...state,
                      allStates: true,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: false,
                      website: {
                        ...state.website,
                        states: buildstates(state.allStatesList)
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All licensed states"
                  checked={state.allLicensedStates}
                  size="large"
                  onClick={() =>
                    setState(state => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: true,
                      allUnlicensedStates: false,
                      customStates: false,
                      website: {
                        ...state.website,
                        states: buildstates(state.allLicensedStatesList)
                      }
                    }))
                  }
                  data-automation="allLicensedStates"
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All unlicensed states"
                  checked={state.allUnlicensedStates}
                  size="large"
                  onClick={() =>
                    setState(state => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: true,
                      customStates: false,
                      website: {
                        ...state.website,
                        states: buildstates(state.allUnlicensedStatesList)
                      }
                    }))
                  }
                  data-automation="allUnlicensedStates"
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Custom (choose your own state selection)"
                  checked={state.customStates}
                  size="large"
                  onClick={() =>
                    setState(state => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: true
                    }))
                  }
                />
              </Form.Field>
              {state.customStates && (
                <Form.Field required>
                  <label>States</label>
                  <Dropdown
                    size="large"
                    fluid
                    multiple
                    selection
                    placeholder="Select States"
                    style={{ fontSize: 16 }}
                    options={state.states}
                    value={state.website.states ? state.website.states.map(cs => cs.stateId) : null}
                    onChange={(_, { value }) => {
                      setState(state => ({
                        ...state,
                        website: {
                          ...state.website,
                          states:
                            value[value.length - 1] === 52
                              ? buildstates([52])
                              : buildstates(value.filter(v => v !== 52))
                        }
                      }));
                    }}
                  />
                </Form.Field>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <Button size="large" className="cancelButton" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button size="large" className="saveButton" onClick={() => saveChanges()}>
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddWebsite);
