import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, TextArea, Input, Checkbox } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddScheduleBIINote({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    scheduleSections: [],
    scheduleBIINote: { scheduleSectionId: +match.params.category },
    editMode: match.path.includes("edit"),
    errorList: [],
    files: [],
    filesToAdd: [],
    filesToDelete: []
  });

  async function getScheduleSections() {
    const result = await getRequest("/scheduleBII/getScheduleSections");
    if (result.success) {
      setState(state => ({
        ...state,
        scheduleSections: result.data
      }));
    }
  }

  async function getScheduleBIINoteById(id) {
    const result = await getRequest(`/scheduleBII/getScheduleBIINoteById`, {
      id
    });
    if (result.success) {
      setState(state => ({
        ...state,
        scheduleBIINote: result.data
      }));
    }
  }

  useEffect(() => {
    getScheduleSections();
    if (state.editMode) {
      getScheduleBIINoteById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    setState(state => ({
      ...state,
      submitting: true
    }));
    const result = await postRequest(`/scheduleBII/editAddScheduleBIINote`, {
      ...state.scheduleBIINote,
      stateId: state.editMode ? state.scheduleBIINote.stateId : +match.params.state,
      filesToAdd: await Promise.all(
        state.filesToAdd.map(async f => ({
          base64File: await readFileAsBase64(f),
          fileName: f.name
        }))
      ),
      filesToDelete: state.filesToDelete
    });
    if (result.success) {
      logEvent(null, state.editMode ? "Edited" : "Added", "Schedule BII Note");
      history.goBack();
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Schedule ${+match.params.state === 44 ? "B" : "BII"} Note`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                {state.scheduleSections.map(s => (
                  <Form.Field>
                    <Checkbox
                      label={s.name}
                      checked={state.scheduleBIINote.scheduleSectionId === s.id}
                      size="large"
                      onClick={() =>
                        setState(state => ({
                          ...state,
                          scheduleBIINote: {
                            ...state.scheduleBIINote,
                            scheduleSectionId: s.id
                          }
                        }))
                      }
                    />
                  </Form.Field>
                ))}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.scheduleBIINote.subject}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      scheduleBIINote: {
                        ...state.scheduleBIINote,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.scheduleBIINote.note}
                  onChange={(_, { value }) =>
                    setState(state => ({
                      ...state,
                      scheduleBIINote: {
                        ...state.scheduleBIINote,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.filesToAdd}
                  setFileToApp={filesToAdd =>
                    setState(state => ({
                      ...state,
                      filesToAdd: [...state.filesToAdd, ...filesToAdd]
                    }))
                  }
                  deleteFileToApp={fileName =>
                    setState(state => ({
                      ...state,
                      filesToAdd: [...state.filesToAdd.filter(f => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.scheduleBIINote.files &&
                state.scheduleBIINote.files.map(f => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState(state => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        scheduleBIINote: {
                          ...state.scheduleBIINote,
                          files: [...state.scheduleBIINote.files.filter(nf => nf.id !== f.id)]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i className="fal fa-trash-alt" style={{ marginRight: 8 }} />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Place after codes
                  <Checkbox
                    checked={state.scheduleBIINote.isAfterCodes}
                    onChange={() => {
                      setState(state => ({
                        ...state,
                        scheduleBIINote: {
                          ...state.scheduleBIINote,
                          isAfterCodes: !state.scheduleBIINote.isAfterCodes
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Highlight Row
                  <Checkbox
                    checked={state.scheduleBIINote.highlight}
                    onChange={() => {
                      setState(state => ({
                        ...state,
                        scheduleBIINote: {
                          ...state.scheduleBIINote,
                          highlight: !state.scheduleBIINote.highlight
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <Button size="large" className="cancelButton" onClick={() => history.goBack()}>
                Cancel
              </Button>
              <Button size="large" className="saveButton" onClick={() => saveChanges()}>
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddScheduleBIINote);
