import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Message,
  Modal,
  Table,
} from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import CustomTable from "../../CustomTable";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import LinesEllipsis from "react-lines-ellipsis";
import { paragraphStyles } from "../../styles/paragraphStyles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useToasts } from "react-toast-notifications";
import { Popup } from "../../Popup";

const linkStyles = css({
  fontSize: 14,
  lineHeight: 1.71,
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});

function SearchGuidelines({ history, editable }) {
  const { user } = useContext(UserContext);
  const { addToast } = useToasts();
  const { selectedState } = useContext(StateContext);

  const [state, setState] = useState({
    searchGuidelines: [],
    searchGuidelineNotes: [],
    initialLoaded: false,
    noteToDelete: null,
  });

  async function getSearchGuidelinesByState() {
    const result = await getRequest(
      "/searchGuideline/getSearchGuidelinesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        searchGuidelines: result.data,
        initialLoaded: true,
      }));
    }
  }

  async function getSearchGuidelineNotesByState() {
    const result = await getRequest(
      "/searchGuideline/getSearchGuidelineNotesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        searchGuidelineNotes: result.data,
        initialLoaded: true,
      }));
    }
  }

  async function download(id, fileType) {
    const result = await getRequest(
      `/searchGuideline/download${fileType}File?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  async function deleteSearchGuidelineNote(id) {
    const result = await postRequest(
      `/searchGuideline/deleteSearchGuidelineNote?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        searchGuidelineNotes: state.searchGuidelineNotes.filter(
          (n) => n.id !== id
        ),
        noteToDelete: null,
      }));
    }
  }

  useEffect(() => {
    if (selectedState) {
      getSearchGuidelinesByState();
      getSearchGuidelineNotesByState();
    }
  }, [selectedState]);

  return (
    <React.Fragment>
      {editable && (
        <React.Fragment>
          <Grid style={{ marginBottom: 17 }}>
            <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
              {(user.isOwner || user.isOwnerPlus) && (
                <Grid.Column
                  floated="right"
                  className={css({ textAlign: "right" })}
                >
                  <span
                    className={linkStyles}
                    onClick={() =>
                      history.push(
                        `/order-entry-instructions/new-search-guideline-note`
                      )
                    }
                  >
                    Add Note
                  </span>
                  <Button
                    icon
                    className="blueButton"
                    onClick={() =>
                      history.push(
                        `/order-entry-instructions/new-search-guideline`
                      )
                    }
                  >
                    <i className="fal fa-plus" />
                  </Button>
                  <span
                    className={css({
                      fontFamily: "Fakt-Bold",
                      fontSize: 14,
                      color: "#000",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#00b8d4",
                      },
                    })}
                    onClick={() =>
                      history.push(
                        `/order-entry-instructions/new-search-guideline`
                      )
                    }
                  >
                    New Guideline/Underwriter
                  </span>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </React.Fragment>
      )}
      {!editable && state.searchGuidelines.length > 0 && (
        <Divider horizontal className={css({ marginTop: "38px !important" })}>
          <Header as="h4">Search Requirements</Header>
        </Divider>
      )}
      {state.searchGuidelineNotes &&
        state.searchGuidelineNotes
          .filter((n) => editable || n.visibleOnCommitmentInstructions)
          .map((n) => (
            <Card.Group itemsPerRow={1}>
              <Card style={{ marginLeft: 0, marginRight: 0 }}>
                <Card.Content>
                  <label
                    className={css({
                      color: "#707898",
                      fontSize: 14,
                      fontFamily: "Fakt-Bold",
                      lineHeight: "17px",
                    })}
                  >
                    {n.subject ? n.subject : "Note"}
                  </label>
                  <p className={paragraphStyles}>{n.note}</p>
                </Card.Content>
                {editable && (
                  <React.Fragment>
                    <span
                      className={css({
                        "&:hover i": { color: "#00b8d4" },
                        cursor: "pointer",
                        position: "absolute",
                        right: 24,
                        top: 31,
                      })}
                      onClick={() =>
                        history.push(
                          `/order-entry-instructions/edit-search-guideline-note/${n.id}`
                        )
                      }
                    >
                      <Icon className="fal fa-pen" />
                    </span>
                    <span
                      className={css({
                        "&:hover i": { color: "#00b8d4" },
                        cursor: "pointer",
                        position: "absolute",
                        right: 48,
                        top: 31,
                      })}
                      onClick={() =>
                        setState((state) => ({ ...state, noteToDelete: n }))
                      }
                    >
                      <Icon className="fal fa-trash" />
                    </span>
                  </React.Fragment>
                )}
              </Card>
            </Card.Group>
          ))}
      <div
        className={css({
          marginTop: state.searchGuidelineNotes.length > 0 ? 38 : 0,
        })}
      >
        {state.searchGuidelines.map((g) => (
          <div
            className={css({
              marginBottom: 40,
            })}
            key={g.id}
          >
            <Header
              as="h3"
              className={css({
                color: "#47517b !important",
                fontSize: "20px !important",
                marginBottom: "0px !important",
              })}
            >
              {g.underwriter.name}
            </Header>
            <CustomTable
              customStyles={{
                "& th": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1,
                },
              }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4} />
                  <Table.HeaderCell width={6}>Purchase</Table.HeaderCell>
                  <Table.HeaderCell width={6}>
                    Refinance
                    {editable && (user.isOwner || user.isOwnerPlus) && (
                      <Button
                        icon
                        onClick={() =>
                          history.push(
                            `/order-entry-instructions/edit-search-guideline/${g.id}`
                          )
                        }
                        className={css({
                          position: "absolute",
                          right: 16,
                          top: 17,
                        })}
                      >
                        <i className="fal fa-pencil" />
                      </Button>
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell
                    style={{
                      textAlign: "left",
                      fontFamily: "FaktSlab-Normal",
                      fontSize: 16,
                      color: "#707898",
                    }}
                  >
                    Full Search
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "left" }}>
                    {g.purchaseFullSearch && (
                      <Popup
                        on={["hover", "focus"]}
                        header={"Full Search - Purchase"}
                        content={g.purchaseFullSearch}
                        trigger={
                          <CopyToClipboard
                            text={g.purchaseFullSearch}
                            onCopy={() =>
                              addToast("Copied!", {
                                appearance: "success",
                                autoDismiss: true,
                              })
                            }
                          >
                            <div
                              className={css(paragraphStyles, {
                                cursor: "pointer",
                              })}
                            >
                              <LinesEllipsis
                                text={g.purchaseFullSearch}
                                maxLine="2"
                                ellipsis="..."
                              />
                            </div>
                          </CopyToClipboard>
                        }
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "left" }}>
                    {g.refinanceFullSearch && (
                      <Popup
                        on={["hover", "focus"]}
                        header={"Full Search - Refinance"}
                        content={g.refinanceFullSearch}
                        trigger={
                          <CopyToClipboard
                            text={g.refinanceFullSearch}
                            onCopy={() =>
                              addToast("Copied!", {
                                appearance: "success",
                                autoDismiss: true,
                              })
                            }
                          >
                            <div
                              className={css(paragraphStyles, {
                                cursor: "pointer",
                              })}
                            >
                              <LinesEllipsis
                                text={g.refinanceFullSearch}
                                maxLine="2"
                                ellipsis="..."
                              />
                            </div>
                          </CopyToClipboard>
                        }
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell
                    style={{
                      textAlign: "left",
                      fontFamily: "FaktSlab-Normal",
                      fontSize: 16,
                      color: "#707898",
                    }}
                  >
                    Prior OP
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "left" }}>
                    {g.purchasePriorOP && (
                      <Popup
                        on={["hover", "focus"]}
                        header={"Prior OP - Purchase"}
                        content={g.purchasePriorOP}
                        trigger={
                          <CopyToClipboard
                            text={g.purchasePriorOP}
                            onCopy={() =>
                              addToast("Copied!", {
                                appearance: "success",
                                autoDismiss: true,
                              })
                            }
                          >
                            <div
                              className={css(paragraphStyles, {
                                cursor: "pointer",
                              })}
                            >
                              <LinesEllipsis
                                text={g.purchasePriorOP}
                                maxLine="2"
                                ellipsis="..."
                              />
                            </div>
                          </CopyToClipboard>
                        }
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "left" }}>
                    {g.refinancePriorOP && (
                      <Popup
                        on={["hover", "focus"]}
                        header={"Prior OP - Refinance"}
                        content={g.refinancePriorOP}
                        trigger={
                          <CopyToClipboard
                            text={g.refinancePriorOP}
                            onCopy={() =>
                              addToast("Copied!", {
                                appearance: "success",
                                autoDismiss: true,
                              })
                            }
                          >
                            <div
                              className={css(paragraphStyles, {
                                cursor: "pointer",
                              })}
                            >
                              <LinesEllipsis
                                text={g.refinancePriorOP}
                                maxLine="2"
                                ellipsis="..."
                              />
                            </div>
                          </CopyToClipboard>
                        }
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ textAlign: "left" }}>
                  <Table.Cell
                    style={{
                      textAlign: "left",
                      fontFamily: "FaktSlab-Normal",
                      fontSize: 16,
                      color: "#707898",
                    }}
                  >
                    Prior LP
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "left" }}>
                    {g.purchasePriorLP && (
                      <Popup
                        on={["hover", "focus"]}
                        header={"Prior LP - Purchase"}
                        content={g.purchasePriorLP}
                        trigger={
                          <CopyToClipboard
                            text={g.purchasePriorLP}
                            onCopy={() =>
                              addToast("Copied!", {
                                appearance: "success",
                                autoDismiss: true,
                              })
                            }
                          >
                            <div
                              className={css(paragraphStyles, {
                                cursor: "pointer",
                              })}
                            >
                              <LinesEllipsis
                                text={g.purchasePriorLP}
                                maxLine="2"
                                ellipsis="..."
                              />
                            </div>
                          </CopyToClipboard>
                        }
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell style={{ textAlign: "left" }}>
                    {g.refinancePriorLP && (
                      <Popup
                        on={["hover", "focus"]}
                        header={"Prior LP - Refinance"}
                        content={g.refinancePriorLP}
                        trigger={
                          <CopyToClipboard
                            text={g.refinancePriorLP}
                            onCopy={() =>
                              addToast("Copied!", {
                                appearance: "success",
                                autoDismiss: true,
                              })
                            }
                          >
                            <div
                              className={css(paragraphStyles, {
                                cursor: "pointer",
                              })}
                            >
                              <LinesEllipsis
                                text={g.refinancePriorLP}
                                maxLine="2"
                                ellipsis="..."
                              />
                            </div>
                          </CopyToClipboard>
                        }
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row style={{ textAlign: "left", verticalAlign: "top" }}>
                  <Table.Cell
                    style={{
                      textAlign: "left",
                      fontFamily: "FaktSlab-Normal",
                      fontSize: 16,
                      color: "#707898",
                    }}
                  >
                    Attachments
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {g.searchGuidelinePurchaseFiles.map((f) => (
                      <p
                        style={{ marginBottom: 0 }}
                        className={paragraphStyles}
                        key={f.id}
                      >
                        <a
                          onClick={() => download(f.id, "Purchase")}
                          className={css({
                            "&:hover": {
                              textDecoration: "underline",
                              color: "#00b8d4",
                              cursor: "pointer",
                            },
                          })}
                        >
                          <i
                            className="fal fa-paperclip"
                            style={{ color: "#00b8d4", marginRight: 8 }}
                          />
                          {f.fileName}
                        </a>
                      </p>
                    ))}
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {g.searchGuidelineRefinanceFiles.map((f) => (
                      <p className={paragraphStyles} key={f.id}>
                        <a
                          onClick={() => download(f.id, "Refinance")}
                          className={css({
                            "&:hover": {
                              textDecoration: "underline",
                              color: "#00b8d4",
                              cursor: "pointer",
                            },
                          })}
                        >
                          <i
                            className="fal fa-paperclip"
                            style={{ color: "#00b8d4", marginRight: 8 }}
                          />
                          {f.fileName}
                        </a>
                      </p>
                    ))}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </CustomTable>
          </div>
        ))}
      </div>
      {state.noteToDelete && (
        <Modal
          size={"tiny"}
          open={state.noteToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, noteToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete{" "}
              {state.noteToDelete.subject && (
                <strong>{state.noteToDelete.subject}</strong>
              )}
              ?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, noteToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteSearchGuidelineNote(state.noteToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {editable &&
        state.searchGuidelineNotes.length <= 0 &&
        state.searchGuidelines.length <= 0 && (
          <Message size="big">No search guidelines found</Message>
        )}
    </React.Fragment>
  );
}

export default withRouter(SearchGuidelines);
