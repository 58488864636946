import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import { StateContext } from "../../State";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import shortid from "shortid";
import { logEvent } from "../../../helpers/analyticsLogger";

const defaultLien = {
  waterSewerIsItLienable: undefined,
  personalPropertyTaxesIsItLienable: undefined
};

const sectionHeaderStyles = css({
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  marginBottom: 17,
  lineHeight: 1.71
});

export function EditLien({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    lien: defaultLien,
    waterSewerLienFiles: [],
    waterSewerFilesToDelete: [],
    realEstatePropertyTaxesLienFiles: [],
    realEstatePropertyTaxesFilesToDelete: [],
    personalPropertyTaxesLienFiles: [],
    personalPropertyTaxesFilesToDelete: [],
    mechanicsLienConstructionLienFiles: [],
    mechanicsLienConstructionFilesToDelete: []
  });

  async function getLienByState(stateId) {
    const result = await getRequest(`/lien/getLienByState`, {
      stateId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        lien: result.data
      }));
    }
  }

  useEffect(() => {
    getLienByState(selectedState);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({ ...state, submitting: true }));
      const result = await postRequest("/lien/editLien", {
        ...state.lien,
        stateId: selectedState,
        waterSewerLienFiles: await Promise.all(
          state.waterSewerLienFiles.map(async (f) => ({
            base64File: await readFileAsBase64(f),
            fileName: f.name
          }))
        ),
        waterSewerFilesToDelete: state.waterSewerFilesToDelete,
        realEstatePropertyTaxesLienFiles: await Promise.all(
          state.realEstatePropertyTaxesLienFiles.map(async (f) => ({
            base64File: await readFileAsBase64(f),
            fileName: f.name
          }))
        ),
        realEstatePropertyTaxesFilesToDelete:
          state.realEstatePropertyTaxesFilesToDelete,
        personalPropertyTaxesLienFiles: await Promise.all(
          state.personalPropertyTaxesLienFiles.map(async (f) => ({
            base64File: await readFileAsBase64(f),
            fileName: f.name
          }))
        ),
        personalPropertyTaxesFilesToDelete:
          state.personalPropertyTaxesFilesToDelete,
        mechanicsLienConstructionLienFiles: await Promise.all(
          state.mechanicsLienConstructionLienFiles.map(async (f) => ({
            base64File: await readFileAsBase64(f),
            fileName: f.name
          }))
        ),
        mechanicsLienConstructionFilesToDelete:
          state.mechanicsLienConstructionFilesToDelete
      });
      if (result.success) {
        logEvent(null, state.editMode ? "Edited" : "Added", "Lien");
        setTimeout(() => history.goBack(), 1000);
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        Lien
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <div className={sectionHeaderStyles}>Water/Sewer</div>
                <label>Is it lienable?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.lien.waterSewerIsItLienable}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        waterSewerIsItLienable:
                          state.lien.waterSewerIsItLienable === null ||
                          state.lien.waterSewerIsItLienable === false
                            ? true
                            : null
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={state.lien.waterSewerIsItLienable === false}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        waterSewerIsItLienable:
                          state.lien.waterSewerIsItLienable === null ||
                          state.lien.waterSewerIsItLienable === true
                            ? false
                            : null
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.lien.waterSewerNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        waterSewerNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.waterSewerLienFiles}
                  setFileToApp={(waterSewerLienFiles) =>
                    setState((state) => ({
                      ...state,
                      waterSewerLienFiles: [
                        ...state.waterSewerLienFiles,
                        ...waterSewerLienFiles
                      ]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      waterSewerLienFiles: [
                        ...state.waterSewerLienFiles.filter(
                          (f) => f.name !== fileName
                        )
                      ]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.lien.waterSewerFiles &&
                state.lien.waterSewerFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        waterSewerFilesToDelete: [
                          ...state.waterSewerFilesToDelete,
                          f.id
                        ],
                        lien: {
                          ...state.lien,
                          waterSewerFiles: [
                            ...state.lien.waterSewerFiles.filter(
                              (wsf) => wsf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <div className={sectionHeaderStyles}>
                  Real estate property taxes
                </div>
                <label>Duration</label>
                <TextArea
                  placeholder="Duration"
                  style={{ height: 47.4 }}
                  value={state.lien.realEstatePropertyTaxesDuration}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        realEstatePropertyTaxesDuration: value
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.lien.realEstatePropertyTaxesNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        realEstatePropertyTaxesNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.realEstatePropertyTaxesLienFiles}
                  setFileToApp={(realEstatePropertyTaxesLienFiles) =>
                    setState((state) => ({
                      ...state,
                      realEstatePropertyTaxesLienFiles: [
                        ...state.realEstatePropertyTaxesLienFiles,
                        ...realEstatePropertyTaxesLienFiles
                      ]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      realEstatePropertyTaxesLienFiles: [
                        ...state.realEstatePropertyTaxesLienFiles.filter(
                          (f) => f.name !== fileName
                        )
                      ]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.lien.realEstatePropertyTaxesFiles &&
                state.lien.realEstatePropertyTaxesFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        realEstatePropertyTaxesFilesToDelete: [
                          ...state.realEstatePropertyTaxesFilesToDelete,
                          f.id
                        ],
                        lien: {
                          ...state.lien,
                          realEstatePropertyTaxesFiles: [
                            ...state.lien.realEstatePropertyTaxesFiles.filter(
                              (reptf) => reptf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <div className={sectionHeaderStyles}>
                  Personal property taxes
                </div>
                <label>Is it lienable?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.lien.personalPropertyTaxesIsItLienable}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        personalPropertyTaxesIsItLienable:
                          state.lien.personalPropertyTaxesIsItLienable ===
                            null ||
                          state.lien.personalPropertyTaxesIsItLienable === false
                            ? true
                            : null
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.lien.personalPropertyTaxesIsItLienable === false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        personalPropertyTaxesIsItLienable:
                          state.lien.personalPropertyTaxesIsItLienable ===
                            null ||
                          state.lien.personalPropertyTaxesIsItLienable === true
                            ? false
                            : null
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.lien.personalPropertyTaxesNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        personalPropertyTaxesNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.personalPropertyTaxesLienFiles}
                  setFileToApp={(personalPropertyTaxesLienFiles) =>
                    setState((state) => ({
                      ...state,
                      personalPropertyTaxesLienFiles: [
                        ...state.personalPropertyTaxesLienFiles,
                        ...personalPropertyTaxesLienFiles
                      ]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      personalPropertyTaxesLienFiles: [
                        ...state.personalPropertyTaxesLienFiles.filter(
                          (f) => f.name !== fileName
                        )
                      ]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.lien.personalPropertyTaxesFiles &&
                state.lien.personalPropertyTaxesFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        personalPropertyTaxesFilesToDelete: [
                          ...state.personalPropertyTaxesFilesToDelete,
                          f.id
                        ],
                        lien: {
                          ...state.lien,
                          personalPropertyTaxesFiles: [
                            ...state.lien.personalPropertyTaxesFiles.filter(
                              (ptf) => ptf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <div className={sectionHeaderStyles}>
                  Mechanic’s Lien/Construction
                </div>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.lien.mechanicsLienConstructionNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      lien: {
                        ...state.lien,
                        mechanicsLienConstructionNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.mechanicsLienConstructionLienFiles}
                  setFileToApp={(mechanicsLienConstructionLienFiles) =>
                    setState((state) => ({
                      ...state,
                      mechanicsLienConstructionLienFiles: [
                        ...state.mechanicsLienConstructionLienFiles,
                        ...mechanicsLienConstructionLienFiles
                      ]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      mechanicsLienConstructionLienFiles: [
                        ...state.mechanicsLienConstructionLienFiles.filter(
                          (f) => f.name !== fileName
                        )
                      ]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.lien.mechanicsLienConstructionFiles &&
                state.lien.mechanicsLienConstructionFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        mechanicsLienConstructionFilesToDelete: [
                          ...state.mechanicsLienConstructionFilesToDelete,
                          f.id
                        ],
                        lien: {
                          ...state.lien,
                          mechanicsLienConstructionFiles: [
                            ...state.lien.mechanicsLienConstructionFiles.filter(
                              (mlcf) => mlcf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}
