import React from "react";
import { Modal, Button } from "semantic-ui-react";

export default function DeleteNoteAlert({ onClose, deleteNote }) {
  return (
    <Modal size={"tiny"} open={true} onClose={onClose} dimmer="inverted">
      <Modal.Header>Delete Note</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          onClick={onClose}
          style={{
            backgroundColor: "#fff",
            border: "solid 1px #e5e5ea",
            color: "rgba(0, 0, 0, 0.87)"
          }}
        >
          No
        </Button>
        <Button
          positive
          onClick={deleteNote}
          style={{ backgroundColor: "#1f2b5e" }}
        >
          Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
