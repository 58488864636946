import React, { useState, useEffect, useContext, useRef } from "react";
import { StateContext } from "../State";
import { Accordion, Icon, Label } from "semantic-ui-react";
import { css } from "emotion";
import WithholdingTax from "../rates/taxes/WithholdingTax";
import MortgageTax from "../rates/taxes/MortgageTax";
import TransferTax from "../rates/taxes/TransferTax";
import OPEndorsements from "../rates/premiums/OPEndorsements";
import LPEndorsements from "../rates/premiums/LPEndorsements";
import Manuals from "../rates/manuals/Manuals";
import CalculatorCard from "../rates/premiums/CalculatorCard";
import Contacts from "../contacts/Contacts";
import InvoiceCharges from "../rates/invoiceCharges/InvoiceCharges";
import Forms from "../forms/Forms";
import QuoteTemplates from "../rates/premiums/QuoteTemplates";
import Liens from "../info/liens/Liens";
import AdditionalLiens from "../info/liens/AdditionalLiens";
import Recordings from "../info/recordings/Recordings";
import Codes from "../info/commitmentInstructions/Codes";
import PolicyDragSortNotes from "../info/policyInstructions/DragSortNotes";
import CommitmentDragSortNotes from "../info/commitmentInstructions/DragSortNotes";
import HelpfulWordings from "../info/policyInstructions/HelpfulWordings";
import BIGuidelinesToOmit from "../info/policyInstructions/BIGuidelinesToOmit";
import Endorsements from "../info/policyInstructions/Endorsements";
import { UserContext } from "../User";
import HighLiabilityApprovals from "../info/highLiabilityApprovals/HighLiabilityApprovals";
import StateIdiosyncrasies from "../info/stateIdiosyncrasies/StateIdiosyncrasies";
import ExceptionCodes from "../info/surveys/ExceptionCodes";
import DragSurveySortLegalDescriptionNotes from "../info/surveys/DragSurveySortLegalDescriptionNotes";
import Modifications from "../info/modifications/Modifications";

function Results({
  contacts,
  companies,
  invoiceCharges,
  taxes,
  endorsements,
  calculators,
  manuals,
  forms,
  quoteTemplates,
  liens,
  additionalLiens,
  recordings,
  stateIdiosyncrasies,
  modifications,
  highLiabilityApprovals,
  scheduleANotes,
  scheduleBICodes,
  policyScheduleBICodes,
  commitmentScheduleBIICodes,
  scheduleBINotes,
  scheduleBIINotes,
  commitmentGeneralInfoNotes,
  policyGeneralInfoNotes,
  policyHelpfulWordings,
  policyEndorsements,
  policySurveyNotes,
  surveyLegalDescriptionNotes,
  surveyExceptionCodes,
  noState = false,
  filter
}) {
  const { states } = useContext(StateContext);
  const { user } = useContext(UserContext);

  const [biCodesAndNotes, setBiCodesAndNotes] = useState([[]]);

  const updateBiCodesAndNotes = codesAndNotes => {
    setBiCodesAndNotes(codesAndNotes);
  };

  const [biiCodesAndNotes, setBiiCodesAndNotes] = useState([[]]);

  const updateBiiCodesAndNotes = codesAndNotes => {
    setBiiCodesAndNotes(codesAndNotes);
  };

  const [state, setState] = useState({
    invoiceChargeStates: [
      ...new Set(
        invoiceCharges &&
          invoiceCharges
            .map(ic => ic.invoiceChargeStates)
            .flat()
            .map(ics => ics.stateId)
      )
    ].sort(),
    formStates: [
      ...new Set(
        forms &&
          forms
            .map(f => f.formStates)
            .flat()
            .map(fs => fs.stateId)
      )
    ].sort()
  });
  const [activeIndex, setActiveIndex] = useState(null);

  const firstResult = () => {
    if (
      (filter === "Contacts" || filter === "All") && contactAndCompanyResults
        ? contactAndCompanyResults.length > 0
        : false
    ) {
      setActiveIndex("contacts");
    } else if ((filter === "Info" || filter === "All") && stateIdiosyncrasies.length > 0) {
      setActiveIndex("stateIdiosyncrasies" + stateIdiosyncrasies[0].stateId);
    } else if ((filter === "Info" || filter === "All") && modifications.length > 0) {
      setActiveIndex("modifications" + modifications[0].stateId);
    } else if ((filter === "Rates" || filter === "All") && taxes && taxes.length > 0) {
      setActiveIndex("tax" + taxes[0].stateId);
    } else if ((filter === "Policy" || filter === "All") && endorsements.length > 0) {
      setActiveIndex("endorsement" + endorsements[0].stateId);
    } else if ((filter === "Rates" || filter === "All") && manuals && manuals.length > 0) {
      setActiveIndex("manuals" + manuals[0][0].stateId);
    } else if ((filter === "Rates" || filter === "All") && noState && state.invoiceChargeStates.length > 0) {
      setActiveIndex("invoiceCharges" + state.invoiceChargeStates[0]);
    } else if (
      (filter === "Rates" || filter === "All" || filter === "Commitment") &&
      invoiceCharges &&
      invoiceCharges.length > 0
    ) {
      setActiveIndex("invoiceCharges");
    } else if ((filter === "Forms" || filter === "All") && noState && state.formStates.length > 0) {
      setActiveIndex("forms" + state.formStates[0]);
    } else if ((filter === "Forms" || filter === "All") && forms && forms.length > 0) {
      setActiveIndex("forms");
    } else if ((filter === "Rates" || filter === "All") && quoteTemplates && quoteTemplates.length > 0) {
      setActiveIndex("quoteTemplates" + quoteTemplates[0][0].stateId);
    } else if (liens && (filter === "Info" || filter === "All") && liens.length > 0) {
      setActiveIndex("liens" + liens[0].stateId);
    } else if (additionalLiens && (filter === "Info" || filter === "All") && additionalLiens.length > 0) {
      setActiveIndex("additionalLiens" + additionalLiens[0].stateId);
    } else if ((filter === "Info" || filter === "All") && recordings && recordings.length > 0) {
      setActiveIndex("recordings" + recordings[0].stateId);
    } else if ((filter === "Info" || filter === "All") && highLiabilityApprovals.length > 0) {
      setActiveIndex("highLiabilityApprovals" + highLiabilityApprovals[0].stateId);
    } else if ((filter === "Rates" || filter === "All") && calculators && calculators.length > 0) {
      setActiveIndex("calculators");
    } else if ((filter === "Commitment" || filter === "All") && scheduleANotes && scheduleANotes.length > 0) {
      if (!noState) {
        setActiveIndex("scheduleANotes");
      } else {
        setActiveIndex("scheduleANotes" + scheduleANotes[0].stateId);
      }
    } else if (
      ((filter === "Commitment" || filter === "All") && scheduleBICodes && scheduleBICodes.length > 0) ||
      (scheduleBINotes && scheduleBINotes.length > 0)
    ) {
      if (!noState) {
        setActiveIndex("scheduleBICodes");
      } else {
        setActiveIndex("scheduleBICodes" + biCodesAndNotes[0]?.stateId);
      }
    } else if (
      ((filter === "Commitment" || filter === "All") &&
        commitmentScheduleBIICodes &&
        commitmentScheduleBIICodes.length > 0) ||
      (scheduleBIINotes && scheduleBIINotes.length > 0)
    ) {
      if (!noState) {
        setActiveIndex("commitmentScheduleBIICodes");
      } else {
        setActiveIndex("commitmentScheduleBIICodes" + biiCodesAndNotes[0]?.stateId);
      }
    } else if (
      (filter === "Commitment" || filter === "All") &&
      commitmentGeneralInfoNotes &&
      commitmentGeneralInfoNotes.length > 0
    ) {
      if (!noState) {
        setActiveIndex("commitmentGeneralInfoNotes");
      } else {
        setActiveIndex("commitmentGeneralInfoNotes" + commitmentGeneralInfoNotes[0].stateId);
      }
    } else if (
      (filter === "Policy" || filter === "All") &&
      policyGeneralInfoNotes &&
      policyGeneralInfoNotes.length > 0
    ) {
      if (!noState) {
        setActiveIndex("policyGeneralInfoNotes");
      } else {
        setActiveIndex("policyGeneralInfoNotes" + policyGeneralInfoNotes[0].stateId);
      }
    } else if ((filter === "Surveys" || filter === "All") && policySurveyNotes && policySurveyNotes.length > 0) {
      if (!noState) {
        setActiveIndex("policySurveyNotes");
      } else {
        setActiveIndex("policySurveyNotes" + policySurveyNotes[0].stateId);
      }
    } else if (
      (filter === "Surveys" || filter === "All") &&
      surveyLegalDescriptionNotes &&
      surveyLegalDescriptionNotes.length > 0
    ) {
      if (!noState) {
        setActiveIndex("surveyLegalDescriptionNotes");
      } else {
        setActiveIndex("surveyLegalDescriptionNotes" + surveyLegalDescriptionNotes[0].stateId);
      }
    } else if ((filter === "Surveys" || filter === "All") && surveyExceptionCodes && surveyExceptionCodes.length > 0) {
      if (!noState) {
        setActiveIndex("surveyExceptionCodes");
      } else {
        setActiveIndex("surveyExceptionCodes" + surveyExceptionCodes[0].stateId);
      }
    } else if ((filter === "Policy" || filter === "All") && policyHelpfulWordings && policyHelpfulWordings.length > 0) {
      if (!noState) {
        setActiveIndex("policyHelpfulWordings");
      } else {
        setActiveIndex("policyHelpfulWordings");
      }
    } else if ((filter === "Policy" || filter === "All") && policyEndorsements && policyEndorsements.length > 0) {
      if (!noState) {
        setActiveIndex("policyEndorsements");
      } else {
        setActiveIndex("policyEndorsements");
      }
    } else if ((filter === "Policy" || filter === "All") && policyScheduleBICodes && policyScheduleBICodes.length > 0) {
      if (!noState) {
        setActiveIndex("bi");
      } else {
        setActiveIndex("bi" + policyScheduleBICodes[0].stateId);
      }
    }
  };

  useEffect(() => {
    const states = [...new Set([...scheduleBICodes.map(cs => cs.stateId), ...scheduleBINotes.map(ns => ns.stateId)])];
    const codesAndNotes = states.sort().map(s => ({
      stateId: s,
      codes: !noState
        ? scheduleBICodes.filter(c => c.stateId === s)
        : scheduleBICodes
            .filter(c => c.stateId === s)
            .map(codes => codes.scheduleBICodes.map(code => code.document))
            .flat(),
      notes: !noState
        ? scheduleBINotes.filter(n => n.stateId === s)
        : scheduleBINotes
            .filter(n => n.stateId === s)
            .map(notes => notes.scheduleBINotes.map(note => note.document))
            .flat()
    }));
    updateBiCodesAndNotes(codesAndNotes);
  }, [scheduleBINotes]);

  useEffect(() => {
    const states = [
      ...new Set([...commitmentScheduleBIICodes?.map(cs => cs.stateId), ...scheduleBIINotes.map(ns => ns.stateId)])
    ];
    const codesAndNotes = states.sort().map(s => ({
      stateId: s,
      codes: !noState
        ? commitmentScheduleBIICodes.filter(c => c.stateId === s)
        : commitmentScheduleBIICodes
            .filter(c => c.stateId === s)
            .map(codes => codes.scheduleBIICodes.map(code => code.document))
            .flat(),
      notes: !noState
        ? scheduleBIINotes.filter(n => n.stateId === s)
        : scheduleBIINotes
            .filter(n => n.stateId === s)
            .map(notes => notes.scheduleBIINotes.map(note => note.document))
            .flat()
    }));
    updateBiiCodesAndNotes(codesAndNotes);
  }, [scheduleBIINotes]);

  useEffect(() => {
    firstResult();
  }, [
    contacts,
    invoiceCharges,
    state.invoiceChargeStates,
    taxes,
    endorsements,
    calculators,
    manuals,
    forms,
    quoteTemplates,
    liens,
    additionalLiens,
    stateIdiosyncrasies,
    modifications,
    recordings,
    highLiabilityApprovals,
    scheduleBICodes,
    commitmentScheduleBIICodes,
    scheduleBINotes,
    scheduleBIINotes,
    policyGeneralInfoNotes,
    policyHelpfulWordings,
    policyEndorsements,
    policySurveyNotes,
    surveyLegalDescriptionNotes,
    scheduleANotes,
    commitmentGeneralInfoNotes
  ]);

  const titleStyle = index => ({
    fontFamily: "Fakt-Bold",
    fontSize: 20,
    color: "#000e48",
    padding: "23px 0px",
    lineHeight: 1.05,
    borderBottom: activeIndex !== index ? "solid 1px #ebecf1" : "none",
    userSelect: "none"
  });

  const contactResults =
    (filter === "Contacts" || filter === "All") &&
    contacts &&
    contacts.map(r => ({
      score: r["@search.score"] || r.score,
      type: "contact",
      contact: r
    }));

  const companyResults =
    (filter === "Contacts" || filter === "All") &&
    companies &&
    companies.map(r => ({
      score: r.score,
      type: "company",
      contact: r
    }));

  const contactAndCompanyResults =
    (filter === "Contacts" || filter === "All") &&
    contactResults &&
    [...contactResults, ...companyResults]?.sort(function(a, b) {
      return b.score - a.score;
    });

  return (
    <React.Fragment>
      <Accordion>
        {(contactAndCompanyResults ? contactAndCompanyResults.length > 0 : false) && (
          <React.Fragment>
            <Accordion.Title
              active={activeIndex === "contacts"}
              index={0}
              onClick={() => setActiveIndex(activeIndex !== "contacts" ? "contacts" : null)}
              style={titleStyle("contacts")}
            >
              <Icon name="dropdown" />
              Contacts{" "}
              <span
                className={css({
                  fontFamily: "Fakt-Normal",
                  color: "#707898",
                  marginLeft: 8
                })}
              >
                {contactAndCompanyResults.length}
              </span>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === "contacts"}>
              <Contacts contactSearchResults={contactAndCompanyResults} noState={noState} />
            </Accordion.Content>
          </React.Fragment>
        )}
        {(filter === "Info" || filter === "All") && !noState && stateIdiosyncrasies && stateIdiosyncrasies.length > 0 && (
          <React.Fragment>
            <React.Fragment key={"stateIdiosyncrasies" + stateIdiosyncrasies[0].stateId}>
              <Accordion.Title
                active={activeIndex === "stateIdiosyncrasies" + stateIdiosyncrasies[0].stateId}
                index={"stateIdiosyncrasies" + stateIdiosyncrasies[0].stateId}
                onClick={() =>
                  setActiveIndex(
                    activeIndex !== "stateIdiosyncrasies" + stateIdiosyncrasies[0].stateId
                      ? "stateIdiosyncrasies" + stateIdiosyncrasies[0].stateId
                      : null
                  )
                }
                style={titleStyle("stateIdiosyncrasies" + stateIdiosyncrasies[0].stateId)}
              >
                <Icon name="dropdown" />
                State Idiosyncrasies{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {stateIdiosyncrasies.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "stateIdiosyncrasies" + stateIdiosyncrasies[0].stateId}>
                <StateIdiosyncrasies stateIdiosyncrasySearchResults={stateIdiosyncrasies} />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "Info" || filter === "All") &&
          noState &&
          stateIdiosyncrasies &&
          stateIdiosyncrasies.length > 0 &&
          stateIdiosyncrasies.map(r => (
            <React.Fragment key={"stateIdiosyncrasies" + r.stateId}>
              <Accordion.Title
                active={activeIndex === "stateIdiosyncrasies" + r.stateId}
                index={r.stateId}
                onClick={() =>
                  setActiveIndex(
                    activeIndex !== "stateIdiosyncrasies" + r.stateId ? "stateIdiosyncrasies" + r.stateId : null
                  )
                }
                style={titleStyle("stateIdiosyncrasies" + r.stateId)}
              >
                <Icon name="dropdown" />
                State Idiosyncrasies{" "}
                {noState && (
                  <Label className="secondaryLabel" key={"stateIdiosyncrasies" + r.stateId}>
                    {states.length > 0 &&
                      states.find(s => s.id === r.stateId) &&
                      states.find(s => s.id === r.stateId).name}
                  </Label>
                )}{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {r && r.stateIdiosyncrasies && r.stateIdiosyncrasies.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "stateIdiosyncrasies" + r.stateId}>
                <StateIdiosyncrasies stateIdiosyncrasySearchResults={r.stateIdiosyncrasies} />
              </Accordion.Content>
            </React.Fragment>
          ))}

        {(filter === "Info" || filter === "All") && !noState && modifications && modifications.length > 0 && (
          <React.Fragment>
            <React.Fragment key={"modifications" + modifications[0].stateId}>
              <Accordion.Title
                active={activeIndex === "modifications" + modifications[0].stateId}
                index={"modifications" + modifications[0].stateId}
                onClick={() =>
                  setActiveIndex(
                    activeIndex !== "modifications" + modifications[0].stateId
                      ? "modifications" + modifications[0].stateId
                      : null
                  )
                }
                style={titleStyle("modifications" + modifications[0].stateId)}
              >
                <Icon name="dropdown" />
                Modifications{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {modifications.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "modifications" + modifications[0].stateId}>
                <Modifications modificationSearchResults={modifications} />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "Info" || filter === "All") &&
          noState &&
          modifications &&
          modifications.length > 0 &&
          modifications.map(r => (
            <React.Fragment key={"modifications" + r.stateId}>
              <Accordion.Title
                active={activeIndex === "modifications" + r.stateId}
                index={r.stateId}
                onClick={() =>
                  setActiveIndex(activeIndex !== "modifications" + r.stateId ? "modifications" + r.stateId : null)
                }
                style={titleStyle("modifications" + r.stateId)}
              >
                <Icon name="dropdown" />
                Modifications{" "}
                {noState && (
                  <Label className="secondaryLabel" key={"modifications" + r.stateId}>
                    {states.length > 0 &&
                      states.find(s => s.id === r.stateId) &&
                      states.find(s => s.id === r.stateId).name}
                  </Label>
                )}{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {r && r.modifications && r.modifications.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "modifications" + r.stateId}>
                <Modifications modificationSearchResults={r.modifications} />
              </Accordion.Content>
            </React.Fragment>
          ))}

        {(filter === "Rates" || filter === "All" || filter === "Commitment") &&
          !noState &&
          invoiceCharges &&
          invoiceCharges.length > 0 && (
            <React.Fragment>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === "invoiceCharges"}
                  index={"invoiceCharges"}
                  onClick={() => setActiveIndex(activeIndex !== "invoiceCharges" ? "invoiceCharges" : null)}
                  style={titleStyle("invoiceCharges")}
                >
                  <Icon name="dropdown" />
                  Invoice Charges{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {invoiceCharges.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "invoiceCharges"}>
                  <InvoiceCharges invoiceChargeSearchResults={invoiceCharges} editable={true} />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {noState &&
          (filter === "Rates" || filter === "All" || filter === "Commitment") &&
          state.invoiceChargeStates.length > 0 && (
            <React.Fragment>
              {state.invoiceChargeStates.map(s => (
                <React.Fragment key={"invoiceCharges" + s}>
                  <Accordion.Title
                    active={activeIndex === "invoiceCharges" + s}
                    index={s}
                    onClick={() => setActiveIndex(activeIndex !== "invoiceCharges" + s ? "invoiceCharges" + s : null)}
                    style={titleStyle("invoiceCharges" + s)}
                  >
                    <Icon name="dropdown" />
                    Invoice Charges{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"invoiceCharges" + s}>
                        {states.length > 0 &&
                          states.find(state => state.id === s) &&
                          states.find(state => state.id === s).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {invoiceCharges.filter(ic => ic.invoiceChargeStates.some(ics => ics.stateId === s)).length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "invoiceCharges" + s}>
                    <InvoiceCharges
                      invoiceChargeSearchResults={invoiceCharges.filter(ic =>
                        ic.invoiceChargeStates.some(ics => ics.stateId === s)
                      )}
                      editable={true}
                    />
                  </Accordion.Content>
                </React.Fragment>
              ))}
            </React.Fragment>
          )}
        {(filter === "Rates" || filter === "All") && taxes && taxes.length > 0 && (
          <React.Fragment>
            {taxes.map(t => (
              <React.Fragment key={"tax" + t.stateId}>
                <Accordion.Title
                  active={activeIndex === "tax" + t.stateId}
                  index={t.stateId}
                  onClick={() => setActiveIndex(activeIndex !== "tax" + t.stateId ? "tax" + t.stateId : null)}
                  style={titleStyle("tax" + t.stateId)}
                >
                  <Icon name="dropdown" />
                  Taxes{" "}
                  {noState && (
                    <Label className="secondaryLabel" key={"tax" + t.stateId}>
                      {states.find(s => s.id === t.stateId) && states.find(s => s.id === t.stateId).name}
                    </Label>
                  )}{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {(t.withholdingTax ? 1 : 0) + (t.mortgageTax ? 1 : 0) + (t.transferTax ? 1 : 0)}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "tax" + t.stateId}>
                  {t.withholdingTax && (
                    <WithholdingTax
                      tax={t.withholdingTax}
                      editRoute={`/rates/states/${t.stateId}/edit-withholding-tax`}
                    />
                  )}
                  {t.mortgageTax && (
                    <MortgageTax tax={t.mortgageTax} editRoute={`/rates/states/${t.stateId}/edit-mortgage-tax`} />
                  )}
                  {t.transferTax && (
                    <TransferTax tax={t.transferTax} editRoute={`/rates/states/${t.stateId}/edit-transfer-tax`} />
                  )}
                </Accordion.Content>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        {(filter === "Rates" || filter === "All") && endorsements.length > 0 && (
          <React.Fragment>
            {endorsements
              .sort((a, b) => {
                return a.stateId - b.stateId;
              })
              .map(e => (
                <React.Fragment key={"endorsement" + e.stateId}>
                  <Accordion.Title
                    active={activeIndex === "endorsement" + e.stateId}
                    index={e.stateId}
                    onClick={() =>
                      setActiveIndex(activeIndex !== "endorsement" + e.stateId ? "endorsement" + e.stateId : null)
                    }
                    style={titleStyle("endorsement" + e.stateId)}
                  >
                    <Icon name="dropdown" />
                    Endorsements{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"endorsement" + e.stateId}>
                        {states.find(s => s.id === e.stateId) && states.find(s => s.id === e.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {(e.opEndorsements ? e.opEndorsements.length : 0) +
                        (e.lpEndorsements ? e.lpEndorsements.length : 0)}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "endorsement" + e.stateId}>
                    {e.opEndorsements && e.opEndorsements.length > 0 && (
                      <OPEndorsements endorsementSearchResults={e.opEndorsements} />
                    )}
                    {e.lpEndorsements.length > 0 && e.opEndorsements.length > 0 && (
                      <div className={css({ marginBottom: 64 })}></div>
                    )}
                    {e.lpEndorsements && e.lpEndorsements.length > 0 && (
                      <LPEndorsements endorsementSearchResults={e.lpEndorsements} />
                    )}
                  </Accordion.Content>
                </React.Fragment>
              ))}
          </React.Fragment>
        )}
        {(filter === "Rates" || filter === "All") && manuals && manuals.length > 0 && (
          <React.Fragment>
            {manuals.map(m => (
              <React.Fragment key={m[0].stateId}>
                <Accordion.Title
                  active={activeIndex === "manuals" + m[0].stateId}
                  index={m[0].stateId}
                  onClick={() =>
                    setActiveIndex(activeIndex !== "manuals" + m[0].stateId ? "manuals" + m[0].stateId : null)
                  }
                  style={titleStyle("manuals" + m[0].stateId)}
                >
                  <Icon name="dropdown" />
                  Manuals{" "}
                  {noState && (
                    <Label className="secondaryLabel" key={m[0].stateId}>
                      {states.length > 0 &&
                        states.find(s => s.id === m[0].stateId) &&
                        states.find(s => s.id === m[0].stateId).name}
                    </Label>
                  )}{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {m.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "manuals" + m[0].stateId}>
                  <Manuals manualSearchResults={m} />
                </Accordion.Content>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        {(filter === "Rates" || filter === "All") && calculators && calculators.length > 0 && (
          <React.Fragment>
            <React.Fragment>
              <Accordion.Title
                active={activeIndex === "calculators"}
                index={"calculators"}
                onClick={() => setActiveIndex(activeIndex !== "calculators" ? "calculators" : null)}
                style={titleStyle("calculators")}
              >
                <Icon name="dropdown" />
                Premium Calculators{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {calculators.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "calculators"}>
                {calculators.map(c => (
                  <CalculatorCard calculator={c} key={c.id} />
                ))}
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "Forms" || filter === "All") && !noState && forms && forms.length > 0 && (
          <React.Fragment>
            <React.Fragment>
              <Accordion.Title
                active={activeIndex === "forms"}
                index={"forms"}
                onClick={() => setActiveIndex(activeIndex !== "forms" ? "forms" : null)}
                style={titleStyle("forms")}
              >
                <Icon name="dropdown" />
                Forms{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {forms.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "forms"}>
                <Forms formSearchResults={forms} categories={[...new Set(forms.map(f => f.formCategoryId))].sort()} />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {noState && (filter === "Forms" || filter === "All") && state.formStates.length > 0 && (
          <React.Fragment>
            {state.formStates.map(s => (
              <React.Fragment key={"forms" + s}>
                <Accordion.Title
                  active={activeIndex === "forms" + s}
                  index={s}
                  onClick={() => setActiveIndex(activeIndex !== "forms" + s ? "forms" + s : null)}
                  style={titleStyle("forms" + s)}
                >
                  <Icon name="dropdown" />
                  Forms{" "}
                  {noState && (
                    <Label className="secondaryLabel" key={"forms" + s}>
                      {states.length > 0 &&
                        states.find(state => state.id === s) &&
                        states.find(state => state.id === s).name}
                    </Label>
                  )}{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {forms && forms.filter(f => f.formStates.some(fs => fs.stateId === s)).length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "forms" + s}>
                  <Forms
                    formSearchResults={forms && forms.filter(f => f.formStates.some(fs => fs.stateId === s))}
                    categories={[
                      ...new Set(
                        forms && forms.filter(f => f.formStates.some(fs => fs.stateId === s)).map(f => f.formCategoryId)
                      )
                    ].sort()}
                  />
                </Accordion.Content>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        {(filter === "Rates" || filter === "All") && quoteTemplates && quoteTemplates.length > 0 && (
          <React.Fragment>
            {quoteTemplates.map(q => (
              <React.Fragment key={q[0].stateId}>
                <Accordion.Title
                  active={activeIndex === "quoteTemplates" + q[0].stateId}
                  index={q[0].stateId}
                  onClick={() =>
                    setActiveIndex(
                      activeIndex !== "quoteTemplates" + q[0].stateId ? "quoteTemplates" + q[0].stateId : null
                    )
                  }
                  style={titleStyle(q[0].stateId)}
                >
                  <Icon name="dropdown" />
                  Quote Templates{" "}
                  {noState && (
                    <Label className="secondaryLabel" key={q[0].stateId}>
                      {states.length > 0 &&
                        states.find(s => s.id === q[0].stateId) &&
                        states.find(s => s.id === q[0].stateId).name}
                    </Label>
                  )}{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {q.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "quoteTemplates" + q[0].stateId}>
                  <QuoteTemplates quoteTemplateSearchResults={q} />
                </Accordion.Content>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        {(filter === "Info" || filter === "All") && liens && liens.length > 0 && (
          <React.Fragment>
            {liens.map(l => (
              <React.Fragment key={"liens" + l.stateId}>
                <Accordion.Title
                  active={activeIndex === "liens" + l.stateId}
                  index={"liens" + l.stateId}
                  onClick={() => setActiveIndex(activeIndex !== "liens" + l.stateId ? "liens" + l.stateId : null)}
                  style={titleStyle("liens" + l.stateId)}
                >
                  <Icon name="dropdown" />
                  Liens{" "}
                  {noState && (
                    <Label className="secondaryLabel" key={"liens" + l.stateId}>
                      {states.length > 0 &&
                        states.find(s => s.id === l.stateId) &&
                        states.find(s => s.id === l.stateId).name}
                    </Label>
                  )}{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {l.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "liens" + l.stateId}>
                  <Liens lienSearchResults={l} editable={true} />
                </Accordion.Content>
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
        {(filter === "Info" || filter === "All") && !noState && additionalLiens && additionalLiens.length > 0 && (
          <React.Fragment>
            <React.Fragment key={additionalLiens[0].stateId}>
              <Accordion.Title
                active={activeIndex === "additionalLiens" + additionalLiens[0].stateId}
                index={additionalLiens[0].stateId}
                onClick={() =>
                  setActiveIndex(
                    activeIndex !== "additionalLiens" + additionalLiens[0].stateId
                      ? "additionalLiens" + additionalLiens[0].stateId
                      : null
                  )
                }
                style={titleStyle("additionalLiens" + additionalLiens[0].stateId)}
              >
                <Icon name="dropdown" />
                Additional Liens{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {additionalLiens.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "additionalLiens" + additionalLiens[0].stateId}>
                <AdditionalLiens additionalLienSearchResults={additionalLiens} />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "Info" || filter === "All") &&
          noState &&
          additionalLiens &&
          additionalLiens.length > 0 &&
          additionalLiens.map(l => (
            <React.Fragment key={l.stateId}>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === l.stateId}
                  index={l.stateId}
                  onClick={() => setActiveIndex(activeIndex !== l.stateId ? l.stateId : null)}
                  style={titleStyle(l.stateId)}
                >
                  <Icon name="dropdown" />
                  Additional Liens{" "}
                  {noState && (
                    <Label className="secondaryLabel" key={l.stateId}>
                      {states.length > 0 &&
                        states.find(s => s.id === l.stateId) &&
                        states.find(s => s.id === l.stateId).name}
                    </Label>
                  )}{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {l.additionalLiens && l.additionalLiens.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === l.stateId}>
                  <AdditionalLiens additionalLienSearchResults={l.additionalLiens} />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          ))}
        {(filter === "Info" || filter === "All") && !noState && recordings && recordings.length > 0 && (
          <React.Fragment>
            <React.Fragment key={"recordings" + recordings[0].stateId}>
              <Accordion.Title
                active={activeIndex === "recordings" + recordings[0].stateId}
                index={"recordings" + recordings[0].stateId}
                onClick={() =>
                  setActiveIndex(
                    activeIndex !== "recordings" + recordings[0].stateId ? "recordings" + recordings[0].stateId : null
                  )
                }
                style={titleStyle("recordings" + recordings[0].stateId)}
              >
                <Icon name="dropdown" />
                Recordings{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {recordings.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "recordings" + recordings[0].stateId}>
                <Recordings recordingSearchResults={recordings} />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "info" || filter === "All") &&
          noState &&
          recordings &&
          recordings.length > 0 &&
          recordings.map(r => (
            <React.Fragment key={"recordings" + r.stateId}>
              <Accordion.Title
                active={activeIndex === "recordings" + r.stateId}
                index={r.stateId}
                onClick={() =>
                  setActiveIndex(activeIndex !== "recordings" + r.stateId ? "recordings" + r.stateId : null)
                }
                style={titleStyle("recordings" + r.stateId)}
              >
                <Icon name="dropdown" />
                Recordings{" "}
                {noState && (
                  <Label className="secondaryLabel" key={"recordings" + r.stateId}>
                    {states.length > 0 &&
                      states.find(s => s.id === r.stateId) &&
                      states.find(s => s.id === r.stateId).name}
                  </Label>
                )}{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {r && r.recordings && r.recordings.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "recordings" + r.stateId}>
                <Recordings recordingSearchResults={r.recordings} />
              </Accordion.Content>
            </React.Fragment>
          ))}
        {(filter === "Info" || filter === "All") &&
          !noState &&
          highLiabilityApprovals &&
          highLiabilityApprovals.length > 0 && (
            <React.Fragment>
              <React.Fragment key={"highLiabilityApprovals" + highLiabilityApprovals[0].stateId}>
                <Accordion.Title
                  active={activeIndex === "highLiabilityApprovals" + highLiabilityApprovals[0].stateId}
                  index={"highLiabilityApprovals" + highLiabilityApprovals[0].stateId}
                  onClick={() =>
                    setActiveIndex(
                      activeIndex !== "highLiabilityApprovals" + highLiabilityApprovals[0].stateId
                        ? "highLiabilityApprovals" + highLiabilityApprovals[0].stateId
                        : null
                    )
                  }
                  style={titleStyle("highLiabilityApprovals" + highLiabilityApprovals[0].stateId)}
                >
                  <Icon name="dropdown" />
                  High Liability Approvals{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {highLiabilityApprovals.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === "highLiabilityApprovals" + highLiabilityApprovals[0].stateId}
                >
                  <HighLiabilityApprovals highLiabilityApprovalSearchResults={highLiabilityApprovals} />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {(filter === "Rates" || filter === "All") &&
          noState &&
          highLiabilityApprovals &&
          highLiabilityApprovals.length > 0 &&
          highLiabilityApprovals.map(r => (
            <React.Fragment key={"highLiabilityApprovals" + r.stateId}>
              <Accordion.Title
                active={activeIndex === "highLiabilityApprovals" + r.stateId}
                index={r.stateId}
                onClick={() =>
                  setActiveIndex(
                    activeIndex !== "highLiabilityApprovals" + r.stateId ? "highLiabilityApprovals" + r.stateId : null
                  )
                }
                style={titleStyle("highLiabilityApprovals" + r.stateId)}
              >
                <Icon name="dropdown" />
                High Liability Approvals{" "}
                {noState && (
                  <Label className="secondaryLabel" key={"highLiabilityApprovals" + r.stateId}>
                    {states.length > 0 &&
                      states.find(s => s.id === r.stateId) &&
                      states.find(s => s.id === r.stateId).name}
                  </Label>
                )}{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {r && r.highLiabilityApprovals && r.highLiabilityApprovals.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "highLiabilityApprovals" + r.stateId}>
                <HighLiabilityApprovals highLiabilityApprovalSearchResults={r.highLiabilityApprovals} />
              </Accordion.Content>
            </React.Fragment>
          ))}
        {(filter === "Commitment" || filter === "All") && !noState && scheduleANotes && scheduleANotes.length > 0 && (
          <React.Fragment key={"scheduleANotes"}>
            <React.Fragment>
              <Accordion.Title
                active={activeIndex === "scheduleANotes"}
                index={"scheduleANotes"}
                onClick={() => setActiveIndex(activeIndex !== "scheduleANotes" ? "scheduleANotes" : null)}
                style={titleStyle("scheduleANotes")}
              >
                <Icon name="dropdown" />
                Schedule A{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {scheduleANotes.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "scheduleANotes"}>
                <CommitmentDragSortNotes
                  items={scheduleANotes.map(n => n.document || n)}
                  draggable={false}
                  editNotePath={`/commitment-instructions/edit-schedule-a-note`}
                  editable={user.isAdmin || user.isOwnerPlus}
                  downloadUrl={`/scheduleA/downloadFile`}
                />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "Commitment" || filter === "All") &&
          noState &&
          scheduleANotes &&
          scheduleANotes.length > 0 &&
          scheduleANotes.map(
            n =>
              n.scheduleANotes && (
                <React.Fragment key={"scheduleANotes" + n.stateId}>
                  <Accordion.Title
                    active={activeIndex === "scheduleANotes" + n.stateId}
                    index={"scheduleANotes" + n.stateId}
                    onClick={() =>
                      setActiveIndex(activeIndex !== "scheduleANotes" + n.stateId ? "scheduleANotes" + n.stateId : null)
                    }
                    style={titleStyle("scheduleANotes" + n.stateId)}
                  >
                    <Icon name="dropdown" />
                    Schedule A{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"scheduleANotes" + n.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === n.stateId) &&
                          states.find(s => s.id === n.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {n && n.scheduleANotes && n.scheduleANotes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "scheduleANotes" + n.stateId}>
                    <CommitmentDragSortNotes
                      items={n.scheduleANotes.map(n => n.document)}
                      draggable={false}
                      editNotePath={`/commitment-instructions/edit-schedule-a-note`}
                      editable={user.isAdmin || user.isOwnerPlus}
                      downloadUrl={`/scheduleA/downloadFile`}
                    />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Commitment" || filter === "All") &&
          !noState &&
          ((scheduleBICodes && scheduleBICodes.length > 0) || (scheduleBINotes && scheduleBINotes.length > 0)) && (
            <React.Fragment key={"scheduleBICodes"}>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === "scheduleBICodes"}
                  index={"scheduleBICodes"}
                  onClick={() => setActiveIndex(activeIndex !== "scheduleBICodes" ? "scheduleBICodes" : null)}
                  style={titleStyle("scheduleBICodes")}
                >
                  <Icon name="dropdown" />
                  Schedule BI{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {scheduleBICodes.length + scheduleBINotes.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "scheduleBICodes"}>
                  <CommitmentDragSortNotes
                    items={scheduleBINotes}
                    draggable={false}
                    editNotePath={`/commitment-instructions/edit-schedule-bi-note`}
                    editable={user.isAdmin || user.isOwnerPlus}
                    downloadUrl={`/scheduleBI/downloadFile`}
                  />
                  <Codes
                    searchResults={scheduleBICodes.map(c => c.document || c)}
                    type="BI"
                    downloadUrl={`/scheduleBI/downloadCodeFile`}
                  />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {(filter === "Commitment" || filter === "All") &&
          noState &&
          biCodesAndNotes &&
          biCodesAndNotes.length > 0 &&
          biCodesAndNotes.map(
            c =>
              (c.codes || c.notes) && (
                <React.Fragment key={"scheduleBICodes" + c.stateId}>
                  <Accordion.Title
                    active={activeIndex === "scheduleBICodes" + c.stateId}
                    index={c.stateId}
                    onClick={() =>
                      setActiveIndex(
                        activeIndex !== "scheduleBICodes" + c.stateId ? "scheduleBICodes" + c.stateId : null
                      )
                    }
                    style={titleStyle("scheduleBICodes" + c.stateId)}
                  >
                    <Icon name="dropdown" />
                    Schedule BI{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"scheduleBICodes" + c.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === c.stateId) &&
                          states.find(s => s.id === c.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {c && c.codes && c.codes.length + c.notes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "scheduleBICodes" + c.stateId}>
                    <CommitmentDragSortNotes
                      items={c.notes}
                      draggable={false}
                      editNotePath={`/commitment-instructions/edit-schedule-bi-note`}
                      editable={user.isAdmin || user.isOwnerPlus}
                      downloadUrl={`/scheduleBI/downloadFile`}
                    />
                    <Codes searchResults={c.codes} downloadUrl={`/scheduleBI/downloadCodeFile`} />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Commitment" || filter === "All") &&
          !noState &&
          ((commitmentScheduleBIICodes && commitmentScheduleBIICodes.length > 0) ||
            (scheduleBIINotes && scheduleBIINotes.length > 0)) && (
            <React.Fragment key={"commitmentScheduleBIICodes"}>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === "commitmentScheduleBIICodes"}
                  index={"commitmentScheduleBIICodes"}
                  onClick={() =>
                    setActiveIndex(activeIndex !== "commitmentScheduleBIICodes" ? "commitmentScheduleBIICodes" : null)
                  }
                  style={titleStyle("commitmentScheduleBIICodes")}
                >
                  <Icon name="dropdown" />
                  Schedule BII{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {commitmentScheduleBIICodes.length + scheduleBIINotes.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "commitmentScheduleBIICodes"}>
                  <CommitmentDragSortNotes
                    items={scheduleBIINotes}
                    draggable={false}
                    editNotePath={`/commitment-instructions/edit-schedule-bii-note`}
                    editable={user.isAdmin || user.isOwnerPlus}
                    downloadUrl={`/scheduleBII/downloadCodeFile`}
                  />
                  <Codes
                    searchResults={commitmentScheduleBIICodes.map(c => c.document || c)}
                    type="BII"
                    downloadUrl={`/scheduleBII/downloadCodeFile`}
                  />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {(filter === "Commitment" || filter === "All") &&
          noState &&
          biiCodesAndNotes &&
          biiCodesAndNotes.length > 0 &&
          biiCodesAndNotes.map(
            c =>
              (c.codes || c.notes) && (
                <React.Fragment key={"commitmentScheduleBIICodes" + c.stateId}>
                  <Accordion.Title
                    active={activeIndex === "commitmentScheduleBIICodes" + c.stateId}
                    index={c.stateId}
                    onClick={() =>
                      setActiveIndex(
                        activeIndex !== "commitmentScheduleBIICodes" + c.stateId
                          ? "commitmentScheduleBIICodes" + c.stateId
                          : null
                      )
                    }
                    style={titleStyle("commitmentScheduleBIICodes" + c.stateId)}
                  >
                    <Icon name="dropdown" />
                    Schedule BII{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"commitmentScheduleBIICodes" + c.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === c.stateId) &&
                          states.find(s => s.id === c.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {c && c.codes && c.codes.length + c.notes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "commitmentScheduleBIICodes" + c.stateId}>
                    <CommitmentDragSortNotes
                      items={c.notes}
                      draggable={false}
                      editNotePath={`/commitment-instructions/edit-schedule-bii-note`}
                      editable={user.isAdmin || user.isOwnerPlus}
                      downloadUrl={`/scheduleBII/downloadFile`}
                    />
                    <Codes searchResults={c.codes} downloadUrl={`/scheduleBII/downloadCodeFile`} />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Commitment" || filter === "All") &&
          !noState &&
          commitmentGeneralInfoNotes &&
          commitmentGeneralInfoNotes.length > 0 && (
            <React.Fragment key={"commitmentGeneralInfoNotes"}>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === "commitmentGeneralInfoNotes"}
                  index={"commitmentGeneralInfoNotes"}
                  onClick={() =>
                    setActiveIndex(activeIndex !== "commitmentGeneralInfoNotes" ? "commitmentGeneralInfoNotes" : null)
                  }
                  style={titleStyle("commitmentGeneralInfoNotes")}
                >
                  <Icon name="dropdown" />
                  General Info{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {commitmentGeneralInfoNotes.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "commitmentGeneralInfoNotes"}>
                  <CommitmentDragSortNotes
                    items={commitmentGeneralInfoNotes.map(n => n.document || n)}
                    draggable={false}
                    editNotePath={`/commitment-instructions/edit-commitment-general-info-note`}
                    editable={user.isAdmin || user.isOwnerPlus}
                    downloadUrl={`/commitmentGeneral/downloadFile`}
                  />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {(filter === "Commitment" || filter === "All") &&
          noState &&
          commitmentGeneralInfoNotes &&
          commitmentGeneralInfoNotes.length > 0 &&
          commitmentGeneralInfoNotes.map(
            n =>
              n.commitmentGeneralInfoNotes && (
                <React.Fragment key={"commitmentGeneralInfoNotes" + n.stateId}>
                  <Accordion.Title
                    active={activeIndex === "commitmentGeneralInfoNotes" + n.stateId}
                    index={"commitmentGeneralInfoNotes" + n.stateId}
                    onClick={() =>
                      setActiveIndex(
                        activeIndex !== "commitmentGeneralInfoNotes" + n.stateId
                          ? "commitmentGeneralInfoNotes" + n.stateId
                          : null
                      )
                    }
                    style={titleStyle("commitmentGeneralInfoNotes" + n.stateId)}
                  >
                    <Icon name="dropdown" />
                    Commitment General Info{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"commitmentGeneralInfoNotes" + n.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === n.stateId) &&
                          states.find(s => s.id === n.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {n && n.commitmentGeneralInfoNotes && n.commitmentGeneralInfoNotes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "commitmentGeneralInfoNotes" + n.stateId}>
                    <CommitmentDragSortNotes
                      items={n.commitmentGeneralInfoNotes.map(n => n.document)}
                      draggable={false}
                      editNotePath={`/commitment-instructions/edit-commitment-general-info-note`}
                      editable={user.isAdmin || user.isOwnerPlus}
                      downloadUrl={`/commitmentGeneral/downloadFile`}
                    />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Policy" || filter === "All") &&
          !noState &&
          policyGeneralInfoNotes &&
          policyGeneralInfoNotes.length > 0 && (
            <React.Fragment key={"policyGeneralInfoNotes"}>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === "policyGeneralInfoNotes"}
                  index={"policyGeneralInfoNotes"}
                  onClick={() =>
                    setActiveIndex(activeIndex !== "policyGeneralInfoNotes" ? "policyGeneralInfoNotes" : null)
                  }
                  style={titleStyle("policyGeneralInfoNotes")}
                >
                  <Icon name="dropdown" />
                  Policy General Info{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {policyGeneralInfoNotes.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "policyGeneralInfoNotes"}>
                  <PolicyDragSortNotes
                    items={policyGeneralInfoNotes.map(n => n.document)}
                    draggable={false}
                    userCanEdit={user.isAdmin || user.isOwner || user.isOwnerPlus}
                    editNotePath={`/policy-instructions/edit-general-info-note`}
                  />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {(filter === "Policy" || filter === "All") &&
          noState &&
          policyGeneralInfoNotes &&
          policyGeneralInfoNotes.length > 0 &&
          policyGeneralInfoNotes.map(
            n =>
              n.policyGeneralInfoNotes && (
                <React.Fragment key={"policyGeneralInfoNotes" + n.stateId}>
                  <Accordion.Title
                    active={activeIndex === "policyGeneralInfoNotes" + n.stateId}
                    index={n.stateId}
                    onClick={() =>
                      setActiveIndex(
                        activeIndex !== "policyGeneralInfoNotes" + n.stateId
                          ? "policyGeneralInfoNotes" + n.stateId
                          : null
                      )
                    }
                    style={titleStyle("policyGeneralInfoNotes" + n.stateId)}
                  >
                    <Icon name="dropdown" />
                    Policy General Info{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"policyGeneralInfoNotes" + n.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === n.stateId) &&
                          states.find(s => s.id === n.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {n && n.policyGeneralInfoNotes && n.policyGeneralInfoNotes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "policyGeneralInfoNotes" + n.stateId}>
                    <PolicyDragSortNotes
                      items={n.policyGeneralInfoNotes.map(n => n.document)}
                      draggable={false}
                      userCanEdit={user.isAdmin || user.isOwner || user.isOwnerPlus}
                      editNotePath={`/policy-instructions/edit-general-info-note`}
                    />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Surveys" || filter === "All") && !noState && policySurveyNotes && policySurveyNotes.length > 0 && (
          <React.Fragment key={"policySurveyNotes"}>
            <React.Fragment>
              <Accordion.Title
                active={activeIndex === "policySurveyNotes"}
                index={"policySurveyNotes"}
                onClick={() => setActiveIndex(activeIndex !== "policySurveyNotes" ? "policySurveyNotes" : null)}
                style={titleStyle("policySurveyNotes")}
              >
                <Icon name="dropdown" />
                Surveys{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {policySurveyNotes.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "policySurveyNotes"}>
                <PolicyDragSortNotes
                  items={policySurveyNotes.map(n => n.document)}
                  draggable={false}
                  userCanEdit={user.isAdmin || user.isOwner || user.isOwnerPlus}
                  editNotePath={`/surveys/edit-survey-note`}
                />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "Surveys" || filter === "All") &&
          noState &&
          policySurveyNotes &&
          policySurveyNotes.length > 0 &&
          policySurveyNotes.map(
            n =>
              n.policySurveyNotes && (
                <React.Fragment key={"policySurveyNotes" + n.stateId}>
                  <Accordion.Title
                    active={activeIndex === "policySurveyNotes" + n.stateId}
                    index={n.stateId}
                    onClick={() =>
                      setActiveIndex(
                        activeIndex !== "policySurveyNotes" + n.stateId ? "policySurveyNotes" + n.stateId : null
                      )
                    }
                    style={titleStyle("policySurveyNotes" + n.stateId)}
                  >
                    <Icon name="dropdown" />
                    Surveys{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"policySurveyNotes" + n.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === n.stateId) &&
                          states.find(s => s.id === n.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {n && n.policySurveyNotes && n.policySurveyNotes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "policySurveyNotes" + n.stateId}>
                    <PolicyDragSortNotes
                      items={n.policySurveyNotes.map(n => n.document)}
                      draggable={false}
                      userCanEdit={user.isAdmin || user.isOwner || user.isOwnerPlus}
                      editNotePath={`/surveys/edit-survey-note`}
                    />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Surveys" || filter === "All") &&
          !noState &&
          surveyLegalDescriptionNotes &&
          surveyLegalDescriptionNotes.length > 0 && (
            <React.Fragment key={"surveyLegalDescriptionNotes"}>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === "surveyLegalDescriptionNotes"}
                  index={"surveyLegalDescriptionNotes"}
                  onClick={() =>
                    setActiveIndex(activeIndex !== "surveyLegalDescriptionNotes" ? "surveyLegalDescriptionNotes" : null)
                  }
                  style={titleStyle("surveyLegalDescriptionNotes")}
                >
                  <Icon name="dropdown" />
                  Surveys{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {surveyLegalDescriptionNotes.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "surveyLegalDescriptionNotes"}>
                  <DragSurveySortLegalDescriptionNotes
                    items={surveyLegalDescriptionNotes.map(n => n.document)}
                    saveSortPath="/survey/sortSurveyLegalDescriptionNotes"
                    draggable={false}
                    userCanEdit={user.isAdmin || user.isOwner || user.isOwnerPlus}
                    editNotePath={`/surveys/edit-legal-description-note`}
                  />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {(filter === "Surveys" || filter === "All") &&
          noState &&
          surveyLegalDescriptionNotes &&
          surveyLegalDescriptionNotes.length > 0 &&
          surveyLegalDescriptionNotes.map(
            n =>
              n.surveyLegalDescriptionNotes && (
                <React.Fragment key={"surveyLegalDescriptionNotes" + n.stateId}>
                  <Accordion.Title
                    active={activeIndex === "surveyLegalDescriptionNotes" + n.stateId}
                    index={n.stateId}
                    onClick={() =>
                      setActiveIndex(
                        activeIndex !== "surveyLegalDescriptionNotes" + n.stateId
                          ? "surveyLegalDescriptionNotes" + n.stateId
                          : null
                      )
                    }
                    style={titleStyle("surveyLegalDescriptionNotes" + n.stateId)}
                  >
                    <Icon name="dropdown" />
                    Surveys{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"surveyLegalDescriptionNotes" + n.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === n.stateId) &&
                          states.find(s => s.id === n.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {n && n.surveyLegalDescriptionNotes && n.surveyLegalDescriptionNotes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "surveyLegalDescriptionNotes" + n.stateId}>
                    <PolicyDragSortNotes
                      items={n.surveyLegalDescriptionNotes.map(n => n.document)}
                      draggable={false}
                      userCanEdit={user.isAdmin || user.isOwner || user.isOwnerPlus}
                      editNotePath={`/surveys/edit-legal-description-note`}
                    />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Surveys" || filter === "All") &&
          !noState &&
          surveyExceptionCodes &&
          surveyExceptionCodes.length > 0 && (
            <React.Fragment key={"surveyExceptionCodes"}>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === "surveyExceptionCodes"}
                  index={"surveyExceptionCodes"}
                  onClick={() => setActiveIndex(activeIndex !== "surveyExceptionCodes" ? "surveyExceptionCodes" : null)}
                  style={titleStyle("surveyExceptionCodes")}
                >
                  <Icon name="dropdown" />
                  Surveys{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {surveyExceptionCodes.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "surveyExceptionCodes"}>
                  <ExceptionCodes searchResults={surveyExceptionCodes.map(c => c.document)} />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {(filter === "Surveys" || filter === "All") &&
          noState &&
          surveyExceptionCodes &&
          surveyExceptionCodes.length > 0 &&
          surveyExceptionCodes.map(
            c =>
              c.surveyExceptionCodes && (
                <React.Fragment key={"surveyExceptionCodes" + c.stateId}>
                  <Accordion.Title
                    active={activeIndex === "surveyExceptionCodes" + c.stateId}
                    index={c.stateId}
                    onClick={() =>
                      setActiveIndex(
                        activeIndex !== "surveyExceptionCodes" + c.stateId ? "surveyExceptionCodes" + c.stateId : null
                      )
                    }
                    style={titleStyle("surveyExceptionCodes" + c.stateId)}
                  >
                    <Icon name="dropdown" />
                    Surveys{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"surveyExceptionCodes" + c.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === c.stateId) &&
                          states.find(s => s.id === c.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {c && c.surveyExceptionCodes && c.surveyExceptionCodes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "surveyExceptionCodes" + c.stateId}>
                    <ExceptionCodes searchResults={c.surveyExceptionCodes.map(c => c.document)} />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Policy" || filter === "All") && policyHelpfulWordings && policyHelpfulWordings.length > 0 && (
          <React.Fragment key={"policyHelpfulWordings"}>
            <React.Fragment>
              <Accordion.Title
                active={activeIndex === "policyHelpfulWordings"}
                index={"policyHelpfulWordings"}
                onClick={() => setActiveIndex(activeIndex !== "policyHelpfulWordings" ? "policyHelpfulWordings" : null)}
                style={titleStyle("policyHelpfulWordings")}
              >
                <Icon name="dropdown" />
                Policy Helpful Wording{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {policyHelpfulWordings.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "policyHelpfulWordings"}>
                <HelpfulWordings policyHelpfulWordingSearchResults={policyHelpfulWordings} />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "Policy" || filter === "All") &&
          !noState &&
          policyScheduleBICodes &&
          policyScheduleBICodes.length > 0 && (
            <React.Fragment key={"bi"}>
              <React.Fragment>
                <Accordion.Title
                  active={activeIndex === "bi"}
                  index={"bi"}
                  onClick={() => setActiveIndex(activeIndex !== "bi" ? "bi" : null)}
                  style={titleStyle("bi")}
                >
                  <Icon name="dropdown" />
                  BI{" "}
                  <span
                    className={css({
                      fontFamily: "Fakt-Normal",
                      color: "#707898",
                      marginLeft: 8
                    })}
                  >
                    {policyScheduleBICodes.length}
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === "bi"}>
                  <BIGuidelinesToOmit searchResults={policyScheduleBICodes.map(c => c.document)} />
                </Accordion.Content>
              </React.Fragment>
            </React.Fragment>
          )}
        {(filter === "Policy" || filter === "All") &&
          noState &&
          policyScheduleBICodes &&
          policyScheduleBICodes.length > 0 &&
          policyScheduleBICodes.map(
            c =>
              c.codes && (
                <React.Fragment key={"bi" + c.stateId}>
                  <Accordion.Title
                    active={activeIndex === "bi" + c.stateId}
                    index={"bi" + c.stateId}
                    onClick={() => setActiveIndex(activeIndex !== "bi" + c.stateId ? "bi" + c.stateId : null)}
                    style={titleStyle("bi" + c.stateId)}
                  >
                    <Icon name="dropdown" />
                    BI{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"bi" + c.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === c.stateId) &&
                          states.find(s => s.id === c.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {c && c.codes && c.codes.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "bi" + c.stateId}>
                    {<BIGuidelinesToOmit searchResults={c.codes.map(c => c.document).filter(c => c)} />}
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
        {(filter === "Policy" || filter === "All") && !noState && policyEndorsements && policyEndorsements.length > 0 && (
          <React.Fragment key={"policyEndorsements"}>
            <React.Fragment>
              <Accordion.Title
                active={activeIndex === "policyEndorsements"}
                index={"policyEndorsements"}
                onClick={() => setActiveIndex(activeIndex !== "policyEndorsements" ? "policyEndorsements" : null)}
                style={titleStyle("policyEndorsements")}
              >
                <Icon name="dropdown" />
                Endorsements{" "}
                <span
                  className={css({
                    fontFamily: "Fakt-Normal",
                    color: "#707898",
                    marginLeft: 8
                  })}
                >
                  {policyEndorsements.length}
                </span>
              </Accordion.Title>
              <Accordion.Content active={activeIndex === "policyEndorsements"}>
                <Endorsements searchResults={policyEndorsements.map(e => e.document)} />
              </Accordion.Content>
            </React.Fragment>
          </React.Fragment>
        )}
        {(filter === "Policy" || filter === "All") &&
          noState &&
          policyEndorsements &&
          policyEndorsements.length > 0 &&
          policyEndorsements.map(
            e =>
              e.policyEndorsements && (
                <React.Fragment key={"policyEndorsements" + e.stateId}>
                  <Accordion.Title
                    active={activeIndex === "policyEndorsements" + e.stateId}
                    index={"policyEndorsements" + e.stateId}
                    onClick={() =>
                      setActiveIndex(
                        activeIndex !== "policyEndorsements" + e.stateId ? "policyEndorsements" + e.stateId : null
                      )
                    }
                    style={titleStyle("policyEndorsements" + e.stateId)}
                  >
                    <Icon name="dropdown" />
                    Policy Endorsements{" "}
                    {noState && (
                      <Label className="secondaryLabel" key={"policyEndorsements" + e.stateId}>
                        {states.length > 0 &&
                          states.find(s => s.id === e.stateId) &&
                          states.find(s => s.id === e.stateId).name}
                      </Label>
                    )}{" "}
                    <span
                      className={css({
                        fontFamily: "Fakt-Normal",
                        color: "#707898",
                        marginLeft: 8
                      })}
                    >
                      {e && e.policyEndorsements && e.policyEndorsements.length}
                    </span>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === "policyEndorsements" + e.stateId}>
                    <Endorsements searchResults={e.policyEndorsements.map(e => e.document)} />
                  </Accordion.Content>
                </React.Fragment>
              )
          )}
      </Accordion>
    </React.Fragment>
  );
}

export default Results;
