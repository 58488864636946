import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Header, Message, Modal } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import { Highlight } from "../../search/Highlight";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
});
const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 14,
  marginTop: 24,
});

function HighLiabilityApprovals({
  history,
  match: { params },
  highLiabilityApprovalSearchResults,
}) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);

  const [state, setState] = useState({
    highLiabilityApprovals: [],
    noResults: false,
    initialLoaded: false,
    highLiabilityApprovalToDelete: null,
  });

  async function getHighLiabilityApprovalsByState() {
    const result = await getRequest(
      "/highLiabilityApproval/getHighLiabilityApprovalsByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        highLiabilityApprovals: result.data,
        initialLoaded: true,
      }));
    }
  }

  async function deleteHighLiabilityApproval(id) {
    const result = await postRequest(
      `/highLiabilityApproval/deleteHighLiabilityApproval?id=${id}`,
      id
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        highLiabilityApprovals: state.highLiabilityApprovals.filter(
          (r) => r.id !== id
        ),
        highLiabilityApprovalToDelete: null,
      }));
    }
  }

  async function download(id) {
    const result = await getRequest(
      `/highLiabilityApproval/downloadHighLiabilityApprovalFile?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  useEffect(() => {
    if (selectedState) {
      getHighLiabilityApprovalsByState();
    }
  }, [selectedState]);

  const filteredHighLiabilityApprovals = highLiabilityApprovalSearchResults
    ? highLiabilityApprovalSearchResults
    : state.highLiabilityApprovals;

  return (
    <React.Fragment>
      {!highLiabilityApprovalSearchResults && (
        <Grid>
          <Grid.Row columns={2} verticalAlign="middle">
            {(user.isOwner || user.isOwnerPlus) && (
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                <Button
                  icon
                  className="blueButton"
                  onClick={() =>
                    history.push(`/info/new-high-liability-approval`)
                  }
                >
                  <i className="fal fa-plus" />
                </Button>
                <span
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 14,
                    color: "#000",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00b8d4",
                    },
                  })}
                  onClick={() =>
                    history.push(`/info/new-high-liability-approval`)
                  }
                >
                  New High Liability Approval
                </span>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
      <div
        className={css({
          marginBottom: highLiabilityApprovalSearchResults ? 0 : 19.75,
          marginTop: highLiabilityApprovalSearchResults ? 0 : 40,
        })}
      >
        {filteredHighLiabilityApprovals &&
          filteredHighLiabilityApprovals.map((r) => (
            <Card fluid style={{ padding: 28 }} key={r.id}>
              <Card.Content>
                {(user.isOwner || user.isOwnerPlus) && (
                  <React.Fragment>
                    <i
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          highLiabilityApprovalToDelete: r,
                        }))
                      }
                      className="fal fa-trash"
                      style={{
                        position: "absolute",
                        right: 56,
                        cursor: "pointer",
                      }}
                    />
                    <i
                      onClick={() =>
                        history.push(
                          `/info/edit-high-liability-approval/${r.id}`
                        )
                      }
                      className="fal fa-pencil"
                      style={{
                        position: "absolute",
                        right: 28,
                        cursor: "pointer",
                      }}
                    />
                  </React.Fragment>
                )}
                <Card.Header content={<Highlight children={r.subject} />} />
                <Card.Description
                  style={{ whiteSpace: "pre-wrap" }}
                  content={<Highlight children={r.note} />}
                />
                {r.highLiabilityApprovalFiles &&
                  r.highLiabilityApprovalFiles.length > 0 && (
                    <div className={labelStyles}>Attachments</div>
                  )}
                {r.highLiabilityApprovalFiles &&
                  r.highLiabilityApprovalFiles.map((f) => (
                    <p className={paragraphStyles} key={f.id}>
                      <a
                        onClick={() => download(f.id)}
                        className={css({
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#00b8d4",
                          },
                        })}
                      >
                        <i
                          className="fal fa-paperclip"
                          style={{ color: "#00b8d4", marginRight: 8 }}
                        />
                        <Highlight children={f.fileName} />
                      </a>
                    </p>
                  ))}
              </Card.Content>
            </Card>
          ))}
        {state.highLiabilityApprovals.length < 1 && state.initialLoaded && (
          <Message size="big">No High Liability Approvals...</Message>
        )}
      </div>
      {state.highLiabilityApprovalToDelete && (
        <Modal
          size={"tiny"}
          open={state.highLiabilityApprovalToDelete !== null}
          onClose={() =>
            setState((state) => ({
              ...state,
              highLiabilityApprovalToDelete: null,
            }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete HighLiabilityApproval</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({
                  ...state,
                  highLiabilityApprovalToDelete: null,
                }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() =>
                deleteHighLiabilityApproval(
                  state.highLiabilityApprovalToDelete.id
                )
              }
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(HighLiabilityApprovals);
