import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, Modal } from "semantic-ui-react";
import { css } from "emotion";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import { withRouter } from "react-router-dom";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import InvoiceCharges from "../../rates/invoiceCharges/InvoiceCharges";
import NoteAccordion from "../../NoteAccordion";
import DragSortNotes from "./DragSortNotes";

const linkStyles = css({
  fontSize: 14,
  lineHeight: "36px",
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
});

function GeneralInfo({ history, match }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  const [state, setState] = useState({
    noteCategories: [],
    noteToDelete: null
  });

  const [showAll, setShowAll] = useState(false);
  const [notes, setNotes] = useState([]);

  async function getNoteCategories() {
    const result = await getRequest(`/commitmentGeneralInfo/getNoteCategories`);
    if (result.success) {
      setState((state) => ({
        ...state,
        noteCategories: result.data
      }));
    }
  }

  async function getGeneralInfoNotesByState(stateId) {
    const result = await getRequest(
      `/commitmentGeneralInfo/GetGeneralInfoNotesByState`,
      {
        stateId
      }
    );
    if (result.success) {
      setNotes(result.data);
    }
  }

  useEffect(() => {
    getNoteCategories();
  }, []);

  useEffect(() => {
    getGeneralInfoNotesByState(match.params.state || selectedState);
  }, [match.params.state]);

  async function deleteCommitmentGeneralInfoNote(id) {
    const result = await postRequest(
      `/commitmentGeneralInfo/deleteCommitmentGeneralInfoNote?id=${id}`
    );
    if (result.success) {
      setNotes((notes) => notes.filter((n) => n.id !== id));
      setState((state) => ({
        ...state,
        noteToDelete: null
      }));
    }
  }

  const filteredCategories = state.noteCategories.filter((c) =>
    notes.some((note) => note.commitmentGeneralInfoNoteCategoryId === c.id)
  );

  const notesWithoutCategory = notes.filter(
    (note) => note.commitmentGeneralInfoNoteCategoryId === null
  );

  const isCollapsible = selectedState === 52;

  return (
    <React.Fragment>
      <Grid style={{ marginBottom: 17 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column
            floated="left"
            className={css({
              textAlign: "left",
              color: "#47517B",
              fontSize: 20,
              fontFamily: "Fakt-Bold"
            })}
          >
            General Info
          </Grid.Column>
          {userCanEdit && (
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <span
                className={linkStyles}
                onClick={() =>
                  history.push(
                    `/commitment-instructions/states/${selectedState}/new-commitment-general-info-note`
                  )
                }
              >
                Add Note
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      {isCollapsible && notes.length > 0 && (
        <Button
          basic
          onClick={() => setShowAll((showAll) => !showAll)}
          style={{ marginBottom: 32 }}
        >
          {showAll ? "Collapse All" : "Open All"}
        </Button>
      )}
      {notes.length > 0 && (
        <div className={css({ marginBottom: 24 })}>
          {!isCollapsible && notesWithoutCategory && (
            <DragSortNotes
              items={notesWithoutCategory}
              saveSortPath="/commitmentGeneralInfo/sortNotes"
              draggable={userCanEdit}
              editNotePath={`/commitment-instructions/edit-commitment-general-info-note`}
              editable={userCanEdit}
              downloadUrl={`/commitmentGeneralInfo/downloadFile`}
              deleteAction={(value) =>
                setState((state) => ({ ...state, noteToDelete: value }))
              }
            />
          )}
          {isCollapsible && notesWithoutCategory && (
            <NoteAccordion
              notes={notesWithoutCategory}
              setNotes={setNotes}
              deleteAction={(value) =>
                setState((state) => ({ ...state, noteToDelete: value }))
              }
              showAll={showAll}
              downloadURL="/commitmentGeneralInfo/downloadFile"
              saveSortURL="/commitmentGeneralInfo/sortNotes"
              editURL="/commitment-instructions/edit-commitment-general-info-note"
            />
          )}
          {!isCollapsible &&
            filteredCategories.length > 0 &&
            filteredCategories.map((c) => (
              <React.Fragment key={c.id}>
                <div
                  className={css({
                    textAlign: "left",
                    color: "#47517B",
                    fontSize: 16,
                    fontFamily: "Fakt-Bold",
                    marginTop: 24,
                    marginBottom: 16
                  })}
                >
                  {c.name}
                </div>
                <DragSortNotes
                  items={notes.filter(
                    (note) => note.commitmentGeneralInfoNoteCategoryId === c.id
                  )}
                  saveSortPath="/commitmentGeneralInfo/sortNotes"
                  draggable={userCanEdit}
                  editable={userCanEdit}
                  editNotePath={`/commitment-instructions/edit-commitment-general-info-note`}
                  downloadUrl={`/commitmentGeneralInfo/downloadFile`}
                  deleteAction={(value) =>
                    setState((state) => ({ ...state, noteToDelete: value }))
                  }
                />
              </React.Fragment>
            ))}
          {isCollapsible &&
            filteredCategories.length > 0 &&
            filteredCategories.map((c) => (
              <React.Fragment key={c.id}>
                <div
                  className={css({
                    textAlign: "left",
                    color: "#47517B",
                    fontSize: 16,
                    fontFamily: "Fakt-Bold",
                    marginTop: 24,
                    marginBottom: 16
                  })}
                >
                  {c.name}
                </div>
                <NoteAccordion
                  notes={notes.filter(
                    (note) => note.commitmentGeneralInfoNoteCategoryId === c.id
                  )}
                  setNotes={setNotes}
                  deleteAction={(value) =>
                    setState((state) => ({ ...state, noteToDelete: value }))
                  }
                  showAll={showAll}
                  downloadURL="/commitmentGeneralInfo/downloadFile"
                  saveSortURL="/commitmentGeneralInfo/sortNotes"
                  editURL="/commitment-instructions/edit-commitment-general-info-note"
                />
              </React.Fragment>
            ))}
          {state.noteToDelete && (
            <Modal
              size={"tiny"}
              open={state.noteToDelete !== null}
              onClose={() =>
                setState((state) => ({ ...state, noteToDelete: null }))
              }
              dimmer="inverted"
            >
              <Modal.Header>Delete Note</Modal.Header>
              <Modal.Content>
                <p>
                  Are you sure you want to delete{" "}
                  {state.noteToDelete.subject && (
                    <strong>{state.noteToDelete.subject}</strong>
                  )}
                  ?
                </p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  negative
                  onClick={() =>
                    setState((state) => ({ ...state, noteToDelete: null }))
                  }
                  style={{
                    backgroundColor: "#fff",
                    border: "solid 1px #e5e5ea",
                    color: "rgba(0, 0, 0, 0.87)"
                  }}
                >
                  No
                </Button>
                <Button
                  positive
                  onClick={() =>
                    deleteCommitmentGeneralInfoNote(state.noteToDelete.id)
                  }
                  style={{ backgroundColor: "#1f2b5e" }}
                >
                  Yes
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </div>
      )}
      <InvoiceCharges editable={false} />
    </React.Fragment>
  );
}

export default withRouter(GeneralInfo);
