import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Input,
  Message,
  Dropdown
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../helpers/analyticsLogger";

export function EditAddUnderwriter({ match, history }) {
  const defaultUnderwriter = {
    name: undefined,
    underwriterStates: []
  };
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    underwriter: defaultUnderwriter,
    editMode: match.path === "/admin/edit-underwriter/:id",
    states: [{}],
    errorList: []
  });

  async function getUnderwriterById(id) {
    const result = await getRequest(`/underwriter/getUnderwriterById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        underwriter: result.data
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  useEffect(() => {
    getStates();
    state.editMode && getUnderwriterById(match.params.id);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/underwriter/${
            state.editMode ? "editUnderwriter" : "addUnderwriter"
          }`,
          {
            ...state.underwriter,
            contacts: []
          }
        );
        if (result.success) {
          logEvent(null, state.editMode ? "Edited" : "Added", "Underwriter");
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.underwriter.name) {
      list.push("You must provide a name.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.underwriter]);

  function buildUnderwriterStates(value) {
    return value.map((v) => ({
      underwriterId: 0,
      stateId: v
    }));
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Underwriter`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Name</label>
                <Input
                  placeholder="Name"
                  size="large"
                  value={state.underwriter.name}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      underwriter: { ...state.underwriter, name: value }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    (state.underwriter.name === "" ||
                      state.underwriter.name === undefined)
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>States</label>
                <Dropdown
                  size="large"
                  fluid
                  multiple
                  selection
                  placeholder="Select States"
                  style={{ fontSize: 16 }}
                  options={state.states}
                  value={
                    state.underwriter.underwriterStates
                      ? state.underwriter.underwriterStates.map(
                          (us) => us.stateId
                        )
                      : null
                  }
                  onChange={(_, { value }) => {
                    setState((state) => ({
                      ...state,
                      underwriter: {
                        ...state.underwriter,
                        underwriterStates:
                          value[value.length - 1] === 52
                            ? buildUnderwriterStates([52])
                            : buildUnderwriterStates(
                                value.filter((v) => v !== 52)
                              )
                      }
                    }));
                  }}
                  error={
                    state.submitAttempted &&
                    !state.underwriter.underwriterStates.length > 0
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddUnderwriter);
