import React from "react";
import { css } from "emotion";
import Colors from "../Colors";

export function SectionTitle({ text, style }) {
  return (
    <h5
      className={css({
        color: Colors["00b8d4"],
        fontFamily: "Fakt-Bold",
        fontSize: 16,
        lineHeight: 1.5,
        marginBottom: 0,
        marginTop: 16
      })}
      style={style}
    >
      {text}
    </h5>
  );
}
