import { css } from "emotion";

export const linkStyles = css({
  fontSize: 14,
  lineHeight: "36px",
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
});
