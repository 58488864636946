import React, { useContext } from "react";
import { Icon, Label, Table } from "semantic-ui-react";
import { css } from "emotion";
import { paragraphStyles } from "../../styles/paragraphStyles";
import CustomTable from "../../CustomTable";
import LinesEllipsis from "react-lines-ellipsis";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Highlight } from "../../search/Highlight";
import { Popup } from "../../Popup";
import { ClickToView } from "../../ClickToView";
import { withRouter } from "react-router-dom";
import getPAStateId from "./getPAStateId";
import { UnderwriterContext } from "../../Underwriter";

function BIGuidelinesTable({ code, searchResults, state, addToast, userCanEdit, selectedState, history, setState }) {
  const { underwriters } = useContext(UnderwriterContext);
  let stateId = getPAStateId(selectedState, state.selectedUnderwriter);
  return (
    <React.Fragment>
      <div
        className={css({
          textAlign: "left",
          color: "#47517B",
          fontSize: 16,
          fontFamily: "Fakt-Bold"
        })}
      >
        {code.category}
      </div>
      <CustomTable
        customStyles={{
          "& tr td:first-of-type, & tr th:first-of-type": {
            paddingLeft: "64px !important"
          },
          "& th": {
            position: "sticky",
            top: 0,
            zIndex: 1
          },
          marginBottom: "24px !important"
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4} style={{ textAlign: "left" }}>
              Case Scenario
            </Table.HeaderCell>
            <Table.HeaderCell width={4}>Exception Codes</Table.HeaderCell>
            <Table.HeaderCell width={4}>Exception Wordings {!searchResults && <ClickToView />}</Table.HeaderCell>
            <Table.HeaderCell width={4}>Guidelines to Omit {!searchResults && <ClickToView />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {code.codes.length < 1 && (
            <Table.Row>
              <Table.Cell
                className={css({
                  textAlign: "left !important",
                  border: "none !important",
                  position: "relative !important"
                })}
              >
                No codes available
              </Table.Cell>
            </Table.Row>
          )}
          {code.codes.length > 0 &&
            code.codes.map(c => {
              var guidelinesToOmits;
              if (!searchResults) {
                guidelinesToOmits = [c.biGuidelinesToOmits.find(g => g.underwriterId === state.selectedUnderwriter)];
              } else {
                guidelinesToOmits = c.biGuidelinesToOmits;
              }

              return (
                <Table.Row
                  key={c.id}
                  className={
                    (c.highlight && guidelinesToOmits[0]?.highlight != false) || guidelinesToOmits[0]?.highlight
                      ? "highlight"
                      : null
                  }
                >
                  <Table.Cell
                    style={{
                      textAlign: "left",
                      position: "relative"
                    }}
                  >
                    <React.Fragment>
                      <div
                        className={css({
                          display: "inline-block",
                          width: "calc(100% - 70px)"
                        })}
                      >
                        {userCanEdit && !searchResults && code.isOther && (
                          <React.Fragment>
                            <span
                              className={css({
                                "&:hover i": { color: "#00b8d4" },
                                cursor: "pointer",
                                position: "absolute",
                                left: 16,
                                top: 25
                              })}
                              onClick={() => history.push(`/policy-instructions/edit-other-exception/${c.id}`)}
                            >
                              <Icon className="fal fa-pen" />
                            </span>
                            <span
                              className={css({
                                "&:hover i": { color: "#00b8d4" },
                                cursor: "pointer",
                                display: "inline-block",
                                position: "absolute",
                                left: 36,
                                top: 25
                              })}
                              onClick={() =>
                                setState(state => ({
                                  ...state,
                                  otherExceptionToDelete: c
                                }))
                              }
                            >
                              <Icon style={{ paddingRight: 32.5 }} className="fal fa-trash" />
                            </span>
                          </React.Fragment>
                        )}
                        <Highlight children={c.caseScenario} />
                      </div>
                    </React.Fragment>
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "left",
                      position: "relative"
                    }}
                  >
                    <React.Fragment>
                      <div
                        className={css({
                          display: "inline-block",
                          width: "calc(100% - 70px)"
                        })}
                      >
                        <span
                          className={css({
                            fontFamily: "Fakt-Bold"
                          })}
                        >
                          <Highlight children={c.code} />
                        </span>
                      </div>
                    </React.Fragment>
                  </Table.Cell>
                  <Table.Cell style={{ position: "relative" }}>
                    {c.wording && (
                      <CopyToClipboard
                        style={{
                          position: "absolute",
                          top: 16,
                          right: 10,
                          cursor: "pointer"
                        }}
                        text={c.wording}
                        onCopy={() =>
                          addToast("Copied!", {
                            appearance: "success",
                            autoDismiss: true
                          })
                        }
                      >
                        <span
                          className={css({
                            "&:hover i": { color: "#00b8d4" }
                          })}
                        >
                          <Icon className="fal fa-copy" />
                        </span>
                      </CopyToClipboard>
                    )}
                    <span className={paragraphStyles}>
                      {c.wording && !searchResults ? (
                        <Popup
                          style={{ height: 500 }}
                          on={["click"]}
                          header={"Exception - " + c.code}
                          content={c.wording}
                          trigger={
                            <LinesEllipsis
                              text={c.wording}
                              maxLine="2"
                              ellipsis="..."
                              style={{
                                textAlign: "left",
                                paddingRight: 24
                              }}
                            />
                          }
                        />
                      ) : (
                        <Highlight children={c.wording} />
                      )}
                    </span>
                  </Table.Cell>
                  <Table.Cell
                    style={{
                      textAlign: "left",
                      position: "relative"
                    }}
                  >
                    {userCanEdit && !searchResults && state.selectedUnderwriter && !code.isOther && (
                      <span
                        className={css({
                          "&:hover i": { color: "#00b8d4" },
                          cursor: "pointer",
                          position: "absolute",
                          left: 16,
                          top: 25
                        })}
                        onClick={() =>
                          history.push(
                            `/policy-instructions/states/${stateId}/edit-guidelines-to-omit/${state.selectedUnderwriter}/${c.id}`
                          )
                        }
                      >
                        <Icon className="fal fa-pen" />
                      </span>
                    )}
                    <span className={paragraphStyles}>
                      {guidelinesToOmits.length > 0 && !searchResults ? (
                        <>
                          {guidelinesToOmits.map(g => (
                            <Popup
                              on={["click"]}
                              header={"Exception - " + c.code}
                              content={g?.guidelinesToOmit || ""}
                              trigger={
                                <LinesEllipsis
                                  text={g?.guidelinesToOmit || ""}
                                  maxLine="2"
                                  ellipsis="..."
                                  style={{
                                    textAlign: "left",
                                    paddingRight: 24
                                  }}
                                />
                              }
                            />
                          ))}
                        </>
                      ) : (
                        <>
                          {guidelinesToOmits.map(g => (
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <Label
                                style={{
                                  display: "block",
                                  width: 100,
                                  textAlign: "center",
                                  marginBottom: "8px"
                                }}
                              >
                                {underwriters.find(u => u.id === g.underwriterId)?.name}
                              </Label>
                              <Highlight children={g && g?.guidelinesToOmit} style={{ width: "100%" }} />
                            </div>
                          ))}
                        </>
                      )}
                    </span>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </CustomTable>
    </React.Fragment>
  );
}

export default withRouter(BIGuidelinesTable);
