import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Input,
  Checkbox,
  Message,
  Modal,
  ButtonContent,
  Icon
} from "semantic-ui-react";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import shortid from "shortid";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import { logEvent } from "../../../helpers/analyticsLogger";

const responsiblePartys = [
  { key: null, text: "TBD", value: null },
  { key: 0, text: "Buyer/Borrower", value: 0 },
  { key: 1, text: "Lender", value: 1 },
  { key: 2, text: "Seller", value: 2 },
  { key: 3, text: "Owner", value: 3 }
];

css({
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  marginBottom: 17,
  lineHeight: 1.71
});
export function EditAddScheduleBICode({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    errorList: [],
    codeCategories: [],
    scheduleSections: [],
    newCategory: undefined,
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    code: { scheduleSectionId: +match.params.category, code: null },
    codeStates: [+match.params.state],
    editMode: match.path.includes("edit"),
    editAllStates: false,
    editCode: false,
    editWording: false,
    editNote: false,
    editCaseScenario: false,
    editInstructionsToOmit: false,
    editHighlightRow: false,
    editTaskText: false,
    confirmEdit: null,
    originalCodeName: null,
    files: [],
    filesToDelete: []
  });

  const [subTasks, setSubTasks] = useState([]);
  const [taskOmits, setTaskOmits] = useState([]);

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allLicensedStatesList: [52, ...result.data]
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function getScheduleBICodeById(id) {
    const result = await getRequest(`/scheduleBI/getScheduleBICodeById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        code: result.data,
        originalCodeName: result.data.code
      }));
      setSubTasks(result.data.scheduleBICodeSubTasks);
      setTaskOmits(result.data.scheduleBICodeTaskOmits || []);
    }
  }

  async function getCodeStates(id) {
    const result = await getRequest(`/scheduleBI/getCodeStates`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        codeStates: result.data
      }));
    }
  }

  async function getScheduleSections() {
    const result = await getRequest("/scheduleBI/getScheduleSections");
    if (result.success) {
      setState((state) => ({
        ...state,
        scheduleSections: result.data
      }));
    }
  }

  async function getCodeCategories() {
    const result = await getRequest(`/codeCategory/getCodeCategories`);
    if (result.success) {
      setState((state) => ({
        ...state,
        codeCategories: [
          {
            key: null,
            value: null,
            text: "None"
          },
          ...result.data.map((c) => ({
            key: c.id,
            text: c.name,
            value: c.id
          }))
        ]
      }));
    }
  }

  function getErrorList() {
    let list = [];
    if (state.code.code === "" || state.code.code === null) {
      list.push("You must provide a code.");
    }
    if (!state.codeStates.length > 0) {
      list.push("You must select at least one state.");
    }
    return list;
  }

  useEffect(() => {
    getScheduleSections();
    getCodeCategories();
    if (state.editMode) {
      getScheduleBICodeById(match.params.id);
      getCodeStates(match.params.id);
    } else {
      getStates();
      getAllStatesList();
      getAllLicensedStatesList();
      getAllUnlicensedStatesList();
    }
  }, []);

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.codeStates, state.code.code, state.submitAttempted]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      customStates:
        !state.allLicensedStates &&
        !state.allUnlicensedStates &&
        !state.allStates
    }));
  }, [state.allStates, state.allUnlicensedStates, state.allLicensedStates]);

  const setTaskEdited = () => {
    setState((state) => ({
      ...state,
      editTaskText: true
    }));
  };

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true
      }));
      if (!state.errorList.length > 0) {
        setState((state) => ({
          ...state,
          submitting: true
        }));
        let result;
        if (state.editMode) {
          result = await postRequest("/scheduleBI/editScheduleBICode", {
            scheduleBICode: {
              ...state.code,
              scheduleBICodeTaskOmits: taskOmits
                .filter((o) => o.option)
                .map((o) => ({ ...o, id: 0, scheduleBICodeId: 0 })),
              scheduleBICodeSubTasks: subTasks.map((t) => ({
                ...t,
                id: 0,
                scheduleBICodeTaskOmits: t.scheduleBICodeTaskOmits
                  .filter((o) => o.option)
                  .map((o) => ({
                    ...o,
                    id: 0,
                    scheduleBICodeSubTaskId: 0
                  }))
              }))
            },
            newCategory: state.newCategory,
            editAllStates: state.editAllStates,
            editCode: state.editCode,
            editNote: state.editNote,
            editWording: state.editWording,
            editCaseScenario: state.editCaseScenario,
            editInstructionsToOmit: state.editInstructionsToOmit,
            editHighlightRow: state.editHighlightRow,
            editTaskText: state.editTaskText,
            files: await Promise.all(
              state.files.map(async (f) => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            filesToDelete: state.filesToDelete
          });
        } else {
          result = await postRequest(
            "/scheduleBI/addScheduleBICodeToMultipleStates",
            {
              scheduleBICode: {
                ...state.code,
                scheduleBICodeSubTasks: subTasks.map((t) => ({ ...t, id: 0 }))
              },
              newCategory: state.newCategory,
              codeStates: state.codeStates,
              files: await Promise.all(
                state.files.map(async (f) => ({
                  base64File: await readFileAsBase64(f),
                  fileName: f.name
                }))
              ),
              filesToDelete: state.filesToDelete
            }
          );
        }
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Schedule BI Code"
          );
          setState((state) => ({
            ...state,
            submitAttempted: false,
            submitting: false
          }));
          history.goBack();
        }
      }
    }
  }

  const addSubTask = () => {
    const newSubTask = {
      id: Date.now(),
      taskText: "",
      responsibleParty: null,
      scheduleBICodeTaskOmits: []
    };
    setSubTasks((subTasks) => [...subTasks, newSubTask]);
    setTaskEdited();
  };

  const removeSubTask = (id) => {
    const filteredTasks = subTasks.filter((t) => t.id !== id);
    setSubTasks(filteredTasks);
    setTaskEdited();
  };

  const updateSubTaskText = (id, newText) => {
    setSubTasks((subTasks) =>
      subTasks.map((t) => (t.id === id ? { ...t, taskText: newText } : t))
    );
    setTaskEdited();
  };

  const updateSubTaskResponsibleParty = (id, newParty) => {
    setSubTasks((subTasks) =>
      subTasks.map((t) =>
        t.id === id ? { ...t, taskResponsibleParty: newParty } : t
      )
    );
    setTaskEdited();
  };

  const addTaskOmit = () => {
    const newTaskOmit = {
      id: Date.now(),
      scheduleBICodeId: state.code.id,
      option: ""
    };
    setTaskOmits((taskOmits) => [...taskOmits, newTaskOmit]);
    setTaskEdited();
  };

  const updateTaskOmit = (id, newText) => {
    setTaskOmits((taskOmits) =>
      taskOmits.map((to) =>
        to.id === id ? { ...to, scheduleBICodeId: to.id, option: newText } : to
      )
    );
    setTaskEdited();
  };

  const removeTaskOmit = (id) => {
    setTaskOmits((taskOmits) => taskOmits.filter((to) => to.id !== id));
    setTaskEdited();
  };

  const addSubTaskOmit = (subTaskId) => {
    const newTaskOmit = {
      id: Date.now(),
      scheduleBICodeSubTaskId: subTaskId,
      option: ""
    };
    setSubTasks((subTasks) =>
      subTasks.map((t) =>
        t.id === subTaskId
          ? {
              ...t,
              scheduleBICodeTaskOmits: [
                ...t.scheduleBICodeTaskOmits,
                newTaskOmit
              ]
            }
          : t
      )
    );
    setTaskEdited();
  };

  const removeSubTaskOmit = (subTaskId, omitId) => {
    setSubTasks((subTasks) =>
      subTasks.map((st) =>
        st.id === subTaskId
          ? {
              ...st,
              scheduleBICodeTaskOmits: st.scheduleBICodeTaskOmits.filter(
                (to) => to.id !== omitId
              )
            }
          : st
      )
    );
    setTaskEdited();
  };

  const updateSubTaskOmit = (subTaskId, omitId, newText) => {
    setSubTasks((subTasks) =>
      subTasks.map((st) =>
        st.id === subTaskId
          ? {
              ...st,
              scheduleBICodeTaskOmits: st.scheduleBICodeTaskOmits.map((to) =>
                to.id === omitId ? { ...to, option: newText } : to
              )
            }
          : st
      )
    );
    setTaskEdited();
  };

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {state.editMode ? "Edit" : "New"} Code
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                {state.scheduleSections.map((s) => (
                  <Form.Field key={"section" + s.id}>
                    <Checkbox
                      label={s.name}
                      checked={state.code.scheduleSectionId === s.id}
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          code: {
                            ...state.code,
                            scheduleSectionId: s.id
                          }
                        }))
                      }
                    />
                  </Form.Field>
                ))}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Code</label>
                <Input
                  placeholder="Code"
                  size="large"
                  value={state.code.code}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      editCode: true,
                      code: {
                        ...state.code,
                        code: value
                      }
                    }))
                  }
                  error={
                    state.submitAttempted &&
                    (state.code.code === "" || state.code.code === null)
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Category</label>
                <Dropdown
                  size="large"
                  options={state.codeCategories}
                  placeholder="Choose Category"
                  search
                  selection
                  allowAdditions
                  style={{ fontSize: 16 }}
                  value={state.code.codeCategoryId}
                  onAddItem={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      newCategory: value,
                      codeCategories: [
                        ...state.codeCategories.filter((c) => c.value !== 0),
                        {
                          key: 0,
                          text: value,
                          value: 0
                        }
                      ],
                      code: { ...state.code, codeCategoryId: 0 }
                    }))
                  }
                  onChange={(e, { value, text }) => {
                    setState({
                      ...state,
                      code: { ...state.code, codeCategoryId: value },
                      newCategory: text
                    });
                  }}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {state.code.scheduleSectionId === 1 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label>Case Scenario</label>
                  <TextArea
                    placeholder="Case Scenario"
                    size="large"
                    value={state.code.caseScenario}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        editCaseScenario: true,
                        code: {
                          ...state.code,
                          caseScenario: value
                        }
                      }))
                    }
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Wording</label>
                <TextArea
                  placeholder="Wording"
                  style={{ minHeight: 196 }}
                  value={state.code.wording}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      editWording: true,
                      code: {
                        ...state.code,
                        wording: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 196 }}
                  value={state.code.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      editNote: true,
                      code: {
                        ...state.code,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Instructions to Omit</label>
                <TextArea
                  placeholder="Instructions to Omit"
                  style={{ minHeight: 196 }}
                  value={state.code.instructionsToOmit}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      editInstructionsToOmit: true,
                      code: {
                        ...state.code,
                        instructionsToOmit: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid
            style={{
              backgroundColor: "#f0f0f0",
              padding: "16px",
              borderRadius: "8px",
              marginBottom: "16px",
              marginTop: "16px",
              width: "calc(100% + 32px)"
            }}
          >
            <Grid.Row columns={1}>
              <Grid.Column>
                <Grid verticalAlign="bottom">
                  <Grid.Column floated="left" width={8}>
                    <label>
                      {subTasks.length > 0 ? "Parent Task" : "Task"}
                    </label>
                  </Grid.Column>
                  <Grid.Column floated="right" width={8} textAlign="right">
                    <Button
                      style={{ color: "#fff" }}
                      onClick={addSubTask}
                      size="small"
                    >
                      <ButtonContent visible>Add Sub Task</ButtonContent>
                    </Button>
                  </Grid.Column>
                </Grid>
                <Form.Field style={{ marginTop: 8 }}>
                  <Input
                    size="large"
                    placeholder={
                      subTasks.length > 0 ? "Parent Task Text" : "Task Text"
                    }
                    value={state.code.taskText}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        editTaskText: true,
                        code: {
                          ...state.code,
                          taskText: value
                        }
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    placeholder={
                      subTasks.length > 0
                        ? "Parent Responsible Party"
                        : "Responsible Party"
                    }
                    selection
                    options={responsiblePartys}
                    size="large"
                    style={{ fontSize: 16 }}
                    onChange={(_, { value }) =>
                      setState((state) => ({
                        ...state,
                        editTaskText: true,
                        code: {
                          ...state.code,
                          taskResponsibleParty: value
                        }
                      }))
                    }
                    value={state.code.taskResponsibleParty}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  {taskOmits.length > 0 && <label>Omit Options</label>}
                  <div style={{ marginBottom: "16px" }}>
                    {taskOmits.map((omit, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px"
                        }}
                      >
                        <Input
                          placeholder="Omit Option"
                          value={omit.option}
                          onChange={(e) =>
                            updateTaskOmit(omit.id, e.target.value)
                          }
                          style={{ flex: 1, marginRight: "8px" }}
                        />
                        <Button
                          icon="trash"
                          style={{ color: "#fff" }}
                          onClick={() => removeTaskOmit(omit.id)}
                        />
                      </div>
                    ))}
                    <Button style={{ color: "#fff" }} onClick={addTaskOmit}>
                      Add Omit
                    </Button>
                  </div>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            {subTasks.map((t, i) => (
              <Grid.Row
                verticalAlign="middle"
                key={t.id}
                style={{
                  border: "1px solid #ccc",
                  padding: "16px",
                  borderRadius: "8px",
                  marginBottom: "16px"
                }}
              >
                <Grid.Column width={15}>
                  <Grid verticalAlign="bottom">
                    <Grid.Column floated="left" width={8}>
                      <label>Sub Task #{i + 1}</label>
                    </Grid.Column>
                    <Grid.Column floated="right" width={8} textAlign="right">
                      <Button
                        onClick={() => removeSubTask(t.id)}
                        style={{ color: "#fff" }}
                        size="small"
                      >
                        Delete
                      </Button>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={15}>
                  <Form.Field style={{ marginTop: 8 }}>
                    <Input
                      size="large"
                      placeholder="Sub Task Text"
                      onChange={(_, { value }) =>
                        updateSubTaskText(t.id, value)
                      }
                      value={t.taskText}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Responsible Party</label>
                    <Dropdown
                      placeholder="Responsible Party"
                      selection
                      options={responsiblePartys}
                      size="large"
                      onChange={(_, { value }) =>
                        updateSubTaskResponsibleParty(t.id, value)
                      }
                      value={t.taskResponsibleParty}
                    />
                  </Form.Field>
                  <Form.Field>
                    {t.scheduleBICodeTaskOmits.length > 0 && (
                      <label>Sub Task Omit Options</label>
                    )}
                    {t.scheduleBICodeTaskOmits.map((omit, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px"
                        }}
                      >
                        <Input
                          placeholder="Omit Option"
                          value={omit.option}
                          onChange={(e) =>
                            updateSubTaskOmit(t.id, omit.id, e.target.value)
                          }
                          style={{ flex: 1, marginRight: "8px" }}
                        />
                        <Button
                          icon="trash"
                          style={{ color: "#fff" }}
                          onClick={() => removeSubTaskOmit(t.id, omit.id)}
                        />
                      </div>
                    ))}
                    <Button
                      onClick={() => addSubTaskOmit(t.id)}
                      style={{ color: "#fff" }}
                    >
                      Add Omit
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>

          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={(files) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files, ...files]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files.filter((f) => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.code.files &&
                state.code.files.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        code: {
                          ...state.code,
                          files: [
                            ...state.code.files.filter((nf) => nf.id !== f.id)
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          {!state.editMode && (
            <React.Fragment>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Form.Field>
                    <Checkbox
                      label="All states"
                      checked={state.allStates}
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          allStates: true,
                          allLicensedStates: false,
                          allUnlicensedStates: false,
                          customStates: false,
                          codeStates: state.allStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="All licensed states"
                      checked={state.allLicensedStates}
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: true,
                          allUnlicensedStates: false,
                          customStates: false,
                          codeStates: state.allLicensedStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="All unlicensed states"
                      checked={state.allUnlicensedStates}
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: false,
                          allUnlicensedStates: true,
                          customStates: false,
                          codeStates: state.allUnlicensedStatesList
                        }))
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      label="Custom (choose your own state selection)"
                      checked={state.customStates}
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          allStates: false,
                          allLicensedStates: false,
                          allUnlicensedStates: false,
                          customStates: true
                        }))
                      }
                    />
                  </Form.Field>
                  {state.customStates && (
                    <Form.Field required>
                      <label>States</label>
                      <Dropdown
                        size="large"
                        fluid
                        multiple
                        selection
                        placeholder="Select States"
                        style={{ fontSize: 16 }}
                        options={state.states}
                        value={state.codeStates}
                        onChange={(_, { value }) => {
                          setState((state) => ({
                            ...state,
                            codeStates: value
                          }));
                        }}
                        error={
                          state.submitAttempted && !state.codeStates.length > 0
                        }
                      />
                    </Form.Field>
                  )}
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          )}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Highlight Row
                  <Checkbox
                    value={!state.code.highlight}
                    checked={state.code.highlight}
                    onChange={() => {
                      setState((state) => ({
                        ...state,
                        editHighlightRow: true,
                        code: {
                          ...state.code,
                          highlight: !state.code.highlight
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  setState((state) => ({
                    ...state,
                    submitAttempted: true
                  }));
                  if (
                    state.editMode &&
                    state.codeStates.length > 1 &&
                    state.errorList.length < 1
                  ) {
                    setState((state) => ({ ...state, confirmEdit: true }));
                  } else {
                    saveChanges();
                  }
                }}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
      {state.confirmEdit && (
        <Modal
          size={"tiny"}
          open={state.confirmEdit !== null}
          onClose={() => setState((state) => ({ ...state, confirmEdit: null }))}
          dimmer="inverted"
        >
          <Modal.Header>Edit Options</Modal.Header>
          <Modal.Content>
            <p className={css({ fontFamily: "Fakt-Bold" })}>
              Code{" "}
              <span
                className={css({
                  color: "#767676",
                  textDecoration: "underline"
                })}
              >
                {state.originalCodeName}
              </span>{" "}
              found in the following states.
            </p>
            {state.codeStates.map((s) => s).join(", ")}
            <Form.Field className={css({ marginTop: 16 })}>
              <Checkbox
                label={"Edit " + state.code.state.name}
                checked={!state.editAllStates}
                size="large"
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    editAllStates: false
                  }))
                }
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label={"Edit All States"}
                checked={state.editAllStates}
                size="large"
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    editAllStates: true
                  }))
                }
              />
            </Form.Field>
            {state.editAllStates && (
              <div className={css({ marginTop: 16 })}>
                <Form.Field>
                  <Checkbox
                    label={"Code"}
                    checked={state.editCode}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        editCode: !state.editCode
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Case Scenario"}
                    checked={state.editCaseScenario}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        editCaseScenario: !state.editCaseScenario
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Wording"}
                    checked={state.editWording}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        editWording: !state.editWording
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Note"}
                    checked={state.editNote}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        editNote: !state.editNote
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Instructions To Omit"}
                    checked={state.editInstructionsToOmit}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        editInstructionsToOmit: !state.editInstructionsToOmit
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label="Tasks"
                    checked={state.editTaskText}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        editTaskText: !state.editTaskText
                      }))
                    }
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    label={"Highlight Row"}
                    checked={state.editHighlightRow}
                    size="large"
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        editHighlightRow: !state.editHighlightRow
                      }))
                    }
                  />
                </Form.Field>
              </div>
            )}
          </Modal.Content>
          <Modal.Actions>
            <React.Fragment>
              <Button
                negative
                onClick={() =>
                  setState((state) => ({
                    ...state,
                    confirmEdit: null
                  }))
                }
                style={{
                  backgroundColor: "#fff",
                  border: "solid 1px #e5e5ea",
                  color: "rgba(0, 0, 0, 0.87)"
                }}
              >
                Cancel
              </Button>
              <Button
                positive
                onClick={() => saveChanges()}
                style={{ backgroundColor: "#1f2b5e" }}
              >
                Submit
              </Button>
            </React.Fragment>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
}
