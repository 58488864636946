import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Input,
  Dropdown,
  Message,
  TextArea
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { logEvent } from "../../../helpers/analyticsLogger";

export function AddOPEndorsement({ history }) {
  const { selectedState } = useContext(StateContext);

  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    opUnderwriters: [],
    selectedUnderwriter: null,
    underwriters: [],
    endorsements: [],
    errorList: []
  });

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState((state) => ({
        ...state,
        underwriters: result.data.map((u) => ({
          key: u.id,
          value: u.id,
          text: u.name
        }))
      }));
    }
  }

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: state.selectedUnderwriter ? true : false
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          "/endorsement/addOPUnderwriter",
          state.opUnderwriters
        );
        if (result.success) {
          logEvent(null, "Added", "OP Underwriter");
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (state.selectedUnderwriter === null) {
      list.push("You must select an underwriter.");
    }
    return list;
  }

  async function getOPEndorsementsByState() {
    const result = await getRequest("/endorsement/getOPEndorsementsByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        endorsements: result.data,
        opUnderwriters: result.data.map((d) => ({
          opEndorsementId: d.id,
          charge: "",
          underwriterId: state.selectedUnderwriter
        }))
      }));
    }
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.selectedUnderwriter]);

  useEffect(() => {
    getOPEndorsementsByState();
  }, [selectedState]);

  useEffect(() => {
    getUnderwriters();
  }, []);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        New Underwriter
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Underwriter Name</label>
                <Dropdown
                  size="large"
                  fluid
                  selection
                  placeholder="Select Underwriter"
                  style={{ fontSize: 16 }}
                  options={state.underwriters}
                  value={state.selectedUnderwriter}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      selectedUnderwriter: value,
                      opUnderwriters: state.opUnderwriters.map((opu) => ({
                        ...opu,
                        underwriterId: value
                      }))
                    }))
                  }
                  error={
                    state.submitAttempted && state.selectedUnderwriter === null
                  }
                  data-automation="underwriters"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          {state.underwriters.length > 0 && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Form.Field>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.92857143em",
                      color: "rgba(0, 0, 0, 0.87)"
                    }}
                  >
                    Endorsement Charges
                  </label>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
          {state.endorsements.map((e) => (
            <React.Fragment>
              <Grid.Row columns={2}>
                <Grid.Column width={4}>
                  <label
                    style={{
                      position: "relative",
                      top: 14,
                      fontWeight: "bold",
                      fontSize: "0.92857143em",
                      color: "rgba(0, 0, 0, 0.87)"
                    }}
                  >
                    {e.name}
                  </label>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Form.Field>
                    <Input
                      size="large"
                      placeholder="Charge"
                      value={
                        state.opUnderwriters.find(
                          (opu) => opu.opEndorsementId === e.id
                        ).charge
                      }
                      onChange={(_, { value }) =>
                        setState((state) => ({
                          ...state,
                          opUnderwriters: [
                            ...state.opUnderwriters.map((opu) =>
                              opu.opEndorsementId === e.id
                                ? { ...opu, charge: value }
                                : opu
                            )
                          ]
                        }))
                      }
                      autoComplete="new-password"
                    />
                  </Form.Field>
                  <Form.Field>
                    <TextArea
                      placeholder="Note"
                      style={{ minHeight: 96 }}
                      value={
                        state.opUnderwriters.find(
                          (opu) => opu.opEndorsementId === e.id
                        ).note
                      }
                      onChange={(_, { value }) =>
                        setState((state) => ({
                          ...state,
                          opUnderwriters: [
                            ...state.opUnderwriters.map((opu) =>
                              opu.opEndorsementId === e.id
                                ? { ...opu, note: value }
                                : opu
                            )
                          ]
                        }))
                      }
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          ))}
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  saveChanges();
                }}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(AddOPEndorsement);
