import React, { useContext } from "react";
import { Input, Dropdown } from "semantic-ui-react";
import { css } from "emotion";
import StateDropdown from "../StateDropdown";
import { StateContext } from "../State";
import ls from "local-storage";

const options = [
  "All",
  "Contacts",
  "Info",
  "Commitment",
  "Policy",
  "Surveys",
  "Forms",
  "Rates",
];

const filterOptions = options.map((value) => ({
  key: value,
  text: value,
  value: value,
}));

export function SearchBar({
  history,
  wordsTyped,
  setWordsTyped,
  setTouched,
  loading,
  filter,
}) {
  const { selectedState, noState } = useContext(StateContext);
  const search = () => {
    history.push(
      `/search/states/${
        noState ? "no_state" : selectedState
      }/words/${wordsTyped}/filter/${filter}`
    );
    setTouched(true);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  return (
    <div className={css({ position: "relative" })}>
      <StateDropdown
        pushHistory={(value) =>
          wordsTyped
            ? history.push(
                `/search/states/${value}/words/${wordsTyped}/filter/${filter}`
              )
            : history.push(`/search/states/${value}/filter/${filter}`)
        }
        search
        style={{ verticalAlign: "top" }}
        noStateAble
      />
      <Dropdown
        floating
        button
        className={css({
          fontSize: "1.14285714rem !important",
          "& i.icon.filter:before": {
            marginLeft: 8,
          },
          height: 40,
          verticalAlign: "top !important",
          paddingTop: "13px !important",
        })}
        onChange={(_, { value }) => {
          wordsTyped
            ? history.push(
                `/search/states/${
                  noState ? "no_state" : selectedState
                }/words/${wordsTyped}/filter/${value}`
              )
            : history.push(
                `/search/states/${
                  noState ? "no_state" : selectedState
                }/filter/${value}`
              );
          ls.set("filter", value);
        }}
        value={filter}
        options={filterOptions}
      />
      <div
        className={css({
          width: 456,
          display: "inline-block",
          position: "relative",
        })}
      >
        <Input
          loading={loading}
          size="large"
          icon={{
            name: "search",
            circular: false,
            link: true,
            onClick: search,
          }}
          placeholder="Search Madison Intel"
          className={css({
            "& input": {
              backgroundColor: "#ebecf1 !important",
              border: "inherit !important",
            },
            "i.icon:after": {
              borderColor: "#00b8d4 transparent transparent !important",
            },
            width: 456,
          })}
          value={wordsTyped}
          onChange={(_, { value }) => {
            setWordsTyped(value);
          }}
          onKeyPress={handleKeyPress}
        />
      </div>
    </div>
  );
}
