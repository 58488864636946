import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Message,
  Input,
  TextArea,
  Checkbox,
  Dropdown
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddPolicyHelpfulWording({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    helpfulWording: {
      helpfulWordingCategoryId: 1,
      policyHelpfulWordingStates:
        match.path !== "/policy-instructions/edit-helpful-wording/:id"
          ? [
              {
                policyHelpfulWordingId: 0,
                stateId: +match.params.id
              }
            ]
          : []
    },
    editMode: match.path === "/policy-instructions/edit-helpful-wording/:id",
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    errorList: [],
    helpfulWordingCategories: [],
    state: []
  });

  async function getHelpfulWordingById(id) {
    const result = await getRequest(
      `/policyHelpfulWording/getHelpfulWordingById`,
      {
        id
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWording: result.data
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allLicensedStatesList: result.data
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function getHelpfulWordingCategories() {
    const result = await getRequest(
      "/policyHelpfulWording/getHelpfulWordingCategories"
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        helpfulWordingCategories: result.data
      }));
    }
  }

  useEffect(() => {
    getHelpfulWordingCategories();
    getStates();
    getAllStatesList();
    getAllLicensedStatesList();
    getAllUnlicensedStatesList();
    if (state.editMode) {
      getHelpfulWordingById(match.params.id);
    }
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: !state.errorList.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/policyHelpfulWording/${
            state.editMode ? "editHelpfulWording" : "addHelpfulWording"
          }`,
          state.helpfulWording
        );
        if (result.success) {
          logEvent(
            null,
            state.editMode ? "Edited" : "Added",
            "Helpful Wording"
          );
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!state.helpfulWording.subject) {
      list.push("You must provide a subject.");
    }
    if (state.helpfulWording.policyHelpfulWordingStates.length < 1) {
      list.push("You must select at least one state.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.helpfulWording, state.helpfulWording.policyHelpfulWordingStates]);

  useEffect(() => {
    if (state.editMode && state.helpfulWording.id) {
      let checkIfContainsAll = (arr, target) =>
        target.every((v) => arr.includes(v));
      setState((state) => ({
        ...state,
        allStates: checkIfContainsAll(
          state.helpfulWording.policyHelpfulWordingStates.length > 0
            ? state.helpfulWording.policyHelpfulWordingStates.map(
                (ws) => ws.stateId
              )
            : [],
          state.allStatesList
        ),
        allLicensedStates:
          checkIfContainsAll(
            state.helpfulWording.policyHelpfulWordingStates.length > 0
              ? state.helpfulWording.policyHelpfulWordingStates.map(
                  (ws) => ws.stateId
                )
              : [],
            state.allLicensedStatesList
          ) &&
          state.helpfulWording.policyHelpfulWordingStates.length ===
            state.allLicensedStatesList.length,
        allUnlicensedStates:
          checkIfContainsAll(
            state.helpfulWording.policyHelpfulWordingStates.length > 0
              ? state.helpfulWording.policyHelpfulWordingStates.map(
                  (ws) => ws.stateId
                )
              : [],
            state.allUnlicensedStatesList
          ) &&
          state.helpfulWording.policyHelpfulWordingStates.length ===
            state.allUnlicensedStatesList.length
      }));
    } else {
      return;
    }
  }, [
    state.helpfulWording.id,
    state.allStatesList,
    state.allLicensedStatesList,
    state.allUnlicensedStatesList
  ]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      customStates:
        !state.allLicensedStates &&
        !state.allUnlicensedStates &&
        !state.allStates
    }));
  }, [state.allStates, state.allUnlicensedStates, state.allLicensedStates]);

  function buildHelpfulWordingStates(value) {
    return value.map((v) => ({
      policyHelpfulWordingId: 0,
      stateId: v
    }));
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Helpful Wording`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                {state.helpfulWordingCategories.map((c) => (
                  <Form.Field key={c.id}>
                    <Checkbox
                      label={c.name}
                      checked={
                        state.helpfulWording.policyHelpfulWordingCategoryId ===
                        c.id
                      }
                      size="large"
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          helpfulWording: {
                            ...state.helpfulWording,
                            policyHelpfulWordingCategoryId: c.id
                          }
                        }))
                      }
                    />
                  </Form.Field>
                ))}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.helpfulWording.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Body</label>
                <TextArea
                  placeholder="Body"
                  style={{ minHeight: 292 }}
                  value={state.helpfulWording.body}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        body: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 192 }}
                  value={state.helpfulWording.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      helpfulWording: {
                        ...state.helpfulWording,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="All states"
                  checked={state.allStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: true,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: false,
                      helpfulWording: {
                        ...state.helpfulWording,
                        policyHelpfulWordingStates: buildHelpfulWordingStates(
                          state.allStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All licensed states"
                  checked={state.allLicensedStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: true,
                      allUnlicensedStates: false,
                      customStates: false,
                      helpfulWording: {
                        ...state.helpfulWording,
                        policyHelpfulWordingStates: buildHelpfulWordingStates(
                          state.allLicensedStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All unlicensed states"
                  checked={state.allUnlicensedStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: true,
                      customStates: false,
                      helpfulWording: {
                        ...state.helpfulWording,
                        policyHelpfulWordingStates: buildHelpfulWordingStates(
                          state.allUnlicensedStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Custom (choose your own state selection)"
                  checked={state.customStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: true
                    }))
                  }
                />
              </Form.Field>
              {state.customStates && (
                <Form.Field required>
                  <label>States</label>
                  <Dropdown
                    size="large"
                    fluid
                    multiple
                    selection
                    placeholder="Select States"
                    style={{ fontSize: 16 }}
                    options={state.states}
                    value={
                      state.helpfulWording.policyHelpfulWordingStates
                        ? state.helpfulWording.policyHelpfulWordingStates.map(
                            (ws) => ws.stateId
                          )
                        : null
                    }
                    onChange={(_, { value }) => {
                      setState((state) => ({
                        ...state,
                        helpfulWording: {
                          ...state.helpfulWording,
                          policyHelpfulWordingStates: buildHelpfulWordingStates(
                            value
                          )
                        }
                      }));
                    }}
                    error={
                      state.submitAttempted &&
                      !state.helpfulWording.policyHelpfulWordingStates.length >
                        0
                    }
                  />
                </Form.Field>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddPolicyHelpfulWording);
