import React, { useContext } from "react";
import Highlighter from "react-highlight-words";
import { SearchWordsContext } from "./SearchWords";

export function Highlight({ children }) {
  const { searchWords } = useContext(SearchWordsContext);
  return (
    <React.Fragment>
      {searchWords ? (
        <Highlighter
          highlightClassName="YourHighlightClass"
          searchWords={searchWords}
          autoEscape={true}
          textToHighlight={children || ""}
        />
      ) : (
        children
      )}
    </React.Fragment>
  );
}
