import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Message, Modal } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import { Highlight } from "../../search/Highlight";
import "reactjs-popup/dist/index.css";
import EventLogs from "./EventLogs";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
});
const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 14,
  marginTop: 24,
});

function StateIdiosyncrasies({
  history,
  match: { params },
  stateIdiosyncrasySearchResults,
}) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);

  const [state, setState] = useState({
    stateIdiosyncrasies: [],
    noResults: false,
    initialLoaded: false,
    idiosyncrasyToDelete: null,
  });

  async function getStateIdiosyncrasiesByState() {
    const result = await getRequest(
      "/stateIdiosyncrasy/getStateIdiosyncrasiesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        stateIdiosyncrasies: result.data,
        initialLoaded: true,
      }));
    }
  }

  async function deleteStateIdiosyncrasy(id) {
    const result = await postRequest(
      `/stateIdiosyncrasy/deleteStateIdiosyncrasy?id=${id}`,
      id
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        stateIdiosyncrasies: state.stateIdiosyncrasies.filter(
          (i) => i.id !== id
        ),
        idiosyncrasyToDelete: null,
      }));
    }
  }

  async function download(id) {
    const result = await getRequest(
      `/stateIdiosyncrasy/downloadStateIdiosyncrasyFile?id=${id}`
    );
    if (result.success) {
      convertToFileDownload(result.data.base64File, result.data.name);
    }
  }

  useEffect(() => {
    if (selectedState && !stateIdiosyncrasySearchResults) {
      getStateIdiosyncrasiesByState();
    }
  }, [selectedState]);

  const filteredStateIdiosyncrasies = stateIdiosyncrasySearchResults
    ? stateIdiosyncrasySearchResults
    : state.stateIdiosyncrasies;

  return (
    <React.Fragment>
      {!stateIdiosyncrasySearchResults && (
        <Grid>
          <Grid.Row columns={2} verticalAlign="middle">
            {(user.isOwner || user.isOwnerPlus) && (
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                <Button
                  icon
                  className="blueButton"
                  onClick={() => history.push(`/info/new-state-idiosyncrasy`)}
                >
                  <i className="fal fa-plus" />
                </Button>
                <span
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 14,
                    color: "#000",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00b8d4",
                    },
                  })}
                  onClick={() => history.push(`/info/new-state-idiosyncrasy`)}
                >
                  New State Idiosyncrasy
                </span>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      )}
      <div
        className={css({
          marginBottom: stateIdiosyncrasySearchResults ? 0 : 19.75,
          marginTop: stateIdiosyncrasySearchResults ? 0 : 40,
        })}
      >
        {filteredStateIdiosyncrasies &&
          filteredStateIdiosyncrasies.map((i) => (
            <Card fluid style={{ padding: 28 }} key={i.id}>
              <Card.Content>
                {i.eventLogs && i.eventLogs.length > 0 && (
                  <EventLogs
                    eventLogs={i.eventLogs}
                    position={{
                      right: user.isOwner || user.isOwnerPlus ? 82 : 24,
                    }}
                  />
                )}
                {(user.isOwner || user.isOwnerPlus) && (
                  <React.Fragment>
                    <i
                      onClick={() =>
                        setState((state) => ({
                          ...state,
                          idiosyncrasyToDelete: i,
                        }))
                      }
                      className="fal fa-trash"
                      style={{
                        position: "absolute",
                        right: 56,
                        cursor: "pointer",
                      }}
                    />
                    <i
                      onClick={() =>
                        history.push(`/info/edit-state-idiosyncrasy/${i.id}`)
                      }
                      className="fal fa-pencil"
                      style={{
                        position: "absolute",
                        right: 28,
                        cursor: "pointer",
                      }}
                    />
                  </React.Fragment>
                )}
                <Card.Header content={<Highlight children={i.subject} />} />
                <Card.Description
                  style={{ whiteSpace: "pre-wrap" }}
                  content={<Highlight children={i.note} />}
                />
                {i.stateIdiosyncrasyFiles &&
                  i.stateIdiosyncrasyFiles.length > 0 && (
                    <div className={labelStyles}>Attachments</div>
                  )}
                {i.stateIdiosyncrasyFiles &&
                  i.stateIdiosyncrasyFiles.map((f) => (
                    <p className={paragraphStyles} key={f.id}>
                      <a
                        onClick={() => download(f.id)}
                        className={css({
                          "&:hover": {
                            textDecoration: "underline",
                            color: "#00b8d4",
                          },
                        })}
                      >
                        <i
                          className="fal fa-paperclip"
                          style={{ color: "#00b8d4", marginRight: 8 }}
                        />
                        <Highlight children={f.fileName} />
                      </a>
                    </p>
                  ))}
              </Card.Content>
            </Card>
          ))}
        {filteredStateIdiosyncrasies.length < 1 && state.initialLoaded && (
          <Message size="big">No available idiosyncrasies...</Message>
        )}
      </div>
      {state.idiosyncrasyToDelete && (
        <Modal
          size={"tiny"}
          open={state.idiosyncrasyToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, idiosyncrasyToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Idiosyncrasy</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, idiosyncrasyToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() =>
                deleteStateIdiosyncrasy(state.idiosyncrasyToDelete.id)
              }
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(StateIdiosyncrasies);
