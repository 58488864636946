import React, { useState, useEffect } from "react";
import { css } from "emotion";
import { Table, Icon, Grid, Button } from "semantic-ui-react";
import { getRequest } from "../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import CustomTable from "../CustomTable";

function Underwriters({ history }) {
  const [state, setState] = useState({
    initialLoading: true,
    noResults: false,
    underwriters: []
  });

  async function getUnderwriters() {
    const result = await getRequest("/underwriter/getUnderwriters");
    if (result.success) {
      setState(state => ({
        ...state,
        underwriters: result.data.filter(u => u.name !== "All Underwriters"),
        initialLoading: false
      }));
    }
  }

  useEffect(() => {
    getUnderwriters();
  }, []);

  return (
    <React.Fragment>
      <div
        className={css({
          marginBottom: 19.75
        })}
      >
        <Grid>
          <Grid.Row columns={2} verticalAlign="middle">
            <Grid.Column floated="left">
              <div
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 20,
                  lineHeight: 1.2,
                  color: "#47517b",
                  display: "inline-block",
                  marginRight: 64
                })}
              >
                Underwriters
              </div>
            </Grid.Column>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                icon
                className="blueButton"
                onClick={() => history.push(`/admin/new-underwriter`)}
              >
                <i className="fal fa-plus" />
              </Button>
              <span
                className={css({
                  fontFamily: "Fakt-Bold",
                  fontSize: 14,
                  color: "#000",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#00b8d4"
                  }
                })}
                onClick={() => history.push(`/admin/new-underwriter`)}
              >
                New Underwriter
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <CustomTable
          customStyles={{
            "& tr td:first-of-type": {
              paddingLeft: "40.5px !important"
            },
            "& th": {
              position: "sticky",
              top: 0,
              zIndex: 1
            }
          }}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={5}>Underwriter</Table.HeaderCell>
              <Table.HeaderCell>Servicing States</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {state.underwriters.length < 1 && (
              <Table.Row>
                <Table.Cell>No underwriters available</Table.Cell>
              </Table.Row>
            )}
            {state.underwriters.map(u => (
              <Table.Row key={u.id}>
                <Table.Cell
                  style={{
                    textAlign: "left"
                  }}
                >
                  <span
                    className={css({
                      "&:hover i": { color: "#00b8d4" },
                      cursor: "pointer",
                      verticalAlign: "top",
                      display: "inline-block"
                    })}
                    onClick={() =>
                      history.push(`/admin/edit-underwriter/${u.id}`)
                    }
                  >
                    <Icon
                      style={{ paddingRight: 32.5 }}
                      className="fal fa-pen"
                    />
                  </span>
                  <React.Fragment>
                    <div
                      className={css({
                        display: "inline-block",
                        width: "calc(100% - 70px)"
                      })}
                    >
                      <span className={css({ fontFamily: "Fakt-Bold" })}>
                        {u.name}
                      </span>
                    </div>
                  </React.Fragment>
                </Table.Cell>
                <Table.Cell
                  style={{
                    textAlign: "left"
                  }}
                >
                  {u.underwriterStates.map(us => (
                    <li style={{ float: "left", marginLeft: 16 }}>
                      {us.state.name}
                    </li>
                  ))}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </CustomTable>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Underwriters);
