import React, { useState } from "react";
import TrainingMedia from "./TrainingMedia";
import SystemTips from "./SystemTips";
import { Menu } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

function TrainingAndSystemTips({ history, match }) {
  const selectedSection = match.params.section;
  return (
    <React.Fragment>
      {(!selectedSection || selectedSection === "system-tips") && (
        <Menu tabular style={{ marginTop: 55, marginBottom: 32 }}>
          <Menu.Item
            style={{ marginLeft: 21 }}
            name="Training Media"x
            active={selectedSection === undefined}
            onClick={() => history.push(`/training`)}
          />
          <Menu.Item
            name="System Tips"
            active={selectedSection === "system-tips"}
            onClick={() => history.push(`/training/system-tips`)}
          />
        </Menu>
      )}
      {selectedSection === undefined && <TrainingMedia />}
      {selectedSection === "system-tips" && <SystemTips />}
    </React.Fragment>
  );
}

export default withRouter(TrainingAndSystemTips);
