import React, { useState, useEffect } from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";
import { Table, Icon, Form } from "semantic-ui-react";
import { css } from "emotion";
import { postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import CustomTable from "../../CustomTable";
import Textarea from "react-textarea-autosize";
import { Highlight } from "../../search/Highlight";

const editTextareaStyles = css({
  backgroundColor: "transparent !important",
  borderRadius: "5px !important",
  border: "solid 1px #c2c5d3 !important",
  fontSize: "12px !important",
  resize: "none !important"
});

const DragHandle = SortableHandle(() => (
  <span
    className={css({
      color: "#c3c3c3",
      cursor: "grab",
      marginRight: 16
    })}
  >
    <i className="fal fa-sort" />
  </span>
));

function DragSortCharges({
  items,
  saveSortPath,
  history,
  draggable,
  sortable,
  editable,
  chargeToEdit,
  saveChanges,
  selectedState,
  setState
}) {
  const [sort, setSort] = useState({
    items: items,
    sorted: false
  });

  async function saveSort() {
    postRequest(
      saveSortPath,
      sort.items.map(i =>
        i.invoiceChargeStates.find(cs => cs.stateId === selectedState)
      )
    );
  }

  const SortableItem = SortableElement(({ value }) => (
    <Table.Row key={value.id}>
      <Table.Cell
        style={{
          textAlign: "left"
        }}
      >
        {draggable && editable && (
          <React.Fragment>
            {chargeToEdit.id !== value.id && (
              <React.Fragment>
                <DragHandle />
                <span
                  className={css({
                    "&:hover i": { color: "#00b8d4" },
                    cursor: "pointer"
                  })}
                  onClick={() =>
                    history.push(`/rates/edit-invoice-charge/${value.id}`)
                  }
                >
                  <Icon style={{ paddingRight: 16 }} className="fal fa-pen" />
                </span>
                <span
                  className={css({
                    "&:hover i": { color: "#00b8d4" },
                    cursor: "pointer"
                  })}
                  onClick={() => {
                    setState(state => ({
                      ...state,
                      chargeToDelete: value
                    }));
                  }}
                >
                  <Icon
                    style={{ paddingRight: 32.5 }}
                    className="fal fa-trash"
                  />
                </span>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        {chargeToEdit.id === value.id && draggable ? (
          <Form>
            <Icon
              style={{
                color: "#00b8d4",
                paddingRight: 32.5,
                marginTop: 9,
                fontSize: 16,
                cursor: "pointer"
              }}
              className="fal fa-check"
              onClick={() => saveChanges()}
            />
            <Textarea
              style={{
                width: "calc(100% - 36px)"
              }}
              className={editTextareaStyles}
              rows={1}
              placeholder="Title"
              value={chargeToEdit.title || ""}
              onChange={event => {
                event.persist();
                setState(state => ({
                  ...state,
                  chargeToEdit: {
                    ...state.chargeToEdit,
                    title: event.target.value
                  }
                }));
              }}
            />
          </Form>
        ) : (
          <Highlight children={value.title} />
        )}
      </Table.Cell>
      <Table.Cell>
        {chargeToEdit.id === value.id && draggable ? (
          <Form>
            <Textarea
              className={editTextareaStyles}
              rows={1}
              placeholder="Purchase"
              value={chargeToEdit.purchase || ""}
              onChange={event => {
                event.persist();
                setState(state => ({
                  ...state,
                  chargeToEdit: {
                    ...state.chargeToEdit,
                    purchase: event.target.value
                  }
                }));
              }}
            />
          </Form>
        ) : (
          <Highlight children={value.purchase} />
        )}
      </Table.Cell>
      <Table.Cell>
        {chargeToEdit.id === value.id && draggable ? (
          <Form>
            <Textarea
              className={editTextareaStyles}
              rows={1}
              placeholder="Refinance"
              value={chargeToEdit.refinance || ""}
              onChange={event => {
                event.persist();
                setState(state => ({
                  ...state,
                  chargeToEdit: {
                    ...state.chargeToEdit,
                    refinance: event.target.value
                  }
                }));
              }}
            />
          </Form>
        ) : (
          <Highlight children={value.refinance} />
        )}
      </Table.Cell>
    </Table.Row>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <CustomTable
        customStyles={{
          "& th": {
            position: "sticky",
            top: 0,
            zIndex: 1
          },
          "& tr td:first-of-type": {
            paddingLeft:
              draggable && editable ? "40px !important" : "69px !important",
            fontFamily: "Fakt-Bold"
          }
        }}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Purchase</Table.HeaderCell>
            <Table.HeaderCell>Refinance</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map((value, index) => (
            <SortableItem
              key={`item-${value.id}`}
              index={index}
              value={value}
            />
          ))}
        </Table.Body>
      </CustomTable>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSort(state => ({
      ...state,
      sorted: true,
      items: arrayMove(sort.items, oldIndex, newIndex).map((n, i) => ({
        ...n,
        invoiceChargeStates: n.invoiceChargeStates.map(s =>
          s.stateId === selectedState ? { ...s, sortNum: i } : s
        )
      }))
    }));
  };

  useEffect(() => {
    sortable && saveSort(sort.items);
  }, [sort.items]);

  useEffect(() => {
    setSort(sort => ({ ...sort, items: items }));
  }, [items]);

  return (
    <SortableList items={sort.items} onSortEnd={onSortEnd} useDragHandle />
  );
}

export default withRouter(DragSortCharges);
