import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import { StateContext } from "../../State";
import FileUpload from "../../FileUpload";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import shortid from "shortid";
import { logEvent } from "../../../helpers/analyticsLogger";

const defaultMortgageTax = {
  isApplicable: null,
  isExemptOfAssignmentOfMortgage: null,
  isExemptOfAssumptionOfMortgage: null
};

export function EditMortgageTax({ match, history }) {
  const { selectedState } = useContext(StateContext);
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    mortgageTax: defaultMortgageTax,
    files: [],
    filesToDelete: []
  });

  async function getMortgageTaxByState(stateId) {
    const result = await getRequest(`/tax/getMortgageTaxByState`, {
      stateId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        mortgageTax: result.data
      }));
    }
  }

  useEffect(() => {
    getMortgageTaxByState(selectedState);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({ ...state, submitting: true }));
      const result = await postRequest("/tax/editMortgageTax", {
        ...state.mortgageTax,
        stateId: selectedState,
        files: await Promise.all(
          state.files.map(async (f) => ({
            base64File: await readFileAsBase64(f),
            fileName: f.name
          }))
        ),
        filesToDelete: state.filesToDelete
      });
      if (result.success) {
        logEvent(null, "Edited", "Mortgage Tax");
        setTimeout(() => history.goBack(), 1000);
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        Mortgage Tax
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Is it applicable?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.mortgageTax.isApplicable}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isApplicable: !state.mortgageTax.isApplicable
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={state.mortgageTax.isApplicable === false}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isApplicable: state.mortgageTax.isApplicable === false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.mortgageTax.isApplicableNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isApplicableNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Is the Assumption of Mortgage exempt from tax?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.mortgageTax.isExemptOfAssumptionOfMortgage}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isExemptOfAssumptionOfMortgage: !state.mortgageTax
                          .isExemptOfAssumptionOfMortgage
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.mortgageTax.isExemptOfAssumptionOfMortgage === false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isExemptOfAssumptionOfMortgage:
                          state.mortgageTax.isExemptOfAssumptionOfMortgage ===
                          false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.mortgageTax.isExemptOfAssumptionOfMortgageNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isExemptOfAssumptionOfMortgageNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Is the Assignment of Mortgage exempt from tax?</label>
                <Checkbox
                  style={{ marginRight: 20 }}
                  label="Yes"
                  checked={state.mortgageTax.isExemptOfAssignmentOfMortgage}
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isExemptOfAssignmentOfMortgage: !state.mortgageTax
                          .isExemptOfAssignmentOfMortgage
                      }
                    }))
                  }
                />
                <Checkbox
                  label="No"
                  checked={
                    state.mortgageTax.isExemptOfAssignmentOfMortgage === false
                  }
                  size="large"
                  onChange={() =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isExemptOfAssignmentOfMortgage:
                          state.mortgageTax.isExemptOfAssignmentOfMortgage ===
                          false
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 96 }}
                  value={state.mortgageTax.isExemptOfAssignmentOfMortgageNote}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      mortgageTax: {
                        ...state.mortgageTax,
                        isExemptOfAssignmentOfMortgageNote: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={(files) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files, ...files]
                    }))
                  }
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files.filter((f) => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.mortgageTax.mortgageTaxFiles &&
                state.mortgageTax.mortgageTaxFiles.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        mortgageTax: {
                          ...state.mortgageTax,
                          mortgageTaxFiles: [
                            ...state.mortgageTax.mortgageTaxFiles.filter(
                              (mtf) => mtf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}
