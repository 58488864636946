import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Button, Grid, Message, Modal } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import NoteAccordion from "../../NoteAccordion";

const linkStyles = css({
  fontSize: 14,
  lineHeight: "36px",
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline"
  }
});

function SurveyLegalDescriptionNotes({ history, match: { params } }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwner || user.isOwnerPlus;

  const [notes, setNotes] = useState([]);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [showAll, setShowAll] = useState(false);

  async function getSurveyLegalDescriptionNotesByState() {
    const result = await getRequest("/survey/getSurveyLegalDescriptionNotesByState", {
      stateId: selectedState
    });
    if (result.success) {
      const notes = result.data.map(n => ({ ...n, files: n.surveyLegalDescriptionNoteFiles }));
      setNotes(notes);
    }
  }

  async function deleteNote(id, type) {
    const result = await postRequest(`/survey/deleteSurveyLegalDescriptionNote?id=${id}`, id);
    if (result.success) {
      setNoteToDelete(null);
    }
  }

  useEffect(() => {
    if (selectedState) {
      getSurveyLegalDescriptionNotesByState();
    }
  }, [selectedState]);

  return (
    <React.Fragment>
      <Grid style={{ marginBottom: 17 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          <Grid.Column floated="left">
            <div
              className={css({
                fontFamily: "Fakt-Bold",
                fontSize: 20,
                color: "#000e48",
                marginBottom: 40,
                marginTop: 40
              })}
            >
              Legal Descriptions
            </div>
          </Grid.Column>
          {userCanEdit && (
            <Grid.Column floated="right" className={css({ textAlign: "right" })}>
              <span
                className={linkStyles}
                onClick={() => history.push(`/surveys/states/${selectedState}/new-legal-description-note`)}
              >
                Add Note
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
        {notes.length > 0 && (
          <Button basic onClick={() => setShowAll(showAll => !showAll)}>
            {showAll ? "Collapse All" : "Open All"}
          </Button>
        )}
      </Grid>
      {notes.length > 0 && (
        <React.Fragment>
          <NoteAccordion
            notes={notes}
            setNotes={setNotes}
            deleteAction={value => setNoteToDelete(value)}
            showAll={showAll}
            downloadURL="/survey/downloadSurveyLegalDescriptionNoteFile"
            saveSortURL="/survey/sortSurveyLegalDescriptionNotes"
            editURL="/surveys/edit-legal-description-note"
          />
        </React.Fragment>
      )}
      {notes.length < 1 && <Message size="big">No info available</Message>}
      {noteToDelete && (
        <Modal size={"tiny"} open={noteToDelete !== null} onClose={() => setNoteToDelete(null)} dimmer="inverted">
          <Modal.Header>Delete Note</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => setNoteToDelete(null)}
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button positive onClick={() => deleteNote(noteToDelete.id)} style={{ backgroundColor: "#1f2b5e" }}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(SurveyLegalDescriptionNotes);
