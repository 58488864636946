import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import { Button, Header, Grid, Form, Input, Checkbox } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditScheduleBIStateLabel({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    label: { stateId: +match.params.state },
    mortgageState: false,
    deedOfTrustState: false,
    editMode: match.path.includes("edit"),
    errorList: []
  });

  async function getScheduleBILabelByState(stateId) {
    const result = await getRequest(`/scheduleBI/getScheduleBILabelByState`, {
      stateId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        label: result.data,
        mortgageState: result.data.name === "Mortgage State",
        deedOfTrustState: result.data.name === "Deed of Trust State",
        custom:
          result.data.name !== "Mortgage State" &&
          result.data.name !== "Deed of Trust State"
      }));
    }
  }

  useEffect(() => {
    if (state.editMode) {
      getScheduleBILabelByState(match.params.state);
    }
  }, []);

  async function saveChanges() {
    const result = await postRequest(`/scheduleBI/editAddScheduleBILabel`, {
      ...state.label,
      name: state.mortgageState
        ? "Mortgage State"
        : state.deedOfTrustState
        ? "Deed of Trust State"
        : state.label.name
    });
    if (result.success) {
      logEvent(null, state.editMode ? "Edited" : "Added", "Schedule BI Label");
      history.goBack();
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {state.editMode ? "Edit" : "Add"} State Label
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="Mortgage State"
                  checked={state.mortgageState}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      mortgageState: true,
                      deedOfTrustState: false,
                      custom: false
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Deed of Trust State"
                  checked={state.deedOfTrustState}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      deedOfTrustState: true,
                      mortgageState: false,
                      custom: false
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Custom"
                  checked={state.custom}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      custom: true,
                      deedOfTrustState: false,
                      mortgageState: false
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                {state.custom && (
                  <React.Fragment>
                    <Input
                      placeholder="Custom Label"
                      value={state.label.name}
                      onChange={(_, { value }) =>
                        setState((state) => ({
                          ...state,
                          label: {
                            ...state.label,
                            name: value
                          }
                        }))
                      }
                    />
                  </React.Fragment>
                )}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditScheduleBIStateLabel);
