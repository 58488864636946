import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Input,
  Dropdown,
  Message,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddInvoiceCharge({ match, history }) {
  const [state, setState] = useState({
    states: [{}],
    submitAttempted: false,
    submitting: false,
    invoiceCharge: {
      title: null,
      purchase: null,
      refinance: null,
      invoiceChargeStates:
        match.path !== "/rates/edit-invoice-charge/:id"
          ? [
              {
                invoiceChargeId: 0,
                stateId: +match.params.id
              }
            ]
          : []
    },
    allStates: false,
    allLicensedStates: false,
    allUnlicensedStates: false,
    customStates: true,
    allStatesList: [],
    allLicensedStatesList: [],
    allUnlicensedStatesList: [],
    errorList: [],
    editMode: match.path === "/rates/edit-invoice-charge/:id"
  });

  async function getInvoiceChargeById(id) {
    const result = await getRequest(`/invoiceCharge/getInvoiceChargeById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        invoiceCharge: result.data
      }));
    }
  }

  async function getStates() {
    const result = await getRequest("/general/getStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        states: result.data.map((s) => ({
          key: s.id,
          value: s.id,
          text: s.name
        }))
      }));
    }
  }

  async function getAllStatesList() {
    const result = await getRequest("/general/GetAllStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allStatesList: result.data
      }));
    }
  }

  async function getAllLicensedStatesList() {
    const result = await getRequest("/general/GetAllLicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allLicensedStatesList: result.data
      }));
    }
  }

  async function getAllUnlicensedStatesList() {
    const result = await getRequest("/general/GetAllUnlicensedStates");
    if (result.success) {
      setState((state) => ({
        ...state,
        allUnlicensedStatesList: result.data
      }));
    }
  }

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({
        ...state,
        submitAttempted: true,
        submitting: state.invoiceCharge.invoiceChargeStates.length > 0
      }));
      if (!state.errorList.length > 0) {
        const result = await postRequest(
          `/invoiceCharge/${
            state.editMode ? "editInvoiceCharge" : "addInvoiceCharge"
          }`,
          state.invoiceCharge
        );
        if (result.success) {
          logEvent(null, state.editMode ? "Edited" : "Added", "Invoice Charge");
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (state.invoiceCharge.invoiceChargeStates.length < 1) {
      list.push("You must select at least one state.");
    }
    return list;
  }

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.invoiceCharge.invoiceChargeStates]);

  useEffect(() => {
    getStates();
    getAllStatesList();
    getAllLicensedStatesList();
    getAllUnlicensedStatesList();
    state.editMode && getInvoiceChargeById(match.params.id);
  }, []);

  function handleInputChange(value, prop) {
    setState((state) => ({
      ...state,
      invoiceCharge: { ...state.invoiceCharge, [prop]: value }
    }));
  }

  function buildInvoiceChargeStates(value) {
    return value.map((v) => ({
      invoiceChargeId: 0,
      stateId: v
    }));
  }

  useEffect(() => {
    if (state.editMode && state.invoiceCharge.id) {
      let checkIfContainsAll = (arr, target) =>
        target.every((v) => arr.includes(v));
      setState((state) => ({
        ...state,
        allStates: checkIfContainsAll(
          state.invoiceCharge.invoiceChargeStates.length > 0
            ? state.invoiceCharge.invoiceChargeStates.map((ws) => ws.stateId)
            : [],
          state.allStatesList
        ),
        allLicensedStates:
          checkIfContainsAll(
            state.invoiceCharge.invoiceChargeStates.length > 0
              ? state.invoiceCharge.invoiceChargeStates.map((ws) => ws.stateId)
              : [],
            state.allLicensedStatesList
          ) &&
          state.invoiceCharge.invoiceChargeStates.length ===
            state.allLicensedStatesList.length,
        allUnlicensedStates:
          checkIfContainsAll(
            state.invoiceCharge.invoiceChargeStates.length > 0
              ? state.invoiceCharge.invoiceChargeStates.map((ws) => ws.stateId)
              : [],
            state.allUnlicensedStatesList
          ) &&
          state.invoiceCharge.invoiceChargeStates.length ===
            state.allUnlicensedStatesList.length
      }));
    } else {
      return;
    }
  }, [
    state.invoiceCharge.id,
    state.allStatesList,
    state.allLicensedStatesList,
    state.allUnlicensedStatesList
  ]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      customStates:
        !state.allLicensedStates &&
        !state.allUnlicensedStates &&
        !state.allStates
    }));
  }, [state.allStates, state.allUnlicensedStates, state.allLicensedStates]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Invoice Charge`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Title</label>
                <Input
                  size="large"
                  value={state.invoiceCharge.title}
                  onChange={(_, { value }) => handleInputChange(value, "title")}
                  autoComplete="new-password"
                  data-automation="title"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Purchase</label>
                <TextArea
                  placeholder="Purchase"
                  style={{ minHeight: 192 }}
                  value={state.invoiceCharge.purchase}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      invoiceCharge: {
                        ...state.invoiceCharge,
                        purchase: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Refinance</label>
                <TextArea
                  placeholder="Refinance"
                  style={{ minHeight: 192 }}
                  value={state.invoiceCharge.refinance}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      invoiceCharge: {
                        ...state.invoiceCharge,
                        refinance: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <Checkbox
                  label="All states"
                  checked={state.allStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: true,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: false,
                      invoiceCharge: {
                        ...state.invoiceCharge,
                        invoiceChargeStates: buildInvoiceChargeStates(
                          state.allStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All licensed states"
                  checked={state.allLicensedStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: true,
                      allUnlicensedStates: false,
                      customStates: false,
                      invoiceCharge: {
                        ...state.invoiceCharge,
                        invoiceChargeStates: buildInvoiceChargeStates(
                          state.allLicensedStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="All unlicensed states"
                  checked={state.allUnlicensedStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: true,
                      customStates: false,
                      invoiceCharge: {
                        ...state.invoiceCharge,
                        invoiceChargeStates: buildInvoiceChargeStates(
                          state.allUnlicensedStatesList
                        )
                      }
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  label="Custom (choose your own state selection)"
                  checked={state.customStates}
                  size="large"
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      allStates: false,
                      allLicensedStates: false,
                      allUnlicensedStates: false,
                      customStates: true
                    }))
                  }
                />
              </Form.Field>
              {state.customStates && (
                <Form.Field required>
                  <label>States</label>
                  <Dropdown
                    size="large"
                    fluid
                    multiple
                    selection
                    placeholder="Select States"
                    style={{ fontSize: 16 }}
                    options={state.states}
                    value={
                      state.invoiceCharge.invoiceChargeStates
                        ? state.invoiceCharge.invoiceChargeStates.map(
                            (ics) => ics.stateId
                          )
                        : null
                    }
                    onChange={(_, { value }) => {
                      setState((state) => ({
                        ...state,
                        invoiceCharge: {
                          ...state.invoiceCharge,
                          invoiceChargeStates:
                            value[value.length - 1] === 52
                              ? buildInvoiceChargeStates([52])
                              : buildInvoiceChargeStates(
                                  value.filter((v) => v !== 52)
                                )
                        }
                      }));
                    }}
                    error={
                      state.submitAttempted &&
                      !state.invoiceCharge.invoiceChargeStates.length > 0
                    }
                  />
                </Form.Field>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>
                  Visible on Commitment Instructions
                  <Checkbox
                    value={!state.invoiceCharge.visibleOnCommitmentInstructions}
                    checked={
                      state.invoiceCharge.visibleOnCommitmentInstructions
                    }
                    onChange={() => {
                      setState((state) => ({
                        ...state,
                        invoiceCharge: {
                          ...state.invoiceCharge,
                          visibleOnCommitmentInstructions: !state.invoiceCharge
                            .visibleOnCommitmentInstructions
                        }
                      }));
                    }}
                    toggle
                    className={css({
                      verticalAlign: "bottom !important",
                      marginLeft: "8px !important",
                      "&.ui.toggle.checkbox input:checked ~ .box:before, &.ui.toggle.checkbox input:checked ~ label:before": {
                        backgroundColor: "#00b8d4 !important"
                      }
                    })}
                  />
                </label>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => {
                  saveChanges();
                }}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddInvoiceCharge);
