import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  Input,
  Message,
  TextArea
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { readFileAsBase64 } from "../../helpers/ReadFileAsBase64";
import FileUpload from "../FileUpload";
import shortid from "shortid";
import { logEvent } from "../../helpers/analyticsLogger";

export function EditAddSystemTips({ match, history }) {
  const defaultSystemTip = {
    subject: undefined,
    note: undefined
  };

  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [systemTip, setSystemTip] = useState(defaultSystemTip);
  const [editMode, setEditMode] = useState(
    match.path === "/training/edit-system-tip/:id"
  );
  const [files, setFiles] = useState([]);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [errorList, setErrorList] = useState([]);

  async function getSystemTipById(id) {
    const result = await getRequest(`/systemTips/getById`, {
      id
    });
    if (result.success) {
      setSystemTip(result.data);
    }
  }

  useEffect(() => {
    editMode && getSystemTipById(match.params.id);
  }, []);

  async function saveChanges() {
    if (!submitting) {
      setSubmitAttempted(true);
      setSubmitting(!errorList.length > 0);
      if (!errorList.length > 0) {
        const result = await postRequest(
          `/systemTips/${editMode ? "edit" : "add"}`,
          {
            ...systemTip,
            files: await Promise.all(
              files.map(async (f) => ({
                base64File: await readFileAsBase64(f),
                fileName: f.name
              }))
            ),
            filesToDelete: filesToDelete
          }
        );
        if (result.success) {
          logEvent(null, editMode ? "Edited" : "Added", "System Tips");
          history.goBack();
        }
      }
    }
  }

  function getErrorList() {
    let list = [];
    if (!systemTip.note) {
      list.push("You must provide a note.");
    }
    return list;
  }

  useEffect(() => {
    setErrorList(getErrorList());
  }, [systemTip]);

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${editMode ? "Edit" : "New"} System Tips`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  size="large"
                  value={systemTip.subject}
                  onChange={(_, { value }) =>
                    setSystemTip({ ...systemTip, subject: value })
                  }
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  value={systemTip.note}
                  style={{ minHeight: 192 }}
                  onChange={(_, { value }) =>
                    setSystemTip({ ...systemTip, note: value })
                  }
                  error={
                    submitAttempted &&
                    (systemTip.subject === "" ||
                      systemTip.subject === undefined)
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <FileUpload
                  fileList={files}
                  setFileToApp={(newFiles) => setFiles([...files, ...newFiles])}
                  deleteFileToApp={(fileName) =>
                    setFiles([...files.filter((f) => f.name !== fileName)])
                  }
                  multiple={true}
                />
              </Form.Field>
              {systemTip.files &&
                systemTip.files.map((f) => (
                  <p
                    key={shortid.generate()}
                    onClick={() => {
                      setFilesToDelete([...filesToDelete, f.id]);
                      setSystemTip({
                        ...systemTip,
                        files: [
                          ...systemTip.files.filter((rf) => rf.id !== f.id)
                        ]
                      });
                    }}
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={submitAttempted && errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

export default withRouter(EditAddSystemTips);
