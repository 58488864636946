import React, { useState, useEffect, useContext } from "react";
import WithholdingTax from "./WithholdingTax";
import MortgageTax from "./MortgageTax";
import TransferTax from "./TransferTax";
import { getRequest } from "../../../helpers/ApiHelpers";
import { StateContext } from "../../State";

export function Taxes() {
  const { selectedState } = useContext(StateContext);
 
  const [state, setState] = useState({
    withholdingTax: {},
    mortgageTax: {},
    transferTax: {},
    initialLoading: true
  });
  

  async function getTransferTaxByState() {
    const result = await getRequest("/tax/getTransferTaxByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({
        ...state,
        transferTax: result.data
      }));
    }
  }

  async function getMortgageTaxByState() {
    const result = await getRequest("/tax/getMortgageTaxByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({
        ...state,
        mortgageTax: result.data
      }));
    }
  }

  async function getWithholdingTaxByState() {
    const result = await getRequest("/tax/getWithholdingTaxByState", {
      stateId: selectedState
    });
    if (result.success) {
      setState(state => ({
        ...state,
        withholdingTax: result.data
      }));
    }
  }

  useEffect(() => {
    getTransferTaxByState();
    getWithholdingTaxByState();
    getMortgageTaxByState();
    setState({
      ...state,
      initialLoading: selectedState ? false : true
    });
  }, [selectedState]);

  if (!selectedState) {
    return <div />;
  }
  
  return (
    <React.Fragment>
      <WithholdingTax
        tax={state.withholdingTax}
        editRoute={`/rates/states/${selectedState}/edit-withholding-tax`}
      />
      <MortgageTax
        tax={state.mortgageTax}
        editRoute={`/rates/states/${selectedState}/edit-mortgage-tax`}
      />
      <TransferTax
        tax={state.transferTax}
        editRoute={`/rates/states/${selectedState}/edit-transfer-tax`}
      />
    </React.Fragment>
  );
}
