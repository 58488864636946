import React from "react";
import Card from "semantic-ui-react/dist/commonjs/views/Card";
import Pop from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import moment from "moment";

export default function StateIdiosyncrasies({ eventLogs, position }) {
  return (
    <Pop
      arrow={false}
      position="top center"
      on={["hover", "click"]}
      trigger={
        <i
          className="fal fa-history"
          style={{
            position: "absolute",
            cursor: "pointer",
            ...position,
          }}
        />
      }
      {...{
        contentStyle: {
          maxHeight: 350,
          overflowY: "auto",
          width: 275,
          backgroundColor: "#fff",
          padding: 16,
          borderRadius: 8,
          boxShadow:
            "0px 0px 0px 1px #d4d4d5, 0px 2px 4px 0px rgb(34 36 38 / 12%), 0px 2px 10px 0px rgb(34 36 38 / 15%);",
        },
      }}
    >
      <div>
        {eventLogs
          .sort(function(a, b) {
            return new Date(b.dateTime) - new Date(a.dateTime);
          })
          .map((e) => (
            <Card raised>
              <Card.Content>
                <Card.Header>{e.eventType}</Card.Header>
                <Card.Meta>
                  {moment(e.dateTime).format("MM/DD/YY h:mm a")}
                </Card.Meta>
                <Card.Meta>{e.username}</Card.Meta>
                <Card.Description>
                  {e.reason && (
                    <div>
                      <strong>Reason:</strong> {e.reason}
                    </div>
                  )}
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
      </div>
    </Pop>
  );
}
