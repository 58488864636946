import React from "react";
import TaxCard from "./TaxCard";
import { css } from "emotion";
import { Highlight } from "../../search/Highlight";
import { getRequest } from "../../../helpers/ApiHelpers";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap"
});

const labelStyles = css({
  fontFamily: "Fakt-Bold",
  fontSize: 14,
  color: "#707898",
  marginBottom: 14,
  marginTop: 24
});

async function download(id) {
  const result = await getRequest(`/tax/downloadWithholdingTaxFile?id=${id}`);
  if (result.success) {
    convertToFileDownload(result.data.base64File, result.data.name);
  }
}

function WithholdingTax({ tax, editRoute }) {
  return (
    <TaxCard
      title="Withholding Tax"
      isApplicable={tax.isApplicable}
      editRoute={editRoute}
    >
      <p className={paragraphStyles}>
        <Highlight children={tax.isApplicableNote} />
      </p>
      {tax.withholdingTaxFiles && tax.withholdingTaxFiles.length > 0 && (
        <div className={labelStyles}>Attachments</div>
      )}
      {tax.withholdingTaxFiles &&
        tax.withholdingTaxFiles.map(f => (
          <p className={paragraphStyles} key={f.id}>
            <a
              onClick={() => download(f.id)}
              className={css({
                "&:hover": { textDecoration: "underline", color: "#00b8d4" }
              })}
            >
              <i
                className="fal fa-paperclip"
                style={{ color: "#00b8d4", marginRight: 8 }}
              />
              <Highlight children={f.fileName} />
            </a>
          </p>
        ))}
    </TaxCard>
  );
}

export default React.memo(WithholdingTax);
