import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Input,
  Message
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { readFileAsBase64 } from "../../../helpers/ReadFileAsBase64";
import FileUpload from "../../FileUpload";
import shortid from "shortid";
import { logEvent } from "../../../helpers/analyticsLogger";

export function EditAddPolicySurveyNote({ match, history }) {
  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    note: {},
    editMode: match.path.includes("edit"),
    errorList: [],
    files: [],
    filesToDelete: []
  });

  async function getPolicySurveyNoteById(id) {
    const result = await getRequest(`/Survey/getPolicySurveyNoteById`, {
      id
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        note: result.data
      }));
    }
  }

  useEffect(() => {
    if (state.editMode) {
      getPolicySurveyNoteById(match.params.id);
    }
  }, []);

  useEffect(() => {
    setState((state) => ({ ...state, errorList: getErrorList() }));
  }, [state.note.subject, state.submitAttempted]);

  async function saveChanges() {
    const result = await postRequest(`/Survey/editAddPolicySurveyNote`, {
      ...state.note,
      stateId: state.editMode ? state.note.stateId : +match.params.state,
      files: await Promise.all(
        state.files.map(async (f) => ({
          base64File: await readFileAsBase64(f),
          fileName: f.name
        }))
      ),
      filesToDelete: state.filesToDelete
    });
    if (result.success) {
      logEvent(null, state.editMode ? "Edited" : "Added", "Policy Survey Note");
      history.goBack();
    }
  }

  function getErrorList() {
    let list = [];
    if (state.note.subject === null || state.note.subject === "") {
      list.push("You must provide a subject.");
    }
    return list;
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        {`${state.editMode ? "Edit" : "New"} Survey Note`}
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field required>
                <label>Subject</label>
                <Input
                  placeholder="Subject"
                  value={state.note.subject}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      note: {
                        ...state.note,
                        subject: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <label>Note</label>
                <TextArea
                  placeholder="Note"
                  style={{ minHeight: 292 }}
                  value={state.note.note}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      note: {
                        ...state.note,
                        note: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Form.Field>
                <FileUpload
                  fileList={state.files}
                  setFileToApp={(files) => {
                    setState((state) => ({
                      ...state,
                      files: [...state.files, ...files]
                    }));
                  }}
                  deleteFileToApp={(fileName) =>
                    setState((state) => ({
                      ...state,
                      files: [...state.files.filter((f) => f.name !== fileName)]
                    }))
                  }
                  multiple={true}
                />
              </Form.Field>
              {state.note.policySurveyNoteFiles &&
                state.note.policySurveyNoteFiles.map((f) => (
                  <p
                    key={f.id}
                    onClick={() =>
                      setState((state) => ({
                        ...state,
                        filesToDelete: [...state.filesToDelete, f.id],
                        note: {
                          ...state.note,
                          policySurveyNoteFiles: [
                            ...state.note.policySurveyNoteFiles.filter(
                              (nf) => nf.id !== f.id
                            )
                          ]
                        }
                      }))
                    }
                    className={css({
                      "&:hover": { color: "#707898" },
                      cursor: "pointer"
                    })}
                  >
                    <i
                      className="fal fa-trash-alt"
                      style={{ marginRight: 8 }}
                    />
                    {f.fileName}
                  </p>
                ))}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message
                visible={state.submitAttempted && state.errorList.length > 0}
                error
                header="There was some errors with your submission"
                list={state.errorList}
                data-automation="message"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditAddPolicySurveyNote);
