import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import { Modal, Button, Message } from "semantic-ui-react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import DragSurveySortCodes from "./DragSurveySortCodes";

function ExceptionCodes({ match: { params }, section, searchResults }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const userCanEdit = user.isAdmin || user.isOwnerPlus || user.isOwner;

  const [state, setState] = useState({
    initialLoading: true,
    noResults: false,
    codes: [],
    submitting: false,
    codeToDelete: null
  });

  const [surveyExceptionSections, setSurveyExceptionSections] = useState([]);

  async function getSurveyExceptionSections() {
    const result = await getRequest("/survey/getSurveyExceptionSections");
    if (result.success) {
      setSurveyExceptionSections(result.data);
    }
  }

  useEffect(() => {
    getSurveyExceptionSections();
  }, []);

  async function getSurveyExceptionCodes() {
    const result = await getRequest(`/survey/getSurveyExceptionCodes`);
    if (result.success) {
      setState((state) => ({
        ...state,
        codes: result.data
      }));
    }
  }

  useEffect(() => {
    // if (!searchResults) {
    getSurveyExceptionCodes();
    setState({
      ...state,
      initialLoading: !selectedState
    });
    // }
  }, [params.state, selectedState]);

  async function deleteCode(id) {
    const result = await postRequest(
      `/survey/deleteSurveyExceptionCode?id=${id}`,
      id
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        codes: state.codes.filter((c) => c.id !== id),
        codeToDelete: null
      }));
    }
  }

  const codes = searchResults ? searchResults : state.codes;

  return (
    <React.Fragment>
      {surveyExceptionSections.map((section) => (
        <React.Fragment>
          {codes.some((c) => c.surveyExceptionSectionId === section.id) && (
            <span
              className={css({
                textAlign: "left",
                color: "#47517B",
                fontSize: 16,
                fontFamily: "Fakt-Bold"
              })}
            >
              {section.name}
            </span>
          )}
          <DragSurveySortCodes
            items={codes.filter(
              (code) => code.surveyExceptionSectionId === section.id
            )}
            saveSortPath={`/survey/sortCodes`}
            draggable={userCanEdit && !searchResults}
            deleteAction={(value) =>
              setState((state) => ({ ...state, codeToDelete: value }))
            }
            section={section}
            searchMode={searchResults}
          />
        </React.Fragment>
      ))}
      {codes.length < 1 && <Message size="big">No codes available</Message>}
      {state.codeToDelete && (
        <Modal
          size={"tiny"}
          open={state.codeToDelete !== null}
          onClose={() =>
            setState((state) => ({ ...state, codeToDelete: null }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Delete Code</Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete code{" "}
              <strong>{state.codeToDelete.code}</strong>?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() =>
                setState((state) => ({ ...state, codeToDelete: null }))
              }
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              No
            </Button>
            <Button
              positive
              onClick={() => deleteCode(state.codeToDelete.id)}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(React.memo(ExceptionCodes));
