import React, { useState, useEffect, useContext } from "react";
import { getRequest, postRequest } from "../../../helpers/ApiHelpers";
import { css } from "emotion";
import {
  Button,
  Header,
  Grid,
  Form,
  TextArea,
  Input,
  Divider
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { StateContext } from "../../State";
import { logEvent } from "../../../helpers/analyticsLogger";

const sectionHeaderStyles = css({
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  marginBottom: 17,
  lineHeight: 1.71
});

export function EditGeneralInfo({ match, history }) {
  const { selectedState, states } = useContext(StateContext);

  const isLiscensed =
    states.length > 0 && states.find((s) => s.id === selectedState)
      ? states.find((s) => s.id === selectedState).isLicensed
      : undefined;

  const [state, setState] = useState({
    submitAttempted: false,
    submitting: false,
    generalInfo: {}
  });

  async function getGeneralInfoByState(stateId) {
    const result = await getRequest(`/generalInfo/getGeneralInfoByState`, {
      stateId
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        generalInfo: result.data
      }));
    }
  }

  useEffect(() => {
    getGeneralInfoByState(selectedState);
  }, []);

  async function saveChanges() {
    if (!state.submitting) {
      setState((state) => ({ ...state, submitting: true }));
      const result = await postRequest("/generalInfo/editGeneralInfo", {
        ...state.generalInfo,
        stateId: selectedState
      });
      if (result.success) {
        logEvent(null, state.editMode ? "Edited" : "Added", "General Info");
        history.goBack();
      }
    }
  }

  return (
    <div className={css({ width: 605, paddingBottom: 34 })}>
      <Header style={{ fontSize: 24, marginBottom: 41 }} as="h3">
        General Info
      </Header>
      <Form>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <div className={sectionHeaderStyles}>
                  {isLiscensed ? "Licensed Note" : "Unlicensed Note"}
                </div>
                <TextArea
                  placeholder={
                    isLiscensed ? "Licensed Note" : "Unlicensed Note"
                  }
                  style={{ minHeight: 192 }}
                  value={state.generalInfo.licensedAndUnlicensed}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        licensedAndUnlicensed: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className={sectionHeaderStyles}>Templates</div>
              <Form.Field>
                <label>Purchase</label>
                <Input
                  className={css({ marginBottom: 16 })}
                  placeholder="Purchase"
                  value={state.generalInfo.purchase}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        purchase: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Refinance</label>
                <Input
                  className={css({ marginBottom: 16 })}
                  placeholder="Refinance"
                  value={state.generalInfo.refinance}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        refinance: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Search Only</label>
                <Input
                  className={css({ marginBottom: 40 })}
                  placeholder="Search Only"
                  value={state.generalInfo.searchOnly}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        searchOnly: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Residential Purchase (CDF)</label>
                <Input
                  className={css({ marginBottom: 16 })}
                  placeholder="Residential Purchase (CDF)"
                  value={state.generalInfo.residentialPurchaseCDF}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        residentialPurchaseCDF: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Residential Purchase (HUD)</label>
                <Input
                  className={css({ marginBottom: 16 })}
                  placeholder="Residential Purchase (HUD)"
                  value={state.generalInfo.residentialPurchaseHUD}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        residentialPurchaseHUD: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Residential Cash Purchase</label>
                <Input
                  className={css({ marginBottom: 40 })}
                  placeholder="Residential Cash Purchase"
                  value={state.generalInfo.residentialCashPurchase}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        residentialCashPurchase: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Residential Refi (CDF)</label>
                <Input
                  className={css({ marginBottom: 16 })}
                  placeholder="Residential Refi (CDF)"
                  value={state.generalInfo.residentialRefiCDF}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        residentialRefiCDF: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Residential Refi (HUD)</label>
                <Input
                  className={css({ marginBottom: 16 })}
                  placeholder="Residential Refi (HUD)"
                  value={state.generalInfo.residentialRefiHUD}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        residentialRefiHUD: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>Residential Search Only</label>
                <Input
                  placeholder="Residential Search Only"
                  value={state.generalInfo.residentialSearchOnly}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        residentialSearchOnly: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Form.Field>
                <div className={sectionHeaderStyles}>State Specific Rules</div>
                <TextArea
                  placeholder="State Specific Rules"
                  style={{ minHeight: 192 }}
                  value={state.generalInfo.stateRules}
                  onChange={(_, { value }) =>
                    setState((state) => ({
                      ...state,
                      generalInfo: {
                        ...state.generalInfo,
                        stateRules: value
                      }
                    }))
                  }
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column
              floated="right"
              className={css({ textAlign: "right" })}
            >
              <Button
                size="large"
                className="cancelButton"
                onClick={() => history.goBack()}
                data-automation="cancel"
              >
                Cancel
              </Button>
              <Button
                size="large"
                className="saveButton"
                onClick={() => saveChanges()}
                data-automation="save"
              >
                {state.submitting ? "Submitting..." : "Save"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </div>
  );
}

withRouter(EditGeneralInfo);
