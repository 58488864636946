import React, { useState, useContext, useEffect } from "react";
import { css } from "emotion";
import { Unavailable } from "./../../Unavailable";
import { Highlight } from "../../search/Highlight";
import { getRequest } from "../../../helpers/ApiHelpers";
import { convertToFileDownload } from "../../../helpers/ConvertToFileDownload";
import { Button, Card, Grid, Header } from "semantic-ui-react";
import { UserContext } from "../../User";
import { StateContext } from "../../State";
import { withRouter } from "react-router-dom";

const paragraphStyles = css({
  color: "#000",
  fontSize: 14,
  lineHeight: 1.5,
  whiteSpace: "pre-wrap"
});
const labelStyles = css({
  fontSize: 14,
  color: "#707898",
  marginBottom: 14,
  marginTop: 24
});

function getAnswer(q) {
  return q === true ? "YES" : q === false ? "NO" : <Unavailable />;
}

async function download(id, path) {
  const result = await getRequest(`/lien/${path}?id=${id}`);
  if (result.success) {
    convertToFileDownload(result.data.base64File, result.data.name);
  }
}

function Liens({ match, history, lienSearchResults, editable }) {
  const { user } = useContext(UserContext);
  const { selectedState } = useContext(StateContext);
  const [lien, setLien] = useState(lienSearchResults || {});

  async function getLienByState(stateId) {
    const result = await getRequest(`/lien/getLienByState`, {
      stateId
    });
    if (result.success) {
      setLien(result.data);
    }
  }

  useEffect(() => {
    if (!lienSearchResults) {
      getLienByState(selectedState);
    }
  }, []);

  useEffect(() => {
    if (!lienSearchResults) {
      getLienByState(selectedState);
    }
  }, [selectedState]);

  return (
    <div
      className={css({
        "& .ui .card": {
          marginLeft: "0px !important",
          marginRight: "0px !important"
        }
      })}
    >
      <Card.Group itemsPerRow={1}>
        <Card>
          <Card.Content>
            <Grid>
              <Grid.Row columns={2} verticalAlign="middle">
                <Grid.Column
                  floated="right"
                  className={css({ textAlign: "right" })}
                >
                  {editable &&
                    (user.isOwner || user.isOwnerPlus || user.isAdmin) && (
                      <Button
                        icon
                        onClick={() =>
                          history.push(
                            `/info/states/${selectedState}/edit-lien`
                          )
                        }
                      >
                        <i className="fal fa-pencil" />
                      </Button>
                    )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div
              className={css({
                marginTop: 24
              })}
            >
              <Header
                as="h3"
                className={css({
                  color: "#47517b !important",
                  fontSize: "20px !important",
                  marginBottom: "0px !important"
                })}
              >
                Water/Sewer
              </Header>
              <div className={labelStyles}>Is it lienable?</div>
              <p className={paragraphStyles}>
                {getAnswer(lien.waterSewerIsItLienable)}
                {lien.waterSewerNote && " - "}
                <Highlight children={lien.waterSewerNote} />
              </p>
              {lien.waterSewerFiles && lien.waterSewerFiles.length > 0 && (
                <div className={labelStyles}>Attachments</div>
              )}
              {lien.waterSewerFiles &&
                lien.waterSewerFiles.map(f => (
                  <p className={paragraphStyles} key={f.id}>
                    <a
                      onClick={() => download(f.id, "downloadWaterSewerFile")}
                      className={css({
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#00b8d4"
                        }
                      })}
                    >
                      <i
                        className="fal fa-paperclip"
                        style={{ color: "#00b8d4", marginRight: 8 }}
                      />
                      <Highlight children={f.fileName} />
                    </a>
                  </p>
                ))}
              <Header
                as="h3"
                className={css({
                  color: "#47517b !important",
                  fontSize: "20px !important",
                  marginBottom: "0px !important"
                })}
              >
                Real estate property taxes
              </Header>
              <div className={labelStyles}>Duration:</div>
              <p className={paragraphStyles}>
                {!lien.realEstatePropertyTaxesDuration &&
                  !lien.realEstatePropertyTaxesNote && <Unavailable />}
                {lien.realEstatePropertyTaxesDuration}
                {lien.realEstatePropertyTaxesDuration && " - "}
                <Highlight children={lien.realEstatePropertyTaxesNote} />
              </p>
              {lien.realEstatePropertyTaxesFiles &&
                lien.realEstatePropertyTaxesFiles.length > 0 && (
                  <div className={labelStyles}>Attachments</div>
                )}
              {lien.realEstatePropertyTaxesFiles &&
                lien.realEstatePropertyTaxesFiles.map(f => (
                  <p className={paragraphStyles} key={f.id}>
                    <a
                      onClick={() =>
                        download(f.id, "downloadRealEstatePropertyTaxesFile")
                      }
                      className={css({
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#00b8d4"
                        }
                      })}
                    >
                      <i
                        className="fal fa-paperclip"
                        style={{ color: "#00b8d4", marginRight: 8 }}
                      />
                      <Highlight children={f.fileName} />
                    </a>
                  </p>
                ))}
              <Header
                as="h3"
                className={css({
                  color: "#47517b !important",
                  fontSize: "20px !important",
                  marginBottom: "0px !important"
                })}
              >
                Personal property taxes
              </Header>
              <div className={labelStyles}>Is it lienable?</div>
              <p className={paragraphStyles}>
                {getAnswer(lien.personalPropertyTaxesIsItLienable)}
                {lien.personalPropertyTaxesNote && " - "}
                <Highlight children={lien.personalPropertyTaxesNote} />
              </p>
              {lien.personalPropertyTaxesFiles &&
                lien.personalPropertyTaxesFiles.length > 0 && (
                  <div className={labelStyles}>Attachments</div>
                )}
              {lien.personalPropertyTaxesFiles &&
                lien.personalPropertyTaxesFiles.map(f => (
                  <p className={paragraphStyles} key={f.id}>
                    <a
                      onClick={() =>
                        download(f.id, "downloadPersonalPropertyTaxesFile")
                      }
                      className={css({
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#00b8d4"
                        }
                      })}
                    >
                      <i
                        className="fal fa-paperclip"
                        style={{ color: "#00b8d4", marginRight: 8 }}
                      />
                      <Highlight children={f.fileName} />
                    </a>
                  </p>
                ))}
              <Header
                as="h3"
                className={css({
                  color: "#47517b !important",
                  fontSize: "20px !important",
                  marginBottom: "24px !important"
                })}
              >
                Mechanic’s Liens/Construction
              </Header>
              <p className={paragraphStyles}>
                {lien.mechanicsLienConstructionNote ? (
                  <Highlight children={lien.mechanicsLienConstructionNote} />
                ) : (
                  <Unavailable />
                )}
              </p>
              {lien.mechanicsLienConstructionFiles &&
                lien.mechanicsLienConstructionFiles.length > 0 && (
                  <div className={labelStyles}>Attachments</div>
                )}
              {lien.mechanicsLienConstructionFiles &&
                lien.mechanicsLienConstructionFiles.map(f => (
                  <p className={paragraphStyles} key={f.id}>
                    <a
                      onClick={() =>
                        download(f.id, "downloadMechanicsLienConstructionFile")
                      }
                      className={css({
                        "&:hover": {
                          textDecoration: "underline",
                          color: "#00b8d4"
                        }
                      })}
                    >
                      <i
                        className="fal fa-paperclip"
                        style={{ color: "#00b8d4", marginRight: 8 }}
                      />
                      <Highlight children={f.fileName} />
                    </a>
                  </p>
                ))}
            </div>
          </Card.Content>
        </Card>
      </Card.Group>
    </div>
  );
}

export default React.memo(withRouter(Liens));
