import React, { useState, useEffect, useContext } from "react";
import { css } from "emotion";
import {
  Button,
  Grid,
  Form,
  Modal,
  Radio,
  Divider,
  Header,
} from "semantic-ui-react";
import { getRequest, postRequest } from "../../../../helpers/ApiHelpers";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../../User";
import { StateContext } from "../../../State";
import { convertToFileDownload } from "../../../../helpers/ConvertToFileDownload";
import RemittanceTargetTable from "./RemittanceTargetTable";
const linkStyles = css({
  color: "#00B8D4",
  fontSize: 14,
  fontFamily: "Fakt-Bold",
  cursor: "pointer",
  "&:hover": { opacity: 0.8 },
  marginRight: 16,
});

const addNotelinkStyles = css({
  fontSize: 14,
  lineHeight: 1.71,
  color: "#00b8d4",
  marginRight: 32,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});
function RemittanceTargets({ history, match: { params } }) {
  const { user } = useContext(UserContext);
  const { selectedState, states } = useContext(StateContext);

  const [state, setState] = useState({
    initialLoading: true,
    noResults: false,
    remittanceTargets: [],
    remittances: [],
    remittanceTargetNotes: [],
    remittanceToEdit: {},
    remittanceToAdd: {},
    noteToDelete: null,
    submitting: false,
    confirmClearTable: false,
    clearAllRemittances: false,
    confirmExport: false,
    remittanceToDelete: false,
    exportAllRemittances: false,
    additionalRemittanceTargets: [],
    additionalRemittances: [],
    additionalRemittanceTargetNotes: [],
    additionalRemittanceToEdit: {},
    additionalRemittanceToAdd: {},
    additionalRemittanceToDelete: false,
    additionalNoteToDelete: null,
    additionalOverTenMRemittanceTargets: [],
    additionalOverTenMRemittances: [],
    additionalOverTenMRemittanceTargetNotes: [],
    additionalOverTenMRemittanceToEdit: {},
    additionalOverTenMRemittanceToAdd: {},
    additionalOverTenMRemittanceToDelete: false,
    additionalOverTenMNoteToDelete: null,

    additionalOverFifteenMRemittanceTargets: [],
    additionalOverFifteenMRemittances: [],
    additionalOverFifteenMRemittanceTargetNotes: [],
    additionalOverFifteenMRemittanceToEdit: {},
    additionalOverFifteenMRemittanceToAdd: {},
    additionalOverFifteenMRemittanceToDelete: false,
    additionalOverFifteenMNoteToDelete: null,

    additionalOverTwentyFiveMRemittanceTargets: [],
    additionalOverTwentyFiveMRemittances: [],
    additionalOverTwentyFiveMRemittanceTargetNotes: [],
    additionalOverTwentyFiveMRemittanceToEdit: {},
    additionalOverTwentyFiveMRemittanceToAdd: {},
    additionalOverTwentyFiveMRemittanceToDelete: false,
    additionalOverTwentyFiveMNoteToDelete: null,
  });

  async function getRemittanceTargetsByState() {
    const result = await getRequest(
      "/remittanceTarget/getRemittanceTargetsByState",
      {
        id: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargets: result.data.map((r) => ({
          ...r,
          remittances: r.remittances.reverse(),
        })),
        initialLoading: false,
      }));
    }
  }

  async function getAdditionalRemittanceTargetsByState() {
    const result = await getRequest(
      "/additionalRemittanceTarget/getAdditionalRemittanceTargetsByState",
      {
        id: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalRemittanceTargets: result.data.map((r) => ({
          ...r,
          additionalRemittances: r.additionalRemittances.reverse(),
        })),
        initialLoading: false,
      }));
    }
  }

  async function getAdditionalOverTenMRemittanceTargetsByState() {
    const result = await getRequest(
      "/additionalOverTenMRemittanceTarget/getAdditionalOverTenMRemittanceTargetsByState",
      {
        id: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTenMRemittanceTargets: result.data.map((r) => ({
          ...r,
          additionalOverTenMRemittances: r.additionalOverTenMRemittances.reverse(),
        })),
        initialLoading: false,
      }));
    }
  }

  async function getAdditionalOverFifteenMRemittanceTargetsByState() {
    const result = await getRequest(
      "/additionalOverFifteenMRemittanceTarget/getAdditionalOverFifteenMRemittanceTargetsByState",
      {
        id: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverFifteenMRemittanceTargets: result.data.map((r) => ({
          ...r,
          additionalOverFifteenMRemittances: r.additionalOverFifteenMRemittances.reverse(),
        })),
        initialLoading: false,
      }));
    }
  }

  async function getAdditionalOverTwentyFiveMRemittanceTargetsByState() {
    const result = await getRequest(
      "/additionalOverTwentyFiveMRemittanceTarget/getAdditionalOverTwentyFiveMRemittanceTargetsByState",
      {
        id: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTwentyFiveMRemittanceTargets: result.data.map((r) => ({
          ...r,
          additionalOverTwentyFiveMRemittances: r.additionalOverTwentyFiveMRemittances.reverse(),
        })),
        initialLoading: false,
      }));
    }
  }

  async function getRemittancesByState() {
    const result = await getRequest("/remittanceTarget/getRemittancesByState", {
      stateId: selectedState,
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        remittances: result.data,
      }));
    }
  }

  async function getAdditionalRemittancesByState() {
    const result = await getRequest(
      "/additionalRemittanceTarget/getAdditionalRemittancesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalRemittances: result.data,
      }));
    }
  }

  async function getAdditionalOverTenMRemittancesByState() {
    const result = await getRequest(
      "/additionalOverTenMRemittanceTarget/getAdditionalOverTenMRemittancesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTenMRemittances: result.data,
      }));
    }
  }

  async function getAdditionalOverFifteenMRemittancesByState() {
    const result = await getRequest(
      "/additionalOverFifteenMRemittanceTarget/getAdditionalOverFifteenMRemittancesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverFifteenMRemittances: result.data,
      }));
    }
  }

  async function getAdditionalOverTwentyFiveMRemittancesByState() {
    const result = await getRequest(
      "/additionalOverTwentyFiveMRemittanceTarget/getAdditionalOverTwentyFiveMRemittancesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTwentyFiveMRemittances: result.data,
      }));
    }
  }

  async function getRemittanceTargetNotesByState() {
    const result = await getRequest(
      "/remittanceTarget/getRemittanceTargetNotesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargetNotes: result.data,
      }));
    }
  }

  async function getAdditionalRemittanceTargetNotesByState() {
    const result = await getRequest(
      "/additionalRemittanceTarget/getAdditionalRemittanceTargetNotesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalRemittanceTargetNotes: result.data,
      }));
    }
  }

  async function getAdditionalOverTenMRemittanceTargetNotesByState() {
    const result = await getRequest(
      "/additionalOverTenMRemittanceTarget/getAdditionalOverTenMRemittanceTargetNotesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTenMRemittanceTargetNotes: result.data,
      }));
    }
  }

  async function getAdditionalOverFifteenMRemittanceTargetNotesByState() {
    const result = await getRequest(
      "/additionalOverFifteenMRemittanceTarget/getAdditionalOverFifteenMRemittanceTargetNotesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverFifteenMRemittanceTargetNotes: result.data,
      }));
    }
  }

  async function getAdditionalOverTwentyFiveMRemittanceTargetNotesByState() {
    const result = await getRequest(
      "/additionalOverTwentyFiveMRemittanceTarget/getAdditionalOverTwentyFiveMRemittanceTargetNotesByState",
      {
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTwentyFiveMRemittanceTargetNotes: result.data,
      }));
    }
  }

  async function addRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest("/remittanceTarget/addRemittance", {
      ...state.remittanceToAdd,
      stateId: selectedState,
    });
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceToAdd: {},
        remittanceTargets: state.remittanceTargets.map((t) =>
          t.id === state.remittanceToAdd.remittanceTargetId
            ? { ...t, remittances: [result.data, ...t.remittances] }
            : t
        ),
        remittances: [...state.remittances, result.data],
        submitting: false,
      }));
    }
  }

  async function addAdditionalRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/additionalRemittanceTarget/addAdditionalRemittance",
      {
        ...state.additionalRemittanceToAdd,
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalRemittanceToAdd: {},
        additionalRemittanceTargets: state.additionalRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalRemittanceToAdd.additionalRemittanceTargetId
              ? {
                  ...t,
                  additionalRemittances: [
                    result.data,
                    ...t.additionalRemittances,
                  ],
                }
              : t
        ),
        additionalRemittances: [...state.additionalRemittances, result.data],
        submitting: false,
      }));
    }
  }

  async function addAdditionalOverTenMRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/additionalOverTenMRemittanceTarget/addAdditionalOverTenMRemittance",
      {
        ...state.additionalOverTenMRemittanceToAdd,
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTenMRemittanceToAdd: {},
        additionalOverTenMRemittanceTargets: state.additionalOverTenMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverTenMRemittanceToAdd
              .additionalOverTenMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverTenMRemittances: [
                    result.data,
                    ...t.additionalOverTenMRemittances,
                  ],
                }
              : t
        ),
        additionalOverTenMRemittances: [
          ...state.additionalOverTenMRemittances,
          result.data,
        ],
        submitting: false,
      }));
    }
  }

  async function addAdditionalOverFifteenMRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/additionalOverFifteenMRemittanceTarget/addAdditionalOverFifteenMRemittance",
      {
        ...state.additionalOverFifteenMRemittanceToAdd,
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverFifteenMRemittanceToAdd: {},
        additionalOverFifteenMRemittanceTargets: state.additionalOverFifteenMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverFifteenMRemittanceToAdd
              .additionalOverFifteenMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverFifteenMRemittances: [
                    result.data,
                    ...t.additionalOverFifteenMRemittances,
                  ],
                }
              : t
        ),
        additionalOverFifteenMRemittances: [
          ...state.additionalOverFifteenMRemittances,
          result.data,
        ],
        submitting: false,
      }));
    }
  }

  async function addAdditionalOverTwentyFiveMRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/additionalOverTwentyFiveMRemittanceTarget/addAdditionalOverTwentyFiveMRemittance",
      {
        ...state.additionalOverTwentyFiveMRemittanceToAdd,
        stateId: selectedState,
      }
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTwentyFiveMRemittanceToAdd: {},
        additionalOverTwentyFiveMRemittanceTargets: state.additionalOverTwentyFiveMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverTwentyFiveMRemittanceToAdd
              .additionalOverTwentyFiveMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverTwentyFiveMRemittances: [
                    result.data,
                    ...t.additionalOverTwentyFiveMRemittances,
                  ],
                }
              : t
        ),
        additionalOverTwentyFiveMRemittances: [
          ...state.additionalOverTwentyFiveMRemittances,
          result.data,
        ],
        submitting: false,
      }));
    }
  }

  async function clearRemittances() {
    if (state.clearAllRemittances) {
      const result = await postRequest(`/remittanceTarget/clearAllRemittances`);
      const additionalResult = await postRequest(
        `/additionalRemittanceTarget/clearAllAdditionalRemittances`
      );
      const additionalOverTenMResult = await postRequest(
        `/additionalOverTenMRemittanceTarget/clearAllAdditionalOverTenMRemittances`
      );
      const additionalOverFifteenMResult = await postRequest(
        `/additionalOverFifteenMRemittanceTarget/clearAllAdditionalOverFifteenMRemittances`
      );
      const additionalOverTwentyFiveMResult = await postRequest(
        `/additionalOverTwentyFiveMRemittanceTarget/clearAllAdditionalOverTwentyFiveMRemittances`
      );
      if (result.success) {
        setState((state) => ({ ...state, remittances: [] }));
        getRemittanceTargetsByState();
      }
      if (additionalResult.success) {
        setState((state) => ({ ...state, additionalRemittances: [] }));
        getAdditionalRemittanceTargetsByState();
      }
      if (additionalOverTenMResult.success) {
        setState((state) => ({
          ...state,
          additionalOverTenMRemittances: [],
        }));
        getAdditionalOverTenMRemittanceTargetsByState();
      }
      if (additionalOverFifteenMResult.success) {
        setState((state) => ({
          ...state,
          additionalOverFifteenMRemittances: [],
        }));
        getAdditionalOverFifteenMRemittanceTargetsByState();
      }
      if (additionalOverTwentyFiveMResult.success) {
        setState((state) => ({
          ...state,
          additionalOverTwentyFiveMRemittances: [],
        }));
        getAdditionalOverTwentyFiveMRemittanceTargetsByState();
      }
    } else {
      const result = await postRequest(
        `/remittanceTarget/clearRemittancesByState?stateId=${selectedState}`
      );
      const additionalResult = await postRequest(
        `/additionalRemittanceTarget/clearAdditionalRemittancesByState?stateId=${selectedState}`
      );
      const additionalOverTenMResult = await postRequest(
        `/additionalOverTenMRemittanceTarget/clearAdditionalOverTenMRemittancesByState?stateId=${selectedState}`
      );
      const additionalOverFifteenMResult = await postRequest(
        `/additionalOverFifteenMRemittanceTarget/clearAdditionalOverFifteenMRemittancesByState?stateId=${selectedState}`
      );
      const additionalOverTwentyFiveMResult = await postRequest(
        `/additionalOverTwentyFiveMRemittanceTarget/clearAdditionalOverTwentyFiveMRemittancesByState?stateId=${selectedState}`
      );
      if (result.success) {
        setState((state) => ({ ...state, remittances: [] }));
        getRemittanceTargetsByState();
      }
      if (additionalResult.success) {
        setState((state) => ({ ...state, additionalRemittances: [] }));
        getAdditionalRemittanceTargetsByState();
      }
      if (additionalOverTenMResult.success) {
        setState((state) => ({
          ...state,
          additionalOverTenMRemittances: [],
        }));
        getAdditionalOverTenMRemittanceTargetsByState();
      }
      if (additionalOverFifteenMResult.success) {
        setState((state) => ({
          ...state,
          additionalOverFifteenMRemittances: [],
        }));
        getAdditionalOverFifteenMRemittanceTargetsByState();
      }
      if (additionalOverTwentyFiveMResult.success) {
        setState((state) => ({
          ...state,
          additionalOverTwentyFiveMRemittances: [],
        }));
        getAdditionalOverTwentyFiveMRemittanceTargetsByState();
      }
    }
  }

  async function editRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/remittanceTarget/editRemittance",
      state.remittanceToEdit
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceToEdit: {},
        remittanceTargets: state.remittanceTargets.map((t) =>
          t.id === state.remittanceToEdit.remittanceTargetId
            ? {
                ...t,
                remittances: t.remittances.map((r) =>
                  r.id === state.remittanceToEdit.id ? result.data : r
                ),
              }
            : t
        ),
        remittances: state.remittances.map((r) =>
          r.id === state.remittanceToEdit.id ? result.data : r
        ),
        submitting: false,
      }));
    }
  }

  async function editAdditionalRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/additionalRemittanceTarget/editAdditionalRemittance",
      state.additionalRemittanceToEdit
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalRemittanceToEdit: {},
        additionalRemittanceTargets: state.additionalRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalRemittanceToEdit.additionalRemittanceTargetId
              ? {
                  ...t,
                  additionalRemittances: t.additionalRemittances.map((r) =>
                    r.id === state.additionalRemittanceToEdit.id
                      ? result.data
                      : r
                  ),
                }
              : t
        ),
        additionalRemittances: state.additionalRemittances.map((r) =>
          r.id === state.additionalRemittanceToEdit.id ? result.data : r
        ),
        submitting: false,
      }));
    }
  }

  async function editAdditionalOverTenMRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/additionalOverTenMRemittanceTarget/editAdditionalOverTenMRemittance",
      state.additionalOverTenMRemittanceToEdit
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTenMRemittanceToEdit: {},
        additionalOverTenMRemittanceTargets: state.additionalOverTenMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverTenMRemittanceToEdit
              .additionalOverTenMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverTenMRemittances: t.additionalOverTenMRemittances.map(
                    (r) =>
                      r.id === state.additionalOverTenMRemittanceToEdit.id
                        ? result.data
                        : r
                  ),
                }
              : t
        ),
        additionalOverTenMRemittances: state.additionalOverTenMRemittances.map(
          (r) =>
            r.id === state.additionalOverTenMRemittanceToEdit.id
              ? result.data
              : r
        ),
        submitting: false,
      }));
    }
  }

  async function editAdditionalOverFifteenMRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/additionalOverFifteenMRemittanceTarget/editAdditionalOverFifteenMRemittance",
      state.additionalOverFifteenMRemittanceToEdit
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverFifteenMRemittanceToEdit: {},
        additionalOverFifteenMRemittanceTargets: state.additionalOverFifteenMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverFifteenMRemittanceToEdit
              .additionalOverFifteenMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverFifteenMRemittances: t.additionalOverFifteenMRemittances.map(
                    (r) =>
                      r.id === state.additionalOverFifteenMRemittanceToEdit.id
                        ? result.data
                        : r
                  ),
                }
              : t
        ),
        additionalOverFifteenMRemittances: state.additionalOverFifteenMRemittances.map(
          (r) =>
            r.id === state.additionalOverFifteenMRemittanceToEdit.id
              ? result.data
              : r
        ),
        submitting: false,
      }));
    }
  }

  async function editAdditionalOverTwentyFiveMRemittance() {
    setState((state) => ({
      ...state,
      submitting: true,
    }));
    const result = await postRequest(
      "/additionalOverTwentyFiveMRemittanceTarget/editAdditionalOverTwentyFiveMRemittance",
      state.additionalOverTwentyFiveMRemittanceToEdit
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTwentyFiveMRemittanceToEdit: {},
        additionalOverTwentyFiveMRemittanceTargets: state.additionalOverTwentyFiveMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverTwentyFiveMRemittanceToEdit
              .additionalOverTwentyFiveMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverTwentyFiveMRemittances: t.additionalOverTwentyFiveMRemittances.map(
                    (r) =>
                      r.id ===
                      state.additionalOverTwentyFiveMRemittanceToEdit.id
                        ? result.data
                        : r
                  ),
                }
              : t
        ),
        additionalOverTwentyFiveMRemittances: state.additionalOverTwentyFiveMRemittances.map(
          (r) =>
            r.id === state.additionalOverTwentyFiveMRemittanceToEdit.id
              ? result.data
              : r
        ),
        submitting: false,
      }));
    }
  }

  async function downloadRemittanceExport() {
    const result = await getRequest(
      `/remittanceTarget/downloadRemittanceExport?allStates=${state.exportAllRemittances}&stateId=${selectedState}`
    );
    if (result.success) {
      convertToFileDownload(
        result.data.base64File,
        `Remittance_Targets_${new Date().toLocaleDateString()}.xlsx`
      );
    }
  }

  async function deleteRemittanceTargetNote(id) {
    const result = await postRequest(
      `/remittanceTarget/deleteRemittanceTargetNote?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargetNotes: state.remittanceTargetNotes.filter(
          (n) => n.id !== id
        ),
        noteToDelete: null,
      }));
    }
  }

  async function deleteAdditionalRemittanceTargetNote(id) {
    const result = await postRequest(
      `/additionalRemittanceTarget/deleteAdditionalRemittanceTargetNote?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalRemittanceTargetNotes: state.additionalRemittanceTargetNotes.filter(
          (n) => n.id !== id
        ),
        additionalNoteToDelete: null,
      }));
    }
  }

  async function deleteAdditionalOverTenMRemittanceTargetNote(id) {
    const result = await postRequest(
      `/additionalOverTenMRemittanceTarget/deleteAdditionalOverTenMRemittanceTargetNote?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTenMRemittanceTargetNotes: state.additionalOverTenMRemittanceTargetNotes.filter(
          (n) => n.id !== id
        ),
        additionalOverTenMNoteToDelete: null,
      }));
    }
  }

  async function deleteAdditionalOverFifteenMRemittanceTargetNote(id) {
    const result = await postRequest(
      `/additionalOverFifteenMRemittanceTarget/deleteAdditionalOverFifteenMRemittanceTargetNote?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverFifteenMRemittanceTargetNotes: state.additionalOverFifteenMRemittanceTargetNotes.filter(
          (n) => n.id !== id
        ),
        additionalOverFifteenMNoteToDelete: null,
      }));
    }
  }

  async function deleteAdditionalOverTwentyFiveMRemittanceTargetNote(id) {
    const result = await postRequest(
      `/additionalOverTwentyFiveMRemittanceTarget/deleteAdditionalOverTwentyFiveMRemittanceTargetNote?id=${id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTwentyFiveMRemittanceTargetNotes: state.additionalOverTwentyFiveMRemittanceTargetNotes.filter(
          (n) => n.id !== id
        ),
        additionalOverTwentyFiveMNoteToDelete: null,
      }));
    }
  }

  async function deleteRemittance() {
    const result = await postRequest(
      `/remittanceTarget/deleteRemittance?id=${state.remittanceToDelete.id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        remittanceTargets: state.remittanceTargets.map((t) =>
          t.id === state.remittanceToDelete.remittanceTargetId
            ? {
                ...t,
                remittances: t.remittances.filter(
                  (r) => r.id !== state.remittanceToDelete.id
                ),
              }
            : t
        ),
        remittances: state.remittances.filter(
          (r) => r.id !== state.remittanceToDelete.id
        ),
        remittanceToDelete: null,
      }));
    }
  }

  async function deleteAdditionalRemittance() {
    const result = await postRequest(
      `/additionalRemittanceTarget/deleteAdditionalRemittance?id=${state.additionalRemittanceToDelete.id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalRemittanceTargets: state.additionalRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalRemittanceToDelete.additionalRemittanceTargetId
              ? {
                  ...t,
                  additionalRemittances: t.additionalRemittances.filter(
                    (r) => r.id !== state.additionalRemittanceToDelete.id
                  ),
                }
              : t
        ),
        additionalRemittances: state.additionalRemittances.filter(
          (r) => r.id !== state.additionalRemittanceToDelete.id
        ),
        additionalRemittanceToDelete: null,
      }));
    }
  }

  async function deleteAdditionalOverTenMRemittance() {
    const result = await postRequest(
      `/additionalOverTenMRemittanceTarget/deleteAdditionalOverTenMRemittance?id=${state.additionalOverTenMRemittanceToDelete.id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTenMRemittanceTargets: state.additionalOverTenMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverTenMRemittanceToDelete
              .additionalOverTenMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverTenMRemittances: t.additionalOverTenMRemittances.filter(
                    (r) =>
                      r.id !== state.additionalOverTenMRemittanceToDelete.id
                  ),
                }
              : t
        ),
        additionalOverTenMRemittances: state.additionalOverTenMRemittances.filter(
          (r) => r.id !== state.additionalOverTenMRemittanceToDelete.id
        ),
        additionalOverTenMRemittanceToDelete: null,
      }));
    }
  }

  async function deleteAdditionalOverFifteenMRemittance() {
    const result = await postRequest(
      `/additionalOverFifteenMRemittanceTarget/deleteAdditionalOverFifteenMRemittance?id=${state.additionalOverFifteenMRemittanceToDelete.id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverFifteenMRemittanceTargets: state.additionalOverFifteenMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverFifteenMRemittanceToDelete
              .additionalOverFifteenMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverFifteenMRemittances: t.additionalOverFifteenMRemittances.filter(
                    (r) =>
                      r.id !== state.additionalOverFifteenMRemittanceToDelete.id
                  ),
                }
              : t
        ),
        additionalOverFifteenMRemittances: state.additionalOverFifteenMRemittances.filter(
          (r) => r.id !== state.additionalOverFifteenMRemittanceToDelete.id
        ),
        additionalOverFifteenMRemittanceToDelete: null,
      }));
    }
  }

  async function deleteAdditionalOverTwentyFiveMRemittance() {
    const result = await postRequest(
      `/additionalOverTwentyFiveMRemittanceTarget/deleteAdditionalOverTwentyFiveMRemittance?id=${state.additionalOverTwentyFiveMRemittanceToDelete.id}`
    );
    if (result.success) {
      setState((state) => ({
        ...state,
        additionalOverTwentyFiveMRemittanceTargets: state.additionalOverTwentyFiveMRemittanceTargets.map(
          (t) =>
            t.id ===
            state.additionalOverTwentyFiveMRemittanceToDelete
              .additionalOverTwentyFiveMRemittanceTargetId
              ? {
                  ...t,
                  additionalOverTwentyFiveMRemittances: t.additionalOverTwentyFiveMRemittances.filter(
                    (r) =>
                      r.id !==
                      state.additionalOverTwentyFiveMRemittanceToDelete.id
                  ),
                }
              : t
        ),
        additionalOverTwentyFiveMRemittances: state.additionalOverTwentyFiveMRemittances.filter(
          (r) => r.id !== state.additionalOverTwentyFiveMRemittanceToDelete.id
        ),
        additionalOverTwentyFiveMRemittanceToDelete: null,
      }));
    }
  }

  useEffect(() => {
    getRemittanceTargetsByState();
    getRemittancesByState();
    getRemittanceTargetNotesByState();
    if (selectedState === 44 || params.state === 44) {
      getAdditionalRemittanceTargetsByState();
      getAdditionalRemittancesByState();
      getAdditionalRemittanceTargetNotesByState();
      getAdditionalOverTenMRemittanceTargetsByState();
      getAdditionalOverTenMRemittancesByState();
      getAdditionalOverTenMRemittanceTargetNotesByState();
      getAdditionalOverFifteenMRemittanceTargetsByState();
      getAdditionalOverFifteenMRemittancesByState();
      getAdditionalOverFifteenMRemittanceTargetNotesByState();
      getAdditionalOverTwentyFiveMRemittanceTargetsByState();
      getAdditionalOverTwentyFiveMRemittancesByState();
      getAdditionalOverTwentyFiveMRemittanceTargetNotesByState();
    }
  }, [params.state, selectedState]);

  return (
    <React.Fragment>
      <Grid style={{ marginBottom: 17 }}>
        <Grid.Row columns={2} verticalAlign="middle" style={{ padding: 0 }}>
          {user.isOwnerPlus && (
            <React.Fragment>
              <Grid.Column floated="left">
                <div
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 20,
                    lineHeight: 1.2,
                    color: "#47517b",
                    display: "inline-block",
                    marginRight: 32,
                  })}
                >
                  Remittance Targets
                </div>
                <span
                  className={linkStyles}
                  onClick={() =>
                    setState((state) => ({ ...state, confirmExport: true }))
                  }
                >
                  <i className="fal fa-download" /> Export to excel
                </span>
                <span
                  className={linkStyles}
                  onClick={() =>
                    setState((state) => ({ ...state, confirmClearTable: true }))
                  }
                >
                  <i className="fal fa-trash" /> Clear table
                </span>
              </Grid.Column>
              <Grid.Column
                floated="right"
                className={css({ textAlign: "right" })}
              >
                <span
                  className={addNotelinkStyles}
                  onClick={() =>
                    selectedState === 44
                      ? history.push(
                          `/order-entry-instructions/new-split-remittance-target-note/${selectedState}`
                        )
                      : history.push(
                          `/order-entry-instructions/new-remittance-target-note/${selectedState}`
                        )
                  }
                >
                  Add Note
                </span>
                <Button
                  icon
                  className="blueButton"
                  onClick={() =>
                    history.push(
                      `/order-entry-instructions/edit-remittance-targets/${selectedState}`
                    )
                  }
                >
                  <i className="fal fa-pen" />
                </Button>
                <span
                  className={css({
                    fontFamily: "Fakt-Bold",
                    fontSize: 14,
                    color: "#000",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#00b8d4",
                    },
                  })}
                  onClick={() =>
                    history.push(
                      `/order-entry-instructions/edit-remittance-targets/${selectedState}`
                    )
                  }
                >
                  Edit/Set Targets
                </span>
              </Grid.Column>
            </React.Fragment>
          )}
        </Grid.Row>
      </Grid>
      {selectedState === 44 && (
        <Divider horizontal>
          <Header as="h4">0-5 M</Header>
        </Divider>
      )}
      <RemittanceTargetTable
        history={history}
        remittanceTargets={state.remittanceTargets}
        user={user}
        remittanceTargetNotes={state.remittanceTargetNotes}
        remittances={state.remittances}
        remittanceToAdd={state.remittanceToAdd}
        remittanceToEdit={state.remittanceToEdit}
        remittanceToDelete={state.remittanceToDelete}
        addRemittance={addRemittance}
        editRemittance={editRemittance}
        deleteRemittance={deleteRemittance}
        deleteRemittanceTargetNote={deleteRemittanceTargetNote}
        noteToDelete={state.noteToDelete}
        setState={setState}
        selectedState={selectedState}
      />
      {selectedState === 44 && (
        <React.Fragment>
          <Divider horizontal>
            <Header as="h4">5-10 M</Header>
          </Divider>
          <RemittanceTargetTable
            history={history}
            remittanceTargets={state.additionalRemittanceTargets}
            user={user}
            remittanceTargetNotes={state.additionalRemittanceTargetNotes}
            remittances={state.additionalRemittances}
            remittanceToAdd={state.additionalRemittanceToAdd}
            remittanceToEdit={state.additionalRemittanceToEdit}
            remittanceToDelete={state.additionalRemittanceToDelete}
            addRemittance={addAdditionalRemittance}
            editRemittance={editAdditionalRemittance}
            deleteRemittance={deleteAdditionalRemittance}
            deleteRemittanceTargetNote={deleteAdditionalRemittanceTargetNote}
            noteToDelete={state.additionalNoteToDelete}
            setState={setState}
            selectedState={selectedState}
            additional
          />
        </React.Fragment>
      )}
      {selectedState === 44 && (
        <React.Fragment>
          <Divider horizontal>
            <Header as="h4">10-15 M</Header>
          </Divider>
          <RemittanceTargetTable
            history={history}
            remittanceTargets={state.additionalOverTenMRemittanceTargets}
            user={user}
            remittanceTargetNotes={
              state.additionalOverTenMRemittanceTargetNotes
            }
            remittances={state.additionalOverTenMRemittances}
            remittanceToAdd={state.additionalOverTenMRemittanceToAdd}
            remittanceToEdit={state.additionalOverTenMRemittanceToEdit}
            remittanceToDelete={state.additionalOverTenMRemittanceToDelete}
            addRemittance={addAdditionalOverTenMRemittance}
            editRemittance={editAdditionalOverTenMRemittance}
            deleteRemittance={deleteAdditionalOverTenMRemittance}
            deleteRemittanceTargetNote={
              deleteAdditionalOverTenMRemittanceTargetNote
            }
            noteToDelete={state.additionalOverTenMNoteToDelete}
            setState={setState}
            selectedState={selectedState}
            additionalOverTenM
          />
        </React.Fragment>
      )}
      {selectedState === 44 && (
        <React.Fragment>
          <Divider horizontal>
            <Header as="h4">15-25 M</Header>
          </Divider>
          <RemittanceTargetTable
            history={history}
            remittanceTargets={state.additionalOverFifteenMRemittanceTargets}
            user={user}
            remittanceTargetNotes={
              state.additionalOverFifteenMRemittanceTargetNotes
            }
            remittances={state.additionalOverFifteenMRemittances}
            remittanceToAdd={state.additionalOverFifteenMRemittanceToAdd}
            remittanceToEdit={state.additionalOverFifteenMRemittanceToEdit}
            remittanceToDelete={state.additionalOverFifteenMRemittanceToDelete}
            addRemittance={addAdditionalOverFifteenMRemittance}
            editRemittance={editAdditionalOverFifteenMRemittance}
            deleteRemittance={deleteAdditionalOverFifteenMRemittance}
            deleteRemittanceTargetNote={
              deleteAdditionalOverFifteenMRemittanceTargetNote
            }
            noteToDelete={state.additionalOverFifteenMNoteToDelete}
            setState={setState}
            selectedState={selectedState}
            additionalOverFifteenM
          />
        </React.Fragment>
      )}
      {selectedState === 44 && (
        <React.Fragment>
          <Divider horizontal>
            <Header as="h4">25+ M</Header>
          </Divider>
          <RemittanceTargetTable
            history={history}
            remittanceTargets={state.additionalOverTwentyFiveMRemittanceTargets}
            user={user}
            remittanceTargetNotes={
              state.additionalOverTwentyFiveMRemittanceTargetNotes
            }
            remittances={state.additionalOverTwentyFiveMRemittances}
            remittanceToAdd={state.additionalOverTwentyFiveMRemittanceToAdd}
            remittanceToEdit={state.additionalOverTwentyFiveMRemittanceToEdit}
            remittanceToDelete={
              state.additionalOverTwentyFiveMRemittanceToDelete
            }
            addRemittance={addAdditionalOverTwentyFiveMRemittance}
            editRemittance={editAdditionalOverTwentyFiveMRemittance}
            deleteRemittance={deleteAdditionalOverTwentyFiveMRemittance}
            deleteRemittanceTargetNote={
              deleteAdditionalOverTwentyFiveMRemittanceTargetNote
            }
            noteToDelete={state.additionalOverTwentyFiveMNoteToDelete}
            setState={setState}
            selectedState={selectedState}
            additionalOverTwentyFiveM
          />
        </React.Fragment>
      )}
      {state.confirmClearTable && (
        <Modal
          size={"tiny"}
          open={state.confirmClearTable}
          onClose={() =>
            setState((state) => ({ ...state, confirmClearTable: false }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Clear Table</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                Please select one of the following options:
              </Form.Field>
              <Form.Field>
                <Radio
                  label="All States"
                  name="radioGroup"
                  checked={state.clearAllRemittances}
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      clearAllRemittances: true,
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={
                    states.length > 0 &&
                    states.find((s) => s.id === selectedState).name
                  }
                  name="radioGroup"
                  checked={!state.clearAllRemittances}
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      clearAllRemittances: false,
                    }))
                  }
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              onClick={() => {
                setState((state) => ({
                  ...state,
                  confirmClearTable: false,
                }));
              }}
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={() => {
                setState((state) => ({
                  ...state,
                  confirmClearTable: false,
                }));
                clearRemittances();
              }}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Clear
            </Button>
          </Modal.Actions>
        </Modal>
      )}
      {state.confirmExport && (
        <Modal
          size={"tiny"}
          open={state.confirmExport}
          onClose={() =>
            setState((state) => ({ ...state, confirmExport: false }))
          }
          dimmer="inverted"
        >
          <Modal.Header>Export To Excel</Modal.Header>
          <Modal.Content>
            <Form>
              <Form.Field>
                Please select one of the following options:
              </Form.Field>
              <Form.Field>
                <Radio
                  label="All States"
                  name="radioGroup"
                  checked={state.exportAllRemittances}
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      exportAllRemittances: true,
                    }))
                  }
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label={
                    states.length > 0 &&
                    states.find((s) => s.id === selectedState).name
                  }
                  name="radioGroup"
                  checked={!state.exportAllRemittances}
                  onClick={() =>
                    setState((state) => ({
                      ...state,
                      exportAllRemittances: false,
                    }))
                  }
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              onClick={() => {
                setState((state) => ({
                  ...state,
                  confirmExport: false,
                }));
              }}
              style={{
                backgroundColor: "#fff",
                border: "solid 1px #e5e5ea",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={() => {
                setState((state) => ({
                  ...state,
                  confirmExport: false,
                }));
                downloadRemittanceExport();
              }}
              style={{ backgroundColor: "#1f2b5e" }}
            >
              Export
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </React.Fragment>
  );
}

export default withRouter(React.memo(RemittanceTargets));
